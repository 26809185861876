export const ButtonStyles = {
  Base: 'inline-flex items-center justify-center py-2 px-4 rounded-md shadow-sm text-sm font-medium border focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50',
  Blue: 'text-white bg-sky-800 hover:bg-sky-700 focus:ring-sky-500 border-transparent',
  White: 'text-sky-800 hover:text-white bg-white hover:bg-sky-700 border-sky-800 focus:ring-sky-500',
  Red: 'text-white bg-red-600 hover:bg-red-700 focus:ring-sky-500 border-transparent',
  Teal: 'text-white bg-teal-600 hover:bg-teal-700 focus:ring-sky-500 border-transparent',
}

export interface ButtonWithOptionStyle {
  button: string,
  menu: string,
  menuButton: string
}

export const ButtonWithOptionStyles: {
  Blue: ButtonWithOptionStyle,
  White: ButtonWithOptionStyle,
  Red: ButtonWithOptionStyle,
  Teal: ButtonWithOptionStyle
} = {
  Blue: {
    button: ButtonStyles.Blue,
    menu: "bg-sky-800",
    menuButton: "hover:bg-sky-700"
  },
  White: {
    button: ButtonStyles.White,
    menu: "border-sky-800",
    menuButton: "hover:text-white hover:bg-sky-700 border-sky-800 text-sky-800"
  },
  Red: {
    button: ButtonStyles.Red,
    menu: "bg-red-600",
    menuButton: "hover:bg-red-700"
  },
  Teal: {
    button: ButtonStyles.Teal,
    menu: "bg-teal-600",
    menuButton: "hover:bg-teal-700"
  }
}
