import { PersonPage } from '@/pages/Person'
import { userState } from '@/recoil/atoms/auth'
import { IJobCandidate, IRecruitingStep } from '@/recoil/types'
import { AccountRoles, jobCandidatesService } from '@/services'
import { IJobCandidateDocument } from '@/services/jobcandidates.service'
import { BookOpenIcon, MinusIcon, OfficeBuildingIcon, PlusIcon, SpeakerphoneIcon, XCircleIcon } from '@heroicons/react/outline'
import { BookOpenIcon as BookOpenIconSolid, OfficeBuildingIcon as OfficeBuildingIconSolid, SpeakerphoneIcon as SpeakerphoneIconSolid } from '@heroicons/react/solid'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { memo } from 'react'
import { toast } from 'react-toastify'
import { useRecoilValue } from 'recoil'
import BlueButton from '../buttons/BlueButton'
import RedButtonWithOptions from '../buttons/RedButtonWithOptions'
import TealButton from '../buttons/TealButton'
import WhiteButton from '../buttons/WhiteButton'
import JobCandidateFileForm from '../files/JobCandidateFileForm'
import HeadingsH2 from '../headings/HeadingsH2'
import { LoadingInside } from '../layout'
import Modal from '../modals/Modal'
import { RecruitingCandidateDocument } from './RecruitingCandidateDocument'

export interface RecruitingProcessStepDetailProps {
  steps: IRecruitingStep[],
  step: IRecruitingStep,
  candidates: IJobCandidate[],
  closeDetail: () => void,
  reflectCandidateUpdate: (stepId: number, candidateId: number) => void
}

export const RecruitingProcessStepDetail: FC<RecruitingProcessStepDetailProps> = memo(function RecruitingProcessStepDetail({
  steps,
  step,
  candidates,
  closeDetail,
  reflectCandidateUpdate
}) {
  const userData = useRecoilValue(userState)
  const [selectedCandidate, setSelectedCandidate] = useState<IJobCandidate>()
  const [selectedCandidateAdditionalInfo, setSelectedCandidateAdditionalInfo] = useState('')
  const [selectedCandidateDocuments, setSelectedCandidateDocuments] = useState<IJobCandidateDocument[]>([])
  const [fetchingCandidateDocuments, setFetchingCandidateDocuments] = useState(true)
  const [loadingAction, setLoadingAction] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [addDocumentDialogOpen, setAddDocumentDialogOpen] = useState(false)

  useEffect(() => {
    if (candidates.length > 0) {
      setSelectedCandidate(candidates[0])
    }
  }, [step, candidates])

  const nextStep = useMemo(() => steps.find((step2) => step2.order === step.order + 1), [steps, step])
  const rejectedStep = useMemo(() => steps.find((step) => step.type === 'Rejected'), [steps])
  const rejectedAndDeniedStep = useMemo(() => steps.find((step) => step.type === 'Rejected_and_denied'), [steps])
  const hiredStep = useMemo(() => steps.find((step) => step.type === 'Hired'), [steps])
  const initialStep = useMemo(() => steps.find((step) => step.order === 0), [steps])

  const groupings = useMemo(() => {
    const _groupings: { [key: string]: IJobCandidate[] } = {}
    for (const candidate of candidates) {
      if (!candidate.candidate || candidate.created_by.person.id === candidate.candidate.id) {
        _groupings['NO:No Recruiter'] = [...(_groupings['NO:No Recruiter'] ?? []), candidate]
      } else if (candidate.created_by.company) {
        _groupings[`CO:${candidate.created_by.company.name}`] = [...(_groupings[`CO:${candidate.created_by.company.name}`] ?? []), candidate]
      } else {
        const recruiterName = `${candidate.created_by.person.first_name} ${candidate.created_by.person.last_name}`
        _groupings[`RE:${recruiterName}`] = [...(_groupings[`RE:${recruiterName}`] ?? []), candidate]
      }
    }
    return _groupings
  }, [candidates])

  const rejectCandidate = useCallback(() => {
    if (!selectedCandidate) {
      return
    }

    if (rejectedStep) {
      setLoadingAction(true)
      jobCandidatesService.updateStep(selectedCandidate.id, rejectedStep.id).then(() => {
        reflectCandidateUpdate(rejectedStep.id, selectedCandidate.id)
        setLoadingAction(false)
        toast.info("Rejected candidate")
      }).catch(() => {
        toast.error("Failed to reject candidate")
      })
    }
  }, [reflectCandidateUpdate, selectedCandidate, rejectedStep])

  const rejectAndDenyCandidate = useCallback(() => {
    if (!selectedCandidate) {
      return
    }

    if (rejectedAndDeniedStep) {
      setLoadingAction(true)
      jobCandidatesService.updateStep(selectedCandidate.id, rejectedAndDeniedStep.id).then(() => {
        reflectCandidateUpdate(rejectedAndDeniedStep.id, selectedCandidate.id)
        setLoadingAction(false)
        toast.info("Rejected (and denied) candidate")
      }).catch(() => {
        toast.error("Failed to reject candidate")
      })
    }
  }, [reflectCandidateUpdate, selectedCandidate, rejectedAndDeniedStep])

  const progressCandidate = useCallback(() => {
    if (!selectedCandidate) {
      return
    }

    if (nextStep) {
      setLoadingAction(true)
      jobCandidatesService.updateStep(selectedCandidate.id, nextStep.id).then(() => {
        reflectCandidateUpdate(nextStep.id, selectedCandidate.id)
        setLoadingAction(false)
        toast.info("Moved candidate to next step")
      }).catch(() => {
        toast.error("Failed to move candidate")
      })
    }
  }, [reflectCandidateUpdate, selectedCandidate, nextStep])

  const hireCandidate = useCallback(() => {
    if (!selectedCandidate) {
      return
    }

    if (hiredStep) {
      setLoadingAction(true)
      jobCandidatesService.updateStep(selectedCandidate.id, hiredStep.id).then(() => {
        reflectCandidateUpdate(hiredStep.id, selectedCandidate.id)
        setLoadingAction(false)
        toast.info("Hired candidate")
      }).catch(() => {
        toast.error("Failed to hire candidate")
      })
    }
  }, [reflectCandidateUpdate, selectedCandidate, hiredStep])

  const unrejectCandidate = useCallback(() => {
    if (!selectedCandidate) {
      return
    }

    if (initialStep) {
      setLoadingAction(true)
      jobCandidatesService.updateStep(selectedCandidate.id, initialStep.id).then(() => {
        reflectCandidateUpdate(initialStep.id, selectedCandidate.id)
        setLoadingAction(false)
        toast.info("Unrejected candidate")
      }).catch(() => {
        toast.error("Failed to unreject candidate")
      })
    }
  }, [reflectCandidateUpdate, selectedCandidate, initialStep])

  const updateSearch = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchFilter((e.target as HTMLInputElement).value)
  }

  const filterCandidatesBySearch = useCallback((candidates: IJobCandidate[]) => {
    return candidates.filter((candidate) =>
      (candidate.candidate ? `${candidate.candidate.first_name} ${candidate.candidate.last_name}` : (candidate.candidate_name ?? ''))
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(searchFilter.toLowerCase().replace(/\s+/g, ''))
    )
  }, [searchFilter])

  const collapseAll = () => {
    for (const element of document.getElementsByClassName('grouping-input')) {
      (element as HTMLInputElement).checked = false
    }
  }

  const expandAll = () => {
    for (const element of document.getElementsByClassName('grouping-input')) {
      (element as HTMLInputElement).checked = true
    }
  }

  const handleUpdateCandidateAdditionalInfo = useCallback(() => {
    if (!selectedCandidate) {
      return
    }

    jobCandidatesService.updateAdditionalInfo(selectedCandidate.id, selectedCandidateAdditionalInfo).then(() => {
      selectedCandidate.additional_info = selectedCandidateAdditionalInfo
    }).catch(() => {
      toast.error("Failed to update candidate's additional info")
    })
  }, [selectedCandidate, selectedCandidateAdditionalInfo])

  const fetchCandidateDocuments = (candidateId: number) => {
    setFetchingCandidateDocuments(true)
    jobCandidatesService.fetchDocuments(candidateId, null).then((res) => {
      setSelectedCandidateDocuments(res.data)
      setFetchingCandidateDocuments(false)
    })
  }

  useEffect(() => {
    setSelectedCandidateAdditionalInfo(selectedCandidate?.additional_info ?? '')
    selectedCandidate && fetchCandidateDocuments(selectedCandidate?.id)
  }, [selectedCandidate])

  const addCandidateDocument = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    jobCandidatesService.uploadDocument(
      selectedCandidate!.id,
      values['type'],
      values['file']
    ).then(({success, data}) => {
      if (success) {
        toast.success('Successfully added document')
        setAddDocumentDialogOpen(false)
        fetchCandidateDocuments(selectedCandidate!.id)
      } else {
        console.error(data)
        toast.error('Failed to add document')
      }
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to add document')
      setSubmitting(false)
    })
  }

  const deleteDocument = (fileId: number) => {
    if (!selectedCandidate) {
      return
    }

    jobCandidatesService.deleteDocument(selectedCandidate.id, fileId).then((res) => {
      if (res.success) {
        toast.success('Successfully deleted document')
        fetchCandidateDocuments(selectedCandidate.id)
      } else {
        toast.error('Failed to delete document')
      }
    })
  }

  return (
    <>
      <div className="flex justify-between mt-4 mb-2 mx-4 sm:mx-0">
        <div>
          <div className="flex items-center gap-2">
            <div className="w-4 h-4" style={{ backgroundColor: `#${step.color}` }}></div>
            <HeadingsH2 heading={`${step.name}`} className="truncate" />
          </div>
          <div className="text-gray-500">{step.description}</div>
        </div>
        <XCircleIcon className="h-6 w-6 text-teal-900 cursor-pointer flex-shrink-0 mt-1" aria-hidden="true" onClick={() => closeDetail()} />
      </div>
      {candidates.length > 0
        ? <div className="mx-4 sm:mx-0">
            <div className="align-top inline-block w-full sm:w-2/6 shadow rounded-md bg-white">
              <div className="m-2">
                <input
                  type="text"
                  placeholder="Search by name"
                  className="shadow-sm text-sm rounded-md border-gray-300 focus:border-teal-600 focus:ring-teal-600 w-full"
                  onInput={updateSearch}
                  value={searchFilter}
                />
              </div>
              <div className="mx-2 space-x-1">
                <WhiteButton
                  title="Collapse All"
                  className="px-2 py-1 text-xs"
                  onClick={collapseAll}
                />
                <WhiteButton
                  title="Expand All"
                  className="px-2 py-1 text-xs"
                  onClick={expandAll}
                />
              </div>
              <div className="divide-y divide-gray-200 overflow-y-auto max-h-192">
                {Object.entries(groupings).sort((a, b) => a[0].localeCompare(b[0])).map((grouping) => (
                  <div className="py-2" key={`${grouping[0]} container`}>
                    <input type="checkbox" id={grouping[0]} hidden={true} aria-hidden={true} className="grouping-input peer hidden" defaultChecked={true} />
                    <label htmlFor={grouping[0]} key={`${grouping[0]} title 1`} className="hidden px-4 pt-2 pb-2 cursor-pointer font-bold peer-checked:flex items-center justify-between gap-1 text-gray-500 text-sm">
                      <div className="flex gap-1 items-center">
                        {grouping[0].startsWith('NO:') && <BookOpenIconSolid className="w-4 h-4" aria-hidden="true" />}
                        {grouping[0].startsWith('CO:') && <OfficeBuildingIconSolid className="w-4 h-4" aria-hidden="true" />}
                        {grouping[0].startsWith('RE:') && <SpeakerphoneIconSolid className="w-4 h-4" aria-hidden="true" />}
                        <span>{grouping[0].slice(3)} ({filterCandidatesBySearch(grouping[1]).length})</span>
                      </div>
                      <MinusIcon className="h-3 w-3 text-gray-800" aria-hidden="true" />
                    </label>
                    <label htmlFor={grouping[0]} key={`${grouping[0]} title 2`} className="flex px-4 pt-2 pb-2 cursor-pointer font-medium peer-checked:hidden items-center justify-between gap-1 text-gray-500 text-sm">
                      <div className="flex gap-1 items-center">
                        {grouping[0].startsWith('NO:') && <BookOpenIcon className="w-4 h-4" aria-hidden="true" />}
                        {grouping[0].startsWith('CO:') && <OfficeBuildingIcon className="w-4 h-4" aria-hidden="true" />}
                        {grouping[0].startsWith('RE:') && <SpeakerphoneIcon className="w-4 h-4" aria-hidden="true" />}
                        <span>{grouping[0].slice(3)} ({filterCandidatesBySearch(grouping[1]).length})</span>
                      </div>
                      <PlusIcon className="h-3 w-3 text-gray-800" aria-hidden="true" />
                    </label>
                    <div className="hidden peer-checked:block">
                      <div key={`${grouping[0]} content`} className="px-4 space-y-2">
                        {filterCandidatesBySearch(grouping[1]).map((candidate) => (
                          <div
                            key={candidate.id}
                            className="cursor-pointer"
                            onClick={() => setSelectedCandidate(candidate)}
                          >
                            <div className={`text-teal-600 truncate font-${selectedCandidate?.id === candidate.id ? 'bold' : 'medium'}`}>{(candidate.candidate ? `${candidate.candidate.first_name} ${candidate.candidate.last_name}` : candidate.candidate_name)}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="align-top inline-block w-full sm:w-4/6 sm:pl-4 pt-4 sm:pt-0">
              {selectedCandidate && <div className="shadow rounded-md bg-white p-4">
                {step.can_manage_candidates && <div className="flex gap-2">
                  {(step.type !== 'Rejected' && step.type !== 'Rejected_and_denied') && <RedButtonWithOptions
                    title="Reject"
                    onClick={() => rejectCandidate()}
                    options={[ { id: 'reject_and_deny', title: "Reject & Deny" } ]}
                    onOptionClick={() => rejectAndDenyCandidate()}
                    disabled={loadingAction}
                  />}
                  {(step.type === 'Rejected' || step.type === 'Rejected_and_denied') && <BlueButton
                    title="Unreject"
                    onClick={() => unrejectCandidate()}
                    disabled={loadingAction}
                  />}
                  {(nextStep?.id !== hiredStep?.id && step.type === 'Normal') && <BlueButton
                    title="Move Next"
                    onClick={() => progressCandidate()}
                    disabled={loadingAction}
                  />}
                  {((userData?.role === AccountRoles.HIRING_MANAGER && nextStep?.id === hiredStep?.id) || userData?.role === AccountRoles.COMPANY) && <TealButton
                    title="Hire"
                    onClick={() => hireCandidate()}
                    disabled={loadingAction}
                  />}
                </div>}
                {selectedCandidate.candidate
                ? <PersonPage id={selectedCandidate.candidate.id} disableBack={true} />
                : <div className="mt-4">
                  <HeadingsH2 heading={selectedCandidate.candidate_name ?? 'Austin Villee'} />
                  <div className="text-sm italic text-gray-500 mt-2">This candidate was added as an external candidate and doesn't have a profile on the site</div>
                </div>}
                <div className="border-t border-gray-200 pt-5">
                  <label htmlFor={'additional_info'} className="block text-sm font-medium text-gray-700">
                    Candidate Additional Info
                  </label>
                  <div className="mt-2">
                    <textarea
                      id={'additional_info'}
                      name={'additional_info'}
                      className="block w-full shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:text-sm border-gray-300 rounded-md"
                      onBlur={() => handleUpdateCandidateAdditionalInfo()}
                      onChange={(e) => setSelectedCandidateAdditionalInfo(e.target.value)}
                      value={selectedCandidateAdditionalInfo}
                    />
                  </div>
                </div>
                <div className="border-t border-gray-200 pt-5">
                  {!fetchingCandidateDocuments ? <>
                    <Modal
                      title="Add Document"
                      open={addDocumentDialogOpen}
                      setOpen={setAddDocumentDialogOpen}
                      hasForm={true}
                    >
                      <JobCandidateFileForm
                        handleSubmit={addCandidateDocument}
                        files={selectedCandidateDocuments.filter(x => x.type === 'jobcandidate')}
                      />
                    </Modal>
                    <label htmlFor={'files'} className="block text-sm font-medium text-gray-700">
                      Candidate Files
                    </label>
                    <div className="mt-2">
                      {selectedCandidateDocuments.length > 0 && <div className="flex flex-wrap gap-2 mb-3">
                        {selectedCandidateDocuments.map((doc) => <RecruitingCandidateDocument
                          key={doc.id}
                          document={doc}
                          deleteDocument={() => deleteDocument(doc.id)}
                        />)}
                      </div>}
                      {step.can_manage_candidates && selectedCandidateDocuments.filter(x => x.type === 'jobcandidate').length < 3 && <TealButton
                        title='Add Document'
                        onClick={() => setAddDocumentDialogOpen(true)}
                      />}
                    </div>
                  </> : <LoadingInside />}
                </div>
              </div>}
            </div>
          </div>
      : <div className="p-5 bg-white shadow rounded-md text-center mx-4 sm:mx-0">There aren't any candidates in this step</div>}
    </>
  )
})
