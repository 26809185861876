import { classNames } from '../../lib/helpers'
import BaseButton, { IBaseButtonProps } from './BaseButton'

import { ButtonStyles } from './styles'

const TealButton = (props: IBaseButtonProps) => {
  return (
    <BaseButton
      {...props}
      className={classNames(
        props.className,
        ButtonStyles.Teal
      )}
    />
  )
}

export default TealButton
