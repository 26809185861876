import { useState } from "react"
import { toast } from "react-toastify"
import { IUser } from "../../../recoil/types"
import { accountService } from "../../../services"
import Modal from "../../modals/Modal"
import ChangePasswordForm from "./forms/ChangePasswordForm"
import SetPasswordForm from "./forms/SetPasswordForm"
import BlueButton from '../../buttons/BlueButton'
import HeadingsH3 from '../../../components/headings/HeadingsH3'
import NotificationPreferencesForm from "./forms/NotificationPreferencesForm"
import MFASettings from "./MFASettings"
import PayoutAccount from "./PayoutAccount"
import RedButton from "@/components/buttons/RedButton"
import Confirm from "@/components/modals/Confirm"
import { useHistory } from "react-router-dom"

interface ISettingsProps {
  user: IUser
}

const Settings = ({ user }: ISettingsProps) => {
  const hasUsablePassword = user.has_password
  const facebookAccount = user.facebook_uid !== null
  const googleAccount = user.google_uid !== null

  const [setPasswordModalOpen, setSetPasswordModalOpen] = useState(false)
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false)
  const [deletingAccount, setDeletingAccount] = useState(false)
  const [isDeleteAccountConfirmOpen, setIsDeleteAccountConfirmOpen] = useState(false)
  const [requestingAccountData, setRequestingAccountData] = useState(false)
  const history = useHistory()

  const handleSetPassword = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    accountService.setPassword(values.password1, values.password2).then(({success, data}) => {
      if (success) {
        toast.success(`Password set successfully`)
      } else {
        console.error(data)
        toast.error(data)
      }
      setSetPasswordModalOpen(false)
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error(`${error}`)
      setSubmitting(false)
    })
  }

  const handleChangePassword = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    accountService.changePassword(user.email, values.current_password, values.password1, values.password2).then(({success, data}) => {
      if (success) {
        toast.success(`Password changed successfully`)
      } else {
        console.error(data)
        toast.error(data)
      }
      setChangePasswordModalOpen(false)
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error(`${error}`)
      setSubmitting(false)
    })
  }

  const deleteAccount = () => {
    setDeletingAccount(true)
    accountService.deleteAccount().then(() => {
      setDeletingAccount(false)
      toast.success('We have received your request to delete your account. This action will be irreversible after 14 days. You will receive an email with a link to undo deletion until then')
      history.push('/logout')
    }).catch((error) => {
      setDeletingAccount(false)
      console.error(error)
      toast.error('Failed to request account deletion')
    })
  }
  
  const requestAccountData = () => {
    setRequestingAccountData(true)
    accountService.requestAccountData().then(() => {
      setRequestingAccountData(false)
      toast.success('We have received your request for your account data. You will receive an email with the data after the request has been processed')
    }).catch((error) => {
      setRequestingAccountData(false)
      if (error.code === 429) {
        toast.warning('You have already requested account data once within the past 24hr')
      } else {
        console.error(error)
        toast.error('Failed to request account data')
      }
    })
  }

  return (
    <>
      <Modal
        title="Set Password"
        open={setPasswordModalOpen}
        setOpen={setSetPasswordModalOpen}
        hasForm={true}
      >
        <SetPasswordForm
          handleSubmit={handleSetPassword}
          onCancel={() => setSetPasswordModalOpen(false)}
        />
      </Modal>
      <Modal
        title="Change Password"
        open={changePasswordModalOpen}
        setOpen={setChangePasswordModalOpen}
        hasForm={true}
      >
        <ChangePasswordForm
          handleSubmit={handleChangePassword}
          onCancel={() => setChangePasswordModalOpen(false)}
        />
      </Modal>
      <Confirm
        title="Are you sure you want to delete your account?"
        confirm_text="Delete"
        info_text="This action is irreversible after 14 days, until which you can cancel the operation via an email that will be sent to you."
        onConfirm={() => deleteAccount()}
        isOpen={isDeleteAccountConfirmOpen}
        setIsOpen={setIsDeleteAccountConfirmOpen}
      />
      <div className="divide-y divide-gray-200 lg:col-span-9">
        {/* Profile section */}
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
          <div>
            <HeadingsH3 heading="Security"/>
            <p className="mt-1 text-sm text-gray-500">
              Change password, connect or disconnect social accounts.
            </p>
            {(facebookAccount || googleAccount) &&
              <p className="mt-1 text-sm text-gray-500">
                Your account is connected through{" "}
                {facebookAccount && <strong>Facebook</strong>}
                {googleAccount && <strong>Google</strong>}
              </p>
            }
          </div>

          <div className="mt-6 flex flex-col lg:flex-row">
            <div>
              <h3 className="block text-sm font-medium text-gray-700">
                Password
              </h3>
              {
                hasUsablePassword ?
                  <BlueButton
                    type="button"
                    title="Change Password"
                    className="mt-1 w-full"
                    onClick={() => setChangePasswordModalOpen(true)}
                  />
                  :
                  <BlueButton
                    type="button"
                    title="Set Password"
                    className="mt-1 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sky-800 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    onClick={() => setSetPasswordModalOpen(true)}
                  />
              }
            </div>
          </div>
        </div>

        <div className="py-6 divide-y divide-gray-200">
          <div className="px-4 sm:px-6">
            <div>
              <HeadingsH3 heading="Multi-Factor Authentication"/>
              <p className="mt-1 text-sm text-gray-500">
                Setup or remove multi-factor authentication for sign-in
              </p>
            </div>
            <ul className="mt-5">
              <MFASettings />
            </ul>
          </div>
        </div>

        {!user.company && <div className="py-6 divide-y divide-gray-200">
          <div className="px-4 sm:px-6">
            <div>
              <HeadingsH3 heading="Payout Account"/>
              <p className="mt-1 text-sm text-gray-500">
                Link a Stripe account for payouts
              </p>
            </div>
            <ul className="mt-5">
              <PayoutAccount />
            </ul>
          </div>
        </div>}

        <div className="py-6 divide-y divide-gray-200">
          <div className="px-4 sm:px-6">
            <div>
              <HeadingsH3 heading="Notifications"/>
              <p className="mt-1 text-sm text-gray-500">
                Notify me on these changes and events...
              </p>
            </div>
            <ul className="mt-5">
              <NotificationPreferencesForm />
            </ul>
          </div>
        </div>

        <div className="py-4 px-4 sm:px-6 flex gap-4">
          <BlueButton
            title="Request Account Data"
            onClick={() => requestAccountData()}
            disabled={requestingAccountData}
          />
          <RedButton
            title="Delete Account"
            onClick={() => setIsDeleteAccountConfirmOpen(true)}
            disabled={deletingAccount}
          />
        </div>
      </div>
    </>
  )
}

export default Settings
