import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '../../../buttons/BlueButton'
import { TextInputField } from  '@/components/forms/fields'

const RemoveMFAMethodSchema = Yup.object().shape({
  code: Yup.string().required('Required')
})

interface IRemoveMFAMethodFormProps {
  handleSubmit: any,
  onCancel?: Function | null,
}

export default function RemoveMFAMethodForm({
  handleSubmit,
  onCancel = null
}: IRemoveMFAMethodFormProps) {
  return (
    <Formik
      initialValues={{
        code: "",
      }}
      validationSchema={RemoveMFAMethodSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="mb-4">Please enter the MFA code sent to you to confirm MFA removal.</div>
          <TextInputField
            fieldName="code"
            label="MFA Code"
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <BlueButton
              type="submit"
              title={isSubmitting ? "Removing..." : "Remove"}
              className="w-full transition duration-150 ease-in-out"
              disabled={isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
