import { fetch, post } from './base.service'
import * as serverConfig from './server-config'
import { IListRetrieveService, IServicePaginatedListResult, _notImplemented } from './types'
import { withServiceParamsInUrl } from './utils'

async function lastViewedAll(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.profile.tracking.combined}${urlOrQueryString}`
  return await fetch(url, cancelToken)
}

async function lastViewedJobs(cancelToken: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.tracking.jobs}`
  return await fetch(url, cancelToken)
}

async function trackJobView(jobId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.tracking.jobs}`
  const values = {
    job: jobId
  }
  return await post(url, values)
}

async function lastViewedCompanies(cancelToken: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.tracking.jobs}`
  return await fetch(url, cancelToken)
}

async function trackCompanyView(companyId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.tracking.company}`
  const values = {
    company: companyId
  }
  return await post(url, values)
}

async function lastViewedPeople(cancelToken: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.tracking.person}`
  return await fetch(url, cancelToken)
}

async function trackPersonView(personId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.tracking.person}`
  const values = {
    person: personId
  }
  return await post(url, values)
}

export const trackingService = {
  lastViewedAll,
  lastViewedJobs,
  trackJobView,
  lastViewedCompanies,
  trackCompanyView,
  lastViewedPeople,
  trackPersonView
}

export const allTrackingService: IListRetrieveService = {
  list: lastViewedAll,
  detail: _notImplemented
}