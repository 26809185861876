import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'

import { userState } from '../recoil/atoms/auth'
import { accountService, peopleService } from '../services'

import { Layout } from '../components/layout'
import Person from '../components/people/Person'
import InfiniteList, { withGlobalFilter } from '../components/layout/list/InfiniteList'
import GlobalFilter from '../components/layout/list/GlobalFilter'
import { withCollapse } from '../components/utils'
import HeadingsH2 from '../components/headings/HeadingsH2'
import { useCallback } from 'react'

const InfiniteListWithGlobalFilter = withGlobalFilter(InfiniteList)
const FilterWithCollapse = withCollapse(GlobalFilter)


const People = () => {
  const [userData, setUserData] = useRecoilState<any>(userState)
  const aside = <FilterWithCollapse />

  const connectionStatus = useCallback(
    (connection: any) => {
      if (connection.id === userData?.person?.id) {
        return 'self'
      }
      
      const foundConnection = userData?.person?.connections.find((userConn: any) => {
        return userConn.id === connection.id
      })
  
      if (!foundConnection) {
        return 'disconnected'
      } else {
        switch (foundConnection.status) {
          case 'accepted': return 'connected'
          case 'declined': return 'disconnected'
          case 'pending': return 'pending'
          default: return 'disconnected'    
        }
      }
    },
    [userData]
  )

  const connectTo = (personId: number) => {
    accountService.createConnection(personId).then((result) => {
      if (result.success) {
        const newUserData = Object.assign({}, userData,
          {
            person: Object.assign({}, userData.person, {
              connections: userData.person.connections.concat({ ...result.data, status: 'pending' })
            })
          }
        )
        setUserData(newUserData)
        toast.success('Connection invite sent')
      }
    }).catch((error) => {
      if (error.code === 409) {
        accountService.profile().then((res) => setUserData(res.data))
        toast.info('You are already connected with this person')
      } else {
        toast.error('Failed to send connection invite')
      }
    })
  }

  const disconnectFrom = (personId: number) => {
    accountService.deleteConnection(personId).then((result) => {
      if (result.success) {
        accountService.profile().then((res) => setUserData(res.data))
        toast.warning('You are now disconnected')
      }
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to disconnect from person')
    })
  }

  return (
    <Layout title="People" auth={true} aside={aside}>
      <HeadingsH2 heading="People list"/>
      <div className="mt-4">
        <InfiniteListWithGlobalFilter
          service={peopleService}
          onServiceError={toast.error}
          itemComponent={Person}
          itemsProps={{ onConnectClick: connectTo, onDisconnetClick: disconnectFrom, connectionStatus: connectionStatus }}
        />
        </div>
    </Layout>
  )
}

export default People
