import { toast } from "react-toastify"
import { useRecoilValue } from 'recoil'
import { userState } from '@/recoil/atoms/auth'
import { notesService } from "@/services"
import Note from "./Note"
import NoteForm from "./NoteForm"
import PaginatedCRUDList from "@/components/layout/list/PaginatedCRUDList"


const NotesAllTab = ({ serviceParams, pageSize = 10 }: any) => {
  const userData = useRecoilValue<any>(userState)

  return (
    <PaginatedCRUDList
      service={notesService}
      serviceParams={serviceParams}
      onServiceError={toast.error}
      object={{}}
      itemComponent={Note}
      itemsProps={{ showLink: true, showRelated: true, userData }}
      formComponent={NoteForm}
      addButtonTitle={null}
      getUpdateTitle={(object: any) => `Update: ${object?.title}`}
      hasUpdate={false}
      formAlterValues={null}
      onUpdateSuccess={() => toast.success('Update successful')}
      onUpdateError={() => toast.error('Error updating')}
      pageSize={pageSize}
    />
  )
}

export default NotesAllTab
