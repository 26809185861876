import { ReactComponent as Logo } from './logo.svg';
import HeadingsH2 from '../../headings/HeadingsH2'

export default function Header({ title, info }: { title: string, info: string }) {
  return (
    <div className="flex flex-col item-center">
      <div className="p-10 bg-teal-600 -ml-3 -mr-3 sm:ml-0 sm:mr-0">
        <Logo
          className="w-12 mx-auto"
        />
      </div>
      <div className="p-5 text-center">
        <HeadingsH2 heading={title} />
        <p className="mt-1 max-w-2xl text-sm text-gray-500 mx-auto">
          {info}
        </p>
      </div>
    </div>
  )
}
