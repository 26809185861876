/* This example requires Tailwind CSS v2.0+ */
import { toast } from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import { INotification, INotificationAction } from '@/recoil/types'
import NotificationInner from './NotificationInner';
import {
  allNotifications as allNotificationsAtom,
  pendingNotifications as pendingNotificationsAtom
} from '../../recoil/atoms/notification'
import { notificationService } from '../../services/notification.service'

export default function NotificationListItem({ object, onAction }: { object: INotification, onAction?: any }) {
  const [
    allNotifications,
    setAllNotifications
  ] = useRecoilState<INotification[]>(allNotificationsAtom)

  const [
    pendingNotifications,
    setPendingNotifications
  ] = useRecoilState<INotification[]>(pendingNotificationsAtom)
  
  const dismiss = async (action: INotificationAction) => {
    const notifMapFunc = (notif: INotification) => {
      return (
        notif.id === object.id
        ? (
          action.text === 'Dismiss'
          ? { ...notif, has_seen: true }
          : { ...notif, is_pending: false, has_seen: true }
        )
        : notif
      )
    }
    await Promise.all([
      notificationService.markAsSeen(object.id),
      ...(action.text !== 'Dismiss' ? [notificationService.resolvePending(object.id)] : [])
    ])
    setPendingNotifications(action.text === 'Dismiss' ? pendingNotifications.map(notifMapFunc) : pendingNotifications.filter((notif) => notif.id !== object.id))
    setAllNotifications(allNotifications.map(notifMapFunc))
    toast.remove()
  }

  return (
    <div className="flex">
      <NotificationInner
        notification={object}
        onAction={(action) => {
          dismiss(action).then(() => {
            onAction && onAction()
          })
        }}
      />
    </div>
  )
}
