import { fetch } from './base.service'
import { IListRetrieveService, IServicePaginatedListResult, _notImplemented } from './types'
import * as serverConfig from './server-config'
import { withServiceParamsInUrl } from './utils'


async function list(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  // append params
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.reports.jobCandidates}${urlOrQueryString}`
  return await fetch(url, cancelToken)
}

async function timeToFill(cancelToken: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.reports.timeToFill}`
  return await fetch(url, cancelToken)
}


export const reportJobCandidateService: IListRetrieveService = {
  list,
  detail: _notImplemented
}

export const reportsService = {
  timeToFill
}
