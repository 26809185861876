import { toast } from 'react-toastify'

import { jobsService } from '../services'

import { Layout } from '../components/layout'
import Job from '../components/jobs/Job'
import InfiniteList, { withGlobalFilter } from '../components/layout/list/InfiniteList'
import GlobalFilter from '../components/layout/list/GlobalFilter'
import { withCollapse } from '../components/utils'
import HeadingsH2 from '../components/headings/HeadingsH2'


const InfiniteListWithGlobalFilter = withGlobalFilter(InfiniteList)
const FilterWithCollapse = withCollapse(GlobalFilter)

const Jobs = () => {

  const aside = <FilterWithCollapse />

  return (
    <Layout title="Jobs" auth={false} aside={aside}>
      <HeadingsH2 heading="Jobs list"/>
      <div className="mt-4">
        <InfiniteListWithGlobalFilter
          service={jobsService}
          onServiceError={toast.error}
          itemComponent={Job}
          itemsProps={{ link: true }}
        />
      </div>
    </Layout>
  )
}

export default Jobs
