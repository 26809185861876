import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '@/components/buttons/BlueButton'
import { TextInputField } from  '@/components/forms/fields'

const EditProcessNameSchema = Yup.object().shape({
  name: Yup.string().required('Name is required')
})

export default function EditProcessNameForm({ handleSubmit, name }: { handleSubmit: any, name: string }) {
  return (
    <Formik
      initialValues={{
        name
      }}
      validationSchema={EditProcessNameSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <TextInputField
            fieldName="name"
            label="Name"
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <BlueButton
              type="submit"
              title={isSubmitting ? "Updating..." : "Update"}
              className="w-full transition duration-150 ease-in-out"
              disabled={isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
