import { homeService } from '../../services'

import ConnectionsList from './connections/ConnectionsList'
import CarouselList from '../layout/list/CarouselList'

const ConnectionsRecommendations = function () {
  return (
    <CarouselList
      noResultsText="Please complete your profile to receive job recommendations."
      itemComponent={ConnectionsList}
      serviceMethod={homeService.recommendedConnections}
    />
  )
}

export default ConnectionsRecommendations
