import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '@/components/buttons/BlueButton'
import { IJob } from '@/services/jobs.service'
import { NumberInputField } from '@/components/forms/fields'
import { toast } from 'react-toastify'

const UpdateJobPositionsSchema = Yup.object().shape({
  positions_available: Yup.number()
    .required('The new number of positions available is required')
    .integer('Must be a whole number')
    .moreThan(0, 'Must be greater than zero')
})

export default function UpdateJobPositionsForm({ job, handleSubmit }: { job: IJob, handleSubmit: any }) {
  const handleSubmitWrapper = (values: any, doSubmit: (e?: React.FormEvent<HTMLFormElement>) => void, e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    
    if (values['positions_available'] < values['filled_positions']) {
      toast.warn('The new number of positions available cannot be less than the number of already-filled positions')
    } else {
      doSubmit(e)
    }
  }

  return (
    <Formik
      initialValues={{
        old_positions_available: job.positions_available,
        filled_positions: Math.round(job.positions_available * job.filled),
        positions_available: job.positions_available
      }}
      validationSchema={UpdateJobPositionsSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={(e) => handleSubmitWrapper(values, handleSubmit, e)} className="space-y-6">
          <NumberInputField
            fieldName="old_positions_available"
            disabled={true}
            label="Old Number of Positions Available"
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <NumberInputField
            fieldName="filled_positions"
            disabled={true}
            label="Number of Filled Positions"
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <p className="text-sm italic">The new number of positions available cannot be less than the number of already-filled positions.</p>
          <NumberInputField
            fieldName="positions_available"
            label="New Number of Positions Available"
            required={true}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <BlueButton
              type="submit"
              title={isSubmitting ? "Updating..." : "Update"}
              className="w-full transition duration-150 ease-in-out"
              disabled={!isValid || !dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
