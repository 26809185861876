import { fetch, post, patch, _delete, put, postMultipart } from './base.service'
import { IListRetrieveService, ILooseService, IServicePaginatedListResult, IServiceResult, _notImplemented } from './types'
import * as serverConfig from './server-config'
import { IPerson, IRecruitingProcess, IRecruitingStep } from '../recoil/types'
import { withServiceParamsInUrl } from './utils'


const JobCandidateSource = {
  CROWD: 'Crowd',
  SOURCED: 'Sourced',
  VETTED: 'Vetted',
}

const JobCandidateStatus = {
  DRAFT: 'Draft',
  TO_BE_REVIEWED: 'To be reviewed',
  INTERNAL_APPROVED: 'Internal approved',
  INTERNAL_DENIED: 'Internal denied',
  COMPANY_APPROVED: 'Company approved',
  COMPANY_DENIED: 'Company denied',
  READY_FOR_INTERVIEW: 'Ready for interview',
  INTERVIEW_DATE_SET: 'Interview date set',
  HIRED: 'Hired',
  NO_HIRE: 'No hire',
  TECHNICAL_SCREEN_REQUESTED: 'Technical screen requested',
  TECHNICAL_SCREEN: 'Technical Screen',
  TECHNICAL_SCREEN_SET: 'Technical Screen Set',
  TECHNICAL_SCREEN_FINISHED: 'Technical Screen Finished',
  MOVE_TO_POD_INTERVIEW: 'Move to pod interview',
  POD_INTERVIEW_SET: 'POD Interview Set',
  POD_INTERVIEW_FINISHED: 'POD Interview Finished',
  MOVE_TO_PANEL_INTERVIEW: 'Move to panel interview',
  PANEL_INTERVIEWS_SET: 'Panel Interviews Set',
  PANEL_INTERVIEWS_FINISHED: 'Panel Interviews Finished',
}

const JobCandidateStatusesCompany: any = [
  JobCandidateStatus.INTERNAL_DENIED,
  JobCandidateStatus.COMPANY_APPROVED,
  JobCandidateStatus.COMPANY_DENIED,
  JobCandidateStatus.READY_FOR_INTERVIEW,
  JobCandidateStatus.INTERVIEW_DATE_SET,
  JobCandidateStatus.HIRED,
  JobCandidateStatus.NO_HIRE,
  JobCandidateStatus.TECHNICAL_SCREEN_REQUESTED,
  JobCandidateStatus.TECHNICAL_SCREEN,
  JobCandidateStatus.TECHNICAL_SCREEN_SET,
  JobCandidateStatus.TECHNICAL_SCREEN_FINISHED,
  JobCandidateStatus.MOVE_TO_POD_INTERVIEW,
  JobCandidateStatus.POD_INTERVIEW_SET,
  JobCandidateStatus.POD_INTERVIEW_FINISHED,
  JobCandidateStatus.MOVE_TO_PANEL_INTERVIEW,
  JobCandidateStatus.PANEL_INTERVIEWS_SET,
  JobCandidateStatus.PANEL_INTERVIEWS_FINISHED,
]

export const JobCandidateEnum = {
  SOURCE: JobCandidateSource,
  STATUS: JobCandidateStatus,
  STATUSES: {
    INTERNAL: [
      JobCandidateStatus.INTERNAL_APPROVED,
      JobCandidateStatus.INTERNAL_DENIED,
    ].concat(JobCandidateStatusesCompany),
    COMPANY: JobCandidateStatusesCompany,
  },
  statusOptions: (options: any) => {
    return Object.values(JobCandidateEnum.STATUS).filter((val) => options.includes(val)).map((val) => {
       return {id: val, title: val}
    })
  },
  AUTO_NOTE_STATUSES: JobCandidateStatusesCompany.slice(
    JobCandidateStatusesCompany.indexOf(JobCandidateStatus.INTERVIEW_DATE_SET)
  ),
}


export interface IJobCandidate {
  id: number,
  job: any,
  candidate: IPerson,
  candidate_name?: string,
  candidate_email?: string,
  status: string,
  source: string,
  created_by: {
    id: number,
    email: string,
    person: IPerson
  },
  recruiting_process: IRecruitingProcess,
  recruiting_step: IRecruitingStep
}

export interface IJobCandidateDocument {
  id: any,
  title: string,
  file: string,
  type: string,
  can_delete: boolean
}

async function currentList(
  cancelToken: any,
): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobCandidates.myDrafts}`
  return await fetch(url, cancelToken)
}

async function list(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  // append params
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.jobs.fetchCandidates}${urlOrQueryString}`.replace('<id>', serviceParams?.['job'])
  return await fetch(url, cancelToken)
}

async function listOld(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  // append params
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.jobCandidates.list}${urlOrQueryString}`
  return await fetch(url, cancelToken)
}

async function detail(id: string, cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobCandidates.list}${id}/`
  return await fetch(url, cancelToken)
}

async function create(values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobCandidates.create}`
  return await post(url, values)
}

async function update(jobCandidateId: number, values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobCandidates.update}`
    .replace('<job_candidate_id>', jobCandidateId.toString())
  return await patch(url, values)
}

async function deleteJobCandidate(id: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobCandidates.delete}${id.toString()}/`
  return await _delete(url)
}

async function fillExternal(jobId: number, jobVersionId: number, email: string, name: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobCandidates.fillExternal}`
  return await post(url, {
    job: jobId,
    job_version: jobVersionId,
    candidate_email: email,
    candidate_name: name
  })
}

async function updateStep(candidateId: number, stepId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobCandidates.updateStep}`.replace('<job_candidate_id>', candidateId.toString())
  return await put(url, { step_id: stepId })
}

async function uploadDocument(candidateId: number, type: string, file: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobCandidates.document}`.replace('<job_candidate_id>', candidateId.toString())
  return await postMultipart(url, {
    type,
    file
  })
}

async function deleteDocument(candidateId: number, fileId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobCandidates.document}`.replace('<job_candidate_id>', candidateId.toString())
  return await _delete(url, { file_id: fileId })
}

async function fetchDocuments(candidateId: number, cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobCandidates.document}`.replace('<job_candidate_id>', candidateId.toString())
  return await fetch(url, cancelToken)
}

async function updateAdditionalInfo(candidateId: number, additionalInfo: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobCandidates.updateAdditionalInfo}`.replace('<job_candidate_id>', candidateId.toString())
  return await put(url, { additional_info: additionalInfo })
}

async function pendingCandidatesList(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  // append params
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.companies.listPendingCandidates}${urlOrQueryString}`.replace('<company_id>', serviceParams?.['company'])
  return await fetch(url, cancelToken)
}

export const jobCandidatesService: IListRetrieveService & ILooseService & {
  fillExternal: (jobId: number, jobVersionId: number, email: string, name: string) => Promise<IServiceResult>,
  updateStep: (candidateId: number, stepId: number) => Promise<IServiceResult>,
  uploadDocument: (candidateId: number, type: string, file: any) => Promise<IServiceResult>,
  deleteDocument: (candidateId: number, fileId: number) => Promise<IServiceResult>,
  fetchDocuments: (candidateId: number, cancelToken: any) => Promise<IServiceResult>,
  updateAdditionalInfo: (candidateId: number, additionalInfo: string) => Promise<IServiceResult>
} = {
  list,
  detail,
  create,
  update,
  delete: deleteJobCandidate,
  fillExternal,
  updateStep,
  uploadDocument,
  deleteDocument,
  fetchDocuments,
  updateAdditionalInfo
}

export const jobCandidatesOldService: IListRetrieveService = {
  list: listOld,
  detail: _notImplemented
}

export const jobCandidateDraftsService: IListRetrieveService = {
  list: currentList,
  detail: _notImplemented
}

export const jobPendingCandidatesService: IListRetrieveService = {
  list: pendingCandidatesList,
  detail: _notImplemented
}
