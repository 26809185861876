import { userState } from "@/recoil/atoms/auth"
import { IUser } from "@/recoil/types"
import { accountService } from "@/services"
import { useCallback, useEffect } from "react"
import { toast } from "react-toastify"
import { useSetRecoilState } from "recoil"

export default function SubscriptionButton() {
  const setUserData = useSetRecoilState<IUser | null>(userState)

  const checkSubscription = useCallback(async (triesRemaining: number) => {
    triesRemaining--
    const res = await accountService.profile()

    if (res.data.is_paid) {
      setUserData(res.data)
      toast.success('Congratulations! You are now a paid user!')
    } else {
      if (triesRemaining === 0) {
        toast.info('Applying your paid status is taking a while. Please check back and refresh the page in a few minutes.')
      } else {
        setTimeout(() => checkSubscription(triesRemaining), 1000)
      }
    }
  }, [setUserData])

  useEffect(() => {
    const hasParam = new URLSearchParams(window.location.search).has('sub-success')

    if (hasParam) {
      setTimeout(() => checkSubscription(5), 1000)
    }
  }, [checkSubscription])

  return (null)
}
