import { filesService } from "@/services"

import Files from './Files'
import FileForm from "./FileForm"

const MyProfileFiles = ({ person }: any) => {
  return (
    <Files
      service={filesService}
      object={{id: person.user_id}}
      addButtonTitle={"Add File"}
      itemProps={{ showDeleteButton: true }}
      formComponent={FileForm}
    />
  )
}

export default MyProfileFiles
