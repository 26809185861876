import {
  UsersIcon,
  BriefcaseIcon,
  CurrencyDollarIcon
} from '@heroicons/react/outline'

const features = [
  { name: 'Extend your network', icon: UsersIcon, description: '' },
  { name: 'Recommend candidates', icon: BriefcaseIcon, description: '' },
  { name: 'Get paid', icon: CurrencyDollarIcon, description: '' },
]

export default function Features() {
  return (
    <div className="relative bg-white py-8 sm:py-12">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root rounded-lg border-2 border-teal-600 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-white rounded-md">
                        <feature.icon className="h-6 w-6 text-teal-600" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-4 text-lg font-semibold text-teal-600 tracking-tight">{feature.name}</h3>
                    {feature.description && <p className="mt-3 text-base text-gray-500">
                      {feature.description}
                    </p>}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
