import { classNames } from "../../../lib/helpers"

export default function Aside({ colClasses, children }: any) {

  const wrapperClasses = classNames(
    colClasses,
    'order-1 lg:order-2 px-4 py-4 lg:px-0 lg:py-0 bg-teal-50 lg:bg-gray-100 sm:rounded-md lg:rounded-none',
    'md:mb-6'
  )

  return (
    <aside className={wrapperClasses}>
      <div className="sticky top-0 space-y-4 lg:bg-teal-50 sm:p-4 lg:rounded-md ">
        {children}
      </div>
    </aside>
  )
}
