import { fetch, post, postMultipart, put, _delete } from './base.service'
import { IListCRUDService, IListRetrieveService, IServicePaginatedListResult, IServiceResult, _notImplemented } from './types'
import * as serverConfig from './server-config'
import { withServiceParamsInUrl } from './utils'

async function getProcessDetails(id: number, cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.process}`.replace('<id>', id.toString())
  return await fetch(url, cancelToken)
}

async function getAbstractProcesses(cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.abstractProcesses}`
  return await fetch(url, cancelToken)
}

async function getAbstractSteps(cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.abstractSteps}`
  return await fetch(url, cancelToken)
}

async function fetchProcesses(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.processes}${urlOrQueryString}`
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function createProcess(values: { name: string }) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.processes}`
  return await post(url, { name: values.name })
}

async function createStep(
  name: string,
  description: string,
  duration: number,
  involvesCandidate: boolean,
  color: string,
  isAbstract: boolean,
  order: number,
  no_assignees: boolean,
  assignees: number[],
  processId?: number,
  company?: number
) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.createStep}`
  return await post(url, {
    recruiting_process: processId,
    name,
    description,
    duration,
    involves_candidate: involvesCandidate,
    color,
    is_abstract: isAbstract,
    order,
    no_assignees,
    assignees,
    company
  })
}

async function deleteStep(id: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.deleteStep}`.replace('<id>', id.toString())
  return await _delete(url)
}

async function updateStepOrder(stepId: number, prevStepId?: number, nextStepId?: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.updateStepOrder}`.replace('<id>', stepId.toString())
  return await put(url, {
    step_id: stepId,
    previous_step_id: prevStepId,
    next_step_id: nextStepId
  })
}

async function updateProcessName(id: number, name: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.updateProcessName}`.replace('<id>', id.toString())
  return await put(url, { name })
}

async function deleteProcess(id: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.process}`.replace('<id>', id.toString())
  return await _delete(url)
}

async function getRecruiterAbstractProcesses(cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.recruiterAbstractProcesses}`
  return await fetch(url, cancelToken)
}

async function getRecruiterAbstractSteps(cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.recruiterAbstractSteps}`
  return await fetch(url, cancelToken)
}

async function createRecruiterProcess(values: { name: string }) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.recruiterProcesses}`
  return await post(url, { name: values.name })
}

async function linkRecruiterProcessToJob(processId: number, jobId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.recruiterLinkProcessToJob}`.replace('<id>', processId.toString())
  return await put(url, {
    recruiting_process: processId,
    job_id: jobId
  })
}

async function fetchRecruiterProcesses(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.recruiterProcesses}${urlOrQueryString}`
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function fetchRecruiterAbstractProcesses(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.recruiterAbstractProcesses}${urlOrQueryString}`
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function fetchRecruiterRecruits(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.recruiterRecruits}${urlOrQueryString}`
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function fetchRecruited(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.recruitedHistory}${urlOrQueryString}`
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function createRecruiterStep(
  name: string,
  description: string,
  duration: number,
  color: string,
  isAbstract: boolean,
  order: number,
  processId?: number
) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.recruiterSteps}`
  return await post(url, {
    recruiting_process: processId,
    name,
    description,
    duration,
    color,
    is_abstract: isAbstract,
    order
  })
}

async function getRecruiterProcessDetails(id: number, cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.recruiterProcess}`.replace('<id>', id.toString())
  return await fetch(url, cancelToken)
}

async function getRecruiterProcessCandidates(processId: number, cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.recruiterProcessCandidates}`.replace('<id>', processId.toString())
  return await fetch(url, cancelToken)
}

async function recruiterSubmitProcessCandidates(processId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.recruiterSubmitProcessCandidates}`.replace('<id>', processId.toString())
  return await put(url)
}

async function getStepPotentialAssignees(cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.potentialAssignees}`
  return await fetch(url, cancelToken)
}

async function uploadDocument(stepId: number, name: string, file: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.document}`.replace('<id>', stepId.toString())
  return await postMultipart(url, {
    name,
    file
  })
}

async function deleteDocument(stepId: number, fileId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.document}`.replace('<id>', stepId.toString())
  return await _delete(url, { file_id: fileId })
}

async function fetchDocuments(stepId: number, cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.recruitment.document}`.replace('<id>', stepId.toString())
  return await fetch(url, cancelToken)
}

export const recruitmentService = {
  getProcessDetails,
  getAbstractProcesses,
  getAbstractSteps,
  fetchProcesses,
  createProcess,
  createStep,
  deleteStep,
  updateStepOrder,
  updateProcessName,
  deleteProcess,
  getRecruiterAbstractSteps,
  getRecruiterAbstractProcesses,
  createRecruiterProcess,
  linkRecruiterProcessToJob,
  createRecruiterStep,
  getRecruiterProcessDetails,
  getRecruiterProcessCandidates,
  recruiterSubmitProcessCandidates,
  getStepPotentialAssignees,
  uploadDocument,
  deleteDocument,
  fetchDocuments
}

export const recruitmentProcessesService: IListCRUDService = {
  list: fetchProcesses,
  detail: _notImplemented,
  create: createProcess,
  delete: _notImplemented,
  update: _notImplemented,
}

export const recruiterProcessesService: IListRetrieveService = {
  list: fetchRecruiterProcesses,
  detail: _notImplemented
}

export const recruiterAbstractProcessesService: IListCRUDService = {
  list: fetchRecruiterAbstractProcesses,
  detail: _notImplemented,
  create: createRecruiterProcess,
  delete: _notImplemented,
  update: _notImplemented
}

export const recruiterRecruitsService: IListRetrieveService = {
  list: fetchRecruiterRecruits,
  detail: _notImplemented
}

export const recruiterRecruitedService: IListRetrieveService = {
  list: fetchRecruited,
  detail: _notImplemented
}
