//state
import { useRecoilValue } from 'recoil'
import { classNames } from '../../../lib/helpers'
import { globalLoading } from '../../../recoil/atoms/app'

// components
import Loading from './Loading'

function Main({ children, colClasses }: any) {
  const loading = useRecoilValue<boolean>(globalLoading)
  const wrapperClasses = classNames(
    colClasses,
    // 'hidden lg:block'
    'order-2 lg:order-1'
  )
  return (
    <main className={wrapperClasses}>
      {
        loading ?
          <Loading />
          :
          <>
            {children}
          </>
      }
    </main>
  )
}

export default Main