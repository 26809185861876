import { _delete, fetch, post } from './base.service'
import {
  IListCRUDService,
  ILooseService,
  IServicePaginatedListResult,
  _notImplemented
} from './types'
import * as serverConfig from './server-config'
import { withServiceParamsInUrl } from './utils'


export const NoteTables = {
  Job: 'job',
  Person: 'person',
  Company: 'company',
  JobCandidate: 'job_candidate'
}

async function list(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = `${serverConfig.serverURL}${serverConfig.routes.notes.fetchNotes}${urlOrQueryString}`
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function calendar(
  queryString: string,
  cancelToken: any
): Promise<IServicePaginatedListResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.notes.calendar}${queryString}`
  return await fetch(url, cancelToken)
}

async function createNote(values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.notes.createNote}`
  return await post(url, values)
}

async function deleteNote(id: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.notes.deleteNote}`
    .replace('<note_id>', id.toString())
  return await _delete(url)
}


export const notesService: IListCRUDService & ILooseService = {
  list: list,
  detail: _notImplemented,

  create: createNote,
  update: _notImplemented,
  delete: deleteNote,

  calendar: calendar
}
