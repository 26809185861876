import axios from 'axios'
import * as serverConfig from './server-config'



async function onboarding() {
  /* fetch & return common tables for onboarding process */
  const response = await axios.get(
    `${serverConfig.serverURL}${serverConfig.routes.commonOnboarding}`
  )
  if (!response || response.status >= 400) {
    throw (new Error(response.data?.detail))
  } else {
    return {
      success: response.status !== serverConfig.requestCanceledByClientCode,
      data: response.data
    }
  }
}

async function citiesForCountry(countryId: number, stateId: number) {
  if (!countryId || !stateId) return
  const urlPart = serverConfig.routes.citiesForCountry
    .replace('<country_id>', countryId.toString())
    .replace('<state_id>', stateId.toString())

  if (sessionStorage.getItem(urlPart)) {
    return {
      success: true,
      data: JSON.parse(sessionStorage.getItem(urlPart)!)
    }
  } else {
    const response = await axios.get(
      `${serverConfig.serverURL}${urlPart}`
    )
    if (!response || response.status >= 400) {
      throw (new Error(response.data?.detail))
    } else {
      if (response.status !== serverConfig.requestCanceledByClientCode) {
        sessionStorage.setItem(urlPart, JSON.stringify(response.data))
      }

      return {
        success: response.status !== serverConfig.requestCanceledByClientCode,
        data: response.data
      }
    }
  }
}

export const commonService = {
  onboarding,
  citiesForCountry
}

function industryDictionaryToArray(industries: Record<string, any>) {
  if (!industries) return []
  return Object.entries(industries).map(([key, value]: any): any => {
    return {
      id: key,
      title: value.title
    }
  })
}

const industryIdFromKey = (common: any, key: string) => {
  return common?.industries[key]?.id || ''
}

const industryKeyFromId = (common: any, id: string) => {
  if (!common?.industries) return null
  return Object.keys(common.industries).find(key => {
    return common.industries[key].id === parseInt(id)
  })
}

function rolesForIndustryByKey(industries: Record<string, any>, key: string) {
  return industries?.[key]?.roles
}

function rolesForIndustryById(industries: Record<string, any>, id: number) {
  if (!industries || !id) {
    return []
  }

  return Object.values(industries).find((industry) => industry.id === id)?.roles ?? []
}

function conditionallyOmitRole(common: any, industry: any, role: any) {
  return (
    (
      !industry
      || (
        role
        && !commonHelpers.rolesForIndustryByKey(common?.industries, industry!).map((v: any) => v.id).includes(parseInt(role))
      )
    ) ? '' : role
  )
}

async function conditionallyOmitCity(common: any, countryId: any, state: any, city: any) {
  return (
    (
      !state
      || (
        city
        && !((await citiesForCountry(countryId, state!))?.data.map((v: any) => v.id).includes(parseInt(city)))
      )
    ) ? '' : city
  )
}

function defaultCountryID(common: any) {
  if (!common?.countries) return null
  const defaultCountry = common.countries.find((country: any) => {
    return country.slug === 'united-states-of-america'
  })
  return defaultCountry.id || null
}

function defaultCompensationType(common: any) {
  if (!common?.compensation_types) return null
  const defaultType = common.compensation_types.find((type: any) => {
    return type.title === 'Annual'
  })
  return defaultType.id || null
}

export const commonHelpers = {
  industryDictionaryToArray,
  industryIdFromKey,
  industryKeyFromId,
  rolesForIndustryByKey,
  rolesForIndustryById,
  conditionallyOmitRole,
  conditionallyOmitCity,
  defaultCountryID,
  defaultCompensationType
}