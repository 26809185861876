import { Formik } from 'formik'
import { NumberInputField, TextInputField } from  '@/components/forms/fields'
import ColorInputField from '@/components/forms/fields/ColorInputField'
import SwitchInputField from '@/components/forms/fields/SwitchInputField'
import { IRecruitingStep, IRecruitingStepDocument } from '@/recoil/types'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import CreateRecruitingStepFormAssignees from './forms/CreateRecruitingStepFormAssignees'
import { LoadingInside } from '../layout'
import { useEffect, useState } from 'react'
import { recruitmentService } from '@/services'
import Modal from '../modals/Modal'
import { toast } from 'react-toastify'
import RecruitingStepFileForm from '../files/RecruitingStepFileForm'
import { RecruitingStepDocument } from './RecruitingStepDocument'
import TealButton from '../buttons/TealButton'

export default function RecruitingStepInfo({ step, companyId }: { step: IRecruitingStep, companyId?: number }) {
  const [documents, setDocuments] = useState<IRecruitingStepDocument[]>([])
  const [fetchingDocuments, setFetchingDocuments] = useState(false)
  const [addDocumentDialogOpen, setAddDocumentDialogOpen] = useState(false)

  const fetchDocuments = (stepId: number) => {
    setFetchingDocuments(true)
    recruitmentService.fetchDocuments(stepId, null).then((res) => {
      setDocuments(res.data)
      setFetchingDocuments(false)
    })
  }

  useEffect(() => {
    fetchDocuments(step.id)
  }, [step])

  const addDocument = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    recruitmentService.uploadDocument(
      step!.id,
      values['name'],
      values['file']
    ).then(({success, data}) => {
      if (success) {
        toast.success('Successfully added document')
        setAddDocumentDialogOpen(false)
        fetchDocuments(step!.id)
      } else {
        console.error(data)
        toast.error('Failed to add document')
      }
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to add document')
      setSubmitting(false)
    })
  }

  const deleteDocument = (fileId: number) => {
    recruitmentService.deleteDocument(step.id, fileId).then((res) => {
      if (res.success) {
        toast.success('Successfully deleted document')
        fetchDocuments(step.id)
      } else {
        toast.error('Failed to delete document')
      }
    })
  }

  return (
    <>
      <Formik
        initialValues={{
          name: step.name,
          description: step.description,
          duration: step.duration ?? 0,
          color: `#${step.color}`,
          involves_candidate: step.involves_candidate,
          is_abstract: step.is_abstract,
          order: step.order,
          assignees: step.assignees
        }}
        onSubmit={() => {}}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <div className="space-y-6">
            <TextInputField
              fieldName="name"
              label="Name"
              disabled={true}
              styles={{ input: 'block w-full shadow-sm sm:text-sm rounded-md' }}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <TextInputField
              fieldName="description"
              label="Description"
              disabled={true}
              styles={{ input: 'block w-full shadow-sm sm:text-sm rounded-md' }}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <NumberInputField
              fieldName="duration"
              label="Expected Time Spent in Step (hours)"
              disabled={true}
              styles={{ input: 'block w-full shadow-sm sm:text-sm rounded-md' }}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <ColorInputField
              fieldName="color"
              label="Color"
              disabled={true}
              styles={{ input: 'block w-full shadow-sm sm:text-sm rounded-md' }}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            {companyId && <SwitchInputField
              fieldName="involves_candidate"
              disabled={true}
              label={
                <span className="flex items-center gap-1">
                  <span>Involves Candidate?</span>
                  <span className="group relative">
                    <span className="block w-52 text-xs font-medium pointer-events-none absolute -top-16 left-1/2 -translate-x-1/2 rounded bg-black px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:opacity-100">
                      Enabling this sends notifications to the candidate when they are moved into this step
                    </span>
                    <QuestionMarkCircleIcon className="h-4 w-4" aria-hidden="true" />
                  </span>
                </span>
              }
              {...{ values, errors, touched, handleBlur, handleChange }}
            />}
            {(companyId && step.type === 'Normal') && <div className="sm:border-t sm:border-gray-200 sm:pt-5">
              <div className="text-sm font-medium text-gray-700 mb-4">Assign Users / Roles / Recruitment Agencies</div>
              <CreateRecruitingStepFormAssignees
                initialAssignees={step.assignees}
                handleChange={handleChange}
                readonly={true}
              />
            </div>}
          </div>
        )}
      </Formik>
      <div className="border-t border-gray-200 pt-5 mt-2">
        {!fetchingDocuments ? <>
          <Modal
            title="Add Document"
            open={addDocumentDialogOpen}
            setOpen={setAddDocumentDialogOpen}
            hasForm={true}
          >
            <RecruitingStepFileForm
              handleSubmit={addDocument}
            />
          </Modal>
          <label htmlFor={'files'} className="block text-sm font-medium text-gray-700">
            Files
          </label>
          <div className="mt-2">
            {documents.length > 0 && <div className="flex flex-wrap gap-2 mb-3">
              {documents.map((doc) => <RecruitingStepDocument
                key={doc.id}
                document={doc}
                deleteDocument={() => deleteDocument(doc.id)}
              />)}
            </div>}
            <TealButton
              title='Add Document'
              onClick={() => setAddDocumentDialogOpen(true)}
            />
          </div>
        </> : <LoadingInside />}
      </div>
    </>
  )
}
