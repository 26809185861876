import axios from 'axios'
import * as serverConfig from '../services/server-config'

import { accountService } from '../services'

export function errorInterceptor() {
  axios.interceptors.response.use(null, (error) => {
    if (axios.isCancel(error)) {
      return Promise.resolve({ status: serverConfig.requestCanceledByClientCode })
    }
    const { response } = error
    if (!response) {
      // network error
      console.error(error)
      return
    }

    // not auth
    if ([401].includes(response.status)) {
      if (accountService.checkToken()) {
        if (response.data?.code === 'token_not_valid') {
          // send refresh token 
          accountService.refreshToken().then((result) => {
            if (result.success) {
              window.location.reload()
            } else {
              accountService.logout()
              window.location = '/login'
            }
          }).catch(() => {
            accountService.logout()
            window.location = '/login'
          })
        }
      } else {
        // no token in local storage
        if (response.data?.code === 'not_authenticated') {
          accountService.logout()
          window.location = '/login'
        }
      }
    }

    return response
  })
}