import { useState, ChangeEventHandler, FocusEventHandler, useCallback, useEffect } from "react"
import { classNames } from "@/lib/helpers"
import CurrencyInput from "react-currency-input-field"

interface ICurrencyFieldFieldProps {
  currencyType?: string,
  fieldName: string,
  label: string,
  handleChange: ChangeEventHandler,
  handleBlur: FocusEventHandler,
  values: Record<string, any>,
  errors: Record<string, any>,
  touched: Record<string, any>,
  required?: boolean,
  disabled?: boolean,
  styles?: { wrapper?: string, input?: string }
  setFieldValue?: any
}

const NumberInputField = (
  { 
    currencyType,
    fieldName,
    label,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    required = false,
    disabled = false,
    setFieldValue,
    styles
  }: ICurrencyFieldFieldProps) => {
  const [_currencyType, _setCurrencyType] = useState(currencyType ?? "USD")


  const currencyPrefixes: { [key: string]: string } = {
    'USD': '$',
    'CAD': '$',
    'EUR': '€',
    'GBP': '£',
    'AUD': '$',
    'NZD': '$',
    'JPY': '¥',
    'MXN': '$',
  }

  useEffect(() => {
    _setCurrencyType("")
    setTimeout(() => _setCurrencyType(currencyType ?? "USD"), 1)
  }, [currencyType]);
  
  const currencyPrefix = currencyPrefixes[_currencyType ?? 'USD'];

  const renderCurrencyInput = useCallback(() => {
    if(!_currencyType) return null;
    
    return (
      <CurrencyInput
        id={fieldName}
        name={fieldName}
        placeholder={label}
        prefix={currencyPrefix}
        defaultValue={values[fieldName]}
        decimalsLimit={2}
        onValueChange={(value, name) => setFieldValue(name, value)}
        onBlur={handleBlur}
        className={classNames(
          errors[fieldName] && touched[fieldName] && errors[fieldName] ?
            'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
            'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
          , styles?.input ?? 'max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md',
          disabled ? 'bg-gray-200' : ''
        )}
      />
    )
  }, [currencyPrefix, _currencyType, label, disabled, errors, fieldName, handleBlur, setFieldValue, styles?.input, touched, values]);

  return (
    <div className={styles?.wrapper ?? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'}>
      <label htmlFor={fieldName} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        {renderCurrencyInput()}
      </div>
      <small className='text-red-500'>
        {errors[fieldName] && touched[fieldName] && errors[fieldName]}
      </small>
    </div>
  )
}

export default NumberInputField
