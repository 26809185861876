import { Formik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

import "react-datepicker/dist/react-datepicker.css"

import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../../recoil/atoms/auth'
import { commonTables } from '../../../../recoil/atoms/common'
import BlueButton from '../../../buttons/BlueButton'

import { companyService } from '../../../../services'
import { commonHelpers } from '../../../../services/common.service'
import { CityField } from '../../person/forms/JobPreferencesForm'

// any validation
const ProfileInfoSchema = Yup.object().shape({
})

const CompanyForm = ({ toggleIsEditing, company }: any) => {
  const [userData, setUserData] = useRecoilState<any>(userState)
  const common = useRecoilValue<any>(commonTables)

  const handleSubmit = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    values = Object.assign({}, values, { 'industry': commonHelpers.industryIdFromKey(common, values.industry) })
    companyService.update(company.id, values).then((result: any) => {
      const newUserData = Object.assign({}, userData, { company: result.data })
      setUserData(newUserData)
      setSubmitting(false)
      toggleIsEditing()
      toast.success('Company Profile Updated!')
    }).catch((error: any) => {
      toast.error(`${error}`)
      setSubmitting(false)
    })
  }

  return (
    <Formik
      initialValues={{
        // personal
        name: company.name || '',
        description: company.description || '',
        contact: company.contact || '',
        country: commonHelpers.defaultCountryID(common),
        state: company.state?.id || '',
        city: company.city?.id || '',
        industry: company.industry?.slug || '',
      }}
      validationSchema={ProfileInfoSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <div className="space-y-8 sm:space-y-5">
            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Company Name
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    autoComplete="name"
                    disabled={true}
                    className="max-w-lg block w-full shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md bg-gray-200"
                    onChange={handleChange} onBlur={handleBlur} value={values.name}
                  />
                </div>
                <>{errors.name && touched.name && errors.name}</>
              </div>
            </div>
            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Description
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <textarea
                    id="description"
                    name="description"
                    autoComplete="description"
                    className="max-w-lg block w-full shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    onChange={handleChange} onBlur={handleBlur} value={values.description}
                  />
                </div>
                <>{errors.description && touched.description && errors.description}</>
              </div>
            </div>
            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="contact" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Contact
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="contact"
                    name="contact"
                    type="text"
                    autoComplete="contact"
                    className="max-w-lg block w-full shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    onChange={handleChange} onBlur={handleBlur} value={values.contact}
                  />
                </div>
                <>{errors.contact && touched.contact && errors.contact}</>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="industry" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Industry
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="industry"
                  name="industry"
                  autoComplete="industry"
                  onChange={handleChange} onBlur={handleBlur} value={values.industry}
                  className="max-w-lg block focus:ring-teal-600 focus:border-teal-600 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="">Not Specified</option>
                  {Object.keys(common?.industries).map((key) => {
                    return (
                      <option key={key} value={key}>{common?.industries[key]?.title}</option>
                    )
                  })}
                </select>
              </div>
              <>{errors.industry && touched.industry && errors.industry}</>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="state" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                State
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="state"
                  name="state"
                  autoComplete="state"
                  onChange={handleChange} onBlur={handleBlur} value={values.state}
                  className="max-w-lg block focus:ring-teal-600 focus:border-teal-600 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="">Not Specified</option>
                  {common?.states?.map((state: any) => {
                    return (
                      <option key={state.id} value={state.id}>{state.title}</option>
                    )
                  })}
                </select>
              </div>
              <>{errors.state && touched.state && errors.state}</>
            </div>
            <CityField
              {...{ values, errors, touched, handleChange, handleBlur, handleSubmit, common }}
            />
            
            <BlueButton
              title="Save"
              type="submit"
              className="mt-5 mx-auto sm:mx-0"
              disabled={!isValid || !dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

export default CompanyForm
