/* This example requires Tailwind CSS v2.0+ */
import { toast, Toast } from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import { INotification, INotificationAction } from '@/recoil/types'
import NotificationInner from './NotificationInner';
import {
  allNotifications as allNotificationsAtom,
  pendingNotifications as pendingNotificationsAtom
} from '../../recoil/atoms/notification'
import { notificationService } from '../../services/notification.service'

export default function NotificationPopOver({ notification, thisToast }: { notification: INotification, thisToast: Toast }) {
  const [
    allNotifications,
    setAllNotifications
  ] = useRecoilState<INotification[]>(allNotificationsAtom)

  const [
    pendingNotifications,
    setPendingNotifications
  ] = useRecoilState<INotification[]>(pendingNotificationsAtom)
  
  const dismiss = async (action: INotificationAction) => {
    toast.remove(thisToast.id)
    const notifMapFunc = (notif: INotification) => {
      return (
        notif.id === notification.id
        ? (
          action.text === 'Dismiss'
          ? { ...notif, has_seen: true }
          : { ...notif, is_pending: false, has_seen: true }
        )
        : notif
      )
    }
    await Promise.all([
      notificationService.markAsSeen(notification.id),
      ...(action.text !== 'Dismiss' ? [notificationService.resolvePending(notification.id)] : [])
    ])
    setPendingNotifications(action.text === 'Dismiss' ? pendingNotifications.map(notifMapFunc) : pendingNotifications.filter((notif) => notif.id !== notification.id))
    setAllNotifications(allNotifications.map(notifMapFunc))
  }

  return (
    <div
      className={`${
        thisToast.visible ? 'animate-enter' : 'animate-leave'
      } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <NotificationInner
        notification={notification}
        onAction={dismiss}
      />
    </div>
  )
}
