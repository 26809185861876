import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { NoMenuLayout } from '../components/layout'
import { LoadingInside } from '../components/layout/top_level/Loading'
import Header from '../components/welcome/Header'
import { notificationService } from '../services'
import { toast } from 'react-toastify'
import BlueButton from '../components/buttons/BlueButton'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { SelectField } from '@/components/forms/fields'
import { INotificationPreferences } from '@/recoil/types'

const NotificationPreferencesFormSchema = Yup.object().shape({})

export default function ChangeNotificationPreferences() {
  let { token } = useParams<{ token: string }>()
  const [loadingInside, setLoadingInside] = useState(false)
  const [notificationPreferences, setNotificationPreferences] = useState<INotificationPreferences>()
  const history = useHistory()

  useEffect(() => {
    notificationService.getPreferencesWithToken(token, null).then((result) => {
      setNotificationPreferences(result.data)
      setLoadingInside(false)
    }).catch((error) => {
      if (error.code === 401) {
        toast.error('This link to update your notification preferences is either expired or invalid')
      } else {
        toast.error('Failed to fetch notification preferences')
      }

      history.push('/')
    })
  }, [token, history])

  const handleSubmit = (preferences: any, { setSubmitting }: { setSubmitting: Function }) => {
    notificationService.updatePreferencesWithToken(preferences, token).then(() => {
      setSubmitting(false)
      toast.success('Notification preferences updated')
      history.push('/')
    }).catch((error) => {
      toast.error(`${error}`)
      setSubmitting(false)
    })
  }

  const prefOptions = [
    { id: 'Always', title: 'Always' },
    { id: 'Never', title: 'Never' }
  ]

  return (
    <NoMenuLayout title="Notification Prefs" auth={false}>
      <Header
        title='Change Notification Preferences'
        info={'Use the form below to update your email notification preferences.'}
      />
      {loadingInside ? <LoadingInside /> :
        <div className="mx-auto max-w-md text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="sm:space-y-8 flex flex-col justify-items-center px-4">
            {notificationPreferences && <Formik
              initialValues={{
                connections_email: notificationPreferences?.connections_email || 'Always',
                jobs_email: notificationPreferences?.jobs_email || 'Always',
                review_email: notificationPreferences?.review_email || 'Always',
                unreadchat_email: notificationPreferences?.unreadchat_email || 'Always'
              }}
              validationSchema={NotificationPreferencesFormSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <div className="flex items-center justify-between sm:border-t sm:border-gray-200 py-4">
                    <div className="text-sm font-medium text-gray-700 sm:mt-px">Connection invites</div>
                    <SelectField
                      fieldName="connections_email"
                      label=""
                      options={prefOptions}
                      includeNotSpecified={false}
                      styles={{ wrapper: '' }}
                      {...{ values, errors, touched, handleBlur, handleChange }}
                    />
                  </div>
                  <div className="flex items-center justify-between sm:border-t sm:border-gray-200 py-4">
                    <div className="text-sm font-medium text-gray-700 sm:mt-px">New jobs for me</div>
                    <SelectField
                      fieldName="jobs_email"
                      label=""
                      options={prefOptions}
                      includeNotSpecified={false}
                      styles={{ wrapper: '' }}
                      {...{ values, errors, touched, handleBlur, handleChange }}
                    />
                  </div>
                  <div className="flex items-center justify-between sm:border-t sm:border-gray-200 py-4">
                    <div className="text-sm font-medium text-gray-700 sm:mt-px">Job review alerts</div>
                    <SelectField
                      fieldName="review_email"
                      label=""
                      options={prefOptions}
                      includeNotSpecified={false}
                      styles={{ wrapper: '' }}
                      {...{ values, errors, touched, handleBlur, handleChange }}
                    />
                  </div>
                  <div className="flex items-center justify-between sm:border-t sm:border-gray-200 py-4">
                    <div className="text-sm font-medium text-gray-700 sm:mt-px">Unread chat alerts</div>
                    <SelectField
                      fieldName="unreadchat_email"
                      label=""
                      options={prefOptions}
                      includeNotSpecified={false}
                      styles={{ wrapper: '' }}
                      {...{ values, errors, touched, handleBlur, handleChange }}
                    />
                  </div>
                  <BlueButton
                    disabled={!isValid || !dirty || isSubmitting}
                    type="submit"
                    title={isSubmitting ? "Updating..." : "Update"}
                    className="mt-5 w-full"
                  />
                </form>
              )}
            </Formik>}
          </div>
        </div>
      }
    </NoMenuLayout >
  )
}
