import { useState } from 'react'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { NoMenuLayout } from '../../components/layout'
import Loading, { LoadingInside } from '../../components/layout/top_level/Loading'
import Header from '../../components/welcome/Header'
import { companyService, companyMembersService, serviceType } from '../../services'
import { isLoggedIn } from '../../recoil/atoms/auth'
import { toast } from 'react-toastify'
import BlueButton from '../../components/buttons/BlueButton'
import BlueButtonLink from '../../components/links/BlueButtonLink'

export default function AcceptCompanyInvite() {
  let { id } = useParams<{ id: string }>()
  const [company, setCompany] = useState<any>(null)
  const [error, setError] = useState<any>('Company Not Found')
  const [loading, setLoading] = useState(false)
  const [loadingInside, setLoadingInside] = useState(false)
  const loggedIn = useRecoilValue(isLoggedIn)
  let history = useHistory()

  useEffect(() => {
    if (id && loggedIn) {
      setLoading(true)
      companyService.detail(id, null).then((result: serviceType.IServiceResult) => {
        setCompany(result.data)
        setLoading(false)
      }).catch((error: any) => {
        setError(error)
        setLoading(false)
      })
    }
  }, [id, loggedIn])

  const acceptInvite = () => {
    if (company && loggedIn) {
      setLoadingInside(true)
      companyMembersService.accept(company.id).then((result: any) => {
        if (result.success) {
          setLoadingInside(false)
          toast.success('Invitation Accepted')
          history.replace('/profile')
        }
      }).catch((error: any) => {
        toast.error(`${error}`)
        setLoadingInside(false)
      })
    }
  }

  return (
    <NoMenuLayout title="Login" auth={true}>
      {!loading ?
        company !== null ?
          <>
            <Header
              title='Welcome to Gumption!'
              info={`You have been invited to ${company.name}, please accept the invitation on clicking the button below.`}
            />
            {loadingInside ? <LoadingInside /> :
              <div className="mx-auto max-w-md text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="sm:space-y-8 flex flex-col items-center justify-items-center">
                  <BlueButton
                    title="Accept Invite"
                    onClick={acceptInvite}
                    className="mx-auto w-72"
                  />
                </div>
              </div>
            }
          </>
          :
          <>
            <Header
              title='Company Not Found!'
              info={`Company not found message.`}
            />
            <div className="mx-auto max-w-md text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl space-y-4">
              <div className="sm:space-y-8 flex flex-col justify-items-center">
                <p className="text-sm text-red-600 max-w">
                  {error.toString()}
                </p>
              </div>
              <div className="sm:space-y-8 flex flex-col items-center justify-items-center">
                <BlueButtonLink
                  title="Home"
                  to="/"
                  className="w-72"
                />
              </div>
            </div>
          </>
        :
        <Loading />
      }
    </NoMenuLayout >
  )
}
