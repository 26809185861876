import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '../buttons/BlueButton'

import { classNames } from '../../lib/helpers'

// import {
//   Link
// } from 'react-router-dom'

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  new_password1: Yup.string()
    .min(6, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  new_password2: Yup.string()
    .min(6, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
    .when("new_password1", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new_password1")],
        'Passwords do not match.'
      )
    })
})

export default function ResetPasswordForm({ handleSubmit }: { handleSubmit: any }) {
  return (
    <Formik
      initialValues={{
        email: "",
        new_password1: "",
        new_password2: ""
      }}
      validationSchema={ResetPasswordSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className={classNames(
                  errors.email && touched.email && errors.email ?
                    'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
                    'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
                  , 'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm '
                )}
                onChange={handleChange} onBlur={handleBlur} value={values.email}
              />
            </div>
            <small className='text-red-500'>
              {errors.email && touched.email && errors.email}
            </small>
          </div>

          <div>
            <label htmlFor="new_password1" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div className="mt-1">
              <input
                id="new_password1"
                name="new_password1"
                type="password"
                required
                className={classNames(
                  errors.new_password1 && touched.new_password1 && errors.new_password1 ?
                    'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
                    'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
                  , 'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm '
                )}
                onChange={handleChange} onBlur={handleBlur} value={values.new_password1}
              />
            </div>
            <small className='text-red-500'>
              {errors.new_password1 && touched.new_password1 && errors.new_password1}
            </small>
          </div>

          <div>
            <label htmlFor="new_password2" className="block text-sm font-medium text-gray-700">
              Repeat Password
            </label>
            <div className="mt-1">
              <input
                id="new_password2"
                name="new_password2"
                type="password"
                required
                className={classNames(
                  errors.new_password2 && touched.new_password2 && errors.new_password2 ?
                    'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
                    'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
                  , 'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm '
                )}
                onChange={handleChange} onBlur={handleBlur} value={values.new_password2}
              />
            </div>
            <small className='text-red-500'>
              {errors.new_password2 && touched.new_password2 && errors.new_password2}
            </small>
          </div>

          <div>
            <BlueButton
                title={isSubmitting ? "Submitting..." : "Submit"}
                type="submit"
                className="w-full"
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
