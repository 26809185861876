function CorporateContact({ values, errors, touched, handleChange, handleBlur, common }: any) {
  return (
    <>
      <div className="mt-10 text-center font-bold text-teal-900">
        Thanks for submitting, someone from Gumption will contact you.
      </div>
    </>
  )
}

export default CorporateContact
