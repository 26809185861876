import { DocumentTextIcon } from '@heroicons/react/solid'
import { MouseEventHandler, useState } from 'react'
import RedButton from '../buttons/RedButton'
import BlueButton from '../buttons/BlueButton'
// import { Link } from 'react-router-dom'

import Confirm from '../modals/Confirm'

interface IFileInnerProps {
  object: any,
  setIsConfirmDeleteOpen: (value: boolean) => void,
  onUpdateClick: MouseEventHandler<HTMLButtonElement> | null,
  showDeleteButton?: boolean
}

const FileInner = ({
  object,
  setIsConfirmDeleteOpen,
  onUpdateClick,
  showDeleteButton
}: IFileInnerProps) => {
  return (
    <div className="px-4 py-4 flex items-center sm:px-6">
      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between truncate">
        <div className="truncate">

          <div className="flex">
            <div className="flex items-center text-sm text-gray-500">
              <DocumentTextIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                <a href={object.file} rel="noreferrer" target="_blank"><strong>{object.title}</strong></a>
                {'public' in object && !object.public && <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full capitalize bg-purple-100 text-purple-800">
                  Private
                </span>}
              </p>
            </div>
          </div>

        </div>
      </div>
      <div className="ml-2 sm:ml-0 flex flex-col">
        {onUpdateClick &&
          <BlueButton
            title="Update"
            type="button"
            className="mb-2"
            onClick={() => onUpdateClick(object.id)}
          />
        }
        {showDeleteButton &&
          <RedButton
            title="Delete"
            onClick={() => setIsConfirmDeleteOpen(true)}
          />
        }
      </div>
    </div>
  )
}

interface IFileProps {
  object: any,
  showDeleteButton?: boolean,
  onDeleteConfirm: Function,
  onUpdateClick: MouseEventHandler<HTMLButtonElement> | null
}

const File = ({
  object,
  showDeleteButton = false,
  onDeleteConfirm,
  onUpdateClick = null
}: IFileProps) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  
  return (
    <>
      <Confirm
        title={`Are you sure to delete file ${object.title}?`}
        onConfirm={() => onDeleteConfirm(object.id)}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      />
      <li>
        <FileInner
          object={object}
          setIsConfirmDeleteOpen={setIsConfirmOpen}
          onUpdateClick={onUpdateClick}
          showDeleteButton={showDeleteButton}
        />          
      </li>
    </>
  )
}

export default File
