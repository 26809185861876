import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '../../buttons/BlueButton'

// any validation
const EmailShareFormSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
})

const EmailShareForm = ({ handleSubmit }: any) => {
  return (
    <Formik
      initialValues={{
        email: ''
      }}
      validationSchema={EmailShareFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <div className="mt-6 sm:mt-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Email
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="max-w-lg block w-full shadow-sm border focus:bg-teal-50 focus:border-teal-600 focus:border-2 sm:max-w-xs sm:text-sm border-gray-300 rounded-md focus:outline-none focus:ring-teal-600"
                    onChange={handleChange} onBlur={handleBlur} value={values.email}
                  />
                </div>
                {errors.email && touched.email && errors.email}
              </div>
            </div>
            <div className="flex items-center">
              <BlueButton
                title="Share"
                type="submit"
                className="mt-12 disabled:opacity-50 w-full sm:w-1/2 sm:mx-auto"
                disabled={!isValid || !dirty || isSubmitting}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default EmailShareForm
