import { Link, useLocation } from 'react-router-dom'
import { Fragment, useState, createElement } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon, UserGroupIcon, BellIcon } from '@heroicons/react/outline'
import { toast } from 'react-toastify'

// State Imports
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState, isLoggedIn } from '@/recoil/atoms/auth'
import { INotification, IUser } from '@/recoil/types'
import { commonTables } from "@/recoil/atoms/common"

import { routes } from '@/router/Router'
import { classNames } from '@/lib/helpers'

import { ReactComponent as Logo } from '../top_level/logo.svg'
import Search from './Search'
import { AccountRoles, commonHelpers, companyJobsService, notificationService } from "@/services"
import JobForm from '@/components/profile/company/jobs/JobForm'
import SlideOver from '@/components/slideover/SlideOver'
import { ProfileImage } from '@/components/utils'
import TealButton from '@/components/buttons/TealButton'
import ChatBadge from '@/components/chat/ChatBadge'
import NotificationListItem from '@/components/notifications/NotificationListItem'
import WhiteButtonLink from '@/components/links/WhiteButtonLink'
import { unseenNotifications as unseenNotificationsSelector } from '@/recoil/atoms/notification'
import {
  allNotifications as allNotificationsAtom,
  pendingNotifications as pendingNotificationsAtom
} from '@/recoil/atoms/notification'
import WhiteButton from '@/components/buttons/WhiteButton'



function NavItemTitle({ item }: { item: any }) {
  return (
    <span>
      {item.name}
      {item.extraName && <div className="text-xs text-gray-400">{item.extraName}</div>}
      {item.chat && <ChatBadge />}
    </span>
  )
}

export default function Nav() {
  const loggedIn = useRecoilValue(isLoggedIn)
  const common = useRecoilValue<any>(commonTables)
  const user = useRecoilValue(userState) as unknown as IUser
  const location = useLocation<any>()
  const unseenNotifications = useRecoilValue<INotification[]>(unseenNotificationsSelector)
  const [isJobDialogOpen, setIsJobDialogOpen] = useState(false)
  const [
    allNotifications,
    setAllNotifications
  ] = useRecoilState<INotification[]>(allNotificationsAtom)

  const [
    pendingNotifications,
    setPendingNotifications
  ] = useRecoilState<INotification[]>(pendingNotificationsAtom)

  const handleJobSubmit = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    values = Object.assign({}, values, {
      'industry': commonHelpers.industryIdFromKey(common, values.industry),
      'role': commonHelpers.conditionallyOmitRole(common, values.industry, values.role)
    })

    companyJobsService.create(values, user.company!.id).then((result: any) => {
      setSubmitting(false)
      setIsJobDialogOpen(false)
      toast.success('Successfully created job')
    }).catch(() => {
      setSubmitting(false)
    })
  }

  const dismissAllNotifications = () => {
    notificationService.markAllAsSeen()
    const notifMapFunc = (notif: INotification) => ({ ...notif, has_seen: true })
    setPendingNotifications(pendingNotifications.map(notifMapFunc))
    setAllNotifications(allNotifications.map(notifMapFunc))
  }

  const navigation = routes.filter((route) => {
    return (
      route.mainMenu
      && (!route.permission || route.permission(user))
    )
  }).map((route) => {
    return {
      name: route.name,
      path: route.path,
      current: location.pathname === route.path,
      chat: route.chat
    }
  })

  const userNavigation = routes.filter((route) => {
    return (
      route.userMenu
      && (loggedIn ? route.auth : !route.auth)
      && (!route.permission || route.permission(user))
    )
  }).map((route) => {
    return {
      name: route.name,
      path: route.path,
      current: location.pathname === route.path,
      extraName: route.extraName && route.extraName(user)
    }
  })

  return (
    <Popover
      as="header"
      className={({ open }) =>
        classNames(
          open ? 'inset-0 z-40 overflow-y-auto' : '',
          'bg-white shadow-sm md:overflow-y-visible',
          'w-full z-10'
        )
      }
    >
      {({ open }) => (
        <>
          <div className="xl:max-w-full xl:px-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    
            <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
              <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                <div className="flex-shrink-0 flex items-center">
                  <h1 className="sr-only">Gumption, the hiring platform</h1>
                  <Link
                    to="/"
                  >
                    <Logo
                      className="w-8 mx-auto "
                    />
                  </Link>
                </div>
              </div>
              <Search />
              <div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
                {/* Mobile menu button */}
                <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-teal-600 hover:bg-gray-100 hover:text-teal-500">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Popover.Button>
              </div>
              <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                {/*<Link to="/" className="text-sm font-medium text-teal-600 hover:underline">
                  Go Business
                </Link>*/}
                <Link
                  to="/recruit"
                  className="ml-5 flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-600"
                >
                  <span className="sr-only">Recruiting</span>
                  <UserGroupIcon className="h-6 w-6" aria-hidden="true" />
                </Link>

                {loggedIn && <Popover as="div" className="flex-shrink-0 relative ml-5">
                  {({ open }) => (
                    <>
                      <div>
                        <Popover.Button className={"bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-600 " + (unseenNotifications.length > 0 ? "text-red-400" : "text-gray-400")}>
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel static className="origin-top-right absolute z-10 right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {unseenNotifications.length > 0 && <>
                            <div className="divide-y divide-gray-200">
                              {unseenNotifications.slice(0, 3).map((notif) => (
                                <NotificationListItem key={notif.id} object={notif}/>
                              ))}
                              <div>
                                {unseenNotifications.length > 3 && <div className="text-center pt-2 -mb-2 text-xs text-gray-500">+{unseenNotifications.length - 3} more</div>}
                                <div className="py-4 flex gap-4 items-center justify-center">
                                  <WhiteButtonLink
                                    title="All Notifications"
                                    to="/notifications"
                                  />
                                  <WhiteButton
                                    title="Dismiss All"
                                    onClick={dismissAllNotifications}
                                  />
                                </div>
                              </div>
                            </div>
                          </>}
                          {unseenNotifications.length === 0 && (
                            <div className="text-center py-6">
                              <div className="text-gray-400 pb-3 font-medium">
                                No unseen notifications to show
                              </div>
                              <WhiteButtonLink
                                title="All Notifications"
                                to="/notifications"
                              />
                            </div>
                          )}
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>}

                {/* Profile dropdown */}
                <Menu as="div" className="flex-shrink-0 relative ml-5">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-600">
                          <span className="sr-only">Open user menu</span>
                          <ProfileImage image={user?.person?.profile_image} size={8} iconClass={'flex-shrink-0'} />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
                        >
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link
                                  to={item.path}
                                  className={classNames(
                                    active ? 'bg-teal-50' : '',
                                    'block py-2 px-4 text-sm text-gray-500 font-medium'
                                  )}
                                >
                                  <NavItemTitle item={item} />
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
                {(user && user.company && user.company.is_paid && AccountRoles.canManageCompanyJobs(user.role)) && (
                  <>
                    <SlideOver
                      title="New Job"
                      open={isJobDialogOpen}
                      setOpen={setIsJobDialogOpen}
                      hasForm={true}
                    >
                      {
                        createElement(JobForm, {
                          key: user.company?.id,
                          object: user.company,
                          handleSubmit: handleJobSubmit
                        })
                      }
                    </SlideOver>
                    <TealButton
                      title="New Job"
                      className="ml-6"
                      onClick={() => setIsJobDialogOpen(true)}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <Popover.Panel as="nav" className="lg:hidden bg-gray-50" aria-label="Global">
            <div className="w-full pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  aria-current={item.current ? 'page' : undefined}
                  className={classNames(
                    item.current ? 'bg-teal-50 text-teal-600 border-teal-600 border-l-4' : 'border-transparent', 'hover:bg-teal-50', 'hover:text-teal-600',
                    'block py-2 px-6 text-gray-500 font-medium'
                  )}
                >
                  <NavItemTitle item={item} />
                </Link>
              ))}
            </div>
            <div className="border-t-2 border-teal-600 pt-4 pb-3">
              <div className="w-full px-6 flex items-center">
                <div className="flex-shrink-0">
                  <ProfileImage image={user?.person?.profile_image} size={10} iconClass={'flex-shrink-0'} />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-teal-600">{user?.person?.first_name} {user?.person?.last_name}</div>
                  <div className="text-sm font-medium text-gray-500">{user?.email}</div>
                </div>
                <Link
                  to="/recruit"
                  className="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                >
                  <span className="sr-only">Recruiting</span>
                  <UserGroupIcon className="h-6 w-6" aria-hidden="true" />
                </Link>
                {loggedIn && <Link
                  to="/notifications"
                  className={"ml-auto flex-shrink-0 bg-white rounded-full p-1 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 " + (unseenNotifications.length > 0 ? "text-red-400" : "text-gray-400")}
                >
                  <span className="sr-only">View all notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </Link>}
              </div>
              <div className="mt-3 w-full space-y-1">
                {userNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    className="block py-2 px-6 text-gray-500 font-medium hover:bg-teal-50 hover:text-teal-600"
                  >
                    <NavItemTitle item={item} />
                  </Link>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}
