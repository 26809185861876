import React, { useEffect } from 'react'
import { Formik } from 'formik'

import BasicInfoFields from './BasicInfoFields'
import BasicAndCompanyInfoFields from './BasicAndCompanyInfoFields'
import WorkExperienceFields from './WorkExperienceFields'
import RecruiterFields from './RecruiterFields'
import UserRoleFields from './UserRoleFields'
import CorporateContact from './CorporateContact'
import BlueButton from '../buttons/BlueButton'
import WhiteButton from '../buttons/WhiteButton'
import { accountService, commonHelpers } from '../../services'
import { toast } from 'react-toastify'
import MFASetup from './MFASetup'

function OnboardingForm({ handleSubmit, common, step, setStep, stepInfo, initialValues }: any) {
  const formRef = React.useRef<any>()
  const [loading, setLoading] = React.useState(false)
  const lastSentValues = React.useRef<any>()

  const updateOnboardingProgress = async (values: any, isBackground: boolean, isForward = true) => {
    !isBackground && setLoading(true)

    const [stepPrimary, stepSecondary] = step.toString().split('.')
    const newStep = (
      isForward
      ? stepInfo.last ? step : (step === 0 ? parseInt(values['role']) : parseFloat(`${stepPrimary}.${parseInt(stepSecondary ?? '0') + 1}`))
      : (stepSecondary ?? '0') === '0' ? 0 : parseFloat(`${stepPrimary}.${parseInt(stepSecondary ?? '0') - 1}`)
    )

    lastSentValues.current = values

    accountService.updateOnboardingProgress(Object.assign({}, values, {
      'step': isBackground ? step : newStep,
      'work_industry': commonHelpers.industryIdFromKey(common, values.work_industry),
      'work_role': commonHelpers.conditionallyOmitRole(common, values.work_industry, values.work_role),
      'city': await commonHelpers.conditionallyOmitCity(common, values.country, values.state, values.city),
      'work_type': values.work_type ? JSON.stringify(values.work_type) : ''
    })).then(() => {
      if (isBackground) {
        return
      }

      setStep(newStep)
      setLoading(false)

      if (isForward && stepInfo.last) {
        formRef.current.setSubmitting()
        formRef.current.handleSubmit()
      } else {
        setStep(newStep)
      }
    }).catch(() => {
      !isBackground && toast.error('Failed to update onboarding progress. Please try again')
      setLoading(false)
    })
  }

  useEffect(() => {
    formRef.current?.validateForm()
  }, [stepInfo])

  const textInputStyles = {
    'wrapper': 'sm:grid sm:grid-rows-2 sm:gap-2 sm:items-center',
    'input': 'block w-full shadow-sm text-sm rounded-md'
  }

  const selectInputStyles = {
    'wrapper': 'sm:grid sm:grid-rows-2 sm:gap-2 sm:items-center',
    'input': 'block w-full shadow-sm sm:text-sm rounded-md'
  }

  const selectSearchInputStyles = {
    'wrapper': 'sm:grid sm:grid-rows-2 sm:gap-2 sm:items-center',
    'input': 'border block w-full shadow-sm text-sm rounded-md pl-3 py-2 pr-8 text-left'
  }

  const onBlurWrapper = (e: any, values: any, isValid: boolean, dirty: boolean) => {
    const fieldName = e.target.name ?? e.target.title

    if (!isValid || !dirty || lastSentValues.current?.[fieldName] === values[fieldName]) {
      return
    }

    updateOnboardingProgress(values, true)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={stepInfo.validation}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit} onBlur={(e) => onBlurWrapper(e, values, isValid, dirty)}>
          <div className="space-y-8 sm:space-y-5 pt-2">
            {stepInfo.form === "user_role" && <>
              <UserRoleFields
                {...{ values, errors, touched, handleChange, handleBlur, handleSubmit, common }}
              />
            </>}
            {stepInfo.form === "profile" && <>
              <BasicInfoFields
                textInputStyles={textInputStyles}
                selectInputStyles={selectInputStyles}
                selectSearchInputStyles={selectSearchInputStyles}
                {...{ values, errors, touched, handleChange, handleBlur, handleSubmit, common }}
              />
            </>}
            {stepInfo.form === "profile_company" && <>
              <BasicAndCompanyInfoFields
                textInputStyles={textInputStyles}
                selectInputStyles={selectInputStyles}
                selectSearchInputStyles={selectSearchInputStyles}
                {...{ values, errors, touched, handleChange, handleBlur, handleSubmit, common }}
              />
            </>}
            {stepInfo.form === "work_experience" && <>
              <WorkExperienceFields
                textInputStyles={textInputStyles}
                selectInputStyles={selectInputStyles}
                selectSearchInputStyles={selectSearchInputStyles}
                {...{ values, errors, touched, handleChange, handleBlur, handleSubmit, common, setFieldValue }}
              />
            </>}
            {stepInfo.form === "recruiter_info" && <>
              <RecruiterFields
                textInputStyles={textInputStyles}
                selectInputStyles={selectInputStyles}
                selectSearchInputStyles={selectSearchInputStyles}
                {...{ values, errors, touched, handleChange, handleBlur, handleSubmit, common }}
              />
            </>}
            {stepInfo.form === "finish_contact" && <>
              <CorporateContact />
            </>}
            {stepInfo.form === "mfa" && <>
              <MFASetup />
            </>}
            <div className="flex flex-row justify-evenly">
              {step !== 0 && <WhiteButton
                onClick={() => updateOnboardingProgress(values, false, false)}
                title="Back"
                type="button"
                disabled={isSubmitting || loading}
                className="w-50 mt-8"
              />}
              <BlueButton
                onClick={() => updateOnboardingProgress(values, false, true)}
                title={stepInfo.last ? "Finish" : "Next"}
                type="button"
                className="mt-8 w-50"
                disabled={(step !== 0 && (!isValid || isSubmitting)) || loading}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}


export default OnboardingForm
