import React, { CSSProperties, forwardRef, useMemo, useState } from "react"
import { classNames, formatTimestamp } from "@/lib/helpers"
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid'
import * as outlineIcons from "@heroicons/react/outline";
import { mediaServerURL } from "../services/server-config"
import { UserCircleIcon } from "@heroicons/react/outline"
import Scrollbars, { ScrollbarProps } from "react-custom-scrollbars-2";

// wraps anything in collapsable block
const withCollapse = (WrappedComponent: any) => ({
  title = 'Filter by',
  _isCollapsed = null,
  _setIsCollapsed = null,
  ...props
}: any) => {
  let [isCollapsed, setIsCollapsed] = useState(_isCollapsed)
  if (_isCollapsed && _setIsCollapsed) {
    isCollapsed = _isCollapsed
    setIsCollapsed = _setIsCollapsed
  }

  const toggle = () => {
    setIsCollapsed(!isCollapsed)
  }
  const wrapperClasses = classNames(
    isCollapsed ? 'hidden' : 'block'
  )

  const IconComponent = isCollapsed ? ArrowDownIcon : ArrowUpIcon

  return (
    <>
      <div className="flex items-center justify-between">
        <label htmlFor="toggle" className="text-teal-600 font-semibold">{title}</label>
        <button
          type="button"
          className="float-right inline-flex items-center px-2.5 py-1.5 border border-sky-800 shadow-sm text-xs font-medium rounded text-sky-800 bg-white hover:bg-sky-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-sky-800"
          id="toggle"
          name="toggle"
          onClick={toggle}
        >
          <IconComponent
            className="h-5 w-5" aria-hidden="true"
          />
        </button>
      </div>
      <div className={wrapperClasses}>
        <WrappedComponent  {...props} />
      </div>
    </>
  )
}

export { withCollapse }


interface IProfileImage {
  image: string | null | undefined,
  size: number,
  iconClass?: string
}


export const ProfileImage = ({ image, size, iconClass = '' }: IProfileImage) => {
  const profileImageAbsoluteUrl = (url: string) => {
    if (!url) return ''
    if (url.startsWith('http')) {
      return url
    } else {
      return `${mediaServerURL}${url}`
    }
  }

  const sizeClass = useMemo(() => `h-${size} w-${size}`, [size])

  return (
    <>
      {
        image ?
          <img
            className={classNames(
              sizeClass,
              iconClass,
              "rounded-full"
            )}
            src={profileImageAbsoluteUrl(image)} alt="" />
          :
          <UserCircleIcon
            className={classNames(
              sizeClass,
              iconClass,
              "text-teal-600"
            )}
            aria-hidden="true" />
      }</>
  )
}


export const valueOrNA = (value: any) => {
  return value || 'N/A'
}


export const CollapsableText = ({ text }: any) => {
  const [extended, setExtended] = useState(false)
  const escapeHTML = (text: any) => {
    return text.replace(/<[^>]+>/g, '')
  }
  const longText = escapeHTML(text).length > 500

  return (
    <>
      {
        longText ?
          <div>
            <div className="overflow-hidden whitespace-pre-wrap" style={{ height: extended ? 'auto' : '150px' }}>
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
            {extended ?
              <button className="text-sky-800 font-medium text-sm" onClick={() => { setExtended(false) }}>Read Less</button>
              :
              <button className="text-sky-800 font-medium text-sm" onClick={() => { setExtended(true) }}>Read More</button>
            }
          </div>
          :
          <div className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: text }} />
      }
    </>
  )
}


export const FormatedDate = ({ date, relative = false }: { date: Date, relative?: boolean }) => {
  return (
    <>
      {formatTimestamp(date, relative)}
    </>
  )
}

export const Badge = (children: string | JSX.Element, colorClasses = 'bg-sky-100 text-sky-800') => {
  return (
    <span className={`inline-block px-2 text-xs leading-5 font-semibold rounded-full ${colorClasses}`}>{children}</span>
  )
}

export const HeroIconOutline: React.FC<{ icon: string, className: string }> = ({ icon, className }) => {
  const Icon = outlineIcons[icon as keyof typeof outlineIcons];
  if (!Icon) return <></>;
  return <Icon aria-hidden="true" className={className} />;
}

export const HorizontalShadowScrollbars = forwardRef((props: ScrollbarProps, ref: React.ForwardedRef<Scrollbars>) => {
  const [shadowLeftOpacity, setShadowLeftOpacity] = useState(0)
  const [shadowRightOpacity, setShadowRightOpacity] = useState(0)

  const handleUpdate = (values: any) => {
    const { scrollLeft, scrollWidth, clientWidth } = values
    setShadowLeftOpacity(1 / 20 * Math.min(scrollLeft, 20))
    setShadowRightOpacity(1 / 20 * ((scrollWidth - clientWidth) - Math.max(scrollLeft, scrollWidth - clientWidth - 20)))
  }

  const shadowLeftStyle: CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: 10,
    background: 'linear-gradient(to right, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%)',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  }

  const shadowRightStyle: CSSProperties = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: 10,
    background: 'linear-gradient(to left, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%)',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  }

  return (
    <div className="relative z-0 w-full">
      <Scrollbars ref={ref} onUpdate={handleUpdate} className="asdf" {...props} />
      <div style={{ ...shadowLeftStyle, opacity: shadowLeftOpacity }} />
      <div style={{ ...shadowRightStyle, opacity: shadowRightOpacity }} />
    </div>
  )
})
