import { classNames } from "@/lib/helpers"
import { FocusEventHandler } from "react"
import { ChangeEventHandler } from "react"

interface ICheckboxFieldProps {
  fieldName: string,
  label: string | JSX.Element,
  handleChange: ChangeEventHandler,
  handleBlur: FocusEventHandler,
  values: Record<string, any>,
  errors: Record<string, any>,
  touched: Record<string, any>,
  required?: boolean,
  styles?: { wrapper?: string }
}

const CheckboxField = (
  { fieldName,
    label,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    required = false,
    styles
  }: ICheckboxFieldProps) => {
  return (
    <div className={styles?.wrapper ?? 'sm:border-t sm:border-gray-200 sm:pt-5'}>
      <div className="flex items-center gap-2">
        <input
          id={fieldName}
          name={fieldName}
          type="checkbox"
          required={required}
          checked={values[fieldName]}
          onChange={handleChange} onBlur={handleBlur}
          className={classNames(
            errors[fieldName] && touched[fieldName] && errors[fieldName] ?
              'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
              'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
            , 'h-4 w-4 rounded'
          )}
        />
        <label htmlFor={fieldName} className="block text-sm text-gray-900">
          {label}
          {required && <span className="required">*</span>}
        </label>
        <small className='text-red-500'>
          {errors[fieldName] && touched[fieldName] && errors[fieldName]}
        </small>
      </div>
    </div>
  )
}

export default CheckboxField
