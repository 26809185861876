import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Helmet as Head } from 'react-helmet-async'
import { Toaster } from 'react-hot-toast';

// State imports
import { accountService } from '../../../services'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { userState, isLoggedIn } from '../../../recoil/atoms/auth'
import { globalLoading } from '../../../recoil/atoms/app'

import Main from './Main'
import Sidebar from './Sidebar'
import Aside from './Aside'
import Nav from '../navigation/Nav'
import { classNames } from '../../../lib/helpers'
import Footer from './Footer';

interface ILayoutProps {
  title: string
  navigation?: boolean
  sidebar?: boolean
  aside?: any
  auth?: boolean
  back?: boolean,
  authFallback?: string
  children: any
}

export default function Layout({ title, navigation = true, aside = null, sidebar = true, auth = false, authFallback = "/login", children }: ILayoutProps) {
  const [userData, setUserData] = useRecoilState<any>(userState)
  const [loggedIn, setLoggedIn] = useRecoilState(isLoggedIn)
  const [hasCheckedLogin, setHasCheckedLogin] = useState(false)
  const setLoading = useSetRecoilState<boolean>(globalLoading)
  const history = useHistory()
  const location = useLocation()
  const [cookies, setCookie] = useCookies(['visited', 'cookie_consent'])

  const authCheckRunning = useRef(false)

  // check auth, fetch user data
  useEffect(() => {
    // run this whole thing once
    if (authCheckRunning.current) return
    authCheckRunning.current = true
    if (!loggedIn) {
      if (accountService.checkToken()) {
        setLoggedIn(true)
        setHasCheckedLogin(true)
        if (!userData) {
          setLoading(true)
          accountService.profile().then((result) => {
            setUserData(result.data)
            if (!result.data.onboarding_complete) {
              history.push('/onboarding', history.location.state )
            }
            setLoading(false)
          }).catch((error) => {
            console.error(error)
            setLoading(false)
          })
        }
      } else {
        setLoggedIn(false)
        setHasCheckedLogin(true)
        if (auth) {
          history.replace(
            {
              pathname: authFallback,
              state: { from: history.location }
            }
          )
        }
      }
    } else if (!userData) {
      setHasCheckedLogin(true)
      accountService.profile().then((result) => {
        setUserData(result.data)
        if (!result.data.onboarding_complete) {
          history.push('/onboarding', history.location.state )
        }
        setLoading(false)
      }).catch((error) => {
        console.error(error)
        setLoading(false)
      })
    }

  }, [auth, authFallback, history, loggedIn, userData, setCookie, setLoggedIn, setLoading, setUserData, setHasCheckedLogin])

  useEffect(() => {
    if (location.pathname === '/' && hasCheckedLogin && loggedIn === false && cookies.visited !== 'true') {
      history.push('/welcome')
    }

    if (cookies.cookie_consent === 'true') {
      setCookie('visited', true)
    }
  }, [history, cookies, loggedIn, setCookie, location, hasCheckedLogin])

  const _mainColClasses = (sidebar: boolean, aside: boolean) => {
    let mainCols = 12
    let asideCols = 4
    let sidebarCols = 2

    if (aside) {
      mainCols -= asideCols
    }
    if (sidebar) {
      mainCols -= sidebarCols
    }
    return {
      main: `lg:col-span-${mainCols}`,
      aside: `lg:col-span-${asideCols}`,
      sidebar: `lg:col-span-${sidebarCols}`,
    }
  }

  const layoutClasses = _mainColClasses(sidebar, aside)

  const wrapperClasses = classNames(
    'lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8', // large screens
    'xl:max-w-full xl:px-8',
    'flex flex-col', // mobile
    'overflow-y-auto', // infinite scroll - probably move elsewhere
    'md:py-10 sm:px-6',
    'flex-1',
    'mx-auto w-full max-w-3xl'
  )

  return (
    <>
      <Head>
        <title>Gumption - {title}</title>
      </Head>
      <Toaster
        position="top-right"
        reverseOrder={true}
        toastOptions={{
          duration: 2000
        }}
      />
      <div className="flex flex-col h-screen">
        {navigation && <Nav />}
        <div id="infinite-scroll-wrapper" className={wrapperClasses}>
          {sidebar && <Sidebar colClasses={layoutClasses.sidebar} />}
          <Main children={children} navigation={navigation} colClasses={layoutClasses.main} />
          {aside &&
            <Aside colClasses={layoutClasses.aside} >
              {aside}
            </Aside>
          }
        </div>
        <Footer/>
      </div>
    </>
  )
}
