import { _delete, fetch, patch, post } from './base.service'
import {
  IListCRUDService,
  IInvitationService,
  IServicePaginatedListResult,
  IServiceResult,
  _notImplemented
} from './types'
import * as serverConfig from './server-config'
import { withServiceParamsInUrl } from './utils'

export const JobStatus = {
  DRAFT: 'Draft',
  PUBLISHED: 'Published',
  FILLED: 'Filled',
  CLOSED: 'Closed',
}

async function fetchCompanies(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false
): Promise<IServicePaginatedListResult> {
  /*
  Either pass full URL (e.g. next from DRF) or queryString when applying filter.
  */
  const path = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.companies.fetchCompanies}${urlOrQueryString}`
  return await fetch(path, cancelToken)
}

async function fetchCompany(id: string, cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.fetchCompany}${id}/`
  return await fetch(url, cancelToken)
}

async function updateCompany(id: any, values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.updateCompany}`
    .replace('<company_id>', id)
  return await patch(url, values)
}

async function fetchCompanyJobs(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  let id = 0
  if (serviceParams) {
    if (serviceParams && !('id' in serviceParams)) throw new Error('fetchCompanyJobs requires "id" setting')
    id = serviceParams.id
    delete serviceParams.id
    urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  }
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.fetchCompanyJobs}${urlOrQueryString}`.replace('<company_id>', id.toString())
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function fetchCompanyJob(id: number, companyId: number, cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.deleteCompanyJob}`
    .replace('<company_id>', companyId.toString())
    .replace('<job_id>', id.toString())
  return await fetch(url, cancelToken)
}

async function fetchCompanyPublicJobs(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  { id }: { id: string }
): Promise<IServicePaginatedListResult> {
  // appent company id
  urlOrQueryString = `${urlOrQueryString}&company=${id}`
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.fetchJobs}${urlOrQueryString}`
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function createCompanyJob(values: any, companyId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.createCompanyJob}`.replace('<company_id>', companyId.toString())
  return await post(url, values)
}

async function updateCompanyJob(jobId: number, companyId: number, values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.updateCompanyJob}`
    .replace('<company_id>', companyId.toString())
    .replace('<job_id>', jobId.toString())
  return await patch(url, values)
}

async function deleteCompanyJob(id: number, companyId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.deleteCompanyJob}`
    .replace('<company_id>', companyId.toString())
    .replace('<job_id>', id.toString())
  return await _delete(url)
}

async function fetchCompanyMembers(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  let id = 0
  if (serviceParams) {
    if (serviceParams && !('id' in serviceParams)) throw new Error('fetchCompanyMembers requires "id" setting')
    id = serviceParams.id
    delete serviceParams.id
    urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  }
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.fetchCompanyMembers}${urlOrQueryString}`.replace('<company_id>', id.toString())
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function deleteCompanyMember(id: number, companyId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.deleteCompanyMember}`
    .replace('<company_id>', companyId.toString())
    .replace('<member_id>', id.toString())
  return await _delete(url)
}

async function fetchCompanyMemberInvitations(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  let id = 0
  if (serviceParams) {
    if (serviceParams && !('id' in serviceParams)) throw new Error('fetchCompanyMemberInvitations requires "id" setting')
    id = serviceParams.id
    delete serviceParams.id
    urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  }
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.fetchCompanyMemberInvitations}${urlOrQueryString}`.replace('<company_id>', id.toString())
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function deleteCompanyMemberInvitation(id: number, companyId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.deleteCompanyMemberInvitation}`
    .replace('<company_id>', companyId.toString())
    .replace('<invitation_id>', id.toString())
  return await _delete(url)
}

async function inviteCompanyMember(values: any, companyId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.inviteCompanyMember}`.replace('<company_id>', companyId.toString())
  return await post(url, values)
}

async function acceptCompanyMemberInvitation(companyId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.acceptCompanyMemberInvitation}`.replace('<company_id>', companyId.toString())
  return await post(url)
}

async function getPotentialReviewers(companyId: number, cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.fetchPotentialReviewers}`.replace('<company_id>', companyId.toString())
  return await fetch(url, cancelToken)
}

async function getPotentialRecruitmentAssignees(companyId: number, cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.companies.fetchPotentialRecruitmentAssignees}`.replace('<company_id>', companyId.toString())
  return await fetch(url, cancelToken)
}

export const companyService: IListCRUDService = {
  list: fetchCompanies,
  detail: fetchCompany,

  create: _notImplemented,
  update: updateCompany,
  delete: _notImplemented
}

export const companyPublicJobsService: IListCRUDService = {
  list: fetchCompanyPublicJobs,
  detail: _notImplemented,

  create: _notImplemented,
  update: _notImplemented,
  delete: _notImplemented
}

export const companyJobsService: IListCRUDService & {
  getPotentialReviewers: (companyId: number, cancelToken: any) => Promise<IServiceResult>,
  getPotentialRecruitmentAssignees: (companyId: number, cancelToken: any) => Promise<IServiceResult>
} = {
  list: fetchCompanyJobs,
  detail: fetchCompanyJob,

  create: createCompanyJob,
  update: updateCompanyJob,
  delete: deleteCompanyJob,

  getPotentialReviewers,
  getPotentialRecruitmentAssignees
}

export const companyMembersService: IListCRUDService & IInvitationService = {
  list: fetchCompanyMembers,
  detail: _notImplemented,

  create: inviteCompanyMember,
  delete: deleteCompanyMember,
  update: _notImplemented,

  accept: acceptCompanyMemberInvitation
}

export const companyMemberInvitationsService: IListCRUDService & IInvitationService = {
  list: fetchCompanyMemberInvitations,
  detail: _notImplemented,

  create: inviteCompanyMember,
  delete: deleteCompanyMemberInvitation,
  update: _notImplemented,

  accept: acceptCompanyMemberInvitation
}