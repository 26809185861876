import { Formik } from 'formik'

import { classNames } from '../../../lib/helpers'
import { commonHelpers } from '../../../services'
import { CityField, SelectField } from '../../forms/fields'
import { globalFilterAtom, defaultGlobalFilter, IGlobalFilter } from '../../../recoil/atoms/app'
import { commonTables } from '../../../recoil/atoms/common'
import { useRecoilState, useRecoilValue } from 'recoil'
import WhiteButton from '../../buttons/WhiteButton'
import BlueButton from '../../buttons/BlueButton'

export interface IFilterProps {
  doNotTrackFields?: Array<string>,
  FilterFieldsComponent?: any
}

const DefaultFilterFields = ({ common, values, errors, touched, handleBlur, handleChange }: any) => {
  const styles = {
    wrapper: ''
  }

  return (
    <div className="grid grid-cols-2 gap-x-4 gapy-y-3">
      <SelectField
        fieldName="industry"
        label="Industry"
        options={commonHelpers.industryDictionaryToArray(common?.industries)}
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
      <SelectField
        fieldName="role"
        label="Role"
        options={commonHelpers.rolesForIndustryByKey(common?.industries, values.industry!)}
        styles={styles}
        disabled={!values.industry}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
      <SelectField
        fieldName="experience"
        label="Experience"
        options={common?.experience_levels}
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
      <SelectField
        fieldName="availability"
        label="Available"
        options={common?.availabilities}
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
      <SelectField
        fieldName="travel_availability"
        label="Availability to Travel"
        options={common?.travel_availabilities}
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
      <SelectField
        fieldName="work_type"
        label="Work Type"
        options={common?.work_types}
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
      <SelectField
        fieldName="work_env"
        label="Work Env"
        options={common?.work_environments}
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
      <SelectField
        fieldName="compensation_type"
        label="Compensation Type"
        options={common?.compensation_types}
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
      <SelectField
        fieldName="state"
        label="State"
        options={common?.states}
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
      <CityField
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
    </div>
  )
}


const GlobalFilter = ({ doNotTrackFields = ['search', 'page'], FilterFieldsComponent = DefaultFilterFields }: IFilterProps) => {

  const [globalFilter, setGlobalFilter] = useRecoilState<IGlobalFilter>(globalFilterAtom)
  const common = useRecoilValue<any>(commonTables)


  const _initialValues: any = Object.assign({}, globalFilter, {
    industry: commonHelpers.industryKeyFromId(common, globalFilter.industry!) || '',
    country: commonHelpers.defaultCountryID(common)
  })

  for (let field of doNotTrackFields) {
    delete _initialValues[field]
  }

  const applyFilter = (values: any) => {
    values = Object.assign({}, values, {
      'industry': commonHelpers.industryIdFromKey(common, values.industry),
      'role': commonHelpers.conditionallyOmitRole(common, values.industry, values.role)
    })
    
    setGlobalFilter(Object.assign({}, globalFilter, values))
  }

  const clearFilter = () => {
    setGlobalFilter(Object.assign({}, defaultGlobalFilter, {
      search: globalFilter.search
    }))
  }


  return (
    <Formik
      initialValues={_initialValues}
      enableReinitialize={true}
      onSubmit={applyFilter}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <form
          className={classNames(
            'space-y-6 mt-4'
          )}
          onSubmit={handleSubmit}
        >
          <FilterFieldsComponent
            {...{ common, values, errors, touched, handleChange, handleBlur, handleSubmit }}
          />
          <div className="flex flex-row space-x-4 justify-start">
            <WhiteButton 
              title="Clear"
              onClick={clearFilter}
              className="w-24"
            />
            <BlueButton
              title="Apply"
              type="submit"
              className="w-24"
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

export default GlobalFilter
