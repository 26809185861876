import { BriefcaseIcon, LocationMarkerIcon, ClockIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import RedButton from '../../../buttons/RedButton'

import { formatDate } from '@/lib/helpers'
import Confirm from '../../../modals/Confirm'

const JobInner = ({ job, setIsConfirmOpen, canDelete }: any) => {
  console.log(job);
  return (
    <div className="px-4 py-4 flex items-center sm:px-6">
      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="truncate">
          <div className="flex text-sm flex-col gap-1">
            <p className="font-bold text-teal-600 truncate">{job.title}</p>
            <p className="text-gray-600 truncate text-xs line-clamp-2">{job.description}</p>
            <p className="text-gray-600 truncate text-sm line-clamp-2">{formatDate(job.from_date)} - {formatDate(job.to_date) || "Current"}</p>
          </div>
          {job.company && <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                <strong>{job.company}</strong>
              </p>
            </div>
          </div>}
          {(job.state || job.city) && <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                {job.state} {job.city}
              </p>
            </div>
          </div>}
          {job.work_type && <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                {job.work_type}
              </p>
            </div>
          </div>}
        </div>
      </div>
      {canDelete && <div>
        <RedButton
          title="Delete"
          className="w-20"
          onClick={() => setIsConfirmOpen(true)}
        />
      </div>}
    </div>
  )
}


const PreviousJob = ({ object, link = false, from = null, onDeleteConfirm, canDelete = true }: any) => {
  const fromLink = from === null ? '/jobs/' : from
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  return (
    <>
      <Confirm
        title={`Are you sure to delete ${object.title}?`}
        onConfirm={() => onDeleteConfirm(object.id)}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      />
      <li>
        {link ?
          <Link to={{ pathname: `/jobs/${object.id}`, state: { from: fromLink } }} className="block hover:bg-gray-50">
            <JobInner job={object} setIsConfirmOpen={setIsConfirmOpen} canDelete={canDelete} />
          </Link>
          :
          <JobInner job={object} setIsConfirmOpen={setIsConfirmOpen} canDelete={canDelete} />
        }
      </li>
    </>
  )
}

export default PreviousJob
