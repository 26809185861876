import { useEffect, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import axios from 'axios'

import { INotificationPreferences } from '../../../../recoil/types'
import { notificationService } from '../../../../services'
import BlueButton from '../../../buttons/BlueButton'
import { SelectField } from '../../../forms/fields'
import { LoadingInside } from '../../../layout/top_level/Loading'

// any validation
const NotificationPreferencesFormSchema = Yup.object().shape({
})

const NotificationPreferencesForm = () => {
  const [notificationPreferences, setNotificationPreferences] = useState<INotificationPreferences>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const source = axios.CancelToken.source()

    notificationService.getPreferences(source.token).then((result) => {
      setNotificationPreferences(result.data)
      setLoading(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to fetch notification preferences')
    })

    return () => {
      source.cancel()
    }
  }, [])

  const handleSubmit = (preferences: any, { setSubmitting }: { setSubmitting: Function }) => {
    notificationService.updatePreferences(preferences).then(() => {
      setSubmitting(false)
      toast.success('Notification preferences updated')
    }).catch((error) => {
      toast.error(`${error}`)
      setSubmitting(false)
    })
  }

  const prefOptions = [
    { id: 'Always', title: 'Always' },
    { id: 'Never', title: 'Never' }
  ]

  return (
    <>
      {loading
        ? <LoadingInside />
        : <Formik
          initialValues={{
            connections_push: notificationPreferences?.connections_push || 'Always',
            connections_email: notificationPreferences?.connections_email || 'Always',
            jobs_push: notificationPreferences?.jobs_push || 'Always',
            jobs_email: notificationPreferences?.jobs_email || 'Always',
            review_push: notificationPreferences?.review_push || 'Always',
            review_email: notificationPreferences?.review_email || 'Always',
            unreadchat_push: notificationPreferences?.unreadchat_push || 'Always',
            unreadchat_email: notificationPreferences?.unreadchat_email || 'Always'
          }}
          validationSchema={NotificationPreferencesFormSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-3 gap-y-8 sm:gap-y-5">
                <div></div>
                <div className="text-sm font-medium text-gray-700">Push</div>
                <div className="text-sm font-medium text-gray-700">Email</div>
                <div className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-7 sm:pb-1 sm:border-t sm:border-gray-200">Connection invites</div>
                <SelectField
                  fieldName="connections_push"
                  label=""
                  options={prefOptions}
                  includeNotSpecified={false}
                  {...{ values, errors, touched, handleBlur, handleChange }}
                />
                <SelectField
                  fieldName="connections_email"
                  label=""
                  options={prefOptions}
                  includeNotSpecified={false}
                  {...{ values, errors, touched, handleBlur, handleChange }}
                />
                <div className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-7 sm:pb-1 sm:border-t sm:border-gray-200">New jobs for me</div>
                <SelectField
                  fieldName="jobs_push"
                  label=""
                  options={prefOptions}
                  includeNotSpecified={false}
                  {...{ values, errors, touched, handleBlur, handleChange }}
                />
                <SelectField
                  fieldName="jobs_email"
                  label=""
                  options={prefOptions}
                  includeNotSpecified={false}
                  {...{ values, errors, touched, handleBlur, handleChange }}
                />
                <div className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-7 sm:pb-1 sm:border-t sm:border-gray-200">Job review alerts</div>
                <SelectField
                  fieldName="review_push"
                  label=""
                  options={prefOptions}
                  includeNotSpecified={false}
                  {...{ values, errors, touched, handleBlur, handleChange }}
                />
                <SelectField
                  fieldName="review_email"
                  label=""
                  options={prefOptions}
                  includeNotSpecified={false}
                  {...{ values, errors, touched, handleBlur, handleChange }}
                />
                <div className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-7 sm:pb-1 sm:border-t sm:border-gray-200">Unread chat alerts</div>
                <SelectField
                  fieldName="unreadchat_push"
                  label=""
                  options={prefOptions}
                  includeNotSpecified={false}
                  {...{ values, errors, touched, handleBlur, handleChange }}
                />
                <SelectField
                  fieldName="unreadchat_email"
                  label=""
                  options={prefOptions}
                  includeNotSpecified={false}
                  {...{ values, errors, touched, handleBlur, handleChange }}
                />
                <BlueButton
                  disabled={!isValid || !dirty || isSubmitting}
                  type="submit"
                  title={isSubmitting ? "Updating..." : "Update"}
                  className="mt-5 w-full sm:w-20"
                />
              </div>
            </form>
          )
          }
        </Formik >
      }
    </>
  )
}

export default NotificationPreferencesForm
