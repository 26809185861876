import { Badge } from "../../utils"

export const RoleBadge = ({ role }: any) => {
  let text = ''
  switch (role) {
    case 'RE':
      text = 'Recruiter'
      break;

    case 'JS':
      text = 'Job Seeker'
      break;

    case 'ISE':
      text = 'Stratice Employee'
      break;
    case 'CO':
      text = 'Company Owner'
      break;

    case 'HM':
      text = 'Hiring Manager'
      break;

    default: //NONE
      text = 'No Role'
      break;
  }

  return (
    Badge(text)
  )
}