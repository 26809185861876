import { Link } from 'react-router-dom'
import { BriefcaseIcon, OfficeBuildingIcon } from '@heroicons/react/solid'
import { IJobCandidate } from '@/services/jobcandidates.service'
import { IRecruitingStep } from '@/recoil/types'

interface IRecruitInnerProps {
  jobCandidate: IJobCandidate,
  acceptAction?: Function | null
}

interface IRecruitStepBadgeProps {
  step: IRecruitingStep
}
export const RecruitStepBadge = ({ step }: IRecruitStepBadgeProps) => {
  return (
    <div className="ml-2 flex-shirnk-0 flex">
      <p
        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 border-2"
        style={{ borderColor: `#${step.color}` }}
      >{step.name}</p>
    </div>
  )
}

interface IRecruitSourceBadgeProps {
  source: string
}
export const RecruitSourceBadge = ({ source }: IRecruitSourceBadgeProps) => {
  const color = 'bg-gray-200 text-gray-800'
  const text = source
  return (
    <div className="ml-2 flex-shirnk-0 flex">
      <p className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full capitalize ${color} border-2`}>{text}</p>
    </div>
  )
}

const RecruitInner = ({ jobCandidate }: IRecruitInnerProps) => {
  return (
    <div className="px-4 py-4 flex items-center sm:px-6">
      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="truncate">
          <div className="flex text-sm">
            <p className="font-bold text-teal-600 truncate">{jobCandidate.candidate ? `${jobCandidate.candidate.first_name} ${jobCandidate.candidate.last_name}` : jobCandidate.candidate_name}</p>
            <RecruitStepBadge step={jobCandidate.recruiting_step} />
          </div>

          <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                <strong>
                {jobCandidate.job ?
                  <Link to={{pathname: `/jobs/${jobCandidate.job.id}`, state: {from: '/profile/'}}} className="hover:text-teal-900">
                    {jobCandidate.job.published_version?.title}
                  </Link>
                  :
                  'N/A'
                }
                </strong>
              </p>
            </div>
          </div>

          <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <OfficeBuildingIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                <strong>
                {jobCandidate.job.company ?
                  <Link to={{pathname: `/companies/${jobCandidate.job.company_id}`, state: {from: '/profile/'}}} className="hover:text-teal-900">
                    {jobCandidate.job.company.name}
                  </Link>
                  :
                  'N/A'
                }
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface IRecruitProps {
  object: IJobCandidate
}

const Recruit = ({ object, ...props }: IRecruitProps) => {
  return (
    <li>
      <RecruitInner jobCandidate={object} {...props} />
    </li>
  )
}

export default Recruit
