export const serverURL = process.env.REACT_APP_API_SERVER_URL || 'http://localhost:8000/api'
export const mediaServerURL = process.env.REACT_APP_MEDIA_SERVER_URL || 'http://localhost:8000'
export const jwtAccessTokenTTL: number = parseInt(process.env.REACT_APP_JWT_ACCESS_TOKEN_TTL || '3600000')
export const websocketServerURL = process.env.REACT_APP_WEBSOCKET_SERVER_URL || 'ws://localhost:8000/ws'

export const requestCanceledByClientCode = 202
const profileBase = '/users/me/'

export const routes = {
    login: '/auth/login/',   
    loginCode: '/auth/code/',
    linkedInLogin: '/social-auth/linkedin/login/',
    register: '/users/register/',
    forgottenPassword: `${profileBase}password/forgot/`,
    setPassword: `${profileBase}password/set/`,
    changePassword: `${profileBase}password/change/`,
    changePasswordWithToken: `${profileBase}password/change-with-token/`,
    mfaPhone: `${profileBase}mfa-phone/`,
    deleteAccount: '/request-account-deletion/',
    cancelDeleteAccount: '/cancel-account-deletion/?token=<token>',
    requestAccountData: `/request-account-data/`,
    mfaMethods: '/auth/mfa/user-active-methods/',
    mfaSetPrimaryMethod: '/auth/mfa/change-primary-method/',
    activateMfaMethod: '/auth/<method_name>/activate/',
    confirmMfaMethodActivation: '/auth/<method_name>/activate/confirm/',
    deactivateMfaMethod: '/auth/<method_name>/deactivate/',
    requestMfaCode: '/auth/code/request/',
    refreshToken: '/token/refresh/',
    facebookLogin: '/token/social/facebook/',
    googleLogin: '/token/social/google/',
    commonOnboarding: '/common/onboarding/',
    citiesForCountry: '/common/country/<country_id>/<state_id>/cities/',
    onboardingProgress: '/onboarding/',
    completeOnboarding: '/onboarding/complete/',
    queryCompany: '/onboarding/query-company/',
    createProfile: '/people/',
    payments: {
        subscribe: '/payments/stripe/subscribe/?price_id=<price_id>',
        unsubscribe: '/payments/stripe/cancel-subscriptions/',
        account: '/payments/stripe/account/',
        accountOnboarding: '/payments/stripe/account/onboard/',
        accountLoginLink: '/payments/stripe/account/login/',
        payoutHistory: '/payments/stripe/payout-history/'
    },
    profile: {
        base: profileBase,
        recommendedJobs: `${profileBase}recommendations/jobs/`,
        recommendedConnections: `${profileBase}recommendations/connections/`,
        myConnections: `${profileBase}connections/`,
        previousJobs: `${profileBase}previous-jobs/`,
        links: `${profileBase}links/`,
        photo: `${profileBase}photo/`,
        weeklyStats: `${profileBase}stats/weekly/`,
        tracking: {
            jobs: `${profileBase}views/jobs/`,
            company: `${profileBase}views/company/`,
            person: `${profileBase}views/person/`,
            combined: `${profileBase}views/combined/`
        },
        recruitedHistory: `${profileBase}recruited/history/`
    },
    notification: {
        pushToken: `${profileBase}push-token/`,
        fetch: `${profileBase}notifications/`,
        resolvePending: `${profileBase}notifications/<notif_id>/resolve/`,
        markAsSeen: `${profileBase}notifications/<notif_id>/seen/`,
        markAllAsSeen: `${profileBase}notifications/seen/`,
        preferences: `${profileBase}notifications/preferences/`,
        preferencesWithToken: '/notification-preferences/email/'
    },
    jobs: {
        fetchJobs: '/jobs/',
        fetchJob: '/jobs/<id>/',
        deleteJob: '/jobs/<id>/',
        comments: '/jobs/<id>/comments/',
        closeJob: '/jobs/<id>/close/',
        cloneJob: '/jobs/<id>/clone/',
        shareJob: '/share/job/',
        fetchVersions: '/jobs/<id>/versions/',
        createNewDraft: '/jobs/<id>/versions/',
        fetchVersionByNumber: '/jobs/<id>/version-by-number/<number>/',
        fetchJobVersion: '/jobs/versions/<id>/',
        fetchVersionReviewers: '/jobs/versions/<id>/reviewers/',
        approveVersion: '/jobs/versions/<id>/approve/',
        rejectVersion: '/jobs/versions/<id>/reject/',
        submitVersionForReview: '/jobs/versions/<id>/submit-for-review/',
        updateVersionDetails: '/jobs/versions/<id>/',
        endVersionReview: '/jobs/versions/<id>/end-review/',
        closeVersion: '/jobs/versions/<id>/close/',
        publishVersion: '/jobs/versions/<id>/publish/',
        unpublishVersion: '/jobs/versions/<id>/unpublish/',
        versionDifferences: '/jobs/<id>/versions/diff/?prev_version=<prev_id>&current_version=<curr_id>',
        updatePositionsAvailable: '/jobs/<id>/positions/',
        fetchCandidates: '/jobs/<id>/candidates/',
        fetchMyRecruits: '/jobs/<id>/my-recruits/',
        recruitCandidate: '/jobs/<id>/recruit/',
        apply: '/jobs/<id>/apply/',
        updateRecruitingProcess: '/jobs/<id>/recruiting-process/',
        updateVettingType: '/jobs/<id>/vetting-type/',
        searchCandidates: '/jobs/<id>/search-candidates/'
    },
    people: {
        fetchPeople: '/people/',
        fetchPerson: '/people/',
        updatePerson: '/people/<person_id>/'
    },
    companies: {
        fetchCompanies: '/companies/',
        fetchCompany: '/companies/',
        fetchCompanyJobs: '/companies/<company_id>/jobs/',
        fetchPotentialReviewers: '/companies/<company_id>/reviewers/',
        fetchPotentialRecruitmentAssignees: '/companies/<company_id>/recruitment-assignees/',
        createCompanyJob: '/companies/<company_id>/jobs/',
        deleteCompanyJob: '/companies/<company_id>/jobs/<job_id>/',
        updateCompanyJob: '/companies/<company_id>/jobs/<job_id>/',
        fetchCompanyJob: '/companies/<company_id>/jobs/<job_id>/',
        publishCompanyJob: '/companies/<company_id>/jobs/<job_id>/publish/',
        fetchCompanyMembers: '/companies/<company_id>/members/',
        deleteCompanyMember: '/companies/<company_id>/members/<member_id>/',
        fetchCompanyMemberInvitations: '/companies/<company_id>/members/invitations/',
        inviteCompanyMember: '/companies/<company_id>/members/invitations/',
        acceptCompanyMemberInvitation: '/companies/<company_id>/members/invitations/accept/',
        deleteCompanyMemberInvitation: '/companies/<company_id>/members/invitations/<invitation_id>/',
        updateCompany: '/companies/<company_id>/',
        listPendingCandidates: '/companies/<company_id>/pending-candidates/'
    },
    jobCandidates: {
        myDrafts: `${profileBase}recruit/current/`,
        list: '/jobcandidates/',
        create: '/jobcandidates/',
        update: '/jobcandidates/<job_candidate_id>/',
        delete: '/jobcandidates/',
        fillExternal: '/jobcandidates/external/',
        updateStep: '/jobcandidates/<job_candidate_id>/step/',
        document: '/jobcandidates/<job_candidate_id>/document/',
        updateAdditionalInfo: '/jobcandidates/<job_candidate_id>/additional-info/'
    },
    recruitment: {
        abstractProcesses: '/recruitment/abstract-processes/',
        abstractSteps: '/recruitment/abstract-steps/',
        process: '/recruitment/process/<id>/',
        processes: '/recruitment/processes/',
        createStep: '/recruitment/step/',
        deleteStep: '/recruitment/step/<id>/',
        potentialAssignees: '/recruitment/step/potential-assignees/',
        updateStepOrder: '/recruitment/step/<id>/order/',
        updateProcessName: '/recruitment/process/<id>/name/',
        document: '/recruitment/step/<id>/document/',
        recruiterAbstractProcesses: `${profileBase}recruit/abstract-processes/`,
        recruiterAbstractSteps: `${profileBase}recruit/abstract-steps/`,
        recruiterProcesses: `${profileBase}recruit/process/`,
        recruiterProcess: `${profileBase}recruit/process/<id>/`,
        recruiterLinkProcessToJob: `${profileBase}recruit/process/<id>/link/`,
        recruiterProcessCandidates: `${profileBase}recruit/process/<id>/candidates/`,
        recruiterSubmitProcessCandidates: `${profileBase}recruit/process/<id>/submit-candidates/`,
        recruiterSteps: `${profileBase}recruit/steps/`,
        recruiterRecruits: `${profileBase}recruit/history/`
    },
    reports: {
        jobCandidates: '/reporting/job-candidates/',
        timeToFill: '/reporting/time-to-fill/'
    },
    messaging: {
        websocketChat: `/chat/`,
        fetchMessages: '/messages/',
        recentUsers: '/messages/recent/',
        broadcast: '/messages/broadcast/'
    },
    notes: {
        fetchNotes: '/notes/',
        createNote: '/notes/',
        deleteNote: '/notes/<note_id>/',
        calendar: '/notes/calendar/'
    },
    files: {
        fetchFiles: '/files/',
        createFile: '/files/',
        deleteFile: '/files/<file_id>/',
        fetchPersonFiles: '/users/<user_id>/files/',
        fetchCompanyFiles: '/companies/<company_id>/files/',
        createCompanyFile: '/companies/<company_id>/files/',
        deleteCompanyFile: '/companies/<company_id>/files/<file_id>/'
    }
}