import { toast } from "react-toastify"
import { jobCandidatesService } from "@/services"
import PaginatedList from "../../layout/list/PaginatedList"
import Recruit from "../../profile/company/recruits/Recruit"
import { IJob, IJobVersion, jobsService } from "@/services/jobs.service"
import BlueButton from "@/components/buttons/BlueButton"
import Modal from "@/components/modals/Modal"
import { useRef, useState } from "react"
import ExternalCandidateForm from "../forms/ExternalCandidateForm"
import WhiteButtonLink from "@/components/links/WhiteButtonLink"
import UpdateJobRecruitingProcessForm from "../forms/UpdateJobRecruitingProcessForm"


const AppliedPeople = ({ job, jobVersion, refetchJob }: { job: IJob, jobVersion: IJobVersion, refetchJob?: () => void }) => {
  const [externalCandidateModalOpen, setExternalCandidateModalOpen] = useState(false)
  const [updateRecruitingProcessModalOpen, setUpdateRecruitingProcessModalOpen] = useState(false)
  const listRef = useRef<any>()

  const fillWithExternalCandidate = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    jobCandidatesService.fillExternal(
      job.id,
      jobVersion.id,
      values['email'],
      values['name']
    ).then(({success, data}) => {
      if (success) {
        toast.success(`Filled a job position with the candidate: "${values['name']}"`)
        setExternalCandidateModalOpen(false)
        listRef.current?.fetchItems()
        refetchJob && refetchJob()
      } else {
        console.error(data)
        toast.error('Failed to fill job position with external candidate')
      }
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to fill job position with external candidate')
      setSubmitting(false)
    })
  }

  const updateRecruitingProcess = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    jobsService.updateRecruitingProcess(
      job!.id,
      values['recruiting_process']
    ).then(({success, data}) => {
      if (success) {
        toast.success('Successfully added the recruiting pipeline')
        setUpdateRecruitingProcessModalOpen(false)
        refetchJob && refetchJob()
      } else {
        console.error(data)
        toast.error('Failed to add the recruiting pipeline')
      }
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to add the recruiting pipeline')
      setSubmitting(false)
    })
  }

  return (
    <>
      <Modal
        title="Add External Candidate"
        open={externalCandidateModalOpen}
        setOpen={setExternalCandidateModalOpen}
        hasForm={true}
      >
        <ExternalCandidateForm handleSubmit={fillWithExternalCandidate} />
      </Modal>
      <Modal
        title="Update Recruiting Pipeline"
        open={updateRecruitingProcessModalOpen}
        setOpen={setUpdateRecruitingProcessModalOpen}
        hasForm={true}
      >
        {job && <UpdateJobRecruitingProcessForm recruiting_process={job.recruiting_process} handleSubmit={updateRecruitingProcess} />}
      </Modal>
      <div className="flex gap-2 sm:items-center flex-col sm:flex-row mt-4">
        {job.has_been_published && <WhiteButtonLink
          title="Go to Candidates"
          className="my-4"
          to={{
            pathname: `/jobs/${job.id}/candidates`,
            state: { from: `/companies/${job.company?.id}/job/${job.id}` }
          }}
        />}
        {(job.open && !job.has_been_published) && <>
          <WhiteButtonLink
            title="Go to Pipeline"
            to={{
              pathname: `/companies/${job.company?.id}/recruiting-pipeline/${job.recruiting_process.id}`,
              state: { from: `/companies/${job.company?.id}/job/${job.id}` }
            }}
          />
          <BlueButton
            title="Update Pipeline"
            onClick={() => setUpdateRecruitingProcessModalOpen(true)}
          />
        </>}
        {(job.open && job.filled < 1 && job.has_been_published) && <BlueButton
          title="Add External Candidate"
          className="mt-2 sm:mt-0 sm:ml-2"
          onClick={() => setExternalCandidateModalOpen(true)}
        />}
      </div>
      <PaginatedList
        service={jobCandidatesService}
        serviceParams={{
          job: job.id
        }}
        pageSize={10}
        onServiceError={toast.error}
        itemComponent={Recruit}
        itemsProps={{ showCandidatesButton: false }}
        noResultsText="No Applied People"
        ref={listRef}
      />
    </>
  )
}

export default AppliedPeople
