import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useRecoilState } from 'recoil'

import { SearchIcon } from '@heroicons/react/solid'

import { classNames } from '../../../lib/helpers'
import { globalFilterAtom } from '../../../recoil/atoms/app'
import { globalLoading } from '../../../recoil/atoms/app'
import { useEffect } from 'react'
import { trimFilter } from '@/services/utils'
import { companyService, jobsService, peopleService } from '@/services'
import { LoadingInside } from '../top_level/Loading'
import { useHistory } from 'react-router-dom'


const Search = () => {
  const [globalFilter, setGlobalFilter] = useRecoilState(globalFilterAtom)
  const [searchTerm, setSearchTerm] = useState(globalFilter.search)
  const loading = useRecoilValue(globalLoading)
  const [previewLoading, setPreviewLoading] = useState(false)
  const [jobsPreview, setJobsPreview] = useState<string[]>([])
  const [peoplePreview, setPeoplePreview] = useState<string[]>([])
  const [companiesPreview, setCompaniesPreview] = useState<string[]>([])
  const [selectionIndex, setSelectionIndex] = useState<number | undefined>(undefined)
  const [hasSearched, setHasSearched] = useState(false)
  const history = useHistory()

  // update local search state on global search state change 
  useEffect(() => {
    setSearchTerm(globalFilter.search)
  },[globalFilter.search])

  const populatePreviewResults = async (filter: string) => {
    setPreviewLoading(true)
    const jobsRes = await jobsService.list('?' + trimFilter(filter), null, false)
    setJobsPreview(jobsRes.data.results.map((entry: any) => entry.published_version.title).slice(0, 5))
    const peopleRes = await peopleService.list('?' + trimFilter(filter), null, false)
    setPeoplePreview(peopleRes.data.results.map((entry: any) => `${entry.first_name} ${entry.last_name}`).slice(0, 5))
    const companiesRes = await companyService.list('?' + trimFilter(filter), null, false)
    setCompaniesPreview(companiesRes.data.results.map((entry: any) => entry.name).slice(0, 5))
    setPreviewLoading(false)
  }

  const submit = (term: any) => {
    const newGlobalFilter = Object.assign({}, globalFilter, {
      search: term
    })
    setGlobalFilter(newGlobalFilter)
    populatePreviewResults(new URLSearchParams(newGlobalFilter as any).toString())
  }

  const onInputChange = (e: any) => {
    setHasSearched(false)
    setSelectionIndex(undefined)
    setSearchTerm(e.target.value)
    if (e.target.value.length === 0) {
      submit(e.target.value)
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (selectionIndex === undefined) {
        submit(searchTerm)
        setHasSearched(true)
      } else {
        if (selectionIndex === 0) {
          history.push(`/?search=${searchTerm}`)
        } else if (selectionIndex === 1) {
          history.push(`/people?search=${searchTerm}`)
        } else if (selectionIndex === 2) {
          history.push(`/companies?search=${searchTerm}`)
        }
        setSelectionIndex(undefined)
        setHasSearched(false)
      }
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault()
      setSelectionIndex((selectionIndex) => selectionIndex !== undefined ? Math.min(selectionIndex + 1, 2) : 0)
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault()
      setSelectionIndex((selectionIndex) => selectionIndex !== undefined ? Math.max(selectionIndex - 1, 0) : undefined)
    }
  }

  const navigateToSearchPage = (url: string) => {
    history.push(url)
    setHasSearched(false)
    setSelectionIndex(undefined)
  }

  return (
    <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
      <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
        <div className="w-full">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative group">
            <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
              <SearchIcon className="h-5 w-5 text-teal-600" aria-hidden="true" />
            </div>
            <input
              id="search"
              name="search"
              type="search"
              className={classNames(
                "block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-1 sm:pr-3 text-xs sm:text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-teal-600 focus:border-teal-600 sm:text-sm",
                loading ? 'disabled:opacity-50' : ''
              )}
              placeholder='Name, Job Title, Company Title'
              value={searchTerm}
              onInput={onInputChange}
              disabled={loading}
              onKeyDown={onKeyDown}
            />
            <div className={`hidden ${hasSearched && 'group-focus-within:block hover:block'} absolute left-0 right-0 bg-white mt-0.5 border-gray-300 border shadow rounded-md p-2 space-y-1`}>
              {!previewLoading ? <>
                <div onClick={() => navigateToSearchPage(`/?search=${searchTerm}`)} className={`cursor-pointer ${'hover:border-l-4 hover:border-teal-600 hover:pl-2'.replaceAll('hover:', selectionIndex === 0 ? '' : 'hover:')}`}>
                  <div className="text-teal-600 text-sm font-bold mb-1">Jobs</div>
                  {jobsPreview.length > 0 ? <ul className="text-xs text-gray-500 font-semibold space-y-0.5">
                    {jobsPreview.map((name) => <li key={name}>{name}</li>)}
                  </ul> : <div className="text-xs text-gray-500">No Results</div>}
                </div>
                <div onClick={() => navigateToSearchPage(`/people?search=${searchTerm}`)} className={`cursor-pointer ${'hover:border-l-4 hover:border-teal-600 hover:pl-2'.replaceAll('hover:', selectionIndex === 1 ? '' : 'hover:')}`}>
                  <div className="text-teal-600 text-sm font-bold mb-1">People</div>
                  {peoplePreview.length > 0 ? <ul className="text-xs text-gray-500 font-semibold space-y-0.5">
                    {peoplePreview.map((name) => <li key={name}>{name}</li>)}
                  </ul> : <div className="text-xs text-gray-500">No Results</div>}
                </div>
                <div onClick={() => navigateToSearchPage(`/companies?search=${searchTerm}`)} className={`cursor-pointer ${'hover:border-l-4 hover:border-teal-600 hover:pl-2'.replaceAll('hover:', selectionIndex === 2 ? '' : 'hover:')}`}>
                  <div className="text-teal-600 text-sm font-bold mb-1">Companies</div>
                  {companiesPreview.length > 0 ? <ul className="text-xs text-gray-500 font-semibold space-y-0.5">
                    {companiesPreview.map((name) => <li key={name}>{name}</li>)}
                  </ul> : <div className="text-xs text-gray-500">No Results</div>}
                </div>
              </> : <LoadingInside />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Search
