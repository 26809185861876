import DatePicker from 'react-datepicker'
import { useState, useEffect, createElement } from "react"
import { notesService, serviceType } from "../../services"

import Note from "../notes/Note"
import NoResults from "../layout/list/NoResults"
import { LoadingInside } from '../layout'
import SlideOver from '../slideover/SlideOver'
import NoteForm from '../notes/NoteForm'
import TealButton from '../buttons/TealButton'
import { useRecoilValue } from 'recoil'
import { userState } from '@/recoil/atoms/auth'
import { toast } from 'react-toastify'

const Calendar = function () {
  const userData = useRecoilValue(userState)

  const [startDate, setStartDate] = useState(new Date())
  const [selectedMonth, setSelectedMonth] = useState('')

  const [loading, setLoading] = useState<boolean>(false)
  let [items, setItems] = useState<Array<any>>([])
  let [filteredItems, setFilteredItems] = useState<Array<any>>([])
  let [highlightDates, setHighlightDates] = useState<Array<any>>([])
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false)

  const fetchNotes = (month: string) => {
    setLoading(true)
    notesService.calendar(`?month=${month}`, null).then(({ success, data }: serviceType.IServicePaginatedListResult) => {
      if (success) {
        setItems(data.results)
        setLoading(false)
      }
    }).catch((error: any) => {
      setLoading(false)
    })
  }

  useEffect(() => {
    setSelectedMonth(startDate.toISOString().slice(0, 7))
  }, [startDate])

  useEffect(() => {
    if (selectedMonth) {
      fetchNotes(selectedMonth)
    }
  }, [selectedMonth])

  useEffect(() => {
    const date = startDate.toISOString().slice(0, 10)
    const filtered = items.filter((item) => item.datetime && item.datetime.slice(0, 10) === date).sort((a, b) => new Date(b.datetime).valueOf() - new Date(a.datetime).valueOf())
    setFilteredItems(filtered)
  }, [startDate, items])

  useEffect(() => {
    const dates = items.filter(item => item.datetime).map(item => new Date(item.datetime))
    setHighlightDates(dates)
  }, [items])

  const handleNoteSubmit = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    notesService.create(values).then((res: any) => {
      setSubmitting(false)
      setIsNoteDialogOpen(false)
      toast.success('Successfully added to calendar')
      fetchNotes(selectedMonth)
    }).catch(() => {
      setSubmitting(false)
    })
  }

  return (
    <div className="flex sm:flex-row flex-col">
      <div>
        <DatePicker
          selected={startDate}
          onChange={(date: any) => setStartDate(date)}
          highlightDates={highlightDates}
          inline
          adjustDateOnChange
        />
        <SlideOver
          title="Add to Calendar"
          open={isNoteDialogOpen}
          setOpen={setIsNoteDialogOpen}
          hasForm={true}
        >
          {userData && createElement(NoteForm, {
            object: userData.person,
            handleSubmit: handleNoteSubmit,
            initial: {
              datetime: startDate
            }
          })}
        </SlideOver>
        <TealButton
          title="Add to Calendar"
          className="mt-2"
          onClick={() => setIsNoteDialogOpen(true)}
        />
      </div>
      <div className="pt-4 sm:pt-0 sm:pl-4 flex-grow">

      {!loading ?
        <div className="bg-white shadow overflow-hidden rounded-md">
          {
            filteredItems.length > 0 ?
              <>
                <ul className="divide-y divide-gray-200 mt-0">
                  {filteredItems.map((object: any) => (
                    <Note
                      key={object.id}
                      object={object}
                      showRelated={true}
                      showLink={true}
                      onDeleteConfirm={() => {}}
                      onUpdateClick={null}
                      showDeleteButton={false}
                    />
                  ))}
                </ul>
              </>
              :
              <NoResults text={'No Entries'} />
          }
        </div>
        :
        <LoadingInside />
      }
      </div>
    </div>
  )
}

export default Calendar
