import { ChangeEventHandler, FocusEventHandler } from "react"

interface ITextAreaFieldProps {
  fieldName: string,
  label: string,
  handleChange: ChangeEventHandler,
  handleBlur: FocusEventHandler,
  values: Record<string, any>,
  errors: Record<string, any>,
  touched: Record<string, any>,
  required?: boolean
}


const TextAreaField = (
  { fieldName,
    label,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    required = false
  }: ITextAreaFieldProps) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label htmlFor={fieldName} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <textarea
          id={fieldName}
          name={fieldName}
          autoComplete={fieldName}
          required={required}
          className="max-w-lg block w-full shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          onChange={handleChange} onBlur={handleBlur} value={values[fieldName]}
        />
      </div>
      <small className='text-red-500'>
        {errors[fieldName] && touched[fieldName] && errors[fieldName]}
      </small>
    </div>
  )
}

export default TextAreaField
