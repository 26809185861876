import WhiteButton from "../buttons/WhiteButton"

function TermsAndConditions() {
  return (
    <>
      <object data="/terms-and-conditions.pdf" type="application/pdf" width="100%" height="1000px">
        <WhiteButton
          title="Read Terms & Conditions"
          onClick={() => window.open("/terms-and-conditions.pdf", "_blank")}
        />
      </object>
    </>
  )
}

export default TermsAndConditions
