import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ChevronLeftIcon, InformationCircleIcon } from '@heroicons/react/solid'

import { Layout } from '@/components/layout'
import { LoadingInside } from '@/components/layout/top_level/Loading'
import { IJob, IJobVersion, jobsService, JobStatusReadableMap } from '@/services/jobs.service'
import HeadingsH2 from '@/components/headings/HeadingsH2'
import WhiteButton from '@/components/buttons/WhiteButton'
import BlueButton from '@/components/buttons/BlueButton'
import axios, { CancelTokenSource } from 'axios'
import JobComments from '@/components/jobs/JobComments'
import { commonTables } from '@/recoil/atoms/common'
import { useRecoilValue } from 'recoil'
import { commonHelpers, commonService } from '@/services'
import TealButton from '@/components/buttons/TealButton'
import Modal from '@/components/modals/Modal'
import JobPreview from '@/components/jobs/JobPreview'
import { toast } from 'react-toastify'
import Confirm from '@/components/modals/Confirm'
import RedButton from '@/components/buttons/RedButton'
import { FormatedDate } from '@/components/utils'
import { classNames } from '@/lib/helpers'
import JobReviewers from '@/components/jobs/JobReviewers'
import SubmitForReviewForm from '@/components/jobs/forms/SubmitForReviewForm'

const ChangeableFields: ChangeableField[] = [
  { field: 'title', title: 'Title', type: 'text', subtype: 'text' },
  { field: 'description', title: 'Description', type: 'text', subtype: 'text' },
  { field: 'requirements', title: 'Responsibilities', type: 'textarea' },
  { field: 'qualification', title: 'Qualification', type: 'textarea' },
  { field: 'benefits', title: 'Benefits', type: 'textarea' },
  { field: 'state', title: 'State', type: 'select' },
  { field: 'city', title: 'City', type: 'select' },
  { field: 'industry', title: 'Industry', type: 'select' },
  { field: 'role', title: 'Role', type: 'select' },
  { field: 'work_type', title: 'Work Type', type: 'select' },
  { field: 'work_env', title: 'Work Environment', type: 'select' },
  { field: 'experience', title: 'Experience', type: 'select' },
  { field: 'availability', title: 'Availability', type: 'select' },
  { field: 'travel_availability', title: 'Availability To Travel', type: 'select' },
  { field: 'compensation_lower', title: 'Compensation (Lower)', type: 'text', subtype: 'number' },
  { field: 'compensation_upper', title: 'Compensation (Upper)', type: 'text', subtype: 'number' },
  { field: 'compensation_type', title: 'Compensation Type', type: 'select' },
]

type ChangeableField = {
  field: string,
  title: string,
  type: 'text' | 'textarea' | 'select',
  subtype?: 'text' | 'number'
}

const CompanyJobVersionExplorer = () => {
  let { job_id } = useParams<{ job_id: string }>()

  const common = useRecoilValue<any>(commonTables)
  const [job, setJob] = useState<IJob>()
  const [jobVersion, setJobVersion] = useState<IJobVersion>()
  const [creatingVersion, setCreatingVersion] = useState(false)
  const [loading, setLoading] = useState(true)
  const [editingDisabled, setEditingDisabled] = useState(true)
  const [savingDetails, setSavingDetails] = useState(false)
  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  const [reviewersModalOpen, setReviewersModalOpen] = useState(false)
  const [cancelEditingConfirmOpen, setCancelEditingConfirmOpen] = useState(false)
  const [submitForReviewModalOpen, setSubmitForReviewModalOpen] = useState(false)
  const [jobVersionDetails, setJobVersionDetails] = useState<{ [name: string]: any }>({})
  const [renderedFieldInputs, setRenderedFieldInputs] = useState<{ [field: string]: JSX.Element | null }>({})
  const [afterUnsavedConfirmFunc, setAfterUnsavedConfirmFunc] = useState<Function>()
  const [isDraftCreationConfirmOpen, setIsDraftCreationConfirmOpen] = useState(false)
  const versionApiSource = useRef<CancelTokenSource>()

  const setJobVersionDetail = useCallback((key: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setJobVersionDetails({
      ...jobVersionDetails,
      [key]: event.target.value
    })
  }, [jobVersionDetails])

  const location = useLocation<any>()
  const history = useHistory()
  const query = useMemo(() => new URLSearchParams(location.search), [location.search])
  const hasBack = (location?.state?.from !== undefined)

  const versionNumber = useMemo(() => parseInt(query.get('num') ?? '1'), [query])

  const loadJobVersion = useCallback((jobId: number, versionNumber: number, silent = false) => {
    !silent && setLoading(true)
    setEditingDisabled(true)

    if (versionApiSource.current) {
      versionApiSource.current.cancel()
    }

    versionApiSource.current = axios.CancelToken.source()

    jobsService.fetchVersionByNumber(jobId, versionNumber, versionApiSource.current.token).then((res) => {
      setJobVersion(res.data)
      !silent && setLoading(false)
    }).catch(() => {
      toast.error('Failed to load job version')
      !silent && setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!history) {
      return
    }

    if (!versionNumber) {
      history.push('/')
      return
    }

    loadJobVersion(parseInt(job_id), versionNumber)
  }, [versionNumber, history, loadJobVersion, job_id])

  useEffect(() => {
    jobsService.detail(parseInt(job_id), null).then((res) => {
      setJob(res.data)
    })
  }, [job_id])

  useEffect(() => {
    jobVersion && setJobVersionDetails({
      title: jobVersion.title,
      description: jobVersion.description,
      requirements: jobVersion.requirements,
      benefits: jobVersion.benefits,
      qualification: jobVersion.qualification,
      state: jobVersion.state?.id ?? '',
      city: jobVersion.city?.id ?? '',
      industry: jobVersion.industry ? commonHelpers.industryKeyFromId(common, jobVersion.industry.id) : '',
      role: jobVersion.role?.id ?? '',
      work_type: jobVersion.work_type?.id ?? '',
      work_env: jobVersion.work_env?.id ?? '',
      experience: jobVersion.experience?.id ?? '',
      availability: jobVersion.availability?.id ?? '',
      travel_availability: jobVersion.travel_availability?.id ?? '',
      compensation_lower: jobVersion.compensation_lower,
      compensation_upper: jobVersion.compensation_upper,
      compensation_type: jobVersion.compensation_type?.id ?? ''
    })
  }, [common, jobVersion])

  const maxVersionNumber = useMemo(() => job?.latest_version.version_number ?? 9999, [job])

  const goBack = () => {
    history.goBack()
  }

  const createNewDraft = useCallback(() => {
    setCreatingVersion(true)
    jobsService.createNewDraft(job!.id, jobVersion?.id).then((res) => {
      toast.success('Successfully created new version draft')
      setCreatingVersion(false)
      setJob({
        ...job!,
        latest_version: res.data,
        status: 'Draft'
      })
      history.replace({
        pathname: location.pathname,
        search: `?num=${res.data.version_number}`,
        state: { from: location?.state?.from ?? null }
      })
    }).catch(() => {
      toast.error('Failed to create new version draft')
      setCreatingVersion(false)
    })
  }, [history, job, jobVersion, location])

  const createNewDraftWrapper = useCallback(() => {
    if (job!.status === 'Review') {
      setIsDraftCreationConfirmOpen(true)
    } else {
      createNewDraft()
    }
  }, [createNewDraft, job])

  const shiftVersionNumber = (delta: number) => {
    let newVersionNumber = versionNumber + delta
    newVersionNumber = Math.max(newVersionNumber, 1)
    newVersionNumber = Math.min(newVersionNumber, maxVersionNumber)

    history.replace({
      pathname: location.pathname,
      search: `?num=${newVersionNumber}`,
      state: { from: location?.state?.from ?? null }
    })
  }

  const gotoFirstVersion = () => {
    history.replace({
      pathname: location.pathname,
      search: '?num=1',
      state: { from: location?.state?.from ?? null }
    })
  }

  const gotoLastVersion = () => {
    history.replace({
      pathname: location.pathname,
      search: `?num=${maxVersionNumber}`,
      state: { from: location?.state?.from ?? null }
    })
  }

  const openUnsavedConfirm = useCallback((afterConfirmFunc: Function) => {
    if (!editingDisabled) {
      setAfterUnsavedConfirmFunc(() => afterConfirmFunc)
      setCancelEditingConfirmOpen(true)
    } else {
      afterConfirmFunc()
    }
  }, [editingDisabled])

  const submitForReview = useCallback((values: any, { setSubmitting }: { setSubmitting: Function }) => {
    jobsService.submitVersionForReview(
      jobVersion!.id,
      values['required_reviewers'],
      values['optional_reviewers'],
      values['optional_reviewers_required'],
      values['notify_users']
    ).then(({success, data}) => {
      if (success) {
        toast.success('Successfully submitted version for review')
        setSubmitForReviewModalOpen(false)
        loadJobVersion(job!.id, versionNumber)
        setJob({
          ...job!,
          status: 'Review'
        })
      } else {
        console.error(data)
        toast.error('Failed to submit version for review')
      }
      setSubmitting(false)
    }).catch((error) => {
      if (error.code === 409) {
        toast.warning('You cannot have reviewer(s) that are both mandatory and optional reviewers')
      } else {
        console.error(error)
        toast.error('Failed to submit version for review')
      }
      setSubmitting(false)
    })
  }, [job, jobVersion, loadJobVersion, versionNumber])

  const saveDetails = async () => {
    setSavingDetails(true)

    const parsedDetails = Object.assign({}, jobVersionDetails, {
      'industry': commonHelpers.industryIdFromKey(common, jobVersionDetails['industry']),
      'role': commonHelpers.conditionallyOmitRole(common, jobVersionDetails['industry'], jobVersionDetails['role']),
      'city': await commonHelpers.conditionallyOmitCity(
        common,
        commonHelpers.defaultCountryID(common),
        jobVersionDetails['state'],
        jobVersionDetails['city']
      )
    })

    jobsService.updateDetails(jobVersion!.id, parsedDetails).then(() => {
      toast.success('Successfully updated draft details')
      setEditingDisabled(true)
      setSavingDetails(false)
      loadJobVersion(parseInt(job_id), versionNumber, true)
    }).catch(() => {
      toast.error('Failed to update draft details')
      setSavingDetails(false)
    })
  }

  useEffect(() => {
    if (!jobVersion || !common) {
      return
    }

    const inputClasses = `max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md border-gray-300 ${editingDisabled ? 'bg-gray-200' : ''}`

    Promise.all(ChangeableFields.map(async (field) => {
      return new Promise<{ field: string, element: JSX.Element | null }>(async (resolve) => {
        switch (field.type) {
          case 'text':
            return resolve({
              field: field.field,
              element: (
                <input
                  name={field.field}
                  type={field.subtype ?? 'text'}
                  disabled={editingDisabled}
                  className={inputClasses}
                  value={jobVersionDetails[field.field] ?? ''}
                  onChange={event => setJobVersionDetail(field.field, event)}
                />
              )
            })
          case 'textarea':
            return resolve({
              field: field.field,
              element: (
                <textarea
                  name={field.field}
                  disabled={editingDisabled}
                  className={inputClasses}
                  value={jobVersionDetails[field.field] ?? ''}
                  onChange={event => setJobVersionDetail(field.field, event)}
                />
              )
            })
          case 'select':
            let options: any[] = []
            switch (field.field) {
              case 'state': options = common.states; break
              case 'city':
                if (!jobVersionDetails['state']) {
                  options = []
                } else {
                  const res = await commonService.citiesForCountry(commonHelpers.defaultCountryID(common), jobVersionDetails['state'])
                  options = res?.data ?? []
                }
                break
              case 'industry': options = commonHelpers.industryDictionaryToArray(common.industries); break
              case 'role':
                if (!jobVersionDetails['industry']) {
                  options = []
                } else {
                  options = commonHelpers.rolesForIndustryByKey(common.industries, jobVersionDetails['industry']) ?? []
                }
                break
              case 'work_type': options = common.work_types; break
              case 'work_env': options = common.work_environments; break
              case 'experience': options = common.experience_levels; break
              case 'availability': options = common.availabilities; break
              case 'travel_availability': options = common.travel_availabilities; break
              case 'compensation_type': options = common.compensation_types; break
              default: options = []
            }

            return resolve({
              field: field.field,
              element: (
                <select
                  name={field.field}
                  disabled={editingDisabled || options.length === 0}
                  className={classNames(inputClasses, editingDisabled ? 'opacity-100' : '')}
                  value={jobVersionDetails[field.field] ?? ''}
                  onChange={event => setJobVersionDetail(field.field, event)}
                >
                  <option value="">Not Specified</option>
                  {options.map((option: any) => (
                    <option key={option.id} value={option.id}>{option.title}</option>
                  ))}
                </select>
              )
            })
          default:
            return resolve({ field: field.field, element: null })
        }
      })
    })).then((fields) => {
      let renderedFields: { [field: string]: JSX.Element | null } = {}

      for (const field of fields) {
        renderedFields[field.field] = field.element
      }

      setRenderedFieldInputs(renderedFields)
    })
  }, [common, editingDisabled, jobVersion, jobVersionDetails, setJobVersionDetail])

  const cancelEditing = () => {
    setEditingDisabled(true)
    loadJobVersion(parseInt(job_id), versionNumber)
  }

  return (
    <Layout title="Job Version" auth={true} back={true}>
      <Modal
        title={`Preview Version ${jobVersion?.version_number}`}
        open={previewModalOpen}
        setOpen={setPreviewModalOpen}
      >
        {(jobVersion && job) && <JobPreview
          jobVersion={jobVersion}
          job={job}
          refetchJobVersion={() => loadJobVersion(job.id, jobVersion.version_number)}
          closeModal={() => setPreviewModalOpen(false)}
        />}
      </Modal>
      <Modal
        title={`Reviewers for Version ${jobVersion?.version_number}`}
        open={reviewersModalOpen}
        setOpen={setReviewersModalOpen}
      >
        {jobVersion && <JobReviewers jobVersion={jobVersion} />}
      </Modal>
      <Modal
        title="Submit Version for Review"
        open={submitForReviewModalOpen}
        setOpen={setSubmitForReviewModalOpen}
        hasForm={true}
      >
        {job && <SubmitForReviewForm companyId={job.company!.id} handleSubmit={submitForReview} />}
      </Modal>
      <Confirm
        title="Are you sure you want to stop editing?"
        confirm_text="Revert"
        info_text="Any unsaved changes will be reverted."
        onConfirm={() => afterUnsavedConfirmFunc && afterUnsavedConfirmFunc()}
        isOpen={cancelEditingConfirmOpen}
        setIsOpen={setCancelEditingConfirmOpen}
      />
      <Confirm
        title="Are you sure you want to create a new draft version?"
        confirm_text="Create"
        info_text="Creating a new draft version will close the version that is currently in review."
        onConfirm={() => createNewDraft()}
        isOpen={isDraftCreationConfirmOpen}
        setIsOpen={setIsDraftCreationConfirmOpen}
      />
      {!loading && !!job ?
        <>
          <div className="bg-white px-4 py-2">
            {hasBack &&
              <nav className="hidden lg:block flex items-start mb-2 py-4 lg:py-2" aria-label="Breadcrumb">
                <button
                  onClick={goBack}
                  className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
                >
                  <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-600" aria-hidden="true" />
                  <span>Back</span>
                </button>
              </nav>
            }
            <div className="flex justify-between pb-4 pt-2 border-b border-b-gray-200">
              <div className="flex gap-1">
                <WhiteButton
                  title="First"
                  onClick={() => openUnsavedConfirm(() => gotoFirstVersion())}
                  disabled={versionNumber === 1}
                />
                <WhiteButton
                  title="Prev"
                  onClick={() => openUnsavedConfirm(() => shiftVersionNumber(-1))}
                  disabled={versionNumber === 1}
                />
                <WhiteButton
                  title="Next"
                  onClick={() => openUnsavedConfirm(() => shiftVersionNumber(1))}
                  disabled={versionNumber === maxVersionNumber}
                />
                <WhiteButton
                  title="Latest"
                  onClick={() => openUnsavedConfirm(() => gotoLastVersion())}
                  disabled={versionNumber === maxVersionNumber}
                />
              </div>
              {job?.open && <TealButton
                title={`Create New Draft From V${versionNumber ?? 1}`}
                onClick={() => createNewDraftWrapper()}
                disabled={creatingVersion}
              />}
            </div>
            {jobVersion && <div className="flex justify-between gap-4 mt-4">
              <div className="w-1/2">
                <div>
                  <HeadingsH2 heading={jobVersion?.title} />
                  <p className="text-md text-gray-500 font-medium">Version {jobVersion?.version_number}</p>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <p>
                      <span className="font-medium">{JobStatusReadableMap[jobVersion.status]} {jobVersion.status === 'Closed' && jobVersion.closed_reason ? `(${jobVersion.closed_reason})` : ''}</span>
                      {(jobVersion.status === 'Published' || (jobVersion.status === 'Closed' && !!jobVersion.published_start)) && <span className="block">
                        (
                        { (!!jobVersion.published_start && !jobVersion.published_end) && "as of " }
                        { jobVersion.status === 'Closed' && "was published " }
                        <FormatedDate date={new Date(jobVersion.published_start!)} relative={true} />
                        {!!jobVersion.published_end && <>
                          &nbsp;-&nbsp;
                          <FormatedDate date={new Date(jobVersion.published_end!)} relative={true} />
                        </>}
                        )
                      </span>}
                    </p>
                  </div>
                </div>
                <div className="flex gap-2 mt-4">
                  {(jobVersion.status === 'Draft' && editingDisabled) && <BlueButton
                    title="Enable Editing"
                    onClick={() => setEditingDisabled(false)}
                  />}
                  {(jobVersion.status === 'Draft' && !editingDisabled) && <RedButton
                    title="Cancel Editing"
                    onClick={() => openUnsavedConfirm(() => cancelEditing())}
                  />}
                  {(jobVersion.status === 'Draft' && !editingDisabled) && <TealButton
                    title="Save Details"
                    onClick={() => saveDetails()}
                    disabled={savingDetails}
                  />}
                  {editingDisabled && <WhiteButton
                    title="Preview"
                    onClick={() => setPreviewModalOpen(true)}
                  />}
                  {jobVersion.status === 'Review' && <WhiteButton
                    title="Reviewers"
                    onClick={() => setReviewersModalOpen(true)}
                  />}
                  {(editingDisabled && jobVersion.status === 'Draft') && <BlueButton
                    title="Submit for Review"
                    onClick={() => setSubmitForReviewModalOpen(true)}
                  />}
                </div>
                <form className="mt-4 flex flex-col gap-2">
                  {ChangeableFields.map((field) => <div key={field.field} className="flex justify-between items-center gap-1">
                    <p className="text-gray-700 text-sm font-medium">{field.title}</p>
                    {renderedFieldInputs[field.field]}
                  </div>)}
                </form>
              </div>
              <div className="w-1/2">
                <JobComments jobId={parseInt(job_id)} versionId={jobVersion?.id} canAdd={jobVersion.status !== "Closed"} />
              </div>
            </div>}
          </div>
        </>
        :
        <LoadingInside />
      }
    </Layout>
  )
}

export default CompanyJobVersionExplorer
