import Files from './Files'
import { personFilesService } from "../../services"

const ProfileFiles = ({ person }: any) => {
  return (
    <Files
      service={personFilesService}
      object={{id: person.user_id}}
      addButtonTitle={null}
    />
  )
}

export default ProfileFiles
