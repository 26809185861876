import { IJob, IJobVersion } from "@/services/jobs.service"
import JobVersion from "./JobVersion"


const JobVersions = ({ job, jobVersions, refetchJob }: { job: IJob, jobVersions: IJobVersion[], refetchJob: () => void }) => {
  return (
    <div className="divide-y divide-gray-200 mt-0">
      {jobVersions.map((jobVersion) => (
        <JobVersion key={jobVersion.id} job={job} jobVersion={jobVersion} refetch={refetchJob} />
      ))}
    </div>
  )
}

export default JobVersions
