import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '../buttons/BlueButton'

import { classNames } from '../../lib/helpers'
import { useState } from 'react'
import TermsAndConditions from './TermsAndConditions'
import SlideOver from '../slideover/SlideOver'

const SignupSchema = Yup.object().shape({
  password1: Yup.string()
    .min(6, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  password2: Yup.string()
    .min(6, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
    .when("password1", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password1")],
        'Passwords do not match.'
      )
    }),
  accept_tac: Yup.boolean()
    .oneOf([true], 'Required')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
})


export default function RegisterForm({ handleSubmit }: { handleSubmit: any }) {
  const [termsAndConditionsModalOpen, setTermsAndConditionsModalOpen] = useState(false)

  return (
    <>
      <SlideOver
        title="Terms & Conditions"
        open={termsAndConditionsModalOpen}
        setOpen={setTermsAndConditionsModalOpen}
      >
        <TermsAndConditions />
      </SlideOver>
      <Formik
        initialValues={{
          email: "",
          password1: "",
          password2: "",
          accept_tac: false
        }}
        validationSchema={SignupSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email"
                className='block text-sm font-medium text-gray-700'
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className={classNames(
                    errors.email && touched.email && errors.email ?
                      'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
                      'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
                    , 'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm '
                  )}
                  onChange={handleChange} onBlur={handleBlur} value={values.email}
                />
              </div>
              <small className='text-red-500'>
                {errors.email && touched.email && errors.email}
              </small>
            </div>
            <div className="flex gap-6 sm:gap-4 flex-col sm:flex-row">
              <div>
                <label htmlFor="password1" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password1"
                    name="password1"
                    type="password"
                    required
                    className={classNames(
                      errors.password1 && touched.password1 && errors.password1 ?
                        'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
                        'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
                      , 'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm '
                    )}
                    onChange={handleChange} onBlur={handleBlur} value={values.password1}
                  />
                </div>
                <small className='text-red-500'>
                  {errors.password1 && touched.password1 && errors.password1}
                </small>
              </div>
              <div>
                <label htmlFor="password2" className="block text-sm font-medium text-gray-700">
                  Confirm Password
                </label>
                <div className="mt-1">
                  <input
                    id="password2"
                    name="password2"
                    type="password"
                    required
                    className={classNames(
                      errors.password2 && touched.password2 && errors.password2 ?
                        'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
                        'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
                      , 'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm '
                    )}
                    onChange={handleChange} onBlur={handleBlur} value={values.password2}
                  />
                </div>
                <small className='text-red-500'>
                  {errors.password2 && touched.password2 && errors.password2}
                </small>
              </div>
            </div>
            <div className="flex items-center">
              <input
                id="accept_tac"
                name="accept_tac"
                type="checkbox"
                required
                checked={values.accept_tac}
                onChange={handleChange} onBlur={handleBlur}
                className={classNames(
                  errors.accept_tac && touched.accept_tac && errors.accept_tac ?
                    'focus:ring-red-500 border-red-500' :
                    'focus:ring-teal-600 border-gray-300',
                  'h-4 w-4 text-teal-600  rounded'
                )}
              />
              <label htmlFor="accept_tac"
                className={classNames(
                  errors.accept_tac && touched.accept_tac && errors.accept_tac ?
                    'text-red-500' :
                    'text-gray-900',
                  'ml-2 block text-sm')}>
                I accept the <span className="text-teal-600 cursor-pointer" onClick={
                  (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setTermsAndConditionsModalOpen(true)
                  }
                }>Terms &amp; Conditions.</span>
              </label>
            </div>

            <div className="mt-6">
              <BlueButton
                title={isSubmitting ? "Signing Up..." : "Sign Up"}
                type="submit"
                className="w-full active:bg-sky-700 transition duration-150 ease-in-out"
                disabled={isSubmitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}
