/* This example requires Tailwind CSS v2.0+ */
import { createElement } from 'react'
import { useHistory } from 'react-router-dom'
import { FlagIcon } from '@heroicons/react/outline'
import axios from 'axios'
import * as serverConfig from '../../services/server-config'
import { INotification, INotificationAction } from '@/recoil/types'
import RedButton from '../buttons/RedButton'
import WhiteButton from '../buttons/WhiteButton'
import { FormatedDate, HeroIconOutline } from '../utils'
import BlueButton from '../buttons/BlueButton'
import TealButton from '../buttons/TealButton'
import { accountService } from '@/services'
import { useSetRecoilState } from 'recoil'
import { userState } from '@/recoil/atoms/auth'

export default function NotificationInner({ notification, onAction }: { notification: INotification, onAction?: (action: INotificationAction) => void }) {
  const setUserData = useSetRecoilState<any>(userState)
  const history = useHistory()

  const dismissAction = {
    text: "Dismiss",
    color: "white"
  } as INotificationAction

  const handleAction = async (action: INotificationAction) => {
    if (action.endpoint) {
      let methodFunc
      switch (action.endpoint_method) {
        case 'GET':
          methodFunc = axios.get
          break
        case 'PUT':
          methodFunc = axios.put
          break
        case 'PATCH':
          methodFunc = axios.patch
          break
        case 'POST':
          methodFunc = axios.post
          break
        case 'DELETE':
          methodFunc = axios.delete
          break
        default:
          methodFunc = axios.post
          break
      }

      await methodFunc(
        `${serverConfig.serverURL}${action.endpoint}`,
        action.endpoint_payload
      )

      accountService.profile().then((res) => setUserData(res.data))
    } else if (action.link) {
      history.push(action.link)
    }

    if (onAction) {
      onAction(action)
    }
  }

  return (
    <>
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">
            {
              notification.icon
                ? <HeroIconOutline icon={notification.icon} className="h-6 w-6 text-sky-800"/>
                : <FlagIcon className="h-6 w-6 text-sky-800" aria-hidden="true" />
            }
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">
              {notification.title}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              {notification.desc_short}
            </p>
            <p className="text-xs text-gray-500 mt-2 italic">
              <FormatedDate date={new Date(notification.created)} />
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-2 p-4">
        {notification.is_pending && (
          notification.actions?.length > 0
          ? (notification.has_seen ? notification.actions : [...notification.actions, dismissAction])
          : [dismissAction]
        ).map((action, i) => {
          const buttonColorMap = {
            "blue": BlueButton,
            "teal": TealButton,
            "red": RedButton,
            "white": WhiteButton
          }[action.color] ?? WhiteButton

          return createElement(buttonColorMap, {
            key: i,
            type: "button",
            title: action.text,
            onClick: () => handleAction(action),
            className: "mt-3 sm:mt-0 w-full sm:w-auto inline-flex justify-center"
          })
        })}
      </div>
    </>
  )
}
