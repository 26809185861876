import { accountService } from '../../services'

import ConnectionsList from './connections/ConnectionsList'
import CarouselList from '../layout/list/CarouselList'

const MyConnections = function () {
  return (
    <CarouselList
      noResultsText="You have no connections."
      itemComponent={ConnectionsList}
      serviceMethod={accountService.myConnections}
      resultsMap={ (data: any) => { return data.results.map((connection:any) => connection) } }
    />
  )
}

export default MyConnections
