import { Link } from 'react-router-dom'
import { BriefcaseIcon} from '@heroicons/react/solid'
import { ProfileImage } from '../utils'
import WhiteButton from '../buttons/WhiteButton'
import RedButton from '../buttons/RedButton'

const PersonInner = ({ person } : any) => {
  return (
    <div className="flex items-center">
      <div className="mr-4 my-auto">
        <ProfileImage image={person.profile_image} size={10} iconClass={'flex-shrink-0'} />
      </div>

      <div className="truncate">
        <div className="flex text-sm items-center mb-4">
          <p className="font-bold md:text-base text-teal-600 truncate">{person.first_name} {person.last_name}</p>
        </div>
        <div className="mt-2 flex">
          <div className="flex items-center text-sm text-gray-500">
              <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p className="truncate w-10/12 sm:w-full">
                <strong className="truncate">{person.industry}</strong>
              </p>
            </div>
        </div>
      </div>
    </div>
  )
}

const Person = ({ object, children = null, link = true, onConnectClick, onDisconnetClick = null, connectionStatus = null }: any) => {
  return (
    <li className="py-4 px-2 sm:p-4 hover:bg-teal-50">
      <div className="flex items-center space-x-4">
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          {link ?
            <Link to={{ pathname: `/people/${object.id}`, state: { from: '/people/' } }}
              className="flex" >
              <PersonInner person={object} />
            </Link>
            :
            <PersonInner person={object} />
          }
        </div>
        <div className="ml-2 sm:ml-0 flex flex-col">
          {!children && connectionStatus && connectionStatus(object) === 'disconnected' && onConnectClick && <WhiteButton
            title="Connect"
            onClick={() => onConnectClick(object.id)}
          />}
          {!children && connectionStatus && connectionStatus(object) === 'connected' && onDisconnetClick && <RedButton
            onClick={() => onDisconnetClick(object.id)}
            title="Disconnect"
          />}
          {!children && connectionStatus && connectionStatus(object) === 'pending' &&
            <div className="italic text-sm text-gray-600">Pending...</div>
          }
          {children}
        </div>
      </div>
    </li>
  )
}

export default Person

export { Person }
