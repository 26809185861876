import MFASettings from "../profile/person/MFASettings";

function MFASetup({ values, errors, touched, handleChange, handleBlur, common }: any) {
  return (
    <div className="flex flex-col items-center justify-center pt-8">
      <MFASettings />
    </div>
  )
}

export default MFASetup
