import { BriefcaseIcon, LocationMarkerIcon } from "@heroicons/react/outline"
import axios from "axios"
import { useCallback, useRef, useState } from "react"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { useRecoilValue } from "recoil"
import { userState } from "../../recoil/atoms/auth"
import { IUser } from "../../recoil/types"

import { IJob, jobsRecruitsService, jobsService } from "../../services/jobs.service"

import { serviceType } from "../../services"

import { LoadingInside } from "../layout"
import NoResults from "../layout/list/NoResults"
import BlueButton from '../buttons/BlueButton'
import WhiteButton from '../buttons/WhiteButton'
import PaginatedList, { Pagination } from "../layout/list/PaginatedList"
import SearchAndFilter, { defaultFilter } from "../layout/list/SearchAndFilter"
import Recruit from "../profile/company/recruits/Recruit"

interface IRecruitForJobFormProps {
  job: IJob
}

const RecruitForJobForm = ({ job }: IRecruitForJobFormProps) => {
  const pageSize: number = 10
  const [loadingConnections, setLoadingConnections] = useState(false)
  const [submittingRecruits, setSubmittingRecruits] = useState(false)
  const [pagination, setPagination] = useState<serviceType.IListPagination>({ next: '', previous: '', count: 0, pageSize })
  const [myConnections, setMyConnections] = useState<any>([])
  const user = useRecoilValue(userState) as unknown as IUser

  const [filter, setFilter] = useState(defaultFilter)

  const history = useHistory()

  const [selectedRecruitsForJob, setSelectedRecruitsForJob] = useState<Record<string, boolean>>({})

  const serviceParams: any = filter
  serviceParams['page_size'] = pageSize
  serviceParams['job'] = job.id

  // stringify objects for useEffect deps tracking
  const serviceParamsJsonString = JSON.stringify(serviceParams)

  const mounted = useRef(false)

  // checkbox togling
  const _toggleSelectedRecruit = (connectionId: number) => {
    const newSelectedRecruitsForJob = Object.assign({}, selectedRecruitsForJob)
    newSelectedRecruitsForJob[connectionId] = !selectedRecruitsForJob[connectionId]
    setSelectedRecruitsForJob(newSelectedRecruitsForJob)
  }

  const submitRecruit = () => {
    const toRecruit = []
    for (let [recruitId, isSelected] of Object.entries(selectedRecruitsForJob)) {
      if (isSelected) {
        toRecruit.push(parseInt(recruitId))
      }
    }
    if (toRecruit.length === 0) {
      toast.warning('No Recruits Selected.')
      return
    }
    setSubmittingRecruits(true)
    Promise.all(
      toRecruit.map((recruitId) => {
        return jobsService.recruitCandidate(job.id, recruitId)
      })
    ).then(() => {
      toast.success(`Recruits added to job ${job.published_version?.title}`)
      setSubmittingRecruits(false)
      goToRecruit()
    }).catch((error) => {
      setSubmittingRecruits(false)
      toast.error(`Error recruiting candidates for ${job.published_version?.title}: ${error}`)
    })
  }

  const goToRecruit = () => {
    history.push(`/recruit/pipeline/${job.recruiter_process?.id}`, { from: `/jobs/${job.id}` })
  }

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const fetchConenctions = useCallback(() => {
    if (user) {
      const source = axios.CancelToken.source()
      setLoadingConnections(true)
      jobsService.searchCandidates('?', source.token, false, JSON.parse(serviceParamsJsonString)).then(({ success, data }: serviceType.IServicePaginatedListResult) => {
        if (success && mounted.current) {
          setMyConnections(data.results)
          setPagination({
            next: data.next,
            previous: data.previous,
            count: data.count,
            pageSize
          })
          setLoadingConnections(false)
        }
      }).catch((error: any) => {
        toast.error(`${error}`)
        setLoadingConnections(false)
      })
      return () => {
        source.cancel()
      }
    }
  }, [user, serviceParamsJsonString])

  useEffect(() => {
    fetchConenctions()
  }, [fetchConenctions])

  const fetchNext = () => {
    setLoadingConnections(true)
    jobsService.searchCandidates(pagination.next, null, true, JSON.parse(serviceParamsJsonString)).then(({ success, data }: serviceType.IServicePaginatedListResult) => {
      if (success && mounted.current) {
        setMyConnections(data.results)
        setPagination(Object.assign({}, pagination, {
          next: data.next,
          previous: data.previous
        }))
        setLoadingConnections(false)
      }
    }).catch((error: any) => {
      console.error(error)
      setLoadingConnections(false)
    })
  }

  const fetchPrevious = () => {
    setLoadingConnections(true)
    jobsService.searchCandidates(pagination.previous || '', null, true, JSON.parse(serviceParamsJsonString)).then(({ success, data }: serviceType.IServicePaginatedListResult) => {
      if (success && mounted.current) {
        setMyConnections(data.results)
        setPagination(Object.assign({}, pagination, {
          next: data.next,
          previous: data.previous
        }))
        setLoadingConnections(false)
      }
    }).catch((error: any) => {
      console.error(error)
      setLoadingConnections(false)
    })
  }

  return (
    <>
      {
        loadingConnections ? <LoadingInside />
          :
          <div>
            <div className="border-teal-600 border-b pb-2">
              <h1 className="text-teal-600 font-bold lg:text-lg mb-2">{job?.published_version?.role?.title}</h1>
              <div className="flex items-center text-sm text-gray-500">
                <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                <small>{job?.company?.name}</small>
              </div>
              <div className="flex items-center text-sm text-gray-500">
                <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                <small>
                  {job?.published_version?.state?.title}{', '}{job?.published_version?.city?.title}
                </small>
              </div>
            </div>
            <SearchAndFilter 
              filter={filter}
              setFilter={setFilter}
            />
            <div className="bg-white shadow overflow-hidden rounded-md">
              {
                myConnections.length > 0 ?
                  <>
                    <ul className="mt-6 mb-8">
                      {myConnections.map((object: any) => (
                        <li key={object.id} className="py-3 sm:py-0 hover:bg-teal-50">
                          <div className="sm:border-b sm:border-gray-200 sm:py-4 items-center px-2">
                            <div className="flex items-center hover:bg-teal-50">
                              <input
                                id={`recruit-${object.id}`}
                                name={`recruit-${object.id}`}
                                type="checkbox"
                                disabled={submittingRecruits}
                                checked={selectedRecruitsForJob[object.id]}
                                onChange={() => _toggleSelectedRecruit(object.id)}
                                className="disabled:opacity-50 h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded"
                              />
                              <label htmlFor={`recruit-${object.id}`} className="ml-2 block text-sm text-gray-900">
                                {object.first_name} {object.last_name} - {object.industry} / {object.role}
                              </label>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <Pagination pagination={pagination} onPrevClick={fetchPrevious} onNextClick={fetchNext} />
                  </>
                  :
                  <NoResults />
              }
            </div>
            <div className="flex justify-between space-x-4 mt-4">
              <BlueButton
                onClick={submitRecruit}
                type="button"
                disabled={submittingRecruits}
                title={submittingRecruits ? 'Adding...' : 'Add Recruit(s)'}
                className="w-full"
              />
              <WhiteButton
                onClick={goToRecruit}
                type="button"
                title="Go To Pipeline"
                className="w-full"
              />
            </div>
            <div className="mt-6">
              <div className="text-gray-500 font-bold">Your past recruits for this job:</div>
              <PaginatedList
                service={jobsRecruitsService}
                pageSize={5}
                serviceParams={{ job: job.id }}
                onServiceError={toast.error}
                itemComponent={Recruit}
                itemsProps={{ showRecruitedBy: false, showCandidatesButton: true }}
                noResultsText="No recruits"
              />
            </div>
          </div>
      }
    </>
  )
}

export default RecruitForJobForm
