import Files from './Files'
import { companyFilesService } from "../../services"

const CompanyFiles = ({ company, pageSize }: any) => {
  return (
    <Files
      service={companyFilesService}
      object={{id: company.id}}
      addButtonTitle={null}
      pageSize={pageSize}
    />
  )
}

export default CompanyFiles
