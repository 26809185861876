import { toast } from "react-toastify"
import { useRecoilValue } from "recoil"
import { commonTables } from "../../../recoil/atoms/common"
import { accountPreviousJobsService, commonHelpers } from "../../../services"
import PaginatedCRUDList from "../../layout/list/PaginatedCRUDList"
import PreviousJob from "./previous_jobs/PreviousJob"
import PreviousJobForm from "./previous_jobs/PreviousJobForm"

const JobHistory = () => {
  const common = useRecoilValue<any>(commonTables)
  const formAlterValues = (values: any) => {
    values = Object.assign({}, values, {
      'industry': commonHelpers.industryIdFromKey(common, values.industry),
      'role': commonHelpers.conditionallyOmitRole(common, values.industry, values.role),
      'to_date': values.to_date || null
    })
    return values
  }

  return (
    <>
      <PaginatedCRUDList
        service={accountPreviousJobsService}
        onServiceError={toast.error}
        object={{ id: null }}
        itemComponent={PreviousJob}
        formComponent={PreviousJobForm}
        addButtonTitle="Add New Job"
        formAlterValues={formAlterValues}
      />
    </>
  )
}

export default JobHistory
