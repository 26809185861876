import { toast } from "react-toastify"
import { recruitmentProcessesService } from "../../../services"
import Modal from "../../modals/Modal"
import PaginatedCRUDList from "../../layout/list/PaginatedCRUDList"
import CreateRecruitingProcessForm from "../../recruit/forms/CreateRecruitingProcessForm"
import RecruitingProcess from "./RecruitingProcess"

const RecruitingProcesses = ({ company }: any) => {
  const formSuccessAction = (values: any, result: any) => {
    toast.success(`Created pipeline: ${values.name}.`)
  }

  return (
    <PaginatedCRUDList
      service={recruitmentProcessesService}
      onServiceError={toast.error}
      object={company}
      itemComponent={RecruitingProcess}
      itemsProps={{ company: company }}
      formComponent={CreateRecruitingProcessForm}
      ModalComponent={Modal}
      addButtonTitle="Create Recruiting Pipeline"
      formSuccessAction={formSuccessAction}
    />
  )
}

export default RecruitingProcesses
