export const withServiceParamsInUrl = (urlOrQueryString: string, params: Record<string, any> | null) => {
  let _params = ''
  if (params !== null) {
    for (let key in params) {
      if (Array.isArray(params[key])) {
        for (let value of params[key]) {
          _params += `&${key}=${value}`
        }
      }
      else {
        _params += `&${key}=${params[key]}`
      }
    }
  }
  return `${urlOrQueryString}${_params}`
}

export const multipleChoiceQueryString = (key: string, list: any) => {
  let _params = []
  for (let value of list) {
    _params.push(`${key}=${value}`)
  }
  return _params.join('&')
}

export const trimFilter = (filterString: string) => {
  const params = new URLSearchParams(filterString)
  for (const entry of Array.from(params.entries())) {
    if (!entry[1] || entry[1] === '') {
      params.delete(entry[0])
    }
  }
  return params.toString()
}
