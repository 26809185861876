import { useEffect, useState } from 'react'
import { ProfileImage as ProfileImageHelper } from '../../utils'
import ChangePictureForm from './forms/ChangePictureForm'


const ProfileImage = ({ profile_image_url }: { profile_image_url: string }) => {
  const [isEditing, setIsEditing] = useState(false)
  const toggleIsEditing = () => {
    setIsEditing(!isEditing)
  }

  useEffect(() => {
    return () => {
      if (isEditing) setIsEditing(false)
    }
  })

  return (
    <>
      {isEditing ?
        <ChangePictureForm setIsEditing={setIsEditing} />
        :
        <span
          onClick={toggleIsEditing}
          id="profile_image"
        >
          <ProfileImageHelper image={profile_image_url} size={32} iconClass={'flex-shrink-0'} />
        </span>
      }
    </>
  )
}

export default ProfileImage