import { useState, useEffect } from 'react'
import { reportsService } from "@/services"

const TimeToFillReport = () => {
  const [result, setResult] = useState<any>({})

  useEffect(() => {
    reportsService.timeToFill(null).then((_result: any) => {
      setResult(_result.data)
    }).catch((error: any) => {
      console.error(error)
    })
  }, [setResult])

  const className = 'border border-gray-200 p-2'

  return (
    result &&
    <table className="table-fixed w-full text-sm bg-white mt-4">
      <thead>
        <tr>
          <th className={className}>From Date</th>
          <th className={className}>To Date</th>
          <th className={className}>Average Days To Fill</th>
          <th className={className}>Job Count</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={className}>{result.from_date}</td>
          <td className={className}>{result.to_date}</td>
          <td className={className}>{result.average_days_to_fill}</td>
          <td className={className}>{result.job_count}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default TimeToFillReport
