import { InformationCircleIcon, LockClosedIcon } from '@heroicons/react/solid'
import { Link, useHistory } from 'react-router-dom'
import { IRecruitingProcess } from '@/recoil/types'
import WhiteButton from '@/components/buttons/WhiteButton'

const RecruitingProcessInner = ({ process }: { process: IRecruitingProcess }) => {
  const history = useHistory()

  return (<>
    <div className="px-4 py-4 flex items-center sm:px-6">
      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <div className="flex text-sm">
            <p className="font-bold text-teal-600 truncate">{process.name}</p>
          </div>
          {process.is_abstract && <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>Abstract</p>
            </div>
          </div>}
          {process.is_locked && <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <LockClosedIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>Locked</p>
            </div>
          </div>}
        </div>
      </div>
      {process.linked_job && <div className="ml-2 sm:ml-0 flex flex-col">
        <WhiteButton
          title="Linked Job"
          onClick={() => history.push({
            pathname: `/companies/${process.company}/job/${process.linked_job?.id}`,
            state: { from: '/company-profile' }
          })}
        />
      </div>}
    </div>
  </>)
}

interface IRecruitingProcessProps {
  object: any,
  company: any,
  from?: string | null
}

const RecruitingProcess = ({
  object,
  company,
  from = null
}: IRecruitingProcessProps) => {
  const fromLink = from === null ? '/company-profile' : from
  
  return (
    <li>
      <Link to={{ pathname: `/companies/${company.id}/recruiting-pipeline/${object.id}`, state: { from: fromLink } }} className="block hover:bg-gray-50">
        <RecruitingProcessInner process={object} />
      </Link>
    </li>
  )
}

export default RecruitingProcess
