import axios from 'axios'

import { accountService } from '../services'
import * as serverConfig from '../services/server-config'

export function jwtInterceptor() {
  axios.interceptors.request.use(request => {
    // add auth header with jwt if account is logged in and request is to the api url
    const accessToken = accountService.checkToken()
    const isLoggedIn = accessToken != null
    if (!request) return request
    const isApiUrl = request.url.startsWith(serverConfig.serverURL)

    if (isLoggedIn && isApiUrl && !request.headers.Authorization) {
      request.headers.Authorization = `Bearer ${accessToken}`
    }

    return request
  })
}