import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { BriefcaseIcon, OfficeBuildingIcon, UsersIcon, StarIcon } from '@heroicons/react/outline'

function UserRoleFields({ values, errors, touched, handleChange, handleBlur, common }: any) {
  return (
    <>
      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <RadioGroup
          value={values['role']}
          onChange={value => handleChange({ target: { name: 'role', value } })}
          name="role"
          className="space-y-3"
        >
          <RadioGroup.Option value="1">
            {({ checked }) => (
              <div className={'cursor-pointer flex justify-between items-center p-5 pl-7 border rounded-lg ' + (checked ? 'border-teal-600' : 'border-gray-300')}>
                <div className="flex items-center gap-6">
                  <BriefcaseIcon className="w-6 h-6" aria-hidden="true" />
                  <span>I'm searching for a great job</span>
                </div>
                {checked ? <CheckCircleIcon className="w-8 h-8 text-teal-600" aria-hidden="true" /> : <div className="w-8 h-8 border border-gray-300 rounded-full"></div>}
              </div>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value="2">
            {({ checked }) => (
              <div className={'cursor-pointer flex justify-between items-center p-5 pl-7 border rounded-lg ' + (checked ? 'border-teal-600' : 'border-gray-300')}>
                <div className="flex items-center gap-6">
                  <UsersIcon className="w-6 h-6" aria-hidden="true" />
                  <span>I would like to refer people and earn money</span>
                </div>
                {checked ? <CheckCircleIcon className="w-8 h-8 text-teal-600" aria-hidden="true" /> : <div className="w-8 h-8 border border-gray-300 rounded-full"></div>}
              </div>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value="3">
            {({ checked }) => (
              <div className={'cursor-pointer flex justify-between items-center p-5 pl-7 border rounded-lg ' + (checked ? 'border-teal-600' : 'border-gray-300')}>
                <div className="flex items-center gap-6">
                  <StarIcon className="w-6 h-6" aria-hidden="true" />
                  <span>I would like to do both</span>
                </div>
                {checked ? <CheckCircleIcon className="w-8 h-8 text-teal-600" aria-hidden="true" /> : <div className="w-8 h-8 border border-gray-300 rounded-full"></div>}
              </div>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value="4">
            {({ checked }) => (
              <div className={'cursor-pointer flex justify-between items-center p-5 pl-7 border rounded-lg ' + (checked ? 'border-teal-600' : 'border-gray-300')}>
                <div className="flex items-center gap-6">
                  <OfficeBuildingIcon className="w-6 h-6" aria-hidden="true" />
                  <span>Corporate job poster</span>
                </div>
                {checked ? <CheckCircleIcon className="w-8 h-8 text-teal-600" aria-hidden="true" /> : <div className="w-8 h-8 border border-gray-300 rounded-full"></div>}
              </div>
            )}
          </RadioGroup.Option>
        </RadioGroup>
      </div>
    </>
  )
}

export default UserRoleFields
