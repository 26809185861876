function Footer() {
  return (
    <div className="bg-white border-t border-gray-200 shadow-sm z-10">
      <div className="text-gray-600 text-xs flex justify-between items-center py-1 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div>Gumption LLC &copy; 2023</div>
        <a href="/privacy-policy.pdf" target="_blank" className="text-teal-700">Privacy Policy</a>
      </div>
    </div>
  )
}

export default Footer
