import { trackingService } from '../../../services'

import JobsList from '../../home/jobs/JobsList'
import CarouselList from '../../layout/list/CarouselList'

const LastViewedJobs = function () {
  return (
    <CarouselList
      noResultsText="No last viewed jobs."
      itemComponent={JobsList}
      serviceMethod={trackingService.lastViewedJobs}
      resultsMap={ (data: any) => { return data.map((jobView:any) => jobView.job) } }
    />
  )
}

export default LastViewedJobs
