import React from 'react'
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './index.css'

import { initFirebaseSdk } from './lib/firebase.sdk'
import { initFacebookSdk } from './lib/facebook.sdk'
import { jwtInterceptor } from './lib/jwt.interceptor'
import { errorInterceptor } from './lib/error.interceptor' 


initFirebaseSdk()
jwtInterceptor()
errorInterceptor()
initFacebookSdk()

const root = createRoot(document.getElementById('root')!)

root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
