import { toast } from "react-toastify"
// import { useRecoilValue } from 'recoil'
import { useState } from 'react'
import { CSVLink } from 'react-csv'

import { classNames, formatDate } from '@/lib/helpers'
// import { userState } from '@/recoil/atoms/auth'
import { serviceType } from "@/services"

import PaginatedList from '@/components/layout/list/PaginatedList'
import ReportRow from '@/components/reports/ReportRow'
import { ButtonStyles } from '@/components/buttons/styles'

interface IReportTabProps {
  service: serviceType.IListRetrieveService,
  headers: any,
  reportMap: Function,
  exportFilename?: string
}

const ReportTab = ({
  service,
  headers,
  reportMap,
  exportFilename = 'Report'
}: IReportTabProps) => {
  // const userData = useRecoilValue<any>(userState)
  const [items, setItems] = useState<any>([])

  const exportData = () => {
    const data = []
    data.push(headers)
    return data.concat(items.map(reportMap))
  }

  return (
    // userData &&
    <PaginatedList
      service={service}
      // serviceParams={}
      onServiceError={toast.error}
      itemComponent={ReportRow}
      itemsProps={{ reportMap }}
      parentItems={items}
      setParentItems={setItems}
      pageSize={1000}
    >
      {items.length > 0 &&
        <>
          <div className="my-4">
            <CSVLink
              data={exportData()}
              filename={`${exportFilename}_${formatDate(new Date(), 'YYYY-MM-DD')}.csv`}
              className={classNames(
                'button',
                ButtonStyles.Base,
                ButtonStyles.White
              )}
            >
              Download CSV
            </CSVLink>
          </div>
          <table className="table-fixed w-full text-sm">
            <thead>
              <tr>
                {headers.map((header: string, index: number) => (
                  <th key={index} className="border border-gray-200 bg-gray-200 p-2">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
        </>
      }
    </PaginatedList>
  )
}

export default ReportTab
