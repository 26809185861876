import WhiteButton from "../buttons/WhiteButton"

function RecruiterTermsAndConditions() {
  return (
    <>
      <object data="/recruiter-terms-and-conditions.pdf" type="application/pdf" width="100%" height="1000px">
        <WhiteButton
          title="Read Recruiter Terms & Conditions"
          onClick={() => window.open("/recruiter-terms-and-conditions.pdf", "_blank")}
        />
      </object>
    </>
  )
}

export default RecruiterTermsAndConditions
