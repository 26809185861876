import { reportJobCandidateService } from "@/services"

import ReportTab from '@/components/reports/ReportTab'


const JobCandidateNumbersReport = () => {
  const headers = [
    'Title',
    'Count',
  ]

  const reportMap = (object: any) => {
    return [
      object.title,
      object.count,
    ]
  }

  return (
    <ReportTab
      service={reportJobCandidateService}
      headers={headers}
      reportMap={reportMap}
      exportFilename={'JobCandidateNumbersReport'}
    />
  )
}

export default JobCandidateNumbersReport
