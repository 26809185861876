import PaginatedList from '@/components/layout/list/PaginatedList'
import { recruiterRecruitsService } from '@/services'
import { toast } from 'react-toastify'
import Recruit from '../profile/company/recruits/Recruit'

const RecruitRecruitsTable = () => {
  return (
    <PaginatedList
      service={recruiterRecruitsService}
      pageSize={10}
      onServiceError={toast.error}
      itemComponent={Recruit}
      itemsProps={{ showRecruitedBy: false, showCandidatesButton: true }}
      noResultsText="No candidates"
    />
  )
}

export default RecruitRecruitsTable
