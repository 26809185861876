import axios from 'axios'
import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue, useRecoilState } from 'recoil'
import InfiniteScroll from 'react-infinite-scroll-component'

import { messagingService, serviceType } from '../../services'
import { userState } from '../../recoil/atoms/auth'
import { incomingMessage, outgoingMessage, socketState, activeChatUser } from '../../recoil/atoms/chat'
import { classNames, uuidv4, removeDuplicates } from "../../lib/helpers"
import { FormatedDate } from '../utils'

import { LoadingInside } from '../layout/index'
import BlueButton from '../../components/buttons/BlueButton'


const ChatTab = ({ person, showHeader = false }: any) => {
  const userData = useRecoilValue<any>(userState)

  const [, setSocketOutgoingMessage] = useRecoilState(outgoingMessage)
  const socketIncomingMessage = useRecoilValue<any>(incomingMessage)
  const socketOpened = useRecoilValue<boolean>(socketState)
  const [, setActiveChatUser] = useRecoilState(activeChatUser)

  const [inputValue, setInputValue] = useState('')
  const [messages, setMessages] = useState<any>([])
  const [pagination, setPagination] = useState<serviceType.IListSimplePagination>({ next: '' })

  const personRef = useRef<any>(person)
  const initLoadedRef = useRef<boolean>(false)
  const recentMessageIdRef = useRef<any>(null)

  const messageMap = useRef((orig: any) => {
    return {
      id: orig.id,
      from_user: orig.from_user,
      message: orig.message,
      created: orig.created,
      confirmed: true,
      ts: <FormatedDate date={new Date(orig.created)} relative={true} />
    }
  })

  const sendSeen = useRef((messageId: any, fromUser: any) => {
    if (!socketOpened || !userData) return

    const msg = {
      type: 'seen',
      message_id: messageId,
      from_user: fromUser
    }
    setSocketOutgoingMessage(msg)
  })

  const fetchMoreMessages = useRef((extraQuery: string, attachToTop: boolean) => {
    messagingService.list(`?user=${person.user_id}${extraQuery}`, null).then(({ success, data }: serviceType.IServicePaginatedListResult) => {
      if (success) {
        const moreMessages = data.results.map(messageMap.current)
        setMessages((previousMessages: any) => {
          let tempMessages
          if (attachToTop) {
            tempMessages = moreMessages.concat(previousMessages)
          }
          else {
            tempMessages = previousMessages.concat(moreMessages)
          }
          tempMessages = removeDuplicates(tempMessages, 'id')
          return tempMessages
        })
        setPagination({
          next: data.next
        })
      }
    }).catch((error: any) => {
      console.error(error)
    })
  })

  const fetchOlderMessages = () => {
    let lastId = null
    const lastMessage = messages[messages.length-1]
    if (lastMessage) {
      lastId = lastMessage.id
    }
    fetchMoreMessages.current(`&max_id=${lastId}`, false)
  }

  const sendMessage = (message: string) => {
    if (!socketOpened || !userData) return

    const uuid = uuidv4()
    const msg = {
      type: 'message',
      id: uuid,
      request_id: uuid,
      message: message,
      from_user: userData.id,
      to_user: personRef.current.user_id.toString(),
      confirmed: false,
      ts: <FormatedDate date={new Date()} relative={true} />
    }
    setSocketOutgoingMessage(msg)

    setMessages((previousMessages: any) => [{ ...msg }].concat(previousMessages))
  }

  const handleSubmit = () => {
    if (inputValue.trim().length === 0) {
      return
    }
    sendMessage(inputValue)
    setInputValue('')
  }

  useEffect(() => {
    const newMessage = socketIncomingMessage

    if (newMessage.type === 'message') {
      if (newMessage.from_user && newMessage.from_user.toString() === personRef.current.user_id.toString()) {
        const alteredMessage = messageMap.current(newMessage)
        setMessages((previousMessages: any) => [{ ...alteredMessage }].concat(previousMessages))
        sendSeen.current(newMessage.id, newMessage.from_user)
      }
    }
    else if (newMessage.type === 'confirmation') {
      setMessages((previousMessages: any) =>
        previousMessages.map((item: any) =>
          item.request_id === newMessage.request_id ? { ...item, confirmed: true } : item
        )
      )
    }
  }, [socketIncomingMessage])

  useEffect(() => {
    console.log('loaded init msgs')
    const source = axios.CancelToken.source()

    personRef.current = person

    setActiveChatUser(person.user_id)

    messagingService.list(`?user=${person.user_id}`, source.token).then(({ success, data }: serviceType.IServicePaginatedListResult) => {
      if (success) {
        const originalMessages = data.results.map(messageMap.current)
        setMessages(originalMessages)
        setPagination({
          next: data.next
        })
        setSocketOutgoingMessage({
          request_id: uuidv4(),
          type: 'unread'
        })
        initLoadedRef.current = true
      }
    }).catch((error: any) => {
      console.error(error)
    })

    return () => {
      source.cancel()
      console.log('deact chat')
      setActiveChatUser(null)
    }
  }, [person, userData, setMessages, setSocketOutgoingMessage, setActiveChatUser])

  useEffect(() => {
    if (messages.length > 0) {
      recentMessageIdRef.current = messages[0].id
    }
  }, [messages])

  useEffect(() => {
    // load messages which was sent during socket disconnect
    if (initLoadedRef.current && socketOpened && recentMessageIdRef.current) {
      fetchMoreMessages.current(`&min_id=${recentMessageIdRef.current}`, true)
    }
  }, [socketOpened])

  return (
    <>
      <div className="py-4 space-y-6">
        {showHeader &&
          <p>
            Chat with&nbsp;
            <Link to={{ pathname: `/people/${person.id}`, state: { from: '/chat' } }}>
              <strong className="text-teal-600">{person.first_name} {person.last_name}</strong>
            </Link>
          </p>
        }
        {!socketOpened && <p>Reconnecting...</p>}
        <div className="my-3 flex">
          <input
            type="text"
            className="flex flex-grow shadow-sm focus:ring-teal-600 focus:border-teal-600 sm:text-sm border-gray-300 rounded-md"
            value={inputValue}
            onChange={(ev) => setInputValue(ev.target.value)}
            onKeyDown={event => event.key === 'Enter' && handleSubmit()}
            disabled={!socketOpened}
          />

          <BlueButton
            type="submit"
            title="Send"
            className="ml-3"
            onClick={handleSubmit}
            disabled={!socketOpened}
          />
        </div>
        <hr />

        <InfiniteScroll
          dataLength={messages?.length || 0} //This is important field to render the next data
          next={fetchOlderMessages}
          hasMore={pagination.next !== '' && pagination.next !== null}
          loader={<LoadingInside />}
          scrollableTarget="infinite-scroll-wrapper"
        >
          {
            messages.length > 0 &&
            messages.map((message: any) => (
              <div key={message.id} className="my-2">

                {message.from_user.toString() === userData.id.toString() ? // sent from me
                  <div className="float-right text-right">
                    <div className={classNames(
                      'inline-block text-white rounded-lg px-3 py-1.5 text-sm whitespace-pre-line',
                      (message.confirmed ? 'bg-sky-800' : 'bg-sky-600')
                    )}>
                      {message.message}
                    </div>
                    <div className="text-xs text-gray-500 mt-1">
                      {message.ts}
                    </div>
                  </div>
                  :
                  <div>
                    <div className="inline-block text-white rounded-lg px-3 py-1.5 bg-teal-600 text-sm whitespace-pre-line">
                      {message.message}
                    </div>
                    <div className="text-xs text-gray-500 mt-1">
                      {message.ts}
                    </div>
                  </div>
                }
                <div className="clear-both"></div>
              </div>
            ))
          }
        </InfiniteScroll>
      </div>
    </>
  )
}

export default ChatTab
