import { ChangeEventHandler, FocusEventHandler, forwardRef, Fragment, useEffect, useImperativeHandle, useState } from "react"
import { classNames } from "@/lib/helpers"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/solid"

export interface IMultiselectFieldOption {
  id: string,
  title: string
}

interface IMultiselectFieldStyles {
  wrapper?: string,
  input?: string
}

interface IMultiselectFieldProps {
  fieldName: string,
  label?: string,
  options: Array<IMultiselectFieldOption>
  handleChange: ChangeEventHandler<HTMLSelectElement>,
  handleBlur: FocusEventHandler<HTMLSelectElement>,
  values: Record<string, any>,
  errors: Record<string, any>,
  touched: Record<string, any>,
  disabled?: boolean,
  required?: boolean,
  styles?: IMultiselectFieldStyles
}

export interface IMultiselectFieldHandle {
  resetSelection: () => void
}

const MultiselectField = forwardRef(({
  fieldName,
  label,
  options,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  disabled,
  required = false,
  styles
}: IMultiselectFieldProps, ref) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(values[fieldName] || [])

  useEffect(() => {
    handleChange({ target: { name: fieldName, value: selectedOptions } } as any)
  }, [fieldName, handleChange, selectedOptions])

  const publicRef = {
    resetSelection: () => {
      setSelectedOptions([])
    }
  }

  useImperativeHandle(ref, () => publicRef)

  return (
    <div className={styles?.wrapper ?? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'}>
      {label && <label htmlFor={fieldName} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 sm:pb-1">
        {label}
        {required && <span className="required">*</span>}
      </label>}
      <div className="mt-1 sm:mt-0 sm:col-span-2 relative">
        <Listbox
          value={selectedOptions}
          onChange={setSelectedOptions}
          multiple
          name={fieldName}
          disabled={disabled || options.length === 0}
        >
          <Listbox.Button disabled={disabled || options.length === 0} className={classNames(
            errors[fieldName] && touched[fieldName] && errors[fieldName] ?
              'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
              'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
            , styles?.input ?? 'border max-w-lg block w-full shadow-sm sm:max-w-xs text-sm rounded-md pl-3 py-2 text-left truncate',
            disabled || options.length === 0 ? 'opacity-70' : ''
          )}>
            {
              options.length === 0
              ? 'No Options'
              : (
                selectedOptions.length > 0
                ? (
                  selectedOptions.length > 3
                  ? `${selectedOptions.length} Selected`
                  : selectedOptions.map((selected) => options.find((option) => option.id === selected)?.title).join(', ')
                )
                : 'Select Option(s)'
              )
            }
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Listbox.Options className="absolute left-0 z-10 mt-1 w-full origin-top-right rounded-md border-gray-300 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-40 overflow-y-auto">
              {options?.map((option) => (
                <Listbox.Option key={option.id} value={option.id}>
                  <div className={`flex gap-2 items-center w-full text-gray-700 text-sm font-medium py-1 px-2 cursor-pointer hover:bg-gray-200 ${selectedOptions.includes(option.id) ? 'bg-gray-200' : ''}`}>
                    {selectedOptions.includes(option.id) ? <CheckIcon className="h-3 w-3" aria-hidden="true" /> : <div className="h-3 w-3"></div>}
                    {option.title}
                  </div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </Listbox>
      </div>
      <small className='text-red-500'>
        {errors[fieldName] && touched[fieldName] && errors[fieldName]}
      </small>
    </div>
  )
})

export default MultiselectField
