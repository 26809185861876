import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { paymentService } from '../../../services'
import { LoadingInside } from '../../layout/top_level/Loading'
import TealButton from '@/components/buttons/TealButton'
import BlueButton from '@/components/buttons/BlueButton'
import WhiteButton from '@/components/buttons/WhiteButton'

interface IPayoutAccount {
  stripe_id: string,
  stripe_email?: string,
  payouts_enabled: boolean
}

const PayoutAccount = () => {
  const [payoutAccount, setPayoutAccount] = useState<IPayoutAccount>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    paymentService.getAccount().then((result) => {
      setPayoutAccount(result.data)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }, [])

  const createStripeAccount = () => {
    setLoading(true)
    paymentService.createAccount().then(({data}) => {
      setPayoutAccount({
        stripe_id: data.stripe_id,
        payouts_enabled: false
      })
      toast.success('Created new Stripe account. Please click the "Complete Stripe Onboarding" button to continue.')
      setLoading(false)
    }).catch(() => {
      toast.error('Failed to create Stripe account')
      setLoading(false)
    })
  }

  const completeStripeOnboarding = () => {
    setLoading(true)
    paymentService.accountOnboarding().then(({data}) => {
      window.location = data.onboarding_link
      setLoading(false)
    }).catch(() => {
      toast.error('Failed to get Stripe onboarding link')
      setLoading(false)
    })
  }

  const accountLogin = () => {
    setLoading(true)
    paymentService.accountLoginLink().then(({data}) => {
      window.open(data.link, '_blank')
      setLoading(false)
    }).catch(() => {
      toast.error('Your Stripe account is unable to be logged into at this time. Completing onboarding is required before being able to login.')
      setLoading(false)
    })
  }

  return (
    loading
      ? <LoadingInside />
      : payoutAccount
        ? <>
          <div className="text-sm font-medium text-gray-700">Stripe account linked {payoutAccount.stripe_email && `(${payoutAccount.stripe_email})`}</div>
          {!payoutAccount.payouts_enabled
            ? <TealButton
              type="button"
              title="Complete Stripe Onboarding"
              className="mt-2"
              onClick={completeStripeOnboarding}
            />
            : <WhiteButton
              type="button"
              title="Login to Stripe Account"
              className="mt-2"
              onClick={accountLogin}
            />
          }
        </>
        : <BlueButton
          type="button"
          title="Create Stripe Account"
          onClick={createStripeAccount}
        />
  )
}

export default PayoutAccount
