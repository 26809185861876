import { Formik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { accountService } from '@/services'
import BlueButton from '../../../buttons/BlueButton'
import WhiteButton from '../../../buttons/WhiteButton'
import { SelectField, TextInputField } from  '@/components/forms/fields'
import { useRecoilValue } from 'recoil'
import { userState } from '@/recoil/atoms/auth'

const AddMFAMethodSchema = Yup.object().shape({
  method: Yup.string().required('Required'),
  target: Yup.string().when('method', {
    is: (val: any) => val === 'sms_twilio',
    then: Yup.string().required('Required')
  }),
  code: Yup.string().required('Required')
})

interface IAddMFAMethodFormProps {
  handleSubmit: any,
  onCancel?: Function | null
}

const MFAMethods = [
  { id: 'email', title: 'Email' },
  { id: 'sms_twilio', title: 'Text' }
]

export default function AddMFAMethodForm({
  handleSubmit,
  onCancel = null
}: IAddMFAMethodFormProps) {
  const userData = useRecoilValue<any>(userState)
  
  const sendCode = async (method: string, target: string) => {
    if (method === 'sms_twilio') {
      await accountService.setMFAPhone(target)
    }

    if (method === 'email') {
      target = userData.email
    }

    accountService.activateMFAMethod(method as 'email' | 'sms_twilio', target).then((result) => {
      toast.info(`Sent MFA code to "${target}"`)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to send MFA code')
    })
  }

  return (
    <Formik
      initialValues={{
        method: "",
        target: "",
        code: "",
      }}
      validationSchema={AddMFAMethodSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <SelectField
            fieldName="method"
            label="Method Type"
            options={MFAMethods}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          {values['method'] && <>
            {values['method'] === 'sms_twilio' && <TextInputField
              fieldName="target"
              label="Phone Number"
              {...{ values, errors, touched, handleBlur, handleChange }}
            />}
            <TextInputField
              fieldName="code"
              label="MFA Code"
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <WhiteButton
                type="button"
                title="Send Code"
                onClick={() => sendCode(values['method'], values['target'])}
                className="mb-2 sm:mb-0 w-full inline-flex justify-center"
                disabled={!values['method'] || (values['method'] === 'sms_twilio' && !values['target'])}
              />
              <BlueButton
                type="submit"
                title={isSubmitting ? "Adding..." : "Add Method"}
                className="w-full transition duration-150 ease-in-out"
                disabled={isSubmitting || ((values['method'] === 'sms_twilio' && !values['target']) || !values['code'])}
              />
            </div>
          </>}
        </form>
      )}
    </Formik>
  )
}
