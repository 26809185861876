import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import HeadingsH2 from "@/components/headings/HeadingsH2"
import { Layout } from '@/components/layout'

import { useRecoilValue } from "recoil"
import { userState } from '@/recoil/atoms/auth'

import Recruit from "@/components/profile/company/recruits/Recruit"
import { LoadingInside } from '@/components/layout/top_level/Loading'

import {
  jobCandidatesService,
  serviceType
} from "@/services"

const Candidate = () => {
  const { id } = useParams<{ id: string }>()
  const userData = useRecoilValue<any>(userState)

  const [jobCandidate, setJobCandidate] = useState<any>({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const source = axios.CancelToken.source()
    jobCandidatesService.detail(id, source.token).then((result: serviceType.IServiceResult) => {
      if (result.success) {
        setJobCandidate(result.data)
        setLoading(false)
      }
    }).catch((error: any) => {
      console.error(error)
      setLoading(false)
    })
    return () => {
      source.cancel()
    }
  }, [id, setJobCandidate])

  return (
    <Layout title="Job Candidate" auth={true} back={true}>
      {
        userData
        && (
          <>
            <HeadingsH2 heading="Job Candidate" />
            {!loading ?
              <div className="mt-4 bg-white shadow overflow-hidden rounded-md">
                <ul className="divide-y divide-gray-200 mt-0">
                  <Recruit
                    object={jobCandidate}
                    showCandidatesButton={false}
                  />
                </ul>
              </div>
              :
              <LoadingInside />
            }
          </>
        )
      }
    </Layout>
  )
}

export default Candidate
