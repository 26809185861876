import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/solid'

import { Layout } from '@/components/layout'
import { LoadingInside } from '@/components/layout/top_level/Loading'
import { IJob, IJobVersion, IVersionFieldsChanged, jobsService } from '@/services/jobs.service'
import HeadingsH2 from '@/components/headings/HeadingsH2'
import WhiteButton from '@/components/buttons/WhiteButton'
import RedButton from '@/components/buttons/RedButton'
import axios, { CancelTokenSource } from 'axios'
import JobComments from '@/components/jobs/JobComments'
import { commonTables } from '@/recoil/atoms/common'
import { useRecoilValue } from 'recoil'
import { commonHelpers, commonService } from '@/services'
import TealButton from '@/components/buttons/TealButton'
import Modal from '@/components/modals/Modal'
import JobPreview from '@/components/jobs/JobPreview'
import { classNames } from '@/lib/helpers'
import { toast } from 'react-toastify'
import BlueButton from '@/components/buttons/BlueButton'

const ChangeableFields: ChangeableField[] = [
  { field: 'title', title: 'Title', type: 'text' },
  { field: 'description', title: 'Description', type: 'text' },
  { field: 'requirements', title: 'Responsibilities', type: 'textarea' },
  { field: 'qualification', title: 'Qualification', type: 'textarea' },
  { field: 'benefits', title: 'Benefits', type: 'textarea' },
  { field: 'state', title: 'State', type: 'select' },
  { field: 'city', title: 'City', type: 'select' },
  { field: 'industry', title: 'Industry', type: 'select' },
  { field: 'role', title: 'Role', type: 'select' },
  { field: 'work_type', title: 'Work Type', type: 'select' },
  { field: 'work_env', title: 'Work Environment', type: 'select' },
  { field: 'experience', title: 'Experience', type: 'select' },
  { field: 'availability', title: 'Availability', type: 'select' },
  { field: 'travel_availability', title: 'Availability To Travel', type: 'select' },
  { field: 'compensation_lower', title: 'Compensation (Lower)', type: 'text' },
  { field: 'compensation_upper', title: 'Compensation (Upper)', type: 'text' },
  { field: 'compensation_type', title: 'Compensation Type', type: 'select' },
]

type ChangeableField = {
  field: string,
  title: string,
  type: 'text' | 'textarea' | 'select'
}

const CompanyJobVersionReview = () => {
  let { company_id, job_id, version_id } = useParams<{ company_id: string, job_id: string, version_id: string }>()

  const common = useRecoilValue<any>(commonTables)
  const [job, setJob] = useState<IJob>()
  const [jobVersion, setJobVersion] = useState<IJobVersion>()
  const [fieldsChanged, setFieldsChanged] = useState<IVersionFieldsChanged>()
  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  const [renderedFieldInputs, setRenderedFieldInputs] = useState<{ [field: string]: JSX.Element | null }>({})
  const [showOld, setShowOld] = useState(false)
  const [loading, setLoading] = useState(true)
  const apiSource = useRef<CancelTokenSource>()

  const location = useLocation<any>()
  const history = useHistory()
  const hasBack = (location?.state?.from !== undefined)

  useEffect(() => {
    if (apiSource.current) {
      apiSource.current.cancel()
    }
    apiSource.current = axios.CancelToken.source()

    jobsService.versionDetail(parseInt(version_id), apiSource.current.token).then((res: any) => {
      setJobVersion(res.data)
      setLoading(false)
    })

    return () => {
      apiSource.current?.cancel()
    }
  }, [version_id])

  useEffect(() => {
    jobsService.detail(parseInt(job_id), null).then((res) => {
      setJob(res.data)
    })
  }, [job_id])

  useEffect(() => {
    if (!jobVersion || !job) {
      return
    }

    if (jobVersion.version_number === 1) {
      setFieldsChanged({ any_changed: false, fields: {} })
    } else {
      setLoading(true)
      jobsService.versionDifferences(
        job.id,
        job.last_reviewed_version_id ?? jobVersion.id,
        jobVersion.id,
        null
      ).then((res) => {
        setFieldsChanged(res.data)
        setLoading(false)
      }).catch(() => {
        toast.error('Failed to get job version differences')
      })
    }
  }, [job, jobVersion])

  const goBack = () => {
    history.goBack()
  }

  const approveVersion = useCallback(() => {
    jobsService.approveVersion(parseInt(version_id)).then(() => {
      toast.success('Approved job version')
      history.push(`/companies/${company_id}/job/${job_id}`)
    }).catch(() => {
      toast.error('Failed to approve job version')
    })
  }, [company_id, job_id, version_id, history])

  const rejectVersion = useCallback(() => {
    jobsService.rejectVersion(parseInt(version_id)).then(() => {
      toast.success('Rejected job version')
      history.push(`/companies/${company_id}/job/${job_id}`)
    }).catch(() => {
      toast.error('Failed to reject job version')
    })
  }, [company_id, job_id, version_id, history])

  useEffect(() => {
    if (!jobVersion || !fieldsChanged || !common) {
      return
    }

    Promise.all(ChangeableFields.map(async (field) => {
      const inputClasses = `max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md bg-gray-200 ${fieldsChanged.fields[field.field] ? 'border-2 border-teal-600' : 'border-gray-300'}`

      return new Promise<{ field: string, element: JSX.Element | null }>(async (resolve) => {
        let resolvedValue = (
          fieldsChanged.fields[field.field]
          ? showOld
            ? fieldsChanged.fields[field.field].old_value
            : fieldsChanged.fields[field.field].new_value
          : (jobVersion as any)[field.field]
        )

        if (typeof resolvedValue === 'object' && resolvedValue !== null) {
          resolvedValue = resolvedValue.id
        }

        switch (field.type) {
          case 'text':
            return resolve({
              field: field.field,
              element: (
                <input
                  type="text"
                  disabled={true}
                  className={inputClasses}
                  value={resolvedValue ?? ''}
                />
              )
            })
          case 'textarea':
            return resolve({
              field: field.field,
              element: (
                <textarea
                  disabled={true}
                  className={inputClasses}
                  value={resolvedValue ?? ''}
                />
              )
            })
          case 'select':
            let options: any[] = []

            switch (field.field) {
              case 'state': options = common.states; break
              case 'city':
                if (!resolvedValue) {
                  options = []
                } else {
                  const res = await commonService.citiesForCountry(
                    commonHelpers.defaultCountryID(common),
                    (
                      fieldsChanged.fields['state']
                      ? showOld
                        ? fieldsChanged.fields['state'].old_value
                        : fieldsChanged.fields['state'].new_value
                      : (jobVersion as any)['state']?.id
                    )
                  )
                  options = res?.data ?? []
                }
                break
              case 'industry':
                options = commonHelpers.industryDictionaryToArray(common.industries)
                resolvedValue = commonHelpers.industryKeyFromId(common, resolvedValue)
                break
              case 'role':
                if (!resolvedValue) {
                  options = []
                } else {
                  options = commonHelpers.rolesForIndustryById(
                    common.industries,
                    (
                      fieldsChanged.fields['industry']
                      ? showOld
                        ? fieldsChanged.fields['industry'].old_value
                        : fieldsChanged.fields['industry'].new_value
                      : (jobVersion as any)['industry']?.id
                    )
                  ) ?? []
                }
                break
              case 'work_type': options = common.work_types; break
              case 'work_env': options = common.work_environments; break
              case 'experience': options = common.experience_levels; break
              case 'availability': options = common.availabilities; break
              case 'travel_availability': options = common.travel_availabilities; break
              case 'compensation_type': options = common.compensation_types; break
              default: options = []
            }

            return resolve({
              field: field.field,
              element: (
                <select
                  disabled={true}
                  className={classNames(inputClasses, 'opacity-100')}
                  value={resolvedValue ?? ''}
                >
                  <option value="">Not Specified</option>
                  {options.map((option: any) => (
                    <option key={option.id} value={option.id}>{option.title}</option>
                  ))}
                </select>
              )
            })
          default:
            return resolve({ field: field.field, element: null })
        }
      })
    })).then((fields) => {
      let renderedFields: { [field: string]: JSX.Element | null } = {}

      for (const field of fields) {
        renderedFields[field.field] = field.element
      }

      setRenderedFieldInputs(renderedFields)
    })
  }, [fieldsChanged, jobVersion, common, showOld])

  return (
    <Layout title="Job Version" auth={true} back={true}>
      <Modal
        title={`Preview Version ${jobVersion?.version_number}`}
        open={previewModalOpen}
        setOpen={setPreviewModalOpen}
      >
        {(jobVersion && job) && <JobPreview jobVersion={jobVersion} job={job} />}
      </Modal>
      {!loading && jobVersion ?
        <>
          <div className="bg-white px-4 py-2">
            {hasBack &&
              <nav className="hidden lg:block flex items-start mb-2 py-4 lg:py-2" aria-label="Breadcrumb">
                <button
                  onClick={goBack}
                  className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
                >
                  <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-600" aria-hidden="true" />
                  <span>Back</span>
                </button>
              </nav>
            }
            <div className="flex justify-between gap-3">
              <div className="w-1/2">
                <div className="flex justify-between items-center gap-2">
                  <HeadingsH2 heading={jobVersion?.title} />
                  <p className="text-md text-gray-500 font-medium">Version {jobVersion?.version_number}</p>
                </div>
                <div className="flex gap-1 mt-2">
                  {(jobVersion.status === 'Review' && !jobVersion.has_reviewed) && <>
                    <TealButton
                      title="Approve"
                      onClick={() => approveVersion()}
                    />
                    <RedButton
                      title="Reject"
                      onClick={() => rejectVersion()}
                    />
                  </>}
                  <WhiteButton
                    title="Preview"
                    onClick={() => setPreviewModalOpen(true)}
                  />
                  {fieldsChanged?.any_changed && <BlueButton
                    title={`Show ${showOld ? 'New' : 'Old'}`}
                    onClick={() => setShowOld(!showOld)}
                  />}
                </div>
                {fieldsChanged?.any_changed === true && <p className="text-sm text-gray-500 italic mt-2">* Highlighted fields have been changed since the last version</p>}
                {fieldsChanged?.any_changed === false && <p className="text-gray-700 mt-2 font-bold">No fields have been changed</p>}
                {fieldsChanged && <div className="mt-4 flex flex-col gap-2">
                  {ChangeableFields.map((field) => <div key={field.field} className="flex justify-between items-center gap-1">
                    <p className="text-gray-700 text-sm font-medium">{field.title}</p>
                    {renderedFieldInputs[field.field]}
                  </div>)}
                </div>}
              </div>
              <div className="w-1/2">
                <JobComments jobId={parseInt(job_id)} versionId={parseInt(version_id)} canAdd={jobVersion?.status !== 'Closed'} />
              </div>
            </div>
          </div>
        </>
        :
        <LoadingInside />
      }
    </Layout>
  )
}

export default CompanyJobVersionReview
