import { CSSProperties, useCallback, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import Tour, { ReactourStep } from 'reactour'

import { userState } from '../recoil/atoms/auth'
import {
  accountService
} from '../services'


import { Layout } from '../components/layout'
import { LoadingInside } from '../components/layout/top_level/Loading'
import ProfileImage from '../components/profile/person/ProfileImage'
import HeadingsH2 from '../components/headings/HeadingsH2'
import WhiteButtonLink from '../components/links/WhiteButtonLink'


import PersonTabs from '../components/profile/person/Tabs'
import { RoleBadge } from '../components/profile/person/RoleBadge'
// import SubscriptionButton from '@/components/payment/SubscriptionButton'
// import UnsubscribeButton from '@/components/payment/UnsubscribeButton'
import BlueButton from '@/components/buttons/BlueButton'
import TealButton from '@/components/buttons/TealButton'
import WhiteButton from '@/components/buttons/WhiteButton'
import { onboardingJustCompletedAtom } from '@/recoil/atoms/app'

const Profile = () => {
  const [userData, setUserData] = useRecoilState<any>(userState)
  const [loading, setLoading] = useState(true)
  const [isTourOpen, setIsTourOpen] = useState(false)
  const [onboardingJustCompleted, setOnboardingJustCompleted] = useRecoilState(onboardingJustCompletedAtom)

  useEffect(() => {
    if (loading) {
      return
    }
    
    if (onboardingJustCompleted) {
      setIsTourOpen(true)
      setOnboardingJustCompleted(false)
    }
  }, [loading, onboardingJustCompleted, setOnboardingJustCompleted])

  const fetchUserData = useCallback(() => {
    setLoading(true)
    accountService.profile(null).then((result) => {
      setUserData(result.data)
      setLoading(false)
    }).catch((error) => {
      console.error(error)
      setLoading(false)
    })
  }, [setUserData])

  useEffect(() => {
    fetchUserData()
  }, [fetchUserData])

  const stepStyle: CSSProperties = {
    backgroundColor: '#F3F4F6',
    borderRadius: '0.5rem',
    color: '#374151',
    fontSize: '0.875rem',
    lineHeight: '1.25rem'
  }

  const steps: ReactourStep[] = [
    {
      selector: '[data-tabslug="profile"]',
      content: ({ goTo, inDOM }) => (
        <>
          <div>Now you've got Gumption! Let's complete your profile to get you jumpstarted on the site.</div>
          <div className="mt-2 flex gap-2">
            <TealButton
              title="Next"
              onClick={() => goTo(1)}
            />
            <WhiteButton
              title="Cancel"
              onClick={() => setIsTourOpen(false)}
            />
          </div>
        </>
      ),
      position: 'bottom',
      action: (node) => {
        node.click()
      },
      style: stepStyle
    },
    {
      selector: '[data-tour="profileinfo"]',
      content: ({ goTo, inDOM }) => (
        <>
          <div>First, let's start by completing filling out your personal profile details here.</div>
          <TealButton
            title="Next"
            onClick={() => {
              (document.getElementsByClassName('profile-info-toggle-editing')[0] as HTMLButtonElement | undefined)?.click()
              goTo(2)
            }}
            className="mt-2"
          />
        </>
      ),
      position: 'bottom',
      style: stepStyle
    },
    {
      selector: '[data-tour="profileform"]',
      content: ({ goTo, inDOM }) => (
        <>
          <div>Please fill out all of the fields in this form with their relevant information. After you're done, click the button to save your changes and continue.</div>
          <BlueButton
            title="Save"
            onClick={() => {
              (document.getElementsByClassName('profile-info-save-edits')[0] as HTMLButtonElement | undefined)?.click()
              goTo(3)
            }}
            className="mt-2"
          />
        </>
      ),
      position: 'right',
      style: stepStyle
    },
    {
      selector: '[data-tabslug="preferences"]',
      content: ({ goTo, inDOM }) => (
        <>
          <div>Next, if you're looking for a job, what are your job preferences?</div>
          <div className="mt-2 flex gap-2">
            <TealButton
              title="Next"
              onClick={() => {
                (document.getElementsByClassName('profile-job-prefs-toggle-editing')[0] as HTMLButtonElement | undefined)?.click()
                goTo(4)
              }}
            />
            <WhiteButton
              title="Skip"
              onClick={() => {
                document.getElementById('profile_image')?.click()
                goTo(5)
              }}
            />
          </div>
        </>
      ),
      position: 'bottom',
      action: (node) => {
        node.click()
      },
      style: stepStyle
    },
    {
      selector: '[data-tour="jobprefsform"]',
      content: ({ goTo, inDOM }) => (
        <>
          <div>Please fill out all of the fields in this form so that you can be better matched with jobs.</div>
          <BlueButton
            title="Save"
            onClick={() => {
              (document.getElementsByClassName('profile-job-prefs-save-edits')[0] as HTMLButtonElement | undefined)?.click()
              document.getElementById('profile_image')?.click()
              goTo(5)
            }}
            className="mt-2"
          />
        </>
      ),
      position: 'bottom',
      action: (node) => {
        node.focus()
      },
      style: stepStyle
    },
    {
      selector: '[data-tour="changepictureform"]',
      content: ({ goTo, inDOM }) => (
        <>
          <div>Now let's get a profile image uploaded for yourself.</div>
          <TealButton
            title="Next"
            onClick={() => {
              goTo(6)
            }}
            className="mt-2"
          />
        </>
      ),
      position: 'bottom',
      action: (node) => {
        node.click()
      },
      style: stepStyle
    },
    {
      content: ({ goTo, inDOM }) => (
        <>
          <div>Congratulations! If you've filled out all of the fields mentioned in this tutorial your profile should now be complete! Going forward, here are some additional things you can do while you're here...</div>
          <div className="mt-2 flex gap-2">
            <TealButton
              title="Next"
              onClick={() => goTo(7)}
            />
            <WhiteButton
              title="Close"
              onClick={() => setIsTourOpen(false)}
            />
          </div>
        </>
      ),
      style: stepStyle
    },
    {
      selector: '[data-tabslug="history"]',
      content: ({ goTo, inDOM }) => (
        <>
          <div>In this tab you can add previous work history that you'd like to provide.</div>
          <TealButton
            title="Next"
            onClick={() => {
              (document.querySelector('[data-tabslug="profile"]') as HTMLElement | undefined)?.click()
              goTo(8)
            }}
            className="mt-2"
          />
        </>
      ),
      position: 'bottom',
      action: (node) => {
        node.click()
      },
      style: stepStyle
    },
    {
      selector: '[data-tour="profilelinks"]',
      highlightedSelectors: ['[data-tour="modal"]'],
      mutationObservables: ['[data-tour="modal"]'],
      resizeObservables: ['[data-tour="modal"]'],
      content: ({ goTo, inDOM }) => (
        <>
          <div>Here you can add any relevant website / social media links that you'd like for people to be able to view.</div>
          <TealButton
            title="Next"
            onClick={() => {
              goTo(9)
            }}
            className="mt-2"
          />
        </>
      ),
      position: 'bottom',
      style: stepStyle
    },
    {
      content: ({ goTo, inDOM }) => (
        <>
          <div className="font-semibold italic">That's all folks!</div>
          <div className="mt-1">Best of luck on your Gumption journey!</div>
          <WhiteButton
            title="Finish"
            onClick={() => {
              setIsTourOpen(false)
              fetchUserData()
            }}
            className="mt-2"
          />
        </>
      ),
      position: 'bottom',
      style: stepStyle
    }
  ]

  return (
    <Layout title="Profile" auth={true} back={true}>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        rounded={8}
        showNumber={false}
        showCloseButton={false}
        startAt={0}
        nextButton={null}
        showButtons={false}
        disableFocusLock={true}
        disableDotsNavigation={true}
        disableKeyboardNavigation={true}
        closeWithMask={false}
      />
      {!loading ?
        <>
          <div className="relative p-4 md:p-0">
            <HeadingsH2 heading="Profile" />
            <div className="flex justify-between items-start mb-4">
              <div className="flex flex-col text-center pt-1">
                <ProfileImage profile_image_url={userData.person.profile_image} />
                <p className="font-bold text-lg">{userData.person.first_name} {userData.person.last_name}</p>
                <RoleBadge role={userData.role} />
              </div>
              <div className="flex gap-2 flex-col sm:flex-row">
                {userData?.person.profile_completion < 100 && <div data-tour="startbutton">
                  <BlueButton
                    title="Complete Profile"
                    onClick={() => setIsTourOpen(true)}
                    disabled={isTourOpen}
                    className="bg-yellow-400 hover:bg-yellow-400 ring-2 ring-offset-2 ring-yellow-400 sm:mr-6"
                  />
                </div>}
                {/* {!userData?.is_paid ? <SubscriptionButton/> : <UnsubscribeButton/>} */}
                <WhiteButtonLink
                  title="Public Profile"
                  to={{pathname: `/people/${userData.person.id}`, state: {from: '/profile/'}}}
                />
              </div>
            </div>
            <PersonTabs user={userData} />
          </div>
        </>
        :
        <LoadingInside />
      }
    </Layout >
  )
}

export default Profile
