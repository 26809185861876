import { IRecruitingStepDocument } from '@/recoil/types'
import { XCircleIcon } from '@heroicons/react/outline'
import { DocumentIcon } from '@heroicons/react/solid'
import { FC, useState } from 'react'
import { memo } from 'react'
import Confirm from '../modals/Confirm'

export interface RecruitingStepDocumentProps {
  document: IRecruitingStepDocument,
  deleteDocument: () => void
}

export const RecruitingStepDocument: FC<RecruitingStepDocumentProps> = memo(function RecruitingProcessStepDetail({
  document,
  deleteDocument
}) {
  const [confirmDeleteDocument, setConfirmDeleteDocument] = useState(false)

  return (<>
    <Confirm
        title={`Are you sure to delete the document "${document.title}"?`}
        onConfirm={() => deleteDocument()}
        isOpen={confirmDeleteDocument}
        setIsOpen={setConfirmDeleteDocument}
      />
    <div className="border border-gray-300 rounded-md shadow-sm flex items-center">
      <a href={document.file} className="text-teal-600 text-sm font-bold flex items-center p-3">
        <DocumentIcon className="h-5 w-5" aria-hidden="true" />
        <span className="ml-1">{document.title}</span>
      </a>
      {document.can_delete && <XCircleIcon
        className="text-red-500 h-5 w-5 mr-3 cursor-pointer"
        onClick={() => setConfirmDeleteDocument(true)}
      />}
    </div>
  </>)
})
