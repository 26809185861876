import { Link, useHistory } from 'react-router-dom'

import { IRecruitingProcess } from '@/recoil/types'

import WhiteButton from '../buttons/WhiteButton'

const RecruitProcessItemInner = ({ process }: { process: IRecruitingProcess }) => {
  const history = useHistory()

  return (
    <div className="px-4 py-4 flex items-center sm:px-6">
      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between truncate">
        <div className="truncate">
          <div className="text-sm">
            <p className="font-bold text-teal-600 truncate">{process.name}</p>
          </div>
        </div>
      </div>
      <div className="ml-2 sm:ml-0 flex flex-col">
        {process.linked_job && <WhiteButton
          title="Linked Job"
          onClick={() => history.push({
            pathname: `/jobs/${process.linked_job?.id}`,
            state: { from: '/recruit' }
          })}
        />}
      </div>
    </div>
  )
}

const RecruitProcessItem = ({ object }: { object: IRecruitingProcess }) => {
  return (
    <>
      <li>
        <Link to={{ pathname: `/recruit/pipeline/${object.id}` }} className="block hover:bg-gray-50">
          <RecruitProcessItemInner process={object} />
        </Link>
      </li>
    </>
  )
}

export default RecruitProcessItem
