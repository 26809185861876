import { useState, useEffect } from 'react'

import { SelectField } from '..'
import { commonService } from '../../../../services'

const CityField = ({ values, errors, touched, handleChange, handleBlur, styles = null, disabled = false }: any) => {
  const [cities, setCities] = useState<any>([])
  useEffect(() => {
    if (values.country && values.state) {
      commonService.citiesForCountry(values.country, values.state).then(({ data }: any) => {
        setCities(data)
      }).catch((error) => {
        console.error(error)
      })
    } else {
      setCities([])
    }
  }, [values.country, values.state, setCities])

  let commonProps = { values, errors, touched, handleChange, handleBlur }
  if (styles) {
    commonProps = Object.assign({}, commonProps, { styles })
  }

  return (
    <SelectField
      fieldName="city"
      label="City"
      options={cities}
      disabled={disabled}
      {...commonProps}
    />
  )
}

export default CityField