import { classNames } from '../../lib/helpers'
import BaseButtonLink, { IBaseButtonLinkProps } from './BaseButtonLink'

import ButtonLinkStyles from './styles'

const BlueLinkButton = (props: IBaseButtonLinkProps) => {
  return (
    <BaseButtonLink
      {...props}
      className={classNames(
        props.className,
        ButtonLinkStyles.Blue
      )}
    />
  )
}

export default BlueLinkButton