import { CommentFieldNameMap, IJobComment, jobsService } from '@/services/jobs.service'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import TealButton from '../buttons/TealButton'
import { LoadingInside } from '../layout'
import Modal from '../modals/Modal'
import { FormatedDate } from '../utils'
import AddJobCommentForm from './forms/AddJobCommentForm'

const JobComments = ({
  jobId,
  versionId,
  canAdd
}: {
  jobId: number,
  versionId: number,
  canAdd: boolean
}) => {
  const [addCommentModalOpen, setAddCommentModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [comments, setComments] = useState<IJobComment[]>([])

  const loadComments = useCallback(() => {
    setLoading(true)
    jobsService.getComments(jobId, null).then((res) => {
      setComments(res.data)
      setLoading(false)
    }).catch(() => {
      toast.error('Failed to load comments')
      setLoading(false)
    })
  }, [jobId])

  const addComment = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    jobsService.addComment(
      jobId,
      versionId,
      values['comment'],
      values['field']
    ).then(({success, data}) => {
      if (success) {
        toast.success('Successfully added comment')
        setAddCommentModalOpen(false)
        loadComments()
      } else {
        console.error(data)
        toast.error('Failed to add comment')
      }
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to add comment')
      setSubmitting(false)
    })
  }

  useEffect(() => {
    loadComments()
  }, [loadComments])
  
  return (
    <>
      <Modal
        title="Add Comment"
        open={addCommentModalOpen}
        setOpen={setAddCommentModalOpen}
        hasForm={true}
      >
        <AddJobCommentForm handleSubmit={addComment} />
      </Modal>
      {loading
        ? <LoadingInside />
        : <div className="bg-white shadow overflow-hidden rounded-md border border-gray-200">
            <div className="p-5">
              {canAdd && <TealButton
                title="Add Comment"
                onClick={() => setAddCommentModalOpen(true)}
              />}
              <div className="mt-2 space-y-2">
                {comments.map((comment) => <div className={`p-2 rounded-md ${comment.job_version === versionId ? 'bg-gray-100' : ''}`} key={comment.id}>
                  <p className="text-gray-600 space-x-1">
                    <span className="font-bold">{comment.field_name ? `Comment on "${CommentFieldNameMap[comment.field_name]}"` : 'General Comment'}</span>
                    <span className="text-sm text-gray-500">by {comment.user_name}</span>
                  </p>
                  <p className="text-gray-600 text-sm mt-1">
                    {comment.comment}
                  </p>
                  <p className="text-xs text-gray-500 mt-2 italic">
                    Version {comment.version_number}
                    &nbsp;&bull;&nbsp;
                    <FormatedDate date={new Date(comment.created)} relative={true} />
                  </p>
                </div>)}
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default JobComments
