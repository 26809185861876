import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '@/components/buttons/BlueButton'
import { IJob, JobVettingTypeReadableMap } from '@/services/jobs.service'
import { SelectField } from '@/components/forms/fields'
import { IConnection } from '@/recoil/types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { userState } from '@/recoil/atoms/auth'
import MultiselectField, { IMultiselectFieldHandle, IMultiselectFieldOption } from '@/components/forms/fields/MultiselectField'
import { LoadingInside } from '@/components/layout'
import { AccountRoles } from '@/services'

const UpdateJobVettingSchema = Yup.object().shape({
  vetting_type: Yup.string().required('Vetting type is required'),
  vetting_users: Yup.array().when('vetting_type', {
    is: (val: string) => val === 'Recruiter' || val === 'Internal',
    then: (schema) => schema.min(1, 'At least one vetting user is required')
  })
})

export default function UpdateJobVettingForm({ job, handleSubmit }: { job: IJob, handleSubmit: any }) {
  const [potentialRecruiters, setPotentialRecruiters] = useState<IMultiselectFieldOption[]>([])
  const [potentialISE, setPotentialISE] = useState<IMultiselectFieldOption[]>([])
  const [loading, setLoading] = useState(true)
  const userData = useRecoilValue(userState)
  const vettingUsersSelectRef = useRef<IMultiselectFieldHandle>(null)

  useEffect(() => {
    if (!userData || !userData.person) {
      return
    }

    setPotentialRecruiters(userData.person.connections.filter((conn) => conn.role === AccountRoles.RECRUITER).map((entry: IConnection) => {
      return {
        id: entry.user_id.toString(),
        title: `${entry.first_name} ${entry.last_name}`
      }
    }))

    setPotentialISE(userData.person.connections.filter((conn) => conn.role === AccountRoles.INTERNAL_STRATICE).map((entry: IConnection) => {
      return {
        id: entry.user_id.toString(),
        title: `${entry.first_name} ${entry.last_name}`
      }
    }))

    setLoading(false)
  }, [userData])

  const vettingTypeOptions = useMemo(() => {
    const options: IMultiselectFieldOption[] = []

    for (const entry of Object.entries(JobVettingTypeReadableMap)) {
      options.push({ id: entry[0], title: entry[1] })
    }

    return options
  }, [])

  return (
    !loading
    ? <Formik
      initialValues={{
        vetting_type: job.vetting_type,
        vetting_users: job.vetting_users ?? []
      }}
      validationSchema={UpdateJobVettingSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <SelectField
            fieldName="vetting_type"
            label="Vetting Type"
            options={vettingTypeOptions}
            required={true}
            {...{ values, errors, touched, handleBlur, handleChange: (e) => {
              handleChange(e)
              vettingUsersSelectRef.current?.resetSelection()
            } }}
          />
          {(values['vetting_type'] === 'Recruiter' || values['vetting_type'] === 'Internal') && <MultiselectField
            fieldName="vetting_users"
            label="Vetting Users"
            options={values['vetting_type'] === 'Recruiter' ? potentialRecruiters : potentialISE}
            ref={vettingUsersSelectRef}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />}
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <BlueButton
              type="submit"
              title={isSubmitting ? "Updating..." : "Update"}
              className="w-full transition duration-150 ease-in-out"
              disabled={!isValid || !dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
    : <LoadingInside />
  )
}
