import { BriefcaseIcon, OfficeBuildingIcon, UserGroupIcon } from "@heroicons/react/outline"
import { Link } from "react-router-dom"
import { FormatedDate } from "../utils"

interface IHistoryItem {
  object: {
    title: string,
    timestamp: string,
    type: string,
    ref_id: number,
    id: string
  }
}


const HistoryItem = ({ object }: IHistoryItem) => {
  let linkPath: string = '/'
  let icon = null

  if (object.type === 'job') {
    linkPath = `/jobs/${object.ref_id}`
    icon = <BriefcaseIcon className="w-5 h-5 text-gray-500" aria-hidden="true" />
  } else if (object.type === 'person') {
    linkPath = `/people/${object.ref_id}`
    icon = <UserGroupIcon className="w-5 h-5 text-gray-500" aria-hidden="true" />
  } else if (object.type === 'company') {
    linkPath = `/companies/${object.ref_id}`
    icon = <OfficeBuildingIcon className="w-5 h-5 text-gray-500" aria-hidden="true" />
  }

  return (
    <>
      <Link to={{
        pathname: linkPath,
        state: { from: '/history' }
      }}>
        <li key={object.id} className="py-4 bg-white mb-2 rounded-md">
          <div className="flex items-center px-3 justify-between">
            <div className="flex items-center gap-2">
              {icon}
              <p className="text-sm font-medium text-teal-600">{object.title}</p>
            </div>
            <p className="text-sm text-gray-500"><FormatedDate date={new Date(object.timestamp)} relative={true} /></p>
          </div>
        </li>
      </Link>
    </>
  )
}

export default HistoryItem
