import { classNames } from "../../lib/helpers"

export interface IHeadingsH3 {
  heading: string
  className?: string
}

const HeadingsH3 = ({
  heading,
  className = '',
}: IHeadingsH3) => {
  return (
    <h3
      className={classNames(
        className,
       'font-bold text-lg text-teal-600'
      )}
    >
      {heading}
    </h3>
  )
}

export default HeadingsH3
