import { useState } from "react"
import RecruiterTermsAndConditions from "../auth/RecruiterTermsAndConditions"
import { CheckboxField, SelectField } from "../forms/fields"
import SlideOver from "../slideover/SlideOver"


function RecruiterFields({ values, errors, touched, handleChange, handleBlur, common, textInputStyles, selectInputStyles }: any) {
  const [termsAndConditionsModalOpen, setTermsAndConditionsModalOpen] = useState(false)

  const commonFieldProps = { values, errors, touched, handleBlur, handleChange }

  return (
    <>
      <SlideOver
        title="Recruiter Terms & Conditions"
        open={termsAndConditionsModalOpen}
        setOpen={setTermsAndConditionsModalOpen}
      >
        <RecruiterTermsAndConditions />
      </SlideOver>
      <div className="mt-6 sm:mt-5 space-y-1">
        <SelectField
          fieldName="recruiter_type"
          label="Recruiter Type"
          required={true}
          options={common?.recruiter_types}
          styles={selectInputStyles}
          {...commonFieldProps}
        />
        <CheckboxField 
          fieldName="recruiter_tac"
          label={<>
            I accept the <span className="text-teal-600 cursor-pointer" onClick={
              (e) => {
                e.preventDefault()
                e.stopPropagation()
                setTermsAndConditionsModalOpen(true)
              }
            }>Recruiter Terms &amp; Conditions.</span>
          </>}
          required={true}
          styles={{ wrapper: 'sm:pt-5' }}
          {...commonFieldProps}
        />
      </div>
    </>
  )
}

export default RecruiterFields
