// base libs
import axios, { CancelTokenSource } from 'axios'
// react stuff
import React, { forwardRef, FunctionComponent, MouseEventHandler, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
// services
import { IListRetrieveService, IServicePaginatedListResult, IListPagination, IListItem } from '../../../services/types'
// components
import NoResults from "./NoResults"
import { LoadingInside } from '../index'
import WhiteButton from '../../buttons/WhiteButton'
import SearchAndFilter, { RecruitFilterFields, recruitFilter } from './SearchAndFilter'
import { trimFilter } from '@/services/utils'

interface IPaginatedListProps {
  service: IListRetrieveService
  onServiceError?: Function | null
  filter?: any
  itemComponent: FunctionComponent<any>
  itemsProps?: any
  serviceParams?: any,
  pageSize?: number,
  parentItems?: any
  setParentItems?: any,
  noResultsText?: string,
  children?: any
}

interface IPaginationProps {
  pagination: IListPagination,
  onPrevClick: MouseEventHandler<HTMLButtonElement>,
  onNextClick: MouseEventHandler<HTMLButtonElement>
}

export const Pagination = ({ pagination, onPrevClick, onNextClick }: IPaginationProps) => {
  const currentPageNumber = (): number => {
    if (!pagination.previous) return 1
    const previousUrlParams = new URLSearchParams(pagination.previous)
    if (!previousUrlParams.has('page')) return 2
    else return parseInt(previousUrlParams.get('page')!) + 1
  }

  const fromRecord = () => {
    const page: number = currentPageNumber()
    return (page - 1) * pagination.pageSize + 1
  }

  const toRecord = () => {
    const page: number = currentPageNumber()
    const to_record = (page) * pagination.pageSize
    return (to_record <= pagination.count ? to_record : pagination.count)
  }

  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-xs text-gray-700">
          Showing <span className="font-medium">{fromRecord()}</span> to <span className="font-medium">{toRecord()}</span> of{' '}
          <span className="font-medium">{pagination.count}</span> results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end sm:space-x-2">
        <WhiteButton
          title="Previous"
          onClick={onPrevClick}
          disabled={!pagination.previous}
        />
        <WhiteButton
          title="Next"
          onClick={onNextClick}
          disabled={!pagination.next}
        />
      </div>
    </nav>
  )
}

const PaginatedList = forwardRef(({
  service,
  onServiceError = null,
  filter = {},
  itemComponent,
  itemsProps,
  serviceParams = {},
  pageSize = 10,
  parentItems,
  setParentItems,
  noResultsText,
  children = null
}: IPaginatedListProps, ref) => {
  // internal state
  const [loading, setLoading] = useState<boolean>(false)
  const [pagination, setPagination] = useState<IListPagination>({ next: '', previous: '', count: 0, pageSize })

  serviceParams['page_size'] = pageSize

  const serviceParamsCopy = { ...serviceParams }
  const filterCopy = { ...filter }
  Object.keys(filter).forEach(k => {
    if (filter[k] !== undefined && filter[k] !== '') {
      delete serviceParamsCopy[k]
    }
    else {
      delete filterCopy[k]
    }
  })

  // stringify objects for useEffect deps tracking
  const serviceParamsJsonString = JSON.stringify(serviceParamsCopy)
  const filterString = new URLSearchParams(filterCopy).toString()

  let [items, setItems] = useState<Array<IListItem>>([])
  if (parentItems && setParentItems) {
    items = parentItems
    setItems = setParentItems
  }

  // refs (not state)
  const mounted = useRef(false)
  const apiSource = useRef<CancelTokenSource>()

  // effetcs
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // functions
  const fetchItems = useCallback(() => {
    // cancel any ongoing requests
    if (apiSource.current) {
      apiSource.current.cancel()
    }
    apiSource.current = axios.CancelToken.source()
    setLoading(true)
    service.list('?' + trimFilter(filterString), apiSource.current.token, false, JSON.parse(serviceParamsJsonString)).then(({ success, data }: IServicePaginatedListResult) => {
      if (success && mounted.current) {
        setItems(data.results)
        setPagination({
          next: data.next,
          previous: data.previous,
          count: data.count,
          pageSize
        })
        setLoading(false)
      }
    }).catch((error: any) => {
      console.error(error)
      if (onServiceError) onServiceError(error)
      setLoading(false)
    })
    return () => {
      apiSource.current?.cancel()
    }
  },
    [setItems, filterString, service, onServiceError, serviceParamsJsonString, pageSize]
  )

  const fetchNext = () => {
    setLoading(true)
    service.list(pagination.next, null, true).then(({ success, data }: IServicePaginatedListResult) => {
      if (success && mounted.current) {
        setItems(data.results)
        setPagination(Object.assign({}, pagination, {
          next: data.next,
          previous: data.previous
        }))
        setLoading(false)
      }
    }).catch((error: any) => {
      console.error(error)
      if (onServiceError) onServiceError(error)
      setLoading(false)
    })
  }

  const fetchPrevious = () => {
    setLoading(true)
    service.list(pagination.previous, null, true).then(({ success, data }: IServicePaginatedListResult) => {
      if (success && mounted.current) {
        setItems(data.results)
        setPagination(Object.assign({}, pagination, {
          next: data.next,
          previous: data.previous
        }))
        setLoading(false)
      }
    }).catch((error: any) => {
      console.error(error)
      if (onServiceError) onServiceError(error)
      setLoading(false)
    })
  }

  useImperativeHandle(ref, () => ({
    fetchItems: () => { fetchItems() }
  }))

  // fetch on load
  useEffect(() => {
    fetchItems()
  }, [fetchItems])


  return (
    <>
      {children}
      {!loading ?
        <div className="bg-white shadow overflow-hidden rounded-md">
          {
            items.length > 0 ?
              <>
                <ul className="divide-y divide-gray-200 mt-0">
                  {items.map((object: any) => (
                    React.createElement(itemComponent, {
                      key: object.id,
                      object,
                      ...itemsProps
                    })
                  ))}
                </ul>
                <Pagination pagination={pagination} onPrevClick={fetchPrevious} onNextClick={fetchNext} />
              </>
              :
              <NoResults text={noResultsText} />
          }
        </div>
        :
        <LoadingInside />
      }
    </>
  )
})


export const withSearchAndFilter = (WrappedComponent: any) => React.forwardRef((props: any, ref) => {
  // filter state
  const [filter, setFilter] = useState({})
  const { colsInFilter } = props

  return (
    <WrappedComponent ref={ref} {...{ filter }} {...props} >
      <SearchAndFilter
        filter={filter}
        setFilter={setFilter}
        colsInFilter={colsInFilter}
      />
    </WrappedComponent>
  )
})

export const withRecruitSearchAndFilter = (WrappedComponent: any) => React.forwardRef((props: any, ref) => {
  // filter state
  const [filter, setFilter] = useState({})
  const { colsInFilter, extraFields } = props

  return (
    <WrappedComponent ref={ref} {...{ filter }} {...props} >
      <SearchAndFilter
        filter={filter}
        setFilter={setFilter}
        colsInFilter={colsInFilter}
        FilterFieldsComponent={RecruitFilterFields}
        emptyFilter={recruitFilter}
        extraFields={extraFields}
      />
    </WrappedComponent>
  )
})

export default PaginatedList