// base libs
import axios, { CancelTokenSource } from 'axios'
// react stuff
import React, { forwardRef, FunctionComponent, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
// 3rd party
import InfiniteScroll from 'react-infinite-scroll-component'
// services
import { IServicePaginatedListResult, IListSimplePagination, IListItem, IListService } from '../../../services/types'
// components
import NoResults from "./NoResults"
import { LoadingInside } from '../index'
import { globalFilterAtom } from '../../../recoil/atoms/app'
import { useRecoilState } from 'recoil'
import { useQuery } from '../../../lib/helpers'
import { useHistory, useLocation } from 'react-router-dom'
import { trimFilter } from '@/services/utils'

interface IInfiniteListProps {
  service: IListService
  onServiceError?: Function | null
  filter?: string // we use URLSearchParams.toString due to JS failure to compare objects 
  itemComponent: FunctionComponent<any>
  itemsProps?: any
  serviceParams?: any
  parentItems?: any
  setParentItems?: any,
  noResultsText?: string
}

const InfiniteList = forwardRef(({
  service,
  onServiceError = null,
  filter = '',
  itemComponent,
  itemsProps,
  serviceParams = {},
  parentItems,
  setParentItems,
  noResultsText
}: IInfiniteListProps, ref) => {
  // internal state
  const [loading, setLoading] = useState<boolean>(false)
  const [pagination, setPagination] = useState<IListSimplePagination>({ next: '' })

  // stringify objects for useEffect deps tracking
  const serviceParamsJsonString = JSON.stringify(serviceParams)

  let [items, setItems] = useState<Array<IListItem>>([])
  if (parentItems && setParentItems) {
    items = parentItems
    setItems = setParentItems
  }

  // refs (not state)
  const mounted = useRef(false)
  const apiSource = useRef<CancelTokenSource>()

  // effetcs
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // functions
  const fetchItems = useCallback(() => {
    // cancel any previous requests and set new source
    if (apiSource.current) {
      apiSource.current.cancel()
    }
    apiSource.current = axios.CancelToken.source()


    setLoading(true)
    service.list('?' + trimFilter(filter), apiSource.current.token, false, JSON.parse(serviceParamsJsonString)).then(({ success, data }: IServicePaginatedListResult) => {
      if (success && mounted.current) {
        setItems(data.results)
        setPagination({
          next: data.next
        })
        setLoading(false)
      }
    }).catch((error: any) => {
      if (onServiceError) onServiceError(error)
      setLoading(false)
    })
    return () => {
      // cancel request on unmount
      apiSource.current?.cancel()
    }
  },
    [setItems, filter, service, onServiceError, serviceParamsJsonString]
  )

  useImperativeHandle(ref, () => ({
    fetchItems: () => { fetchItems() }
  }))

  // fetch on load
  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  const fetchMoreItems = () => {
    service.list(pagination.next, null, true, serviceParams).then(({ success, data }: IServicePaginatedListResult) => {
      if (success && mounted.current) {
        setItems(items.concat(data.results))
        setPagination({
          next: data.next
        })
      }
    }).catch((error: any) => {
      if (onServiceError) onServiceError(error)
      setLoading(false)
    })
  }

  return (
    <>
      {!loading ?
        <InfiniteScroll
          dataLength={items?.length || 0} //This is important field to render the next data
          next={fetchMoreItems}
          hasMore={pagination.next !== '' && pagination.next !== null}
          loader={<LoadingInside />}
          refreshFunction={fetchItems}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
          }
          releaseToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          }
          scrollableTarget="infinite-scroll-wrapper"
        >
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            {
              items.length > 0 ?
                <ul className="divide-y divide-gray-200 mt-0">
                  {items.map((object: any) => (
                    React.createElement(itemComponent, {
                      key: object.id,
                      object,
                      ...itemsProps
                    })
                  ))}
                </ul>
                :
                <NoResults text={noResultsText} />
            }
          </div>
        </InfiniteScroll>
        :
        <LoadingInside />
      }
    </>
  )
})

export default InfiniteList


const withGlobalFilter = (WrappedComponent: any) => ({ ...props }) => {
  // global filter state
  const [filter, setGlobalFilter] = useRecoilState<any>(globalFilterAtom)
  // ref to check query params on 1st page load / refresh
  const initialLoad = useRef(false)
  const initialSync = useRef(false)
  // history location sync
  let query = useQuery()
  const history = useHistory()
  const location = useLocation()

  const filterString = new URLSearchParams(filter).toString()

  // sync query params on filter change
  useEffect(() => {
    if (!initialSync.current) {
      initialSync.current = true
      return
    }
    history.replace(`${location.pathname}?${filterString}`)
  }, [filterString, location.pathname, history])

  // sync query params on refresh / 1st load
  useEffect(() => {
    if (!initialLoad.current) {
      initialLoad.current = true
      const filterOptionsFromQuery = {
        search: query.get('search') || filter.search,
        page: query.get('page') || filter.page,
        industry: query.get('industry') || filter.industry,
        role: query.get('role') || filter.role,
        experience: query.get('experience') || filter.experience,
        availability: query.get('availability') || filter.availability,
        travel_availability: query.get('travel_availability') || filter.travel_availability,
        compensation_type: query.get('compensation_type') || filter.compensation_type,
        work_type: query.get('work_type') || filter.work_type,
        work_env: query.get('work_env') || filter.work_env,
        state: query.get('state') || filter.state,
        city: query.get('city') || filter.city,
      }
      setGlobalFilter(filterOptionsFromQuery)
    }
  })

  return (
    <WrappedComponent {...{ filter: filterString }} {...props} />
  )
}

export { withGlobalFilter }
