import { useEffect, useState, useRef, useCallback } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { getApp, FirebaseApp } from 'firebase/app'
import { getMessaging, getToken, onMessage, Messaging } from 'firebase/messaging'
import { toast } from 'react-hot-toast'

import { notificationService } from '../../services/notification.service'
import { isLoggedIn } from '../../recoil/atoms/auth'
import { pendingNotifications as pendingNotificationsAtom } from '../../recoil/atoms/notification'
import { firebaseConfig } from '../../config/firebase.config'
import { INotification, INotificationAction } from '@/recoil/types'
import NotificationPopOver from './NotificationPopOver'
import { isIOS } from '@/lib/helpers'

function parseINotification(data: { [key: string]: any }) {
  data['is_pending'] = true
  data.actions = []

  for (let i = 1; i <= 5; i++) {
    if (!data['action' + i + '_text']) {
      break
    }

    data.actions.push({
      text: data['action' + i + '_text'],
      color: data['action' + i + '_color'],
      endpoint: data['action' + i + '_endpoint'],
      endpoint_payload: (
        data['action' + i + '_endpoint_payload']
        ? JSON.parse(data['action' + i + '_endpoint_payload'])
        : undefined
      ),
      endpoint_method: data['action' + i + '_endpoint_method'] ?? 'POST',
      link: data['action' + i + '_link'],
    } as INotificationAction)
  }

  return data as INotification
}

export const NotificationListener = () => {
  const userLoggedIn = useRecoilValue(isLoggedIn)
  const [
    pendingNotifications,
    setPendingNotifications
  ] = useRecoilState<INotification[]>(pendingNotificationsAtom)
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false)
  const [incomingNotification, setIncomingNotification] = useState<INotification>()
  const shouldPoll = useRef(false)
  const pollTimeout = useRef<any>()
  const pendingNotificationsRef = useRef<INotification[]>([])

  useEffect(() => {
    pendingNotificationsRef.current = pendingNotifications
  }, [pendingNotifications])

  const firebaseAppRef = useRef<FirebaseApp>()
  const firebaseMessagingRef = useRef<Messaging>()
  const tokenRef = useRef<string>()
  const lastNotification = useRef<INotification>()

  const fetchPendingNotifications = useCallback(async (): Promise<INotification[]> => {
    const pendingNotifications = await notificationService.getPending(null)
    if (pendingNotifications.success) {
      setPendingNotifications(pendingNotifications.data.results as INotification[])
      return pendingNotifications.data.results
    } else {
      return []
    }
  }, [setPendingNotifications])

  const toastUnseenNotifications = (oldIds: number[], newNotifs: INotification[]): void => {
    for (const notif of newNotifs) {
      if (!oldIds.includes(notif.id) && !notif.has_seen) {
        toast.custom((t) => <NotificationPopOver notification={notif} thisToast={t}/>)
      }
    }
  }

  const pollForNotifications = useCallback(async () => {
    if (!shouldPoll.current) {
      return
    }
    
    const oldPendingIds = pendingNotificationsRef.current.map((notif) => notif.id)
    const newPending = await fetchPendingNotifications();
    toastUnseenNotifications(oldPendingIds, newPending)

    pollTimeout.current = setTimeout(() => {
      pollTimeout.current = null
      pollForNotifications()
    }, parseInt(process.env.REACT_APP_NOTIF_POLLING_INTERVAL ?? '5000'))
  }, [fetchPendingNotifications])

  const checkForNotifications = useCallback(async () => {
    const oldPendingIds = pendingNotificationsRef.current.map((notif) => notif.id)
    const newPending = await fetchPendingNotifications();
    toastUnseenNotifications(oldPendingIds, newPending)
  }, [fetchPendingNotifications])

  useEffect(() => {
    if (!userLoggedIn) {
      return
    }

    const conditionallyStartPolling = () => {
      if (shouldPoll.current) {
        return
      }

      if (pollTimeout.current) {
        clearTimeout(pollTimeout.current)
        pollTimeout.current = null
      }

      shouldPoll.current = true
      pollForNotifications()
    }

    const onVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        if (!userLoggedIn) {
          return
        }

        if (isIOS()) {
          conditionallyStartPolling()
        } else {
          checkForNotifications()
        }
      } else {
        isIOS() && (shouldPoll.current = false)
      }
    }

    document.addEventListener('visibilitychange', onVisibilityChange)

    if (isIOS()) {
      conditionallyStartPolling()
    } else {
      checkForNotifications()
    }

    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        setPermissionGranted(permission === 'granted')
      })
    }

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [userLoggedIn, setPendingNotifications, pollForNotifications, checkForNotifications])

  useEffect(() => {
    if (userLoggedIn && permissionGranted && firebaseMessagingRef.current) {
      getToken(
        firebaseMessagingRef.current,
        { vapidKey: firebaseConfig.vapidKey }
      ).then((token) => {
        tokenRef.current = token
        notificationService.sendPushToken(token)
      })
    }
  }, [userLoggedIn, permissionGranted])

  useEffect(() => {
    if (!permissionGranted) {
      return;
    }

    if (!firebaseAppRef.current) {
      firebaseAppRef.current = getApp()
    }

    if (!firebaseMessagingRef.current) {
      firebaseMessagingRef.current = getMessaging(firebaseAppRef.current)
    }

    if (!tokenRef.current) {
      getToken(
        firebaseMessagingRef.current,
        { vapidKey: firebaseConfig.vapidKey }
      ).then((token) => {
        tokenRef.current = token
        notificationService.sendPushToken(token)
      })
    }

    onMessage(firebaseMessagingRef.current, (payload) => {
      payload.data && setIncomingNotification(parseINotification(payload.data))
    })

    navigator.serviceWorker.onmessage = (event): void => {
      setIncomingNotification(parseINotification(event.data.data))
    }
  }, [permissionGranted])

  useEffect(() => {
    if (!incomingNotification || lastNotification.current?.id === incomingNotification.id) {
      return;
    }

    lastNotification.current = incomingNotification

    if (!pendingNotifications.find((notif) => notif.id === incomingNotification.id)) {
      setPendingNotifications([incomingNotification, ...pendingNotifications])
    }
    
    toast.custom((t) => <NotificationPopOver notification={incomingNotification} thisToast={t}/>)
    setIncomingNotification(undefined)
  }, [incomingNotification, pendingNotifications, setPendingNotifications])

  return (null)
}

export default NotificationListener