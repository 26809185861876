import { fetch } from './base.service'
import { IListRetrieveService, IServicePaginatedListResult, IServiceResult } from './types'
import * as serverConfig from './server-config'

async function list(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false
): Promise<IServicePaginatedListResult> {
  /*
  Either pass full URL (e.g. next from DRF) or queryString when applying filter.
  */
  const url = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.people.fetchPeople}${urlOrQueryString}`
  return await fetch(url, cancelToken)
}

async function detail(id: string, cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.people.fetchPeople}${id}/`
  return await fetch(url, cancelToken)
}

export const peopleService: IListRetrieveService = {
  list,
  detail
}