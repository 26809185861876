import { fetch, put, patch } from './base.service'
import * as serverConfig from './server-config'
import { INotification, INotificationPreferences } from '../recoil/types'
import { IListService, IServicePaginatedListResult, IServiceResult } from './types'
import { withServiceParamsInUrl } from './utils'

function formatNotifications(notifications: INotification[]): INotification[] {
  return notifications.map((notif) => formatNotification(notif))
}

function formatNotification(notification: INotification): INotification {
  if (!notification.icon) {
    switch (notification.type) {
      case 'job':
        notification.icon = 'BriefcaseIcon'
        break;
      case 'connection-approved':
        notification.icon = 'UserAddIcon'
        break;
      case 'connection-request':
        notification.icon = 'ShareIcon'
        break;
      case 'review':
        notification.icon = 'ClipboardCopyIcon'
        break;
      case 'recruitment-step':
        notification.icon = 'CollectionIcon'
        break;
      case 'unread-chat':
        notification.icon = 'ChatIcon'
        break;
      default:
        notification.icon = 'InboxIcon'
        break;
    }
  }

  if (notification.actions && typeof notification.actions === 'string') {
    notification.actions = JSON.parse(notification.actions)
  }

  return notification
}

async function sendPushToken(token: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.notification.pushToken}`
  return await put(url, { token })
}

async function getAll(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = `${serverConfig.serverURL}${serverConfig.routes.notification.fetch}${urlOrQueryString}`
  const path = isUrl ? urlOrQueryString : url
  const result = await fetch(path, cancelToken) as IServicePaginatedListResult
  if (result.success) {
    result.data.results = formatNotifications(result.data.results)
  }
  return result
}

async function getPending(cancelToken: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.notification.fetch}?pending_only=1`
  const result = await fetch(url, cancelToken)
  if (result.success) {
    result.data.results = formatNotifications(result.data.results)
  }
  return result
}

async function resolvePending(id: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.notification.resolvePending}`.replace('<notif_id>', id.toString())
  return await patch(url)
}

async function markAsSeen(id: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.notification.markAsSeen}`.replace('<notif_id>', id.toString())
  return await patch(url)
}

async function markAllAsSeen() {
  const url = `${serverConfig.serverURL}${serverConfig.routes.notification.markAllAsSeen}`
  return await put(url)
}

async function updatePreferences(preferences: INotificationPreferences) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.notification.preferences}`
  return await put(url, preferences)
}

async function updatePreferencesWithToken(preferences: INotificationPreferences, token: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.notification.preferencesWithToken}`
  return await patch(url, preferences, { 'Authorization': `Bearer ${token}` })
}

async function getPreferences(cancelToken: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.notification.preferences}`
  return await fetch(url, cancelToken)
}

async function getPreferencesWithToken(token: string, cancelToken: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.notification.preferencesWithToken}`
  return await fetch(url, cancelToken, { 'Authorization': `Bearer ${token}` })
}

export const notificationService: IListService & {
  sendPushToken: (token: string) => Promise<IServiceResult>,
  getPending: (cancelToken: any) => Promise<IServiceResult>,
  resolvePending: (id: number) => Promise<IServiceResult>,
  markAsSeen: (id: number) => Promise<IServiceResult>,
  markAllAsSeen: () => Promise<IServiceResult>,
  updatePreferences: (preferences: INotificationPreferences) => Promise<IServiceResult>,
  updatePreferencesWithToken: (preferences: INotificationPreferences, token: string) => Promise<IServiceResult>,
  getPreferences: (cancelToken: any) => Promise<IServiceResult>,
  getPreferencesWithToken: (token: string, cancelToken: any) => Promise<IServiceResult>
} = {
  list: getAll,
  sendPushToken,
  getPending,
  resolvePending,
  markAsSeen,
  markAllAsSeen,
  updatePreferences,
  updatePreferencesWithToken,
  getPreferences,
  getPreferencesWithToken
}
