import { IJobCandidate, IRecruitingStep } from '@/recoil/types'
import type { FC } from 'react'
import { memo } from 'react'
import { useDrag } from 'react-dnd'

export interface RecruitingStepCandidateProps {
  candidate: IJobCandidate,
  disabled: boolean,
  loading: boolean,
  step: IRecruitingStep,
  groupingName: string,
  anyOverride: boolean
}

export const RecruitingStepCandidate: FC<RecruitingStepCandidateProps> = memo(function RecruitingStepCandidate({ candidate, disabled, loading, step, groupingName, anyOverride }) {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: anyOverride ? `any_${groupingName}` : `order_${step.order}_${groupingName}`,
      item: { candidateId: candidate.id },
      canDrag: () => !disabled && !loading && step.can_manage_candidates,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [candidate, disabled, loading, step]
  )

  return (
    <div
      ref={drag}
      className={`${step.can_manage_candidates ? 'bg-gray-100' : 'bg-gray-100/75'} border border-gray-300 rounded-md p-2 text-center text-sm truncate ${disabled || !step.can_manage_candidates ? 'cursor-not-allowed' : (loading ? 'cursor-wait' : 'cursor-grab')}`}
      style={{ opacity }}
    >
      {candidate.candidate ? `${candidate.candidate.first_name} ${candidate.candidate.last_name}` : candidate.candidate_name}
    </div>
  )
})
