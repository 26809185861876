import { useState } from 'react'
import RedButton from '../../../buttons/RedButton'


import Confirm from '../../../modals/Confirm'

const ProfileLinkInner = ({ object, deletable, setIsConfirmOpen }: any) => {
  const formatUrl = (url: string) => {
    return url.replace(/(^\w+:|^)\/\//, '')
  }

  return (
    <span className="flex items-center">
      { object.link_type.name === 'Other' ?
        <a href={object.url} className="underline" target="_blank" rel="noreferrer">{formatUrl(object.url)}</a>
        :
        <a href={object.url} className="underline" target="_blank" rel="noreferrer">{object.link_type.name}</a>
      }
      { deletable &&
        <RedButton
          title="Delete"
          className="ml-8 w-20"
          onClick={() => setIsConfirmOpen(true)}
        />
      }
    </span>
  )
}


const ProfileLink = ({ object, deletable = false, onConfirm }: any) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  
  return (
    <>
      <Confirm
        title={`Are you sure to delete ${object.url}?`}
        onConfirm={() => onConfirm(object.id)}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      />
      <ProfileLinkInner object={object} deletable={deletable} setIsConfirmOpen={setIsConfirmOpen} />
    </>
  )
}

export default ProfileLink
