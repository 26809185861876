import { useRecoilState } from 'recoil'
import { toast } from 'react-toastify'

import NotificationListItem from '../components/notifications/NotificationListItem'
import InfiniteList from '../components/layout/list/InfiniteList'
import { notificationService } from '../services'
import { Layout } from '../components/layout'
import HeadingsH2 from '../components/headings/HeadingsH2'
import { INotification } from '../recoil/types'
import { pendingNotifications as pendingNotificationsAtom } from '../recoil/atoms/notification'
import { useEffect, useRef } from 'react'

const Notifications = () => {
  const [pendingNotifications, setPendingNotifications] = useRecoilState<INotification[]>(pendingNotificationsAtom)
  const listRef = useRef<any>()

  useEffect(() => listRef?.current?.fetchItems(), [pendingNotifications])

  const refetchPending = () => {
    notificationService.getPending(null).then((result) => {
      setPendingNotifications(result.data.results as INotification[])
    })
  }
  
  return (
    <Layout title="Notifications" auth={true}>
      <HeadingsH2 heading="All Notifications" />
      <InfiniteList
        service={notificationService}
        onServiceError={toast.error}
        itemComponent={NotificationListItem}
        noResultsText="You have no notifications"
        filter="pending_only=0"
        itemsProps={{ onAction: refetchPending }}
        ref={listRef}
      />
    </Layout>
  )
}

export default Notifications
