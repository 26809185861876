import { ChangeEventHandler, FocusEventHandler } from "react"
import { classNames } from "@/lib/helpers"

interface ISelectFieldOption {
  id: string,
  title: string
}

interface ISelectFieldStyles {
  wrapper?: string,
  input?: string
}

interface ISelectFieldProps {
  fieldName: string,
  label?: string,
  options: Array<ISelectFieldOption>
  includeNotSpecified?: boolean,
  handleChange: ChangeEventHandler<HTMLSelectElement>,
  handleBlur: FocusEventHandler<HTMLSelectElement>,
  values: Record<string, any>,
  errors: Record<string, any>,
  touched: Record<string, any>,
  disabled?: boolean,
  required?: boolean,
  styles?: ISelectFieldStyles
}

const SelectField = (
  { fieldName,
    label,
    options,
    includeNotSpecified = true,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    disabled,
    required = false,
    styles
  }: ISelectFieldProps) => {
  return (
    <div className={styles?.wrapper ?? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'}>
      {label && <label htmlFor={fieldName} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 sm:pb-1">
        {label}
        {required && <span className="required">*</span>}
      </label>}
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <select
          id={fieldName}
          name={fieldName}
          autoComplete={fieldName}
          required={required}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          value={options?.length && values[fieldName] ? options.find((o: any) => o.id === values[fieldName] || o.title === values[fieldName])?.id : ''}
          className={classNames(
            errors[fieldName] && touched[fieldName] && errors[fieldName] ?
              'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
              'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
            , styles?.input ?? 'max-w-lg block w-full shadow-sm sm:max-w-xs text-sm rounded-md'
          )}
        >
          {includeNotSpecified ? <option value="">Not Specified</option> : ''}
          {options?.map((option: ISelectFieldOption) => {
            return (
              <option key={option.id} value={option.id}>{option.title}</option>
            )
          })}
        </select>
      </div>
      <small className='text-red-500'>
        {errors[fieldName] && touched[fieldName] && errors[fieldName]}
      </small>
    </div>
  )
}

export default SelectField
