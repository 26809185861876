import { Formik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import BlueButton from '../../../buttons/BlueButton'

import { useRecoilState } from 'recoil'
import { userState } from '../../../../recoil/atoms/auth'


import { accountService } from '../../../../services'
import { TextInputField, TextAreaField, DateField } from '../../../forms/fields'
import { useState } from 'react'

// any validation
const ProfileInfoSchema = Yup.object().shape({
})

const ProfileForm = ({ toggleIsEditing, person }: any) => {
  const [userData, setUserData] = useRecoilState<any>(userState)
  const [businessPhoneSame, setBusinessPhoneSame] = useState(false)

  const handleSubmit = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    accountService.updateProfile(person.id, values).then((result) => {
      const newUserData = Object.assign({}, userData, { person: result.data })
      setUserData(newUserData)
      setSubmitting(false)
      toggleIsEditing()
      toast.success('Profile Updated!')
    }).catch((error) => {
      toast.error(`${error}`)
      setSubmitting(false)
    })
  }

  const syncBusinessPhone = (values: any) => {
    values['business_phone'] = values['phone']
  }

  const handlePhoneChangeWrapper = (handleChange: any, event: any, values: any) => {
    handleChange(event)
    if (businessPhoneSame) {
      values['business_phone'] = event.target.value
    }
  }

  return (
    <Formik
      initialValues={{
        // personal
        first_name: person.first_name || '',
        last_name: person.last_name || '',
        bio: person.bio || '',
        phone: person.phone || '',
        business_phone: person.business_phone || '',
        date_of_birth: person.date_of_birth || undefined,
      }}
      validationSchema={ProfileInfoSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit} data-tour="profileform">
          <div className="space-y-8 sm:space-y-5">
            <TextInputField
              fieldName="first_name"
              label="First Name"
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <TextInputField
              fieldName="last_name"
              label="Last Name"
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <TextAreaField
              fieldName="bio"
              label="Bio"
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <TextInputField
              fieldName="phone"
              label="Phone"
              {...{ values, errors, touched, handleBlur, handleChange: (handler) => handlePhoneChangeWrapper(handleChange, handler, values) }}
            />
            <TextInputField
              fieldName="business_phone"
              label="Business Phone"
              disabled={businessPhoneSame}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <div className="flex items-center">
              <input
                id="business_phone_same"
                name="business_phone_same"
                type="checkbox"
                checked={businessPhoneSame}
                onChange={e => {
                  setBusinessPhoneSame(e.target.checked)
                  e.target.checked && syncBusinessPhone(values)
                }}
                className='focus:ring-teal-600 border-gray-300 h-4 w-4 text-teal-600 rounded -mt-5'
              />
              <label htmlFor="business_phone_same" className='ml-2 block text-sm font-medium text-gray-700 -mt-5'>
                Same as phone?
              </label>
            </div>
            <DateField
              fieldName="date_of_birth"
              label="Date Of Birth"
              initialDate={person.date_of_birth}
              {...{ values, errors, touched, handleBlur, handleChange, setFieldValue }}
            />
            <BlueButton
              disabled={!isValid || (!dirty && !businessPhoneSame) || isSubmitting}
              type="submit"
              title="Save"
              className="w-full sm:w-20 profile-info-save-edits"
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

export default ProfileForm
