import { formatTimestamp } from '@/lib/helpers'
import { notesService } from "@/services"

import ReportTab from '@/components/reports/ReportTab'


const NotesReport = () => {
  const headers = [
    'ID',
    'Type',
    'Section',
    'Object',
    'Text',
    'Datetime',
    'Created',
    'Created By',
  ]

  const reportMap = (object: any) => {
    let relatedTitle = null
    let section = ''
    if (object.job) {
      relatedTitle = object.job?.published_version.title
      section = 'Job'
    }
    else if (object.company) {
      relatedTitle = object.company.name
      section = 'Company'
    }
    else if (object.person) {
      relatedTitle = `${object.person?.first_name} ${object.person?.last_name}`
      section = 'Person'
    }
    else if (object.job_candidate) {
      relatedTitle = `${object.job_candidate.candidate ? object.job_candidate.candidate.first_name + ' ' + object.job_candidate.candidate.last_name : object.job_candidate.candidate_name} for ${object.job_candidate?.job?.published_version?.title ?? object.job_candidate?.job?.latest_version?.title} (${object.job_candidate?.status})`
      section = 'Job Candidate'
    }

    return [
      object.id,
      object.type,
      section,
      relatedTitle,
      object.text,
      object.datetime && formatTimestamp(new Date(object.datetime)),
      formatTimestamp(new Date(object.created)),
      object.created_by?.person ? `${object.created_by.person.first_name} ${object.created_by.person.last_name}` : undefined
    ]
  }

  return (
    <ReportTab
      service={notesService}
      headers={headers}
      reportMap={reportMap}
      exportFilename={'NotesReport'}
    />
  )
}

export default NotesReport
