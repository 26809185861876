import PaginatedList from '@/components/layout/list/PaginatedList'
import { recruiterAbstractProcessesService, recruiterProcessesService } from '@/services'
import { toast } from 'react-toastify'
import PaginatedCRUDList from '../layout/list/PaginatedCRUDList'
import Modal from '../modals/Modal'
import CreateRecruitingProcessForm from './forms/CreateRecruitingProcessForm'
import RecruitProcessItem from './RecruitProcessItem'

const RecruitProcessTable = ({
  type
}: { type: 'linked' | 'abstract' }) => {
  const formSuccessAction = (values: any, result: any) => {
    toast.success(`Created pipeline: ${values.name}.`)
  }

  return (
    type === 'linked' ? (
      <PaginatedList
        service={recruiterProcessesService}
        pageSize={10}
        onServiceError={toast.error}
        itemComponent={RecruitProcessItem}
        noResultsText="No job pipelines"
      />
    ) : (
      <PaginatedCRUDList
        service={recruiterAbstractProcessesService}
        onServiceError={toast.error}
        object={{}}
        itemComponent={RecruitProcessItem}
        formComponent={CreateRecruitingProcessForm}
        ModalComponent={Modal}
        addButtonTitle="Create Pipeline"
        formSuccessAction={formSuccessAction}
        noResultsText="No abstract pipelines"
      />
    )
  )
}

export default RecruitProcessTable
