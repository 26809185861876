import { fetch, patch, post, put, _delete } from './base.service'
import { IListRetrieveService, IServicePaginatedListResult, IServiceResult, _notImplemented } from './types'
import * as serverConfig from './server-config'
import { ICompany, IRecruitingProcess, IUser } from '@/recoil/types'
import { JobCandidateEnum } from './jobcandidates.service'
import { withServiceParamsInUrl } from './utils'

export interface IJob {
  id: number,
  company?: ICompany,
  company_id?: number,
  status: JobStatus,
  open: boolean,
  positions_available: number,
  filled: number,
  vetting_type: JobVettingType,
  vetting_users: number[],
  published_version?: IJobVersion,
  latest_version: IJobVersion,
  last_reviewed_version_id?: number,
  has_been_published: boolean,
  recruiting_process: IRecruitingProcess,
  recruiter_process?: IRecruitingProcess,
  versions: IJobVersion[]
}

export interface IJobVersion {
  id: number,
  job: IJob,
  status: JobVersionStatus,
  version_number: number,
  closed_reason?: 'rejected',
  has_reviewed: boolean,
  can_review: boolean,
  published_start?: string,
  published_end?: string,
  approved: boolean,
  optional_reviewers_required: number,
  // details
  title: string,
  description: string,
  requirements: string,
  benefits: string,
  qualification: string,
  state: any,
  city: any,
  industry: any,
  role: any,
  work_type: any,
  work_env: any,
  experience: any,
  availability: any,
  travel_availability: any,
  compensation_lower: any,
  compensation_upper: any,
  compensation_type: any
}

export interface IVersionFieldsChanged {
  any_changed: boolean,
  fields: { [name: string]: {
    old_value: any,
    new_value: any
  } }
}

export interface IJobComment {
  id: number,
  created: string,
  job: number,
  job_version: number,
  field_name?: CommentFieldName,
  comment: string,
  user_id: number,
  user_name: string,
  version_number: number
}

export interface IJobReviewer {
  id: number,
  requested_at: string,
  request_until: string,
  status: JobReviewerStatus,
  optional: boolean,
  job_version: IJobVersion,
  user: IUser
}

export type JobStatus = 'Draft' | 'Published' | 'Review' | 'Unfulfilled' | 'Unfulfilled_write_off' | 'Fulfilled'
export type JobVersionStatus = 'Draft' | 'Published' | 'Review' | 'Closed'
export type JobReviewerStatus = 'Pending' | 'Approved' | 'Rejected' | 'Expired'
export type JobVettingType = 'Open' | 'Recruiter' | 'Recruiter_any' | 'Internal' | 'Internal_any'

export const JobStatusReadableMap = {
  'Draft': 'Draft',
  'Published': 'Published',
  'Review': 'Review',
  'Unfulfilled': 'Closed - Unfulfilled',
  'Unfulfilled_write_off': 'Closed - Unfulfilled (Write-Off)',
  'Fulfilled': 'Closed - Fulfilled',
  'Partially_fulfilled': 'Closed - Partially Fulfilled',
  'Closed': 'Closed'
}

export const JobVettingTypeReadableMap = {
  'Open': 'Open',
  'Recruiter': 'Recruiter',
  'Recruiter_any': 'Recruiter (Any)',
  'Internal': 'Internal',
  'Internal_any': 'Internal (Any)'
}

export type CommentFieldName = (
  'title' |
  'description' |
  'requirements' |
  'qualification' |
  'benefits' |
  'state' |
  'City' |
  'industry' |
  'role' |
  'work_type' |
  'work_env' |
  'experience' |
  'availability'
)

export const CommentFieldNameMap = {
  'title': 'Title',
  'description': 'Description',
  'requirements': 'Responsibilities',
  'qualification': 'Qualification',
  'benefits': 'Benefits',
  'state': 'State',
  'City': 'City',
  'industry': 'Industry',
  'role': 'Role',
  'work_type': 'Work Type',
  'work_env': 'Work Env',
  'experience': 'Experience',
  'availability': 'Availability'
}

async function list(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false
): Promise<IServicePaginatedListResult> {
  // Either pass full URL (e.g. next from DRF) or queryString when applying filter.
  const url = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.jobs.fetchJobs}${urlOrQueryString}`
  return await fetch(url, cancelToken)
}

async function detail(id: number, cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.fetchJob}`.replace('<id>', id.toString())
  return await fetch(url, cancelToken)
}

async function versionDetail(id: number, cancelToken: any): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.fetchJobVersion}`.replace('<id>', id.toString())
  return await fetch(url, cancelToken)
}

async function share(jobId: number, email: string) {
  const values = {
    to_email: email,
    job_id: jobId
  }
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.shareJob}`
  return await post(url, values)
}

async function getVersions(jobId: number, cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.fetchVersions}`.replace('<id>', jobId.toString())
  return await fetch(url, cancelToken)
}

async function fetchVersionByNumber(jobId: number, versionNum: number, cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.fetchVersionByNumber}`.replace('<id>', jobId.toString()).replace('<number>', versionNum.toString())
  return await fetch(url, cancelToken)
}

async function getComments(jobId: number, cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.comments}`.replace('<id>', jobId.toString())
  return await fetch(url, cancelToken)
}

async function addComment(jobId: number, jobVersionId: number, comment: string, field?: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.comments}`.replace('<id>', jobId.toString())
  return await post(url, {
    job_id: jobId,
    job_version_id: jobVersionId,
    comment,
    field: field
  })
}

async function closeJob(jobId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.closeJob}`.replace('<id>', jobId.toString())
  return await patch(url)
}

async function cloneJob(jobId: number, jobVersionId: number, recruitingProcessId: number, autoPublish = false) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.cloneJob}`.replace('<id>', jobId.toString())
  return await post(url, {
    job_version_id: jobVersionId,
    recruiting_process_id: recruitingProcessId,
    auto_publish: autoPublish
  })
}

async function approveVersion(jobVersionId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.approveVersion}`.replace('<id>', jobVersionId.toString())
  return await patch(url)
}

async function rejectVersion(jobVersionId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.rejectVersion}`.replace('<id>', jobVersionId.toString())
  return await patch(url)
}

async function submitVersionForReview(
  jobVersionId: number,
  required_reviewers: number[],
  optional_reviewers: number[],
  optional_reviewers_required: number,
  notify_users: number[]
) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.submitVersionForReview}`.replace('<id>', jobVersionId.toString())
  return await patch(url, {
    job_version_id: jobVersionId,
    required_reviewers,
    optional_reviewers,
    optional_reviewers_required,
    notify_users
  })
}

async function updateDetails(jobVersionId: number, details: { [name: string]: any }) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.updateVersionDetails}`.replace('<id>', jobVersionId.toString())
  return await put(url, details)
}

async function endVersionReview(jobVersionId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.endVersionReview}`.replace('<id>', jobVersionId.toString())
  return await patch(url)
}

async function closeVersion(jobVersionId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.closeVersion}`.replace('<id>', jobVersionId.toString())
  return await patch(url)
}

async function publishVersion(jobVersionId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.publishVersion}`.replace('<id>', jobVersionId.toString())
  return await patch(url)
}

async function unpublishVersion(jobVersionId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.unpublishVersion}`.replace('<id>', jobVersionId.toString())
  return await patch(url)
}

async function versionDifferences(jobId: number, prevVersionId: number, currVersionId: number, cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.versionDifferences}`.replace('<id>', jobId.toString()).replace('<prev_id>', prevVersionId.toString()).replace('<curr_id>', currVersionId.toString())
  return await fetch(url, cancelToken)
}

async function createNewDraft(jobId: number, fromVersionId?: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.createNewDraft}`.replace('<id>', jobId.toString())
  return await post(url, fromVersionId ? {
    from_version_id: fromVersionId
  } : {})
}

async function getVersionReviewers(jobVersionId: number, cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.fetchVersionReviewers}`.replace('<id>', jobVersionId.toString())
  return await fetch(url, cancelToken)
}

async function updatePositionsAvailable(jobId: number, positionsAvailable: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.updatePositionsAvailable}`.replace('<id>', jobId.toString())
  return await patch(url, {
    positions_available: positionsAvailable
  })
}

async function deleteJob(jobId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.deleteJob}`.replace('<id>', jobId.toString())
  return await _delete(url)
}

async function getCandidates(jobId: number, cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.fetchCandidates}`.replace('<id>', jobId.toString())
  return await fetch(url, cancelToken)
}

async function getMyRecruits(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any>
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.fetchMyRecruits}${urlOrQueryString}`.replace('<id>', serviceParams['job'])
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function recruitCandidate(jobId: number, personId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.recruitCandidate}`.replace('<id>', jobId.toString())
  return await post(url, {
    candidate: personId,
    source: JobCandidateEnum.SOURCE.SOURCED
  })
}

async function apply(jobId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.apply}`.replace('<id>', jobId.toString())
  return await post(url)
}

async function updateRecruitingProcess(jobId: number, processId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.updateRecruitingProcess}`.replace('<id>', jobId.toString())
  return await put(url, {
    recruiting_process_id: processId
  })
}

async function updateVettingType(jobId: number, type: JobVettingType, users?: number[]) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.updateVettingType}`.replace('<id>', jobId.toString())
  return await put(url, {
    vetting_type: type,
    ...(users && { vetting_users: users })
  })
}

async function searchCandidates(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any>
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = `${serverConfig.serverURL}${serverConfig.routes.jobs.searchCandidates}${urlOrQueryString}`.replace('<id>', serviceParams['job'])
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

export const jobsService: {
  list: (urlOrQueryString: string, cancelToken: any, isUrl: boolean) => Promise<IServicePaginatedListResult>,
  detail: (id: number, cancelToken: any) => Promise<IServiceResult>,
  versionDetail: (id: number, cancelToken: any) => Promise<IServiceResult>,
  share: (jobId: number, email: string) => Promise<IServiceResult>,
  getVersions: (jobId: number, cancelToken: any) => Promise<IServiceResult>,
  getComments: (jobId: number, cancelToken: any) => Promise<IServiceResult>,
  addComment: (jobId: number, jobVersionId: number, comment: string, field?: string) => Promise<IServiceResult>,
  closeJob: (jobId: number) => Promise<IServiceResult>,
  cloneJob: (jobId: number, jobVersionId: number, recruitingProcessId: number, autoPublish: boolean) => Promise<IServiceResult>,
  approveVersion: (jobVersionId: number) => Promise<IServiceResult>,
  rejectVersion: (jobVersionId: number) => Promise<IServiceResult>,
  submitVersionForReview: (
    jobVersionId: number,
    required_reviewers: number[],
    optional_reviewers: number[],
    optional_reviewers_required: number,
    notify_users: number[]
  ) => Promise<IServiceResult>,
  updateDetails: (jobVersionId: number, details: { [name: string]: any }) => Promise<IServiceResult>,
  endVersionReview: (jobVersionId: number) => Promise<IServiceResult>,
  closeVersion: (jobVersionId: number) => Promise<IServiceResult>,
  publishVersion: (jobVersionId: number) => Promise<IServiceResult>,
  unpublishVersion: (jobVersionId: number) => Promise<IServiceResult>,
  versionDifferences: (jobId: number, prevVersionId: number, currVersionId: number, cancelToken: any) => Promise<IServiceResult>,
  fetchVersionByNumber: (jobId: number, versionNum: number, cancelToken: any) => Promise<IServiceResult>,
  createNewDraft: (jobId: number, fromVersionId?: number) => Promise<IServiceResult>,
  getVersionReviewers: (jobVersionId: number, cancelToken: any) => Promise<IServiceResult>,
  updatePositionsAvailable: (jobId: number, positionsAvailable: number) => Promise<IServiceResult>,
  deleteJob: (jobId: number) => Promise<IServiceResult>,
  getCandidates: (jobId: number, cancelToken: any) => Promise<IServiceResult>,
  getMyRecruits: (urlOrQueryString: string, cancelToken: any, isUrl: boolean, serviceParams: Record<string, any>) => Promise<IServiceResult>,
  recruitCandidate: (jobId: number, personId: number) => Promise<IServiceResult>,
  apply: (jobId: number) => Promise<IServiceResult>,
  updateRecruitingProcess: (jobId: number, processId: number) => Promise<IServiceResult>,
  updateVettingType: (jobId: number, type: JobVettingType, users?: number[]) => Promise<IServiceResult>,
  searchCandidates: (urlOrQueryString: string, cancelToken: any, isUrl: boolean, serviceParams: Record<string, any>) => Promise<IServiceResult>
} = {
  list,
  detail,
  versionDetail,
  share,
  getVersions,
  getComments,
  addComment,
  closeJob,
  cloneJob,
  approveVersion,
  rejectVersion,
  submitVersionForReview,
  updateDetails,
  endVersionReview,
  closeVersion,
  publishVersion,
  unpublishVersion,
  versionDifferences,
  fetchVersionByNumber,
  createNewDraft,
  getVersionReviewers,
  updatePositionsAvailable,
  deleteJob,
  getCandidates,
  getMyRecruits,
  recruitCandidate,
  apply,
  updateRecruitingProcess,
  updateVettingType,
  searchCandidates
}

export const jobsRecruitsService: IListRetrieveService = {
  list: getMyRecruits,
  detail: _notImplemented
}
