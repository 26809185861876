import { useCallback, useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import BaseButton from "../buttons/BaseButton"
import BlueButton from "../buttons/BlueButton"
import TealButton from "../buttons/TealButton"

function CookieConsent() {
  const [expanded, setExpanded] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['cookie_consent', 'visited'])

  useEffect(() => {
    if (cookies.cookie_consent === undefined) {
      setExpanded(true)
    }
  }, [cookies])

  const accept = useCallback(() => {
    setExpanded(false)
    setCookie('cookie_consent', true)
    setCookie('visited', true)
  }, [setCookie])

  const reject = useCallback(() => {
    setExpanded(false)
    setCookie('cookie_consent', false)
    removeCookie('visited', { path: '/' })
  }, [setCookie, removeCookie])

  return (
    <div className={`fixed left-2 sm:left-2 bottom-8 sm:bottom-8 bg-teal-50 shadow-md border border-gray-300 rounded-lg z-50 ${expanded ? 'p-4 sm:w-80 right-2 sm:right-auto' : 'p-1 sm:p-2'}`}>
      {expanded ? (<>
        <div className="text-gray-500 text-sm">
          <span>We use our own cookies to personalize content and your experience on the website. </span>
          <a href="/" className="underline">Read more about cookies</a>
        </div>
        <div className="flex gap-2 mt-2">
          <TealButton
            title="Accept"
            onClick={accept}
          />
          <BlueButton
            title="Reject"
            onClick={reject}
          />
        </div>
      </>)
      : (
        <div className="group relative">
          <div className="text-xs font-medium pointer-events-none absolute -top-14 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-black px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:opacity-75">
            Manage<br/>Cookies
          </div>
          <BaseButton
            title=""
            children={
              // using dangerouslySetInnerHTML to preserve HTML comment
              <div className="w-4" dangerouslySetInnerHTML={{__html: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> <path d="M247.2 17c-22.1-3.1-44.6 .9-64.4 11.4l-74 39.5C89.1 78.4 73.2 94.9 63.4 115L26.7 190.6c-9.8 20.1-13 42.9-9.1 64.9l14.5 82.8c3.9 22.1 14.6 42.3 30.7 57.9l60.3 58.4c16.1 15.6 36.6 25.6 58.7 28.7l83 11.7c22.1 3.1 44.6-.9 64.4-11.4l74-39.5c19.7-10.5 35.6-27 45.4-47.2l36.7-75.5c9.8-20.1 13-42.9 9.1-64.9l-14.6-82.8c-3.9-22.1-14.6-42.3-30.7-57.9L388.9 57.5c-16.1-15.6-36.6-25.6-58.7-28.7L247.2 17zM208 208c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32zm0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32zm160 0c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" /></svg>'}} />
            }
            onClick={() => setExpanded(true)}
            className="block px-2 fill-gray-700 border-0"
          />
        </div>
      )}
    </div>
  )
}

export default CookieConsent
