import { IBaseButtonProps } from './BaseButton'

import { ButtonWithOptionStyles } from './styles'
import { BaseButtonWithOptions } from './BaseButtonWithOptions'

type ButtonOption = {
  id: string,
  title: string
}

const BlueButtonWithOptions = (
  props: IBaseButtonProps & {
    options: ButtonOption[],
    onOptionClick?: (option: ButtonOption, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  }
) => {
  return (
    <BaseButtonWithOptions
      {...props}
      style={ButtonWithOptionStyles.Blue}
    />
  )
}

export default BlueButtonWithOptions
