import JobPreferencesForm from "./forms/JobPreferencesForm"
import { valueOrNA } from '../../utils'
import { useState } from "react"
import TealButton from "../../buttons/TealButton"

const JobPreferences = ({ person }: any) => {
  const [isEditing, setIsEditing] = useState(false)
  const toggleIsEditing = () => {
    setIsEditing(!isEditing)
  }
  return (
    <>
      <div className="py-4">
        <TealButton
          title={isEditing ? 'Cancel' : 'Edit'}
          className="profile-job-prefs-toggle-editing"
          onClick={() => { toggleIsEditing() }}
        />
      </div>
      {isEditing ?
        <JobPreferencesForm toggleIsEditing={toggleIsEditing} person={person} />
        :

        <div className="grid grid-cols-1 gap-x-4 gap-y-8 p-3 sm:grid-cols-2 mt-4">
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Industry</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.industry?.title)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Role</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.role?.title)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Expertise</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.experience?.title)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Location</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.state?.title)} - {valueOrNA(person.city?.title)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Available</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.availability?.title)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Work Schedule</dt>
            <dd className="mt-1 text-sm text-gray-900">{person?.work_type?.length ? person.work_type.map((t: any) => t.title).join(', ') : 'N/A'}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Work Environment</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.work_env?.title)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Compensation Expectation</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.compensation_expectation)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Compensation Type</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.compensation_type?.title)}</dd>
          </div>
          <div className="sm:col-span1">
            <dt className="text-sm font-medium text-teal-600">Availability to Travel</dt>
            <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.travel_availability?.title)}</dd>
          </div>
        </div>}
    </>
  )
}

export default JobPreferences
