import { useRecoilValue } from "recoil"
import { userState } from '@/recoil/atoms/auth'

import { AccountRoles } from '@/services'

import { Layout } from '@/components/layout'

import HeadingsH2 from '@/components/headings/HeadingsH2'
import HeadingsH3 from '@/components/headings/HeadingsH3'
import JobRecommendations from '@/components/home/JobRecommendations'
import ConnectionsRecommendations from '@/components/home/ConnectionsRecommendation'
import MyConnections from '@/components/home/MyConnections'
import Calendar from '@/components/home/Calendar'

import NotesAllTab from '@/components/notes/NotesAllTab'
import CompanyFiles from '@/components/files/CompanyFiles'
import { onboardingJustCompletedAtom } from "@/recoil/atoms/app"
import { useEffect, useState } from "react"
import Modal from "@/components/modals/Modal"
import BlueButton from "@/components/buttons/BlueButton"
import { Link, useHistory } from "react-router-dom"
import WhiteButton from "@/components/buttons/WhiteButton"

const Home = () => {
  const userData = useRecoilValue<any>(userState)
  const onboardingJustCompleted = useRecoilValue(onboardingJustCompletedAtom)
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (onboardingJustCompleted) {
      setWelcomeModalOpen(true)
    }
  }, [onboardingJustCompleted])

  return (
    <Layout title="Home" auth={true}>
      <Modal
        title="Now you've got Gumption!"
        open={welcomeModalOpen}
        setOpen={setWelcomeModalOpen}
        hasForm={true}
      >
        <div className="flex gap-2 mt-8">
          <BlueButton
            title="Take Profile Tour"
            onClick={() => history.push('/profile')}
            className="w-full"
          />
          <WhiteButton
            title="Explore Gumption"
            onClick={() => setWelcomeModalOpen(false)}
            className="w-full"
          />
        </div>
      </Modal>
      <div className="px-4 py-4 sm:px-0 md:py-0 space-y-4">
        {userData?.person && <HeadingsH2 heading={`Hello, ${userData.person.first_name}`} />}

        <HeadingsH3 heading="Calendar" />
        <Calendar />

        {
          userData
          && AccountRoles.canManageRecruits(userData.role)
          && userData.company
          &&
          <>
            <HeadingsH3 heading="Company Files" />
            <CompanyFiles
              company={userData.company}
              pageSize={5}
            />
          </>
        }

        {
          userData
          && AccountRoles.canManageRecruits(userData.role)
          && userData.company
          &&
          <>
            <HeadingsH3 heading="Starts &amp; Ends" />
            <NotesAllTab
              serviceParams={{
                company: userData.company.id,
                type: ['Start', 'End']
              }}
              pageSize={5}
            />
          </>
        }

        <Link to="/opportunities">
          <HeadingsH3 heading="Opportunities"/>
        </Link>
        <JobRecommendations />

        <Link to="/recommended-connections">
          <HeadingsH3 heading="Recommended connections" />
        </Link>
        <ConnectionsRecommendations />

        <Link to="/people">
          <HeadingsH3 heading="My connections" />
        </Link>
        <MyConnections />
      </div>
    </Layout>
  );
};

export default Home;
