import { toast } from 'react-toastify'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { LockClosedIcon, ChevronLeftIcon } from '@heroicons/react/outline'
import { BriefcaseIcon, OfficeBuildingIcon } from '@heroicons/react/solid'
import { useRecoilState } from 'recoil'

import { userState } from '../recoil/atoms/auth'
import { accountService, peopleService, NoteTables, serviceType, trackingService } from '../services'

import { Layout } from '../components/layout'
import { LoadingInside } from '@/components/layout/top_level/Loading'
import { Person as PersonListItem } from '../components/people/Person'
import ProfileLink from '../components/profile/person/links/ProfileLink'
import NoResults from '../components/layout/list/NoResults'
import { Badge, ProfileImage } from '../components/utils'
import ChatTab from '../components/chat/ChatTab'
import { valueOrNA } from '../components/utils'
import WhiteButton from '../components/buttons/WhiteButton'
import HeadingsH2 from '../components/headings/HeadingsH2'
import NotesTab from '../components/notes/NotesTab'
import ProfileFiles from '../components/files/ProfileFiles'
import BaseTabs from '../components/layout/navigation/BaseTabs'
import RedButton from '@/components/buttons/RedButton'
import PreviousJob from '@/components/profile/person/previous_jobs/PreviousJob'

const JobPreferences = ({ person }: any) => {
  return (
    <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pb-4">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Industry</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.industry?.title)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Role</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.role?.title)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Expertise</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.experience?.title)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Location</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.state?.title)} - {valueOrNA(person.city?.title)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Available</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.availability?.title)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Work Schedule</dt>
          <dd className="mt-1 text-sm text-gray-900">{person.work_type?.length ? person.work_type.map((t: any) => t.title).join(', ') : 'N/A'}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Work Environment</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.work_env?.title)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Compensation Expectation</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.compensation_expectation)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Compensation Type</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.compensation_type?.title)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Availability to Travel</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.travel_availability?.title)}</dd>
        </div>
      </dl>
    </div>
  )
}

const JobHistory = ({ previous_jobs }: any) => {
  return (
    <>
      {
        previous_jobs.length > 0 ?
          <div className="overflow-hidden sm:rounded-md mt-6">
            <ul className="divide-y divide-gray-200">
              {previous_jobs.map((job: any) => (
                <PreviousJob object={job} link={false} key={job.id} canDelete={false} />
              ))}
            </ul>
          </div>
          :
          <NoResults text={'No previous Jobs.'} />
      }
    </>
  )
}

const ProfileInfo = ({ person }: any) => {
  return (
    <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Bio</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(person.bio)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Phone</dt>
          <dd className="mt-1 text-sm text-gray-900"><LockClosedIcon className="w-5" /></dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Email</dt>
          <dd className="mt-1 text-sm text-gray-900"><LockClosedIcon className="w-5" /></dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Date of Birth</dt>
          <dd className="mt-1 text-sm text-gray-900"><LockClosedIcon className="w-5" /></dd>
        </div>
        {person.links.length > 0 &&
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Links</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <ul className="list-disc px-5">
            {person.links.map((link: any) => (
              <li key={link.id} className="my-2">
                <ProfileLink object={link} />
              </li>
            ))}
            </ul>
          </dd>
        </div>
        }
      </dl>
    </div>
  )
}

const Connections = ({ people }: any) => {
  return (
    <>
      {
        people.length > 0 ?
          <div className="overflow-hidden sm:rounded-md mt-6">
            <ul className="divide-y divide-gray-200">
              {people.map((person: any) => (
                <PersonListItem key={person.id} object={person} link={true} />
              ))}
            </ul>
          </div>
          :
          <NoResults text={'No Connections.'} />
      }
    </>
  )
}

const PersonTabs = ({ person }: any) => {
  const tabs = [
    { slug: 'preferences', name: 'Job Preferences' },
    { slug: 'history', name: 'Job History' },
    { slug: 'profile', name: 'Profile' },
    { slug: 'files', name: 'Files' },
    { slug: 'connections', name: 'Connections' },
    { slug: 'chat', name: 'Chat' },
    { slug: 'notes', name: 'Notes' }
  ]
  const [activeTab, setActiveTab] = useState('preferences')

  return (
    <>
      <BaseTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        extraButtonClass={'mt-4'}
      />
      {activeTab === 'preferences' && <JobPreferences person={person} />}
      {activeTab === 'history' && <JobHistory previous_jobs={person?.previous_jobs} />}
      {activeTab === 'profile' && <ProfileInfo person={person} />}
      {activeTab === 'files' && <ProfileFiles person={person} />}
      {activeTab === 'connections' && <Connections people={person?.connections} />}
      {activeTab === 'chat' && <ChatTab person={person} />}
      {activeTab === 'notes' && <NotesTab noteTable={NoteTables.Person} object={person} />}
    </>
  )
}


export const PersonPage = ({ id, disableBack = false }: { id: any, disableBack?: boolean }) => {
  const [person, setPerson] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useRecoilState<any>(userState)

  const location = useLocation<any>()
  const history = useHistory()
  const hasBack = (location?.state?.from !== undefined)

  const goBack = () => {
    // we migh later use location?.state?.from -
    // now we just check if we can go back, if 1st page load, there is no back
    history.goBack()
  }

  useEffect(() => {
    setLoading(true)
    const source = axios.CancelToken.source()
    peopleService.detail(id, source.token).then((result: serviceType.IServiceResult) => {
      if (result.success) {
        setPerson(result.data)
        setLoading(false)
        trackingService.trackPersonView(parseInt(id)).catch((error) => console.error(error))
      }
    }).catch((error: any) => {
      console.error(error)
      setLoading(false)
    })
    return () => {
      source.cancel()
    }
  }, [id, setPerson])

  const connectTo = (personId: number) => {
    accountService.createConnection(personId).then((result) => {
      if (result.success) {
        const newUserData = Object.assign({}, userData,
          {
            person: Object.assign({}, userData.person, {
              connections: userData.person.connections.concat({ ...result.data, status: 'pending' })
            })
          }
        )
        setUserData(newUserData)
        toast.success('Connection invite sent')
      }
    }).catch((error) => {
      if (error.code === 409) {
        accountService.profile().then((res) => setUserData(res.data))
        toast.info('You are already connected with this person')
      } else {
        toast.error('Failed to send connection invite')
      }
    })
  }

  const disconnectFrom = (personId: number) => {
    accountService.deleteConnection(personId).then((result) => {
      if (result.success) {
        accountService.profile().then((res) => setUserData(res.data))
        toast.warning('You are now disconnected')
      }
    }).catch((error) => {
      if (error.code === 409) {
        accountService.profile().then((res) => setUserData(res.data))
        toast.info('You are already disconnected from this person')
      } else {
        toast.error('Failed to disconnect from this person')
      }
    })
  }

  const connectionStatus = useCallback(
    (connection: any) => {
      if (connection.id === userData?.person?.id) {
        return 'self'
      }
      
      const foundConnection = userData?.person?.connections.find((userConn: any) => {
        return userConn.id === connection.id
      })
  
      if (!foundConnection) {
        return 'disconnected'
      } else {
        switch (foundConnection.status) {
          case 'accepted': return 'connected'
          case 'declined': return 'disconnected'
          case 'pending': return 'pending'
          default: return 'disconnected'    
        }
      }
    },
    [userData]
  )

  return (
    <>
      {!loading ?
        <>
        <div className="bg-white px-4 py-2">
          {(hasBack && !disableBack) &&
            <nav className="hidden lg:block flex items-start mb-2 py-4 lg:py-2" aria-label="Breadcrumb">
              <button
                onClick={goBack}
                className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
              >
                <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-600" aria-hidden="true" />
                <span>Back</span>
              </button>
            </nav>
          }

          <div className="flex items-end justify-between">
            <div className="flex flex-col">
              <ProfileImage image={person.profile_image} size={32} iconClass={'flex-shrink-0'} />
              <HeadingsH2 heading={`${person.first_name} ${person.last_name}`} />
              <div className="space-x-1">
                {person.company && Badge(<span className="flex gap-1 items-center justify-center">
                  <OfficeBuildingIcon className="h-3 w-3 text-sky-800" aria-hidden="true" />
                  <Link to={{ pathname: `/companies/${person.company.id}`, state: { from: `/people/${person.id}` } }}>
                    {person.company.name}
                  </Link>
                </span>)}
                {person.previous_jobs.length > 0 && Badge(<span className="flex gap-1 items-center justify-center">
                  <BriefcaseIcon className="h-3 w-3 text-sky-800" aria-hidden="true" />
                  {person.previous_jobs[0].title}
                </span>, 'bg-teal-100 text-teal-800')}
              </div>
            </div>
            <div className="lg:pr-4 ">
              {connectionStatus(person) === 'disconnected' && <WhiteButton
                title="Connect"
                onClick={() => connectTo(person.id)}
              />}
              {connectionStatus(person) === 'connected' && <RedButton
                title="Disconnect"
                onClick={() => disconnectFrom(person.id)}
              />}
              {connectionStatus(person) === 'pending' && <div className="italic text-sm text-gray-600">Pending...</div>}
            </div>
          </div>
          <PersonTabs person={person} />
        </div>
        </>
        :
        <LoadingInside />
      }
    </>
  )
}

const Person = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <Layout title="Home" auth={true} back={true}>
      <PersonPage id={id} />
    </Layout>
  )
}

export default Person
