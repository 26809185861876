import axios from 'axios'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import { userState } from '@/recoil/atoms/auth'
import {
  accountService
} from '@/services'


import { Layout } from '@/components/layout'
import { LoadingInside } from '@/components/layout/top_level/Loading'
import HeadingsH2 from '@/components/headings/HeadingsH2'
import WhiteButtonLink from '@/components/links/WhiteButtonLink'

import CompanyTabs from '@/components/profile/company/Tabs'

const CompanyProfile = () => {
  const [userData, setUserData] = useRecoilState<any>(userState)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const source = axios.CancelToken.source()
    // fetch latest userData
    accountService.profile(source.token).then((result) => {
      setUserData(result.data)
      setLoading(false)
    }).catch((error) => {
      console.error(error)
      setLoading(false)
    })
    return () => {
      source.cancel()
    }
  }, [setUserData])

  return (
    <Layout title="Company Profile" auth={true} back={true}>
      {!loading ?
        <>
          {userData.company === null ?
            <div>User has no Company</div>
            :
            <div className="relative p-4 md:p-0">
              <div className="flex justify-between items-start mb-4">
                <div className="flex flex-col">
                  <HeadingsH2 heading="Company Profile" />
                  <p className="text-lg">{userData.company.name}</p>
                </div>
                <WhiteButtonLink
                  title="Public Profile"
                  to={{pathname: `/companies/${userData.company.id}`, state: {from: '/company-profile/'}}}
                />
              </div>
              <CompanyTabs user={userData} />
            </div>
          }
        </>
        :
        <LoadingInside />
      }
    </Layout >
  )
}

export default CompanyProfile
