export default function Loading() {
  return (
    <div className="flex items-center justify-center h-screen w-screen fixed z-50">
      <svg className="bg-teal-600 animate-spin h-5 w-5 lg:h-10 lg:w-10 mr-3" viewBox="0 0 24 24"></svg>
    </div>
  )
}

const LoadingInside = function () {
  return (
    <div className="flex items-center justify-center z-50 p-5">
      <svg className="bg-teal-600 animate-spin h-5 w-5 lg:h-10 lg:w-10 mr-3" viewBox="0 0 24 24"></svg>
    </div>
  )
}

export {LoadingInside}
