import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '@/components/buttons/BlueButton'
import { SelectField, TextInputField } from '@/components/forms/fields'

const InviteMemberFormSchema = Yup.object().shape({
  user_email: Yup.string().required('An email address is required').email('Please enter a valid email address'),
  role: Yup.string().required('A role is required')
})

const InvitationRoles = [
  { id: 'RE', title: 'Recruiter' },
  { id: 'HM', title: 'Hiring Manager' }
]

export default function InviteMemberForm({ handleSubmit }: { handleSubmit: any }) {
  return (
    <Formik
      initialValues={{
        user_email: '',
        role: 'RE'
      }}
      validationSchema={InviteMemberFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <TextInputField
            fieldName="user_email"
            label="Email Address"
            required={true}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <SelectField
            fieldName="role"
            label="Role"
            options={InvitationRoles}
            includeNotSpecified={false}
            required={true}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <BlueButton
              type="submit"
              title={isSubmitting ? "Inviting..." : "Invite"}
              className="w-full transition duration-150 ease-in-out"
              disabled={!isValid || !dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
