import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '@/components/buttons/BlueButton'
import { useCallback, useEffect, useState } from 'react'
import { companyJobsService } from '@/services'
import { toast } from 'react-toastify'
import MultiselectField, { IMultiselectFieldOption } from '@/components/forms/fields/MultiselectField'
import { IUser } from '@/recoil/types'
import RangeSliderField from '@/components/forms/fields/RangeSliderField'
import { LoadingInside } from '@/components/layout'

const SubmitForReviewSchema = Yup.object().shape({
  required_reviewers: Yup.array().min(1, 'At least one mandatory reviewer is required')
})

export default function SubmitForReviewForm({ companyId, handleSubmit }: { companyId: number, handleSubmit: any }) {
  const [potentialReviewers, setPotentialReviewers] = useState<IMultiselectFieldOption[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    companyJobsService.getPotentialReviewers(companyId, null).then((res) => {
      setPotentialReviewers(res.data.map((entry: IUser) => {
        return {
          id: entry.id,
          title: `${entry.person?.first_name} ${entry.person?.last_name}`
        }
      }))
      setLoading(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to fetch list of potential reviewers')
    })
  }, [companyId])

  const uniquePotentialReviewers = useCallback((list1: string[], list2: string[]) => {
    return potentialReviewers.filter((reviewer) => !list1.includes(reviewer.id) && !list2.includes(reviewer.id))
  }, [potentialReviewers])

  return (
    !loading
    ? <Formik
      initialValues={{
        required_reviewers: [],
        optional_reviewers: [],
        optional_reviewers_required: 0,
        notify_users: []
      }}
      validationSchema={SubmitForReviewSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <MultiselectField
            fieldName="required_reviewers"
            label="Mandatory Reviewers"
            options={uniquePotentialReviewers(values['optional_reviewers'], values['notify_users'])}
            required={true}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <MultiselectField
            fieldName="optional_reviewers"
            label="Optional Reviewers"
            options={uniquePotentialReviewers(values['required_reviewers'], values['notify_users'])}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <MultiselectField
            fieldName="notify_users"
            label="Additionally Notify"
            options={uniquePotentialReviewers(values['required_reviewers'], values['optional_reviewers'])}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <RangeSliderField
            fieldName="optional_reviewers_required"
            label="Number of Optional Reviewers Required"
            min={0}
            max={Math.max(values['optional_reviewers'].length - 1, 0)}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <BlueButton
              type="submit"
              title={isSubmitting ? "Submitting..." : "Submit For Review"}
              className="w-full transition duration-150 ease-in-out"
              disabled={!isValid || !dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
    : <LoadingInside />
  )
}
