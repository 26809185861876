import { IJobCandidate, IRecruitingStep } from '@/recoil/types'
import { FC, useMemo } from 'react'
import { memo } from 'react'
import { useDrop } from 'react-dnd'
import { RecruitingStepCandidate } from './RecruitingStepCandidate'

export interface RecruitingProcessStepProps {
  step: IRecruitingStep,
  candidates: IJobCandidate[],
  onDrop: (item: any) => void,
  onStepClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  maxCandidates: number,
  isSelected: boolean,
  anySelected: boolean,
  disabled: boolean,
  loading: boolean,
  canManageCandidates: boolean,
  groupingName: string,
  hasHiringPerm: boolean,
  anyOverride: boolean,
  acceptTypes?: string[],
  getGroupingNameForCandidate?: (candidate: IJobCandidate) => string
}

export const RecruitingProcessStep: FC<RecruitingProcessStepProps> = memo(function RecruitingProcessStep({
  step,
  candidates,
  onDrop,
  onStepClick,
  maxCandidates,
  isSelected,
  anySelected,
  disabled,
  loading,
  canManageCandidates,
  groupingName,
  hasHiringPerm,
  anyOverride,
  acceptTypes,
  getGroupingNameForCandidate
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: acceptTypes ?? [
      `any_${groupingName}`,
      ...(step.type !== 'Hired' || (step.type === 'Hired' && hasHiringPerm) ? [
        `order_${step.order - 1}_${groupingName}`,
        `order_${step.order}_${groupingName}`,
        `order_${step.order + 1}_${groupingName}`
      ] : [])
    ],
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const isActive = useMemo(() => isOver && canDrop, [isOver, canDrop])

  return (
    <div className={`w-80 inline-block align-top shadow rounded-md bg-white p-2 ${(anySelected && !isSelected) && 'opacity-40'} ${!canManageCandidates && 'bg-gray-200'}`}>
      <div onClick={onStepClick} className="font-bold text-teal-600 truncate border-b-4 pb-1 cursor-pointer" style={{ borderBottomColor: `#${step.color}` }}>{step.name}</div>
      <div ref={drop} className={`space-y-2 p-2 mt-2 min-h-13.5 border-dashed ${(canDrop && !isOver) && `p-1.5 border-2 ${canManageCandidates ? 'border-gray-200' : 'border-gray-300'}`} ${isActive && 'p-1.5 border-2 border-gray-400'}`}>
        {candidates.slice(0, maxCandidates).map((candidate) => (
          <RecruitingStepCandidate
            candidate={candidate}
            key={candidate.id}
            disabled={disabled}
            loading={loading}
            step={step}
            groupingName={groupingName === 'reject' && !!getGroupingNameForCandidate ? getGroupingNameForCandidate(candidate) : groupingName}
            anyOverride={anyOverride}
          />
        ))}
        {candidates.length > maxCandidates && <div className="bg-gray-100 border border-gray-300 rounded-md p-2 text-center text-sm">
          {anySelected ? `${candidates.length} Candidate${candidates.length !== 1 ? 's' : ''}` : `+${candidates.length - maxCandidates} More`}
        </div>}
      </div>
    </div>
  )
})
