import { Link } from "react-router-dom"
import { classNames } from "../../lib/helpers"
import ButtonLinkStyles from "./styles"

export interface IBaseButtonLinkProps {
  to: any,
  title: string,
  className?: string,
}

const BaseButtonLink = ({
  to,
  title,
  className = ''
}: IBaseButtonLinkProps) => {
  return (
    <Link
      to={to}
      className={classNames(
        className,
        ButtonLinkStyles.Base
      )}
    >
      {title}
    </Link>
  )
}

export default BaseButtonLink