import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '@/components/buttons/BlueButton'
import { SelectField } from  '@/components/forms/fields'
import { useEffect, useState } from 'react'
import { recruitmentService } from '@/services'
import { IRecruitingProcess } from '@/recoil/types'
import { toast } from 'react-toastify'

const UpdateJobRecruitingProcessSchema = Yup.object().shape({
  recruiting_process: Yup.string().required('Required')
})

export default function UpdateJobRecruitingProcessForm({ handleSubmit, recruiting_process }: { handleSubmit: any, recruiting_process: IRecruitingProcess }) {
  const [recruitingProcesses, setRecruitingProcesses] = useState([])

  useEffect(() => {
    recruitmentService.getAbstractProcesses(null).then((res) => {
      setRecruitingProcesses(res.data.map((process: IRecruitingProcess) => ({ id: process.id, title: process.name })))
    }).catch(() => {
      toast.error("Failed to fetch recruiting pipeline options")
    })
  }, [])
  
  return (
    <Formik
      initialValues={{
        recruiting_process: ''
      }}
      validationSchema={UpdateJobRecruitingProcessSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="font-medium text-gray-700 mt-4">Current: "{recruiting_process.name}"</div>
          <SelectField
            fieldName="recruiting_process"
            label="Recruiting Pipeline"
            options={recruitingProcesses}
            required={true}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense items-center">
            <small className='text-red-500'>NOTE: The currently-associated recruiting pipeline will be deleted and a new one will be created from the above selection.</small>
            <BlueButton
              type="submit"
              title={isSubmitting ? "Updating..." : "Update"}
              className="w-full transition duration-150 ease-in-out"
              disabled={!isValid || !dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
