import { SelectField, TextInputField, CurrencyField } from "../forms/fields"
import { commonHelpers } from '../../services'
import MultiselectSearchField from "../forms/fields/MultiselectSearchField"
import SelectSearchField from "../forms/fields/SelectSearchField"

function WorkExperienceFields({ values, errors, touched, handleChange, handleBlur, common, textInputStyles, selectInputStyles, selectSearchInputStyles, setFieldValue }: any) {
  const commonFieldProps = { values, errors, touched, handleBlur, handleChange, setFieldValue }
  
  return (
      <div className="mt-6 sm:mt-5 space-y-1">
          <div className="sm:grid sm:grid-cols-2 sm:gap-3 items-start">
              <SelectSearchField
                  fieldName="work_industry"
                  label="Industry"
                  options={commonHelpers.industryDictionaryToArray(common?.industries) ?? []}
                  styles={selectSearchInputStyles}
                  {...commonFieldProps}
              />
              <SelectSearchField
                  fieldName="work_role"
                  label="Role"
                  disabled={!values.work_industry}
                  options={commonHelpers.rolesForIndustryByKey(common?.industries, values.work_industry) ?? []}
                  styles={selectSearchInputStyles}
                  {...commonFieldProps}
              />
          </div>
          <div className="sm:grid sm:grid-cols-2 sm:gap-3 items-start">
              <SelectField
                  fieldName="work_experience"
                  label="Experience Level"
                  options={common?.experience_levels}
                  styles={selectInputStyles}
                  {...commonFieldProps}
              />
              <SelectField
                  fieldName="work_availability"
                  label="Availability"
                  options={common?.availabilities}
                  styles={selectInputStyles}
                  {...commonFieldProps}
              />
          </div>
          <div className="sm:grid sm:grid-cols-2 sm:gap-3 items-start">
              <MultiselectSearchField
                  fieldName="work_type"
                  label="Work Type"
                  options={common?.work_types}
                  styles={selectSearchInputStyles}
                  {...commonFieldProps}
              />
              <SelectField
                  fieldName="work_environment"
                  label="Work Environment"
                  options={common?.work_environments}
                  styles={selectInputStyles}
                  {...commonFieldProps}
              />
          </div>
          <div className="sm:grid sm:grid-cols-2 sm:gap-3 items-start">
              <CurrencyField
                  fieldName="work_compensation_expectation"
                  label="Expected Compensation"
                  styles={textInputStyles}
                  currencyType={values['work_compensation_expectation_currency']}
                  {...commonFieldProps}
              />
              <div className="sm:pt-14 sm:pl-2 space-x-6">
                  {common?.compensation_types.map((type: any) => {
                      return (<label className="inline-flex items-center" key={type.id}>
                          <input
                              type="radio"
                              name="work_compensation_type"
                              value={type.id}
                              checked={values['work_compensation_type'].toString() === type.id.toString()}
                              onChange={handleChange}
                          />
                          <span className="pl-2">{type.title}</span>
                      </label>)
                  })}
              </div>
          </div>
          <div className="sm:grid sm:grid-cols-2 sm:gap-3 items-start">
              <SelectField
                  fieldName="work_compensation_expectation_currency"
                  label="Compensation Currency"
                  options={common?.currency_options}
                  styles={selectInputStyles}
                  {...commonFieldProps}
              />
              <SelectField
                  fieldName="work_travel_availability"
                  label="Availability to Travel"
                  options={common?.travel_availabilities}
                  styles={{
                      wrapper: 'sm:grid sm:grid-rows-2 sm:gap-2 sm:items-center self-start',
                      input: selectInputStyles.input
                  }}
                  {...commonFieldProps}
              />
          </div>
          <div className="sm:grid sm:grid-cols-2 sm:gap-3 items-start">
              <div>
                  <TextInputField
                      fieldName="work_reference_link"
                      label="Reference Link (LinkedIn, Web Site)"
                      styles={textInputStyles}
                      {...commonFieldProps}
                  />
                  <div className="mt-2">
                      <select
                          id="work_reference_link_type"
                          name="work_reference_link_type"
                          onChange={handleChange} onBlur={handleBlur} value={values['work_reference_link_type']}
                          className="block focus:ring-teal-600 focus:border-teal-600 w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      >
                          {common?.person_link_types?.map((type: any) => {
                              return (
                                  <option key={type.id} value={type.id}>{type.name}</option>
                              )
                          })}
                      </select>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default WorkExperienceFields
