import { toast } from "react-toastify"
import File from "./File"
import PaginatedCRUDList from "@/components/layout/list/PaginatedCRUDList"


const Files = ({ service, object, addButtonTitle, itemProps, formComponent, pageSize }: any) => {
  return (
    <PaginatedCRUDList
      service={service}
      onServiceError={toast.error}
      object={object}
      itemComponent={File}
      itemsProps={itemProps}
      formComponent={formComponent}
      addButtonTitle={addButtonTitle}
      getUpdateTitle={(object: any) => `Update: ${object?.title}`}
      hasUpdate={false}
      formAlterValues={null}
      onUpdateSuccess={() => toast.success('Update successful')}
      onUpdateError={() => toast.error('Error updating')}
      noResultsText={'No Files'}
      pageSize={pageSize}
      // colsInFilter={4}
    />
  )
}

export default Files
