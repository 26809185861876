import { Formik } from 'formik'

import { classNames } from '@/lib/helpers'
import { commonHelpers } from '@/services'
import { CityField, SelectField } from '@/components/forms/fields'
import { commonTables } from '@/recoil/atoms/common'
import { useRecoilValue } from 'recoil'
import WhiteButton from '@/components/buttons/WhiteButton'
import BlueButton from '@/components/buttons/BlueButton'
import { useState } from 'react'
import { ArrowDownIcon, ArrowUpIcon, SearchIcon } from '@heroicons/react/outline'


export interface IFilterProps {
  filter: any,
  setFilter: Function,
  FilterFieldsComponent?: any
  emptyFilter?: any
  colsInFilter?: number
  extraFields?: any
}

export interface IFilterFieldsProps {
  clearFilter: any
  common: any
  values: any
  errors: any
  touched: any
  handleBlur: any
  handleChange: any
  colsInFilter: number
  children?: any
  extraFields?: any
}

export const defaultFilter = {
  search: '',
  page: '1',
  industry: '',
  role: '',
  experience: '',
  availability: '',
  travel_availability: '',
  compensation_type: '',
  work_type: '',
  work_env: '',
  state: '',
  city: ''
}

export const recruitFilter = {
  ...defaultFilter,
  status: ''
}

export const DefaultFilterFields = ({
  clearFilter,
  common,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  colsInFilter,
  children
}: IFilterFieldsProps) => {

  const [isCollapsed, setIsCollapsed] = useState(true)
  const IconComponent = isCollapsed ? ArrowDownIcon : ArrowUpIcon

  const toggle = () => {
    setIsCollapsed(!isCollapsed)
  }
  const wrapperClasses = classNames(
    isCollapsed ? 'hidden' : 'block'
  )

  const styles = {
    wrapper: ''
  }

  return (
    <>
      <div className="bg-gray-200 rounded-md">
        <div className="flex">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              name="search"
              id="email"
              className="focus:ring-teal-600 focus:border-teal-600 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
              placeholder="Name, Job Title, Company Title"
              onChange={handleChange} onBlur={handleBlur} value={values['search']}
            />
          </div>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-sky-800 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-sky-800 focus:border-sky-800"
            onClick={toggle}
          >
            <IconComponent className="h-5 w-5 text-sky-800" aria-hidden="true" />
            <span>Filter</span>
          </button>
        </div>
        <div className={`p-2 md:p-4 ${wrapperClasses}`}>
          <div className={`grid grid-cols-2 lg:grid-cols-${colsInFilter} gap-2 md:gap-x-4`}>
            <SelectField
              fieldName="industry"
              label="Industry"
              options={commonHelpers.industryDictionaryToArray(common?.industries)}
              styles={styles}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="role"
              label="Role"
              disabled={!values.industry}
              options={commonHelpers.rolesForIndustryByKey(common?.industries, values.industry!)}
              styles={styles}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="experience"
              label="Experience"
              options={common?.experience_levels}
              styles={styles}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="availability"
              label="Available"
              options={common?.availabilities}
              styles={styles}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="travel_availability"
              label="Availability to Travel"
              options={common?.travel_availabilities}
              styles={styles}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="work_type"
              label="Work Type"
              options={common?.work_types}
              styles={styles}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="work_env"
              label="Work Env"
              options={common?.work_environments}
              styles={styles}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="compensation_type"
              label="Compensation Type"
              options={common?.compensation_types}
              styles={styles}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="state"
              label="State"
              options={common?.states}
              styles={styles}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <CityField
              styles={styles}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            {children}
          </div>
          <div className="flex mt-6 flex-row space-x-4 justify-start">
            <WhiteButton
              title="Clear"
              onClick={clearFilter}
              className="w-20"
            />
            <BlueButton
              title="Apply"
              type="submit"
              className="w-20"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export const RecruitFilterFields = ({
  clearFilter,
  common,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  colsInFilter,
  extraFields
}: IFilterFieldsProps) => {
  return (
    <DefaultFilterFields
      clearFilter={clearFilter}
      colsInFilter={colsInFilter}
      {...{ common, values, errors, touched, handleChange, handleBlur }}
    >
      {extraFields && extraFields.status &&
        <SelectField
          fieldName="status"
          label="Job Candidate Status"
          options={extraFields.status.options}
          styles={{ wrapper: '' }}
          {...{ values, errors, touched, handleBlur, handleChange }}
        />
      }
    </DefaultFilterFields>
  )
}


const SearchAndFilter = ({
  filter,
  setFilter,
  FilterFieldsComponent = DefaultFilterFields,
  emptyFilter = defaultFilter,
  colsInFilter = 2,
  extraFields
}: IFilterProps) => {

  const common = useRecoilValue<any>(commonTables)

  const _initialValues: any = Object.assign({}, filter, {
    industry: commonHelpers.industryKeyFromId(common, filter.industry!) || '',
    country: commonHelpers.defaultCountryID(common)
  })

  const applyFilter = (values: any) => {
    values = Object.assign({}, values, {
      'industry': commonHelpers.industryIdFromKey(common, values.industry),
      'role': commonHelpers.conditionallyOmitRole(common, values.industry, values.role)
    })
    setFilter(Object.assign({}, filter, values))
  }

  const clearFilter = () => {
    setFilter(Object.assign({}, emptyFilter, {
      search: ''
    }))
  }


  return (
    <div className="mb-5">
      <Formik
        initialValues={_initialValues}
        enableReinitialize={true}
        onSubmit={applyFilter}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form
            className={classNames(
              'space-y-6 mt-4'
            )}
            onSubmit={handleSubmit}
          >
            <FilterFieldsComponent
              clearFilter={clearFilter}
              colsInFilter={colsInFilter}
              extraFields={extraFields}
              {...{ common, values, errors, touched, handleChange, handleBlur, handleSubmit }}
            />
          </form>
        )}
      </Formik>
    </div>
  )
}

export default SearchAndFilter
