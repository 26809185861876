import {
  Link,
} from 'react-router-dom'
import BlueButtonLink from '../links/BlueButtonLink'
import WhiteButtonLink from '../links/WhiteButtonLink'

export default function CrossroadButtons() {
  return (
    <div className="mx-auto max-w-md text-center pb-6 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <div className="mt-8 flex flex-col items-center justify-items-center space-y-3">
        <WhiteButtonLink
          title="I am looking for a job!"
          to='/'
          className="w-72"
        />
        <BlueButtonLink
          title="I am ready to recruit!"
          to='/register'
          className="w-72"
        />
        <p className="mt-3 text-center text-xs sm:text-sm text-gray-600 max-w">
          Already have an account?{' '}
          <Link to='/login' className="font-medium text-sky-800 hover:text-sky-700">
            Sign In
          </Link>
        </p>
      </div>
    </div>
  )
}
