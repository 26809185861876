interface ILocalstorageToken {
  access: string
  refresh: string
  expiry: number
}

async function createLocalstorageItem(key: string, value: any) {
  localStorage.setItem(key, value)
  return true
}

function getLocalstorageItem(key: string):string | null {
  return localStorage.getItem(key)
}

function removeLocalstorageItem(key: string) {
  localStorage.removeItem(key)
}

// token localstorage 
async function setLocalStorageToken(token: ILocalstorageToken) {
  return createLocalstorageItem('token', JSON.stringify(token))
}
function getLocalstorageToken():ILocalstorageToken|null {
  const token = getLocalstorageItem('token')
  if (!token) return null
  return JSON.parse(token)
}

export {
  setLocalStorageToken,
  getLocalstorageToken,
  removeLocalstorageItem
}