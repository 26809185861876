import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { LoadingInside } from '../layout'
import WhiteButton from '../buttons/WhiteButton'

interface ISlideoverProps {
  title: string
  open: boolean,
  setOpen: (value: boolean) => void,
  infoText?: string,
  children?: any,
  hasForm?: any,
  loading?: boolean
}

export default function SliveOver({
  title,
  open = false,
  setOpen,
  infoText = '',
  children = null,
  hasForm = true,
  loading = false
}: ISlideoverProps) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden z-10" open={open} onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 bg-teal-50 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-teal-600">{title}</Dialog.Title>
                          {infoText &&
                            <p className="text-sm text-teal-50">
                              {infoText}
                            </p>}
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="rounded-md text-teal-600"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="py-6 px-6 space-y-6 sm:space-y-0">
                      {
                        loading ? <LoadingInside />
                          :
                          children && children
                      }

                    </div>
                  </div>
                </div>
                {!hasForm &&
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <WhiteButton
                      type="button"
                      title="Close"
                      className="w-full"
                      onClick={() => setOpen(false)}
                    />
                  </div>}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
