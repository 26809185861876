import { commonHelpers } from '../../services'
import { CityField, SelectField } from '../forms/fields'

import DefaultFilter, { IFilterProps } from '../layout/list/GlobalFilter'

const CompanyFilterFields = ({ common, values, errors, touched, handleBlur, handleChange }: any) => {
  const styles = {
    wrapper: ''
  }
  return (
    <div className="grid grid-cols-2 gap-x-4 gapy-y-3">
      <SelectField
        fieldName="industry"
        label="Industry"
        options={commonHelpers.industryDictionaryToArray(common?.industries)}
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
      <SelectField
        fieldName="role"
        label="Role"
        disabled={!values.industry}
        options={commonHelpers.rolesForIndustryByKey(common?.industries, values.industry!)}
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
      <SelectField
        fieldName="state"
        label="State"
        options={common?.states}
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
      <CityField
        styles={styles}
        {...{ values, errors, touched, handleBlur, handleChange }}
      />
    </div>
  )
}


const CompanyGlobalFilter = ({
  doNotTrackFields = ['search',
    'page',
    'experience',
    'availability',
    'travel_availability',
    'compensation_type',
    'work_type',
    'work_env']
}: IFilterProps) => {
  return <DefaultFilter doNotTrackFields={doNotTrackFields} FilterFieldsComponent={CompanyFilterFields} />
}

export default CompanyGlobalFilter