import { Layout } from '@/components/layout'
import HeadingsH2 from '@/components/headings/HeadingsH2'

import ConnectionsRecommendations from '@/components/home/ConnectionsRecommendation';

const RecommendedConnections = () => {
  return (
    <Layout title="Recommended Connections" auth={true} back={true}>
      <HeadingsH2 heading="Recommended Connections" />
      <div className="mt-4">
        
        <ConnectionsRecommendations />
      </div>
    </Layout>
  )
}

export default RecommendedConnections
