import { IJobCandidateDocument } from '@/services/jobcandidates.service'
import { UserIcon, XCircleIcon } from '@heroicons/react/outline'
import { DocumentIcon } from '@heroicons/react/solid'
import { FC, useState } from 'react'
import { memo } from 'react'
import Confirm from '../modals/Confirm'

export interface RecruitingCandidateDocumentProps {
  document: IJobCandidateDocument,
  deleteDocument: () => void
}

export const RecruitingCandidateDocument: FC<RecruitingCandidateDocumentProps> = memo(function RecruitingProcessStepDetail({
  document,
  deleteDocument
}) {
  const [confirmDeleteDocument, setConfirmDeleteDocument] = useState(false)

  return (<>
    <Confirm
        title={`Are you sure to delete the document "${document.title}"?`}
        onConfirm={() => deleteDocument()}
        isOpen={confirmDeleteDocument}
        setIsOpen={setConfirmDeleteDocument}
      />
    <div className="border border-gray-300 rounded-md shadow-sm flex items-center">
      <a href={document.file} className="text-teal-600 text-sm font-bold flex items-center p-3">
        <DocumentIcon className="h-5 w-5" aria-hidden="true" />
        {document.type === "user" && <UserIcon className="h-5 w-5" aria-hidden="true" />}
        <span className="ml-1">{document.title}</span>
      </a>
      {document.can_delete && document.type === "jobcandidate" && <XCircleIcon
        className="text-red-500 h-5 w-5 mr-3 cursor-pointer"
        onClick={() => setConfirmDeleteDocument(true)}
      />}
    </div>
  </>)
})
