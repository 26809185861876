import { IJobReviewer, IJobVersion, JobReviewerStatus, jobsService } from "@/services/jobs.service"
import { AnnotationIcon } from "@heroicons/react/outline"
import { CheckCircleIcon, DotsCircleHorizontalIcon, XCircleIcon, MinusCircleIcon } from "@heroicons/react/solid"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { LoadingInside } from "../layout"
import { FormatedDate } from "../utils"

const JobReviewers = ({ jobVersion }: { jobVersion: IJobVersion }) => {
  const [reviewers, setReviewers] = useState<IJobReviewer[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    jobsService.getVersionReviewers(jobVersion.id, null).then((res) => {
      setReviewers(res.data)
      setLoading(false)
    }).catch(() => {
      toast.error('Failed to get job version reviewers')
    })
  }, [jobVersion])

  const resolveStatus = (reviewer: IJobReviewer): JobReviewerStatus => {
    if (['Approved', 'Rejected', 'Expired'].includes(reviewer.status)) {
      return reviewer.status
    } else {
      return (new Date().valueOf() > new Date(reviewer.request_until).valueOf()) ? 'Expired' : 'Pending'
    }
  }

  const getIconForStatus = (status: JobReviewerStatus): JSX.Element => {
    switch (status) {
      case 'Approved': return <CheckCircleIcon className="h-5 w-5 text-teal-600" aria-hidden="true"/>
      case 'Rejected': return <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
      case 'Pending': return <DotsCircleHorizontalIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
      case 'Expired': return <MinusCircleIcon className="h-5 w-5" aria-hidden="true"/>
      default: return <div className="h-5 w-5"></div>
    }
  }
  
  return (
    !loading
      ? <>
        <p className="text-gray-500 font-medium">Approval Status: <span className="font-bold">{jobVersion.approved ? 'Approved' : 'Not Approved'}</span></p>
        {(jobVersion.optional_reviewers_required > 0) && <p className="mt-2 text-gray-500 font-medium text-sm">Minimum Optional Reviewers Required: <span className="font-bold">{jobVersion.optional_reviewers_required}</span></p>}
        <div className="mt-4 flex flex-col divide-y divide-gray-200">
          {reviewers.map((reviewer) => <div key={reviewer.id} className="py-4 flex justify-between items-center">
            <p className="font-medium text-gray-500 flex items-center gap-2">
              {getIconForStatus(resolveStatus(reviewer))}
              <span>{reviewer.user.person?.first_name} {reviewer.user.person?.last_name}</span>
              {reviewer.optional && <span className="group relative">
                <div className="text-sm font-medium pointer-events-none absolute -top-10 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-black px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:opacity-100">
                  Optional Reviewer
                </div>
                <AnnotationIcon className="h-4 w-4 text-gray-400" aria-hidden="true"/>
              </span>}
            </p>
            <p className="font-medium text-gray-500">
              <span className="font-medium">{resolveStatus(reviewer)}</span>
              {resolveStatus(reviewer) === 'Pending' && <span className="italic text-xs ml-1">until <FormatedDate date={new Date(reviewer.request_until)} relative={true} /></span>}
            </p>
          </div>)}
        </div>
      </>
      : <LoadingInside />
  )
}

export default JobReviewers
