import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '../../../buttons/BlueButton'
import WhiteButton from '../../../buttons/WhiteButton'

import PasswordField from '../../../forms/fields/PasswordField'

const SetPasswordSchema = Yup.object().shape({
  password1: Yup.string()
    .min(6, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  password2: Yup.string()
    .min(6, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
    .when("password1", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password1")],
        'Passwords do not match.'
      )
    })
})

interface ISetPasswordFormProps {
  handleSubmit: any,
  onCancel?: Function | null
}

export default function SetPasswordForm({
  handleSubmit,
  onCancel = null
}: ISetPasswordFormProps) {
  return (
    <Formik
      initialValues={{
        password1: "",
        password2: "",
      }}
      validationSchema={SetPasswordSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (

        <form onSubmit={handleSubmit} className="space-y-6">
          <PasswordField
            fieldName="password1"
            label="Password"
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <PasswordField
            fieldName="password2"
            label="Repeat Password"
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <WhiteButton
              type="button"
              title="Cancel"
              onClick={() => onCancel && onCancel()}
              className="mb-2 sm:mb-0 w-full inline-flex justify-center"
            />
            <BlueButton
              type="submit"
              title={isSubmitting ? "Submitting..." : "Submit"}
              className="w-full transition duration-150 ease-in-out"
              disabled={isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
