import { toast } from 'react-toastify'

import { companyService } from '../services'

import { Layout } from '../components/layout'
import Company from '../components/companies/Company'
import InfiniteList, { withGlobalFilter } from '../components/layout/list/InfiniteList'
import Filter from '../components/companies/GlobalFilter'
import { withCollapse } from '../components/utils'
import HeadingsH2 from '../components/headings/HeadingsH2'


const InfiniteListWithGlobalFilter = withGlobalFilter(InfiniteList)
const FilterWithCollapse = withCollapse(Filter)

const Companies = () => {
  const aside = <FilterWithCollapse />
  return (
    <Layout title="Companies" auth={true} aside={aside}>
      <HeadingsH2 heading="Companies list"/>
      <div className="mt-4">
        <InfiniteListWithGlobalFilter
          service={companyService}
          onServiceError={toast.error}
          itemComponent={Company}
          itemsProps={{ link: true }}
        />
      </div>
    </Layout>
  )
}

export default Companies
