import { IRecruitingStep } from '@/recoil/types'
import { FC } from 'react'

export interface RecruitingProcessStepHeaderProps {
  step: IRecruitingStep,
  onStepClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  isSelected: boolean,
  anySelected: boolean
}

export const RecruitingProcessStepHeader: FC<RecruitingProcessStepHeaderProps> = function RecruitingProcessStepHeader({
  step,
  onStepClick,
  isSelected,
  anySelected,
}) {
  return (
    <div className={`w-80 inline-block align-top shadow rounded-md bg-white p-2 ${(anySelected && !isSelected) && 'opacity-40'} ${!step.can_manage_candidates && 'bg-gray-200'}`}>
      <div onClick={onStepClick} className="font-bold text-teal-600 truncate border-b-4 pb-1 cursor-pointer" style={{ borderBottomColor: `#${step.color}` }}>{step.name}</div>
    </div>
  )
}
