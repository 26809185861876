import { Formik } from 'formik'
import * as Yup from 'yup'

import BlueButton from '@/components/buttons/BlueButton'
import { FileField, SelectField } from '@/components/forms/fields'
import { useMemo } from 'react'
import { IJobCandidateDocument } from '@/services/jobcandidates.service'

const FileSchema = Yup.object().shape({
  file: Yup.string().required('Required'),
  type: Yup.string().required('Please specify a document type')
})

const FileFields = ({ files, values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, setFieldError }: any) => {
  const fileTypes = useMemo(() => {
    const types = [];

    if (!files.find((x: IJobCandidateDocument) => x.title === 'Resume')) {
      types.push({ id: 'resume', title: 'Resume' })
    }

    if (!files.find((x: IJobCandidateDocument) => x.title === 'Cover Letter')) {
      types.push({ id: 'cover', title: 'Cover Letter' })
    }

    if (!files.find((x: IJobCandidateDocument) => x.title === 'Supporting Document')) {
      types.push({ id: 'supporting', title: 'Supporting Document' })
    }

    return types
  }, [files])

  const commonFieldProps = { values, errors, touched, handleBlur, handleChange, setFieldValue, setFieldError }
  return (
    <div className="space-y-8 sm:space-y-5">
      <FileField
        fieldName="file"
        label="Select File"
        required={false}
        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,application/pdf,image/*,.docx,.doc,.xls,.xlsx,.ppt,.pptx,.html,.txt"
        {...commonFieldProps}
      />
      <SelectField
        fieldName="type"
        label="Type"
        options={fileTypes}
        {...commonFieldProps}
      />
    </div>
  )
}

const JobCandidateFileForm = ({ handleSubmit, formClasses = '', files }: any) => {
  return (
    <Formik
      initialValues={{
        file: '',
        type: '',
      }}
      validationSchema={FileSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, setFieldTouched, setFieldError }) => (
        <form onSubmit={handleSubmit} className={formClasses}>
          <FileFields
            files={files}
            {...{ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setFieldError }}
          />
          <BlueButton
            type="submit"
            title={isSubmitting ? "Uploading..." : "Upload"}
            className="mt-5 w-full"
            disabled={!isValid || !dirty || isSubmitting}
          />
        </form>
      )}
    </Formik>
  )
}

export default JobCandidateFileForm
