import { toast } from "react-toastify"
import { IUser } from "@/recoil/types"
import { recruiterRecruitsService, recruiterRecruitedService } from "@/services"
import PaginatedList, { withRecruitSearchAndFilter } from "@/components/layout/list/PaginatedList"
import HeadingsH3 from '@/components/headings/HeadingsH3'
import Recruit from "./recruits/Recruit"

const PaginatedListWithSearchAndFilter = withRecruitSearchAndFilter(PaginatedList)

interface IRecruitHistoryProps {
  user: IUser
}

const RecruitHistory = ({ user }: IRecruitHistoryProps) => {

  return (
    <div className="py-2 sm:py-0 sm:px-2">
      <div className="sm:grid sm:grid-cols-2 sm:gap-4">
        <div className="sm:py-2 mb-4 sm:mb-0 space-y-6">
          <HeadingsH3 heading="My Recruits" />
          <PaginatedListWithSearchAndFilter
            service={recruiterRecruitsService}
            onServiceError={toast.error}
            itemComponent={Recruit}
            noResultsText="You have not recruited anyone yet."
          />
        </div>
        <div className="sm:py-2 mb-4 sm:mb-0 space-y-6">
          <HeadingsH3 heading="Recruited by others" />
          <PaginatedListWithSearchAndFilter
            service={recruiterRecruitedService}
            onServiceError={toast.error}
            itemComponent={Recruit}
            noResultsText="You have not been recruited to any positions yet."
          />
        </div>
      </div>
    </div>
  )
}

export default RecruitHistory
