import { useRecoilValue } from 'recoil'
import { userState } from '@/recoil/atoms/auth'
import NotesAllTab from "./NotesAllTab"


const NotesTableTab = ({ noteTable }: any) => {
  const userData = useRecoilValue<any>(userState)

  const serviceParams = {
    has: noteTable,
    created_by: userData.id
  }

  return (
    userData &&
    <NotesAllTab serviceParams={serviceParams} />
  )
}

export default NotesTableTab
