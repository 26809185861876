import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { ChangeEventHandler, FocusEventHandler, useState } from "react"

interface IDateTimeFieldProps {
  fieldName: string,
  label: string,
  handleChange: ChangeEventHandler,
  handleBlur: FocusEventHandler,
  values: Record<string, any>,
  errors: Record<string, any>,
  touched: Record<string, any>,
  setFieldValue: Function
  required?: boolean,
  initialDate: string
}


const DateTimeField = (
  { fieldName,
    label,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    required = false,
    initialDate = ''
  }: IDateTimeFieldProps) => {

  const [internalDate, setInternalDate] = useState(new Date(initialDate))

  const updateDate = (date: Date, _setFieldValue: Function) => {
    // update both internal date and form field
    setInternalDate(date)
    _setFieldValue(fieldName, date.toISOString())
  }

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label htmlFor={fieldName} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <DatePicker
          showTimeSelect
          selected={internalDate}
          onChange={(date: any) => updateDate(date, setFieldValue)}
          id={fieldName}
          name={fieldName}
          wrapperClassName="w-full sm:max-w-xs"
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </div>
      {errors[fieldName] && touched[fieldName] && errors[fieldName]}
    </div>
  )
}

export default DateTimeField
