import { Formik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import BlueButton from '../../../buttons/BlueButton'

import "react-datepicker/dist/react-datepicker.css"

import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../../recoil/atoms/auth'
import { commonTables } from '../../../../recoil/atoms/common'

import { accountService, commonHelpers } from '../../../../services'

import { CityField, NumberInputField, SelectField } from '../../../forms/fields'
import MultiselectSearchField from '@/components/forms/fields/MultiselectSearchField'


// any validation
const JobPreferencesFormSchema = Yup.object().shape({
  compensation_expectation: Yup.number()
})

const JobPreferencesForm = ({ toggleIsEditing, person }: any) => {

  const common = useRecoilValue<any>(commonTables)
  const [userData, setUserData] = useRecoilState<any>(userState)

  const handleSubmit = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    values = Object.assign({}, values, {
      'industry': commonHelpers.industryIdFromKey(common, values.industry),
      'role': commonHelpers.conditionallyOmitRole(common, values.industry, values.role)
    })
    accountService.updateProfile(person.id, values).then((result) => {
      const newUserData = Object.assign({}, userData, { person: result.data })
      setUserData(newUserData)
      setSubmitting(false)
      toggleIsEditing()
      toast.success('Profile Updated!')
    }).catch((error) => {
      toast.error(`${error}`)
      setSubmitting(false)
    })
  }

  return (
    <Formik
      initialValues={{
        country: commonHelpers.defaultCountryID(common),
        state: person.state?.id || '',
        city: person.city?.id || '',
        industry: person.industry?.slug || '',
        role: person.role?.id || '',
        experience: person.experience?.id || '',
        availability: person.availability?.id || '',
        work_type: person.work_type ? person.work_type.map((x: { id: number, title: string }) => x.id) : [],
        work_env: person.work_env?.id || '',
        compensation_expectation: person.compensation_expectation || '',
        compensation_type: person.compensation_type?.id || '',
        travel_availability: person.travel_availability?.id || '',
      }}
      validationSchema={JobPreferencesFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit} data-tour="jobprefsform">
          <div className="space-y-8 sm:space-y-5">
            <SelectField
              fieldName="industry"
              label="Industry"
              options={commonHelpers.industryDictionaryToArray(common?.industries)}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="role"
              label="Role"
              disabled={!values.industry}
              options={commonHelpers.rolesForIndustryByKey(common?.industries, values.industry)}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="experience"
              label="Experience Level"
              options={common?.experience_levels}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="state"
              label="State"
              options={common?.states}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <CityField
              {...{ values, errors, touched, handleChange, handleBlur, handleSubmit, common }}
            />
            <SelectField
              fieldName="availability"
              label="Availability"
              options={common?.availabilities}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <MultiselectSearchField
              fieldName="work_type"
              label="Work Type"
              options={common?.work_types}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="work_env"
              label="Work Environment"
              options={common?.work_environments}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <NumberInputField
              fieldName="compensation_expectation"
              label="Compensation Expectation"
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="compensation_type"
              label="Compensation Type"
              options={common?.compensation_types}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <SelectField
              fieldName="travel_availability"
              label="Availability to Travel"
              options={common?.travel_availabilities}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <BlueButton
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              title="Save"
              className="mt-5 w-full sm:w-20 profile-job-prefs-save-edits"
            />
          </div>
        </form>
      )
      }
    </Formik >
  )
}

export default JobPreferencesForm

export { CityField }
