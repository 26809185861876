import React, { useEffect } from 'react'
import { NoMenuLayout } from '../../components/layout'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

// State imports
import { useRecoilState } from 'recoil'
import { isLoggedIn } from '../../recoil/atoms/auth'

// Loading
import Loading from '../../components/layout/top_level/Loading'
import RegisterForm from '../../components/auth/RegisterForm'
import { accountService } from '../../services'

import Header from '../../components/auth/Header'
import LinkedInAuth from "@/components/auth/LinkedInAuth";

export default function Register({ location }: any) {
  const [loggedIn, setLoggedIn] = useRecoilState(isLoggedIn)
  const [loading, setLoading] = React.useState(true)
  const history = useHistory()


  const redirectAfterRegisterTo = location.state?.from || '/'

  useEffect(() => {
    if (loggedIn) {
      setLoading(false)
      history.push('/')
    } else {
      setLoading(false)
    }
  }, [loggedIn, history, setLoggedIn])

  const handleSubmit = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    accountService.register(values).then(res => {
      if (res.success) {
        accountService.login({ email: values.email, password: values.password1 }).then(res => {
          if (res.success) {
            toast.success(`Logged in...`)
            history.push('/onboarding', { from: redirectAfterRegisterTo })
          }
        }).catch(err => {
          toast.error(`${err}`)
          setSubmitting(false)
        })
      }
    }).catch(err => {
      if(err.message && err?.message?.split(',')?.length > 1) {
        toast.error(`${err.message.split(',')[0]}`)
      } else {
        toast.error(`${err}`)
      }
      setSubmitting(false)
    })
  }

  return (
    <>
      {loading ? <Loading /> : (<>
        <NoMenuLayout title="Register">
          <div className="bg-gray-50 flex flex-col justify-center">
            <Header title='Join our revolution' info='The easiest way to connect talented people and new opportunities.' />
            <div className="sm:mx-auto sm:w-full sm:max-w-md sm:pb-6">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <RegisterForm handleSubmit={handleSubmit} />
                <p className="mt-2 text-center text-sm leading-5 text-gray-600">
                  Already have an account?{' '}
                  <Link to="/login" className="font-medium text-teal-600 hover:text-teal-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    Sign In
                  </Link>
                </p>
                <LinkedInAuth />
              </div>
            </div>
          </div>
        </NoMenuLayout>
      </>)}
    </>
  )
}
