import { classNames } from '../../../lib/helpers'


interface IBaseTabsProps {
  tabs: any
  activeTab: string
  setActiveTab: Function
  appRole?: string | null
  extraButtonClass?: string | null
}

export default function BaseTabs({ tabs, activeTab, setActiveTab, appRole = null, extraButtonClass = null }: IBaseTabsProps) {
  return (
    <div className="overflow-x-auto -mr-3 scrollbar-hide border-b border-gray-200">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {tabs.filter((tab: any) => tab.accessCheck ? tab.accessCheck(appRole) : true).map((tab: any) => (
          <button
            onClick={() => { setActiveTab(tab.slug) }}
            key={tab.name}
            className={classNames(
              tab.slug === activeTab
                ? 'border-teal-600 text-teal-600 font-bold border-b-4'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              'whitespace-nowrap py-4 px-1 border-b-2 font-bold text-sm',
              extraButtonClass
            )}
            aria-current={tab.slug === activeTab ? 'page' : undefined}
            data-tabslug={tab.slug}
          >
            {tab.name}
          </button>
        ))}
      </nav>
    </div>
  )
}
