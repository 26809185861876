import { atom } from 'recoil'
import { IGlobalFilter } from '../types'

export const globalLoading = atom({
  key: 'globalLoading',
  default: false,
})

export const defaultGlobalFilter: IGlobalFilter = {
  search: '',
  page: '1',
  industry: '',
  role: '',
  experience: '',
  availability: '',
  travel_availability: '',
  compensation_type: '',
  work_type: '',
  work_env: '',
  state: '',
  city: ''
}

const globalFilterAtom = atom({
  key: 'globalFilter',
  default: defaultGlobalFilter as IGlobalFilter
})

const onboardingJustCompletedAtom = atom({
  key: 'onboardingJustCompleted',
  default: false
})

export { globalFilterAtom, onboardingJustCompletedAtom }
export type { IGlobalFilter }