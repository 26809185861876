import { ElementType } from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import { AccountRoles } from '@/services'

import {
  HomeIcon,
  UserGroupIcon,
  BriefcaseIcon,
  OfficeBuildingIcon,
  ChatAlt2Icon,
  PencilAltIcon,
  CollectionIcon,
  DocumentReportIcon,
  ClockIcon
} from '@heroicons/react/outline'

import {
  Home,
  Jobs,
  Job,
  People,
  Person,
  Companies,
  Company,
  Profile,
  CompanyProfile,
  Welcome,
  Onboarding,
  Recruit,
  Chat,
  Notes,
  ISECandidates,
  Reports,
  Candidate,
  Notifications,
  CompanyJob,
  CompanyJobVersionReview,
  CompanyJobVersionExplorer,
  CompanyJobCandidates,
  RecruitingProcess,
  History,
  Opportunities,
  RecommendedConnections
} from '@/pages'

import {
  Login,
  ForgottenPassword,
  Logout,
  ResetPassword,
  Register,
  AcceptCompanyInvite,
  CancelAccountDeletion
} from '@/pages/auth'

import RecruiterProcess from '@/pages/RecruiterProcess';
import ChangeNotificationPreferences from '@/pages/ChangeNotificationPreferences';


const baseName = process.env.REACT_APP_ROUTER_BASENAME || '/'

interface IRouteConfig {
  path: string
  name?: string
  component: ElementType
  mainMenu?: boolean
  userMenu?: boolean
  auth?: boolean
  icon?: ElementType
  chat?: boolean
  permission?: Function,
  extraName?: Function
}

const routes: Array<IRouteConfig> = [
  {
    path: '/',
    component: Home,
    mainMenu: true,
    name: 'Home',
    icon: HomeIcon,
    auth: true
  },
  {
    path: '/welcome',
    component: Welcome,
    name: 'Welcome'
  },
  {
    path: '/onboarding',
    component: Onboarding,
    name: 'Onboarding'
  },
  {
    path: '/jobs',
    component: Jobs,
    mainMenu: true,
    name: 'Jobs',
    icon: BriefcaseIcon
  },
  {
    path: '/opportunities',
    component: Opportunities,
    mainMenu: false,
    name: 'Opportunities',
    icon: UserGroupIcon,
    auth: true
  },
  {
    path: '/recommended-connections',
    component: RecommendedConnections,
    mainMenu: false,
    name: 'Recommended Connections',
    icon: UserGroupIcon,
    auth: true
  },
  {
    path: '/people',
    component: People,
    mainMenu: true,
    name: 'People',
    icon: UserGroupIcon,
    auth: true
  },
  {
    path: '/companies/',
    component: Companies,
    mainMenu: true,
    name: 'Companies',
    icon: OfficeBuildingIcon,
    auth: true
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    userMenu: true,
    auth: true,
    extraName: (user: any) => (user && user.person && `${user.person.first_name} ${user.person.last_name}`)
  },
  {
    path: '/company-profile',
    name: 'Company Profile',
    component: CompanyProfile,
    userMenu: true,
    auth: true,
    permission: (user: any) => (user && user.company),
    extraName: (user: any) => (user && user.company && user.company.name)
  },
  {
    path: '/people/:id',
    component: Person,
    name: 'Person',
  },
  {
    path: '/jobs/:id',
    component: Job,
    name: 'Job',
  },
  {
    path: '/jobs/:job_id/candidates',
    component: CompanyJobCandidates,
    name: 'CompanyJobCandidates',
  },
  {
    path: '/companies/:id',
    component: Company,
    name: 'Company',
  },
  {
    path: '/companies/:company_id/job/:job_id',
    component: CompanyJob,
    name: 'CompanyJob',
  },
  {
    path: '/companies/:company_id/job/:job_id/version-explorer',
    component: CompanyJobVersionExplorer,
    name: 'CompanyJobVersionExplorer',
  },
  {
    path: '/companies/:company_id/job/:job_id/review/:version_id',
    component: CompanyJobVersionReview,
    name: 'CompanyJobVersionReview',
  },
  {
    path: '/companies/:company_id/recruiting-pipeline/:process_id',
    component: RecruitingProcess,
    name: 'RecruitingProcess'
  },
  {
    path: '/recruit/pipeline/:process_id',
    component: RecruiterProcess,
    name: 'RecruiterProcess'
  },
  {
    path: '/notifications',
    component: Notifications,
    name: 'Notifications',
  },
  {
    path: '/login',
    component: Login,
    name: 'Sign In',
    userMenu: true,
  },
  {
    path: '/forgotten-password',
    component: ForgottenPassword,
    name: 'Forgotten Password',
  },
  {
    path: '/logout',
    component: Logout,
    name: 'Sign Out',
    userMenu: true,
    auth: true
  },
  {
    path: '/register',
    component: Register,
    userMenu: true,
    name: 'Sign Up',
  },
  {
    path: '/chat',
    component: Chat,
    name: 'Chat',
    icon: ChatAlt2Icon,
    mainMenu: true,
    auth: true,
    chat: true
  },
  {
    path: '/recruit',
    name: 'Candidates',
    component: Recruit,
    mainMenu: true,
    icon: UserGroupIcon,
    auth: true
  },
  {
    path: '/ise-candidates',
    name: 'Job Candidates',
    component: ISECandidates,
    mainMenu: true,
    icon: CollectionIcon,
    auth: true,
    permission: (user: any) => (user && AccountRoles.canPrecheckManageRecruits(user.role)),
  },
  {
    path: '/job-candidate/:id',
    name: 'Job Candidate',
    component: Candidate,
    auth: true,
  },
  {
    path: '/notes',
    name: 'Notes',
    component: Notes,
    mainMenu: true,
    icon: PencilAltIcon,
    auth: true
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    mainMenu: true,
    icon: DocumentReportIcon,
    auth: true,
    permission: (user: any) => (user && AccountRoles.canManageRecruits(user.role)),
  },
  {
    path: '/chat/:id',
    component: Chat,
    name: 'Chat',
    auth: true
  },
  {
    path: '/accept-company-invite/:id',
    component: AcceptCompanyInvite,
    name: 'Accept Invite'
  },
  {
    path: '/reset-password/:token',
    component: ResetPassword,
    name: 'Reset Password'
  },
  {
    path: '/cancel-account-deletion/:token',
    component: CancelAccountDeletion,
    name: 'Cancel Account Deletion'
  },
  {
    path: '/email-preferences/:token',
    component: ChangeNotificationPreferences,
    name: 'Change Notification Preferences'
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    mainMenu: true,
    icon: ClockIcon,
    auth: true
  },
  {
    path: '*',
    component: Home
  }
]

const AppRouter = () => {
  return (
    <Router basename={baseName}>
      <Switch>
        {
          routes.map((route: any, i) => {
            return <Route key={i} exact {...route} />
          })
        }
      </Switch>
    </Router>
  );
};

export default AppRouter
export { routes }