import { Layout } from '@/components/layout'
import HeadingsH2 from '@/components/headings/HeadingsH2'

import PaginatedList from '@/components/layout/list/PaginatedList'
import { allTrackingService } from '@/services'
import { toast } from 'react-toastify'
import HistoryItem from '@/components/history/HistoryItem'


const History = () => {
  return (
    <Layout title="History" auth={true} back={true}>
      <HeadingsH2 heading="History" />
      <div className="mt-4">
        <PaginatedList
          service={allTrackingService}
          pageSize={10}
          onServiceError={toast.error}
          itemComponent={HistoryItem}
          noResultsText="No site history"
        />
      </div>
    </Layout>
  )
}

export default History
