import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { NoMenuLayout } from '../../components/layout'
import { LoadingInside } from '../../components/layout/top_level/Loading'
import Header from '../../components/welcome/Header'
import { accountService } from '../../services'
import { toast } from 'react-toastify'
import BlueButton from '../../components/buttons/BlueButton'

export default function CancelAccountDeletion() {
  let { token } = useParams<{ token: string }>()
  const [loadingInside, setLoadingInside] = useState(false)
  let history = useHistory()

  const cancelDeletion = () => {
    setLoadingInside(true)
    accountService.cancelAccountDeletion(token).then(() => {
      setLoadingInside(false)
      toast.success('Successfully cancelled account deletion. You may now login')
      history.push('/login')
    }).catch((error) => {
      setLoadingInside(false)
      toast.error('Failed to cancel account deletion')
    })
  }

  return (
    <NoMenuLayout title="Cancel Account Deletion" auth={false}>
      <Header
        title='Welcome back to Gumption!'
        info={'Confirm that you would like to cancel your account deletion by clicking the button below.'}
      />
      {loadingInside ? <LoadingInside /> :
        <div className="mx-auto max-w-md text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="sm:space-y-8 flex flex-col items-center justify-items-center">
            <BlueButton
              title="Cancel Account Deletion"
              onClick={() => cancelDeletion()}
              className="mx-auto w-72"
            />
          </div>
        </div>
      }
    </NoMenuLayout >
  )
}
