import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '../buttons/BlueButton'

import { classNames } from '../../lib/helpers'

const MFACodeSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
})

export default function MFACodeForm({ handleSubmit }: { handleSubmit: any }) {
  return (
    <Formik
      initialValues={{
        code: "",
      }}
      validationSchema={MFACodeSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="code" className="block text-sm font-medium text-gray-700">
              MFA Code
            </label>
            <div className="mt-1">
              <input
                id="code"
                name="code"
                type="text"
                required
                className={classNames(
                  errors.code && touched.code && errors.code ?
                    'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
                    'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
                  , 'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm '
                )}
                onChange={handleChange} onBlur={handleBlur} value={values.code}
              />
            </div>
            <small className='text-red-500'>
              {errors.code && touched.code && errors.code}
            </small>
          </div>
          <div>
            <BlueButton
                title={isSubmitting ? "Signing in..." : "Sign in"}
                type="submit"
                disabled={isSubmitting}
                className="w-full"
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
