import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '@/components/buttons/BlueButton'
import { useEffect, useMemo, useState } from 'react'
import { IJob } from '@/services/jobs.service'
import { SelectField } from '@/components/forms/fields'
import { Switch } from '@headlessui/react'
import { classNames } from '@/lib/helpers'
import { useRecoilValue } from 'recoil'
import { userState } from '@/recoil/atoms/auth'
import { AccountRoles, recruitmentService } from '@/services'
import { toast } from 'react-toastify'
import { IRecruitingProcess } from '@/recoil/types'

const CloneJobSchema = Yup.object().shape({
  version_id: Yup.string().required('A job version is required'),
  recruiting_process: Yup.string().required('A recruiting pipeline is required')
})

export default function CloneJobForm({ job, handleSubmit }: { job: IJob, handleSubmit: any }) {
  const userData = useRecoilValue(userState)
  const versionOptions = useMemo(() => job.versions.map((version) => ({ id: version.id.toString(), title: `Version ${version.version_number}` })), [job])
  const [recruitingProcesses, setRecruitingProcesses] = useState([])

  useEffect(() => {
    recruitmentService.getAbstractProcesses(null).then((res) => {
      setRecruitingProcesses(res.data.map((process: IRecruitingProcess) => ({ id: process.id, title: process.name })))
    }).catch(() => {
      toast.error("Failed to fetch recruiting pipeline options")
    })
  }, [])

  return (
    <Formik
      initialValues={{
        version_id: (versionOptions ?? [])[0]?.id ?? '',
        recruiting_process: '',
        auto_publish: false
      }}
      validationSchema={CloneJobSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, isValid, dirty, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <SelectField
            fieldName="version_id"
            label="Version to Clone From"
            options={versionOptions}
            required={true}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <SelectField
            fieldName="recruiting_process"
            label="Recruiting Pipeline"
            options={recruitingProcesses}
            required={true}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          {userData?.role === AccountRoles.COMPANY && <Switch.Group as="li" className="py-4 flex items-center justify-between">
            <div className="flex flex-col">
              <Switch.Label as="p" className="text-sm font-medium text-gray-700" passive>
                Publish Job?
              </Switch.Label>
              <Switch.Description className="text-sm text-gray-500">
                Enable to auto-publish the job upon creation.
              </Switch.Description>
            </div>
            <Switch
              checked={values['auto_publish']}
              onChange={(enabled: boolean) => handleChange({ target: { name: 'auto_publish', value: enabled } })}
              className={classNames(
                values['auto_publish'] ? 'bg-teal-500' : 'bg-gray-200',
                'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  values['auto_publish'] ? 'translate-x-5' : 'translate-x-0',
                  'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </Switch>
          </Switch.Group>}
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <BlueButton
              type="submit"
              title={isSubmitting ? "Cloning..." : "Clone Job"}
              className="w-full transition duration-150 ease-in-out"
              disabled={!isValid || !dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
