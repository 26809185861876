import { _delete, fetch, postMultipart } from './base.service'
import {
  IListCRUDService,
  IServicePaginatedListResult,
  _notImplemented
} from './types'
import * as serverConfig from './server-config'
import { withServiceParamsInUrl } from './utils'


async function list(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const url = `${serverConfig.serverURL}${serverConfig.routes.files.fetchFiles}${urlOrQueryString}`
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function createFile(values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.files.createFile}`
  return await postMultipart(url, values)
}

async function deleteFile(id: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.files.deleteFile}`
    .replace('<file_id>', id.toString())
  return await _delete(url)
}

async function personList(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any>
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const path = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.files.fetchPersonFiles}${urlOrQueryString}`
    .replace('<user_id>', serviceParams.id.toString())
  return await fetch(path, cancelToken)
}

async function companyList(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any>
): Promise<IServicePaginatedListResult> {
  urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  const path = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.files.fetchCompanyFiles}${urlOrQueryString}`
    .replace('<company_id>', serviceParams.id.toString())
  return await fetch(path, cancelToken)
}

async function createCompanyFile(values: any, companyId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.files.createCompanyFile}`
    .replace('<company_id>', companyId.toString())
  return await postMultipart(url, values)
}

async function deleteCompanyFile(id: number, companyId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.files.deleteCompanyFile}`
    .replace('<company_id>', companyId.toString())
    .replace('<file_id>', id.toString())
  return await _delete(url)
}

export const filesService: IListCRUDService = {
  list: list,
  detail: _notImplemented,

  create: createFile,
  update: _notImplemented,
  delete: deleteFile
}

export const personFilesService: IListCRUDService = {
  list: personList,
  detail: _notImplemented,

  create: _notImplemented,
  update: _notImplemented,
  delete: _notImplemented
}

export const companyFilesService: IListCRUDService = {
  list: companyList,
  detail: _notImplemented,

  create: createCompanyFile,
  update: _notImplemented,
  delete: deleteCompanyFile
}