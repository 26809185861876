import { TextInputField, SelectField, CityField } from '../../components/forms/fields'

function BasicInfoFields({ values, errors, touched, handleChange, handleBlur, common, textInputStyles, selectInputStyles }: any) {
  const commonFieldProps = { values, errors, touched, handleBlur, handleChange }

  return (
    <>
      <div className="mt-6 sm:mt-5 space-y-1">
        <div className="sm:grid sm:grid-cols-2 sm:gap-3 items-start">
          <TextInputField
            fieldName="first_name"
            label="First Name"
            required={true}
            styles={textInputStyles}
            {...commonFieldProps}
          />
          <TextInputField
            fieldName="last_name"
            label="Last Name"
            required={true}
            styles={textInputStyles}
            {...commonFieldProps}
          />
        </div>
        <div className="sm:grid sm:grid-cols-2 sm:gap-3 items-start">
          <SelectField
            fieldName="state"
            label="State"
            options={common?.states}
            styles={selectInputStyles}
            {...commonFieldProps}
          />
          <CityField
            disabled={!values.state}
            styles={selectInputStyles}
            {...commonFieldProps}
          />
        </div>
        <TextInputField
          fieldName="phone"
          label="Phone"
          styles={textInputStyles}
          {...commonFieldProps}
        />
      </div>
    </>
  )
}

export default BasicInfoFields
