import { userState } from "@/recoil/atoms/auth"
import { AccountRoles } from "@/services"
import { IJob, IJobVersion, jobsService } from "@/services/jobs.service"
import { BriefcaseIcon, ClockIcon, LocationMarkerIcon } from "@heroicons/react/solid"
import { useMemo, useState } from "react"
import { toast } from "react-toastify"
import { useRecoilValue } from "recoil"
import RedButton from "../buttons/RedButton"
import HeadingsH2 from "../headings/HeadingsH2"
import Confirm from "../modals/Confirm"
import { CollapsableText, valueOrNA } from "../utils"

const JobPreview = ({ jobVersion, job, refetchJobVersion, closeModal }: { jobVersion: IJobVersion, job: IJob, refetchJobVersion?: () => void, closeModal?: () => void }) => {
  const [isPublishConfirmOpen, setIsPublishConfirmOpen] = useState(false)
  const userData = useRecoilValue(userState)

  const publishVersion = () => {
    jobsService.publishVersion(jobVersion.id).then(() => {
      toast.success(`Published version ${jobVersion.version_number}`)
      refetchJobVersion && refetchJobVersion()
      closeModal && closeModal()
    }).catch(() => {
      toast.error(`Failed to publish version ${jobVersion.version_number}`)
    })
  }

  const canPublishJobVersion = useMemo(() => {
    if (jobVersion.approved) {
      return true
    }
    
    if (userData?.role === AccountRoles.COMPANY) {
      return jobVersion.status === 'Draft' || jobVersion.status === 'Review'
    } else if (userData?.role === AccountRoles.HIRING_MANAGER) {
      return jobVersion.status === 'Review' && jobVersion.approved
    } else {
      return false
    }
  }, [jobVersion, userData])

  return (
    <>
      <Confirm
        title="Are you sure you want to publish this job version?"
        confirm_text="Publish"
        info_text="Publishing a job will notify en masse all users whose preferences match this job."
        onConfirm={() => publishVersion()}
        isOpen={isPublishConfirmOpen}
        setIsOpen={setIsPublishConfirmOpen}
      />
      <div className="truncate space-y-4">
        <HeadingsH2 heading={jobVersion.title} className="truncate" />
        <div className="flex items-center text-sm text-gray-500">
          <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
          <small>{job.company?.name}</small>
        </div>
        {(jobVersion.state || jobVersion.city) && <div className="flex items-center text-sm text-gray-500">
          <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
          <small>
            {jobVersion.state?.title}{(jobVersion.state && jobVersion.city) && ', '}{jobVersion.city?.title}
          </small>
        </div>}
        {jobVersion.work_type && <div className="flex items-center text-sm text-gray-500">
          <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
          <small>
            {jobVersion.work_type.title}
          </small>
        </div>}
        <div className="mt-6 max-w-5xl mx-auto pb-4">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-teal-600">Compensation</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {
                  !jobVersion.compensation_lower && !jobVersion.compensation_upper
                    ? 'N/A'
                    : `${jobVersion.compensation_lower && Number(jobVersion.compensation_lower)}${jobVersion.compensation_upper && `${jobVersion.compensation_lower && ' - '}${jobVersion.compensation_upper && Number(jobVersion.compensation_upper)}`}`
                }
              </dd>
              <dd className="mt-1 text-sm text-gray-900">{valueOrNA(jobVersion.compensation_type?.title)}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-teal-600">Work Schedule</dt>
              <dd className="mt-1 text-sm text-gray-900">{valueOrNA(jobVersion.work_type?.title)}</dd>
              <dd className="mt-1 text-sm text-gray-900">{valueOrNA(jobVersion.work_env?.title)}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-teal-600">Industry / Role</dt>
              <dd className="mt-1 text-sm text-gray-900">{valueOrNA(jobVersion.industry?.title)}</dd>
              <dd className="mt-1 text-sm text-gray-900">{valueOrNA(jobVersion.role?.title)}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-teal-600">Experience</dt>
              <dd className="mt-1 text-sm text-gray-900">{valueOrNA(jobVersion.experience?.title)}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-teal-600">Travel Availability</dt>
              <dd className="mt-1 text-sm text-gray-900">{valueOrNA(jobVersion.travel_availability?.title)}</dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-teal-600">Description</dt>
              <dd className="mt-1 text-sm text-gray-900"><CollapsableText text={valueOrNA(jobVersion.description)} /></dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-teal-600">Responsibilities</dt>
              <dd className="mt-1 text-sm text-gray-900"><CollapsableText text={valueOrNA(jobVersion.requirements)} /></dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-teal-600">Qualification</dt>
              <dd className="mt-1 text-sm text-gray-900"><CollapsableText text={valueOrNA(jobVersion.qualification)} /></dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-teal-600">Benefits</dt>
              <dd className="mt-1 text-sm text-gray-900"><CollapsableText text={valueOrNA(jobVersion.benefits)} /></dd>
            </div>
          </dl>
        </div>
        {refetchJobVersion && job.company?.is_paid && canPublishJobVersion && <RedButton
          title="Publish"
          onClick={() => setIsPublishConfirmOpen(true)}
        />}
      </div>
    </>
  )
}

export default JobPreview
