import React, { useEffect } from 'react'
import { NoMenuLayout } from '../../components/layout'
import { Link } from 'react-router-dom'
import ForgottenPasswordForm from '../../components/auth/ForgottenPasswordForm'
import { accountService } from '../../services'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Header from '../../components/auth/Header'

// State imports
import { useRecoilValue } from 'recoil'
import { isLoggedIn } from '../../recoil/atoms/auth'

// Loading
import Loading from '../../components/layout/top_level/Loading'

export default function ForgottenPassword({ location }: any) {
  const loggedIn = useRecoilValue(isLoggedIn)
  const [loading, setLoading] = React.useState(true)
  let history = useHistory()

  const redirectAfter = '/login'

  useEffect(() => {
    if (loggedIn) {
      setLoading(false)
      history.push('/')
    } else {
      setLoading(false)
    }
  }, [loggedIn, history])

  const handleSubmit = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    accountService.forgottenPassword(values).then(res => {
      if (res.success) {
        toast.success(`Email with instructions was sent`)
        setSubmitting(false)
        history.push(redirectAfter)
      }
    }).catch(err => {
      toast.error(`${err}`)
      setSubmitting(false)
    })
  }

  return (
    <>
      {loading ? <Loading /> : (<>
        <NoMenuLayout title="Forgotten Password">
          <div className="bg-gray-50 flex flex-col justify-center">
            <Header title='Forgotten Password' info='' />
            <div className="sm:mx-auto sm:w-full sm:max-w-md sm:pb-6">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <ForgottenPasswordForm handleSubmit={handleSubmit} />

                <p className="mt-4 text-center text-sm text-gray-600 max-w">
                  Return to{' '}
                  <Link
                    to={{ pathname: '/login/' }} 
                    className="font-medium text-teal-600 hover:text-teal-500">
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </NoMenuLayout>
      </>)}
    </>
  )
}
