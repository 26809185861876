import { toast } from "react-toastify"
import { useRecoilValue } from 'recoil'
import { userState } from '@/recoil/atoms/auth'
import { notesService } from "@/services"
import Note from "./Note"
import NoteForm from "./NoteForm"
import PaginatedCRUDList from "@/components/layout/list/PaginatedCRUDList"


const NotesTab = ({ noteTable, object, createdByAnybody = false, alterValues = {} }: any) => {
  const userData = useRecoilValue<any>(userState)

  const formAlterValues = (values: any) => {
    return {
      ...values,
      [noteTable]: object.id,
      ...alterValues
    }
  }

  let serviceParams: any = {[noteTable]: object.id}
  if (!createdByAnybody) {
    serviceParams['created_by'] = userData.id
  }

  return (
    userData &&
    <PaginatedCRUDList
      service={notesService}
      serviceParams={serviceParams}
      onServiceError={toast.error}
      object={object}
      itemComponent={Note}
      itemsProps={{ userData }}
      formComponent={NoteForm}
      addButtonTitle="Add Note"
      getUpdateTitle={(object: any) => `Update: ${object?.title}`}
      hasUpdate={false}
      formAlterValues={formAlterValues}
      onUpdateSuccess={() => toast.success('Update successful')}
      onUpdateError={() => toast.error('Error updating')}
      // colsInFilter={4}
    />
  )
}

export default NotesTab
