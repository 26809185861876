import { ChangeEventHandler, FocusEventHandler, Fragment, useEffect, useRef, useState } from "react"
import { classNames } from "@/lib/helpers"
import { Combobox, Transition } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/solid"

export interface ISelectSearchFieldOption {
  id: string,
  title: string
}

interface ISelectSearchFieldStyles {
  wrapper?: string,
  input?: string
}

interface ISelectSearchFieldProps {
  fieldName: string,
  label?: string,
  options: Array<ISelectSearchFieldOption>
  handleChange: ChangeEventHandler<HTMLSelectElement>,
  handleBlur: FocusEventHandler<HTMLSelectElement>,
  values: Record<string, any>,
  errors: Record<string, any>,
  touched: Record<string, any>,
  disabled?: boolean,
  required?: boolean,
  styles?: ISelectSearchFieldStyles
}

const SelectSearchField = ({
  fieldName,
  label,
  options,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  disabled,
  required = false,
  styles
}: ISelectSearchFieldProps) => {
  const [selectedOption, setSelectedOption] = useState<any>('')
  const [query, setQuery] = useState('')
  const optionsButton = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    handleChange({ target: { name: fieldName, value: selectedOption } } as any)
  }, [fieldName, handleChange, selectedOption])

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
          option.title
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  const inputPlaceholder = options.length === 0 ? 'No Options' : (selectedOption ? options.find((option) => option.id === selectedOption)?.title : 'Select Option')

  return (
    <div className={styles?.wrapper ?? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'}>
      {label && <label htmlFor={fieldName} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 sm:pb-1">
        {label}
        {required && <span className="required">*</span>}
      </label>}
      <div className="mt-1 sm:mt-0 sm:col-span-2 relative">
        <Combobox
          value={selectedOption}
          onChange={setSelectedOption}
          name={`${fieldName}-container`}
          disabled={disabled || options.length === 0}
        >
          <div
            className={classNames(
              'relative',
              errors[fieldName] && touched[fieldName] && errors[fieldName] ?
                'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
                'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
              , styles?.input ?? 'border max-w-lg block w-full shadow-sm sm:max-w-xs text-sm rounded-md pl-3 py-2 pr-8 text-left',
              disabled || options.length === 0 ? 'opacity-70' : ''
            )}
          >
            <Combobox.Button disabled={disabled || options.length === 0} className="absolute right-2 top-1/2 -translate-y-1/2" ref={optionsButton} />
            <Combobox.Input
              displayValue={(option) => (option?.[0] as any)?.title}
              onChange={(event) => setQuery(event.target.value)}
              onFocus={() => optionsButton.current?.click()}
              placeholder={inputPlaceholder}
              name={fieldName}
              className="border-none outline-none ring-0 focus:border-none focus:outline-none focus:shadow-none focus:ring-0 w-full p-0 text-sm placeholder-gray-900 focus:placeholder-gray-400 truncate"
            />
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Combobox.Options className="absolute left-0 z-10 mt-1 w-full origin-top-right rounded-md border-gray-300 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-40 overflow-y-auto">
              {filteredOptions.length === 0 ? (
                <div className="relative cursor-default select-none py-1 px-2 pl-7 font-medium text-gray-700 text-sm">
                  Nothing found
                </div>
              ) : (
                filteredOptions.map((option) => (
                  <Combobox.Option key={option.id} value={option.id} title={fieldName}>
                    <div className={`flex gap-2 items-center w-full text-gray-700 text-sm font-medium py-1 px-2 cursor-pointer hover:bg-gray-200 ${selectedOption === option.id ? 'bg-gray-200' : ''}`}>
                      {selectedOption === option.id ? <CheckIcon className="h-3 w-3" aria-hidden="true" /> : <div className="h-3 w-3"></div>}
                      {option.title}
                    </div>
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </Combobox>
      </div>
      <small className='text-red-500'>
        {errors[fieldName] && touched[fieldName] && errors[fieldName]}
      </small>
    </div>
  )
}

export default SelectSearchField
