import { useState } from "react"
import { IUser } from "@/recoil/types"
import { AccountRoles } from "@/services"
import CompanyInfo from "./CompanyInfo"
import CompanyJobs from "./CompanyJobs"
import Members from "./Members"
import Recruits from "./Recruits"
import MyCompanyFiles from "@/components/files/MyCompanyFiles"
import BaseTabs from "@/components/layout/navigation/BaseTabs"
import RecruitingProcesses from "./RecruitingProcesses"

interface ICompanyTabsProps {
  user: IUser
}

const Tabs = ({
  user
}: ICompanyTabsProps) => {
  const company = user.company
  const appRole = user.role

  const tabs = [
    { slug: 'company_profile', name: 'Company Profile' },
    { slug: 'company_files', name: 'Company Files' },
    { slug: 'jobs', name: 'Jobs', accessCheck: AccountRoles.canManageCompanyJobs },
    { slug: 'to_review_recruits', name: 'Pending Candidates', accessCheck: AccountRoles.canManageRecruits },
    { slug: 'members', name: 'Members', accessCheck: AccountRoles.canManageCompanyMembers },
    { slug: 'recruiting_processes', name: 'Recruiting Pipelines', accessCheck: AccountRoles.canManageRecruits }
  ]
  const [activeTab, setActiveTab] = useState('company_profile')
  return (
    <>
      <BaseTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        appRole={appRole}
      />
      {activeTab === 'company_profile' && <CompanyInfo company={company} canEdit={AccountRoles.canManageCompanyProfile(appRole)} />}
      {activeTab === 'company_files' && <MyCompanyFiles company={company} canEdit={AccountRoles.canManageCompanyProfile(appRole)} />}
      {activeTab === 'jobs' && <CompanyJobs company={company} />}
      {activeTab === 'to_review_recruits' && <Recruits company={company} />}
      {activeTab === 'members' && <Members company={company} />}
      {activeTab === 'recruiting_processes' && <RecruitingProcesses company={company} />}
    </>
  )
}

export default Tabs
