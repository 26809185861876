import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '../../../buttons/BlueButton'

import { useRecoilValue } from "recoil"
import { commonTables } from "../../../../recoil/atoms/common"

// any validation
const ProfileLinkFormSchema = Yup.object().shape({
  url: Yup.string().url('Link must be a valid URL').required('Required'),
})

const ProfileLinkForm = ({ handleSubmit }: any) => {
  const common = useRecoilValue<any>(commonTables)

  return (
    <Formik
      initialValues={{
        url: '',
        link_type_id: common?.person_link_types.length > 0 && common?.person_link_types[0].id
      }}
      validationSchema={ProfileLinkFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col">
            <div className="mt-6 mb-6 sm:mt-5 sm:mb-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 ">
                <label htmlFor="url" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Link
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="url"
                    name="url"
                    type="text"
                    className="max-w-lg block w-full shadow-sm border focus:bg-teal-50 focus:border-4 focus:border-teal-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md focus:ring-teal-600"
                    onChange={handleChange} onBlur={handleBlur} value={values.url}
                  />
                </div>
                {errors.url && touched.url && errors.url}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="link_type_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Link Type
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="link_type_id"
                  name="link_type_id"
                  onChange={handleChange} onBlur={handleBlur} value={values.link_type_id}
                  className="max-w-lg block focus:ring-teal-600 focus:border-teal-600 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  {common?.person_link_types?.map((type: any) => {
                    return (
                      <option key={type.id} value={type.id}>{type.name}</option>
                    )
                  })}
                </select>
              </div>
              <>{errors.link_type_id && touched.link_type_id && errors.link_type_id}</>
            </div>

            <BlueButton
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              title="Submit"
              className="mt-12 w-full sm:w-1/2 sm:mx-auto"
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

export default ProfileLinkForm
