import { companyFilesService } from "@/services"

import Files from './Files'
import CompanyFileForm from "./CompanyFileForm"

const MyCompanyFiles = ({ company, canEdit = false }: any) => {
  return (
    <Files
      service={companyFilesService}
      object={{id: company.id}}
      addButtonTitle={canEdit ? "Add File" : null}
      itemProps={canEdit ? { showDeleteButton: true } : {}}
      formComponent={CompanyFileForm}
    />
  )
}

export default MyCompanyFiles
