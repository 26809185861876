import React from 'react'
import { accountService } from '@/services'
import { toast } from 'react-toastify'
import LinkedInSignIn from '../../LinkedInSignIn.png'


export default function LinkedInAuth() {
    const loginWithLinkedIn = () => {
      accountService.linkedInLogin().then(res => {
        if (res.success) {
            const url = res.data.login_url
            window.open(url, "_self")
        }
      }).catch(err => {
        toast.error(`${err}`)
      })
  }

  return (
    <div className="mt-8">
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">Or continue with</span>
        </div>
      </div>

      <div className="mt-6 grid justify-center items-center">
        <button className="text-white border-transparent" style={{ background: "#0077b5"}} onClick={loginWithLinkedIn}>
          <img className="max-h-10" src={LinkedInSignIn} alt="Sign in with LinkedIn Button"/>
        </button>
      </div>
    </div>
  )
}
