import { MailIcon, PhoneIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import RedButton from '../../../buttons/RedButton'


import Confirm from '../../../modals/Confirm'
import { RoleBadge } from '../../person/RoleBadge'

const MemberInner = ({ member, setIsConfirmOpen }: any) => {
  return (
    <div className="px-4 py-4 flex items-center sm:px-6">
      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="truncate">
          <div className="flex text-sm space-x-2">
            <p className="font-bold text-teal-600 truncate">{member.person?.first_name} {member.person?.last_name}</p>
            <RoleBadge role={member.role} />
          </div>
          <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                <strong>{member.email}</strong>
              </p>
            </div>
          </div>
          {member.person?.phone && <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                <strong>{member.person.phone}</strong>
              </p>
            </div>
          </div>}
        </div>
      </div>
      <div className="ml-2 sm:ml-0">
        <RedButton
          title="Remove"
          className="w-20"
          onClick={() => setIsConfirmOpen(true)}
        />
      </div>
    </div>
  )
}


const Member = ({ object, onDeleteConfirm }: any) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  
  return (
    <>
      <Confirm
        title={`Are you sure to remove ${object.person?.first_name} ${object.person?.last_name}?`}
        onConfirm={() => onDeleteConfirm(object.id)}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      />
      <li>
        <MemberInner member={object} setIsConfirmOpen={setIsConfirmOpen} />
      </li>
    </>
  )
}

export default Member
