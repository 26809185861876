import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import update from 'immutability-helper'
import { Scrollbars } from 'react-custom-scrollbars-2';

import { LoadingInside } from '@/components/layout/top_level/Loading'
import { RecruitingProcessStep } from '@/components/recruit/RecruitingProcessStep'
import { IJobCandidate, IRecruitingProcess, IRecruitingStep } from '@/recoil/types'
import { RecruitingProcessStepDetail } from '@/components/recruit/RecruitingProcessStepDetail';
import { HorizontalShadowScrollbars } from '@/components/utils';
import { jobCandidatesService, recruitmentService } from '@/services'
import { toast } from 'react-toastify';
import TealButton from '../buttons/TealButton';
import Confirm from '../modals/Confirm';

const RecruiterProcessCandidatesView = ({ process, refetchProcess }: { process: IRecruitingProcess, refetchProcess: () => void }) => {
  const MAX_CANDIDATES = 10

  const scrollbarsRef = useRef<Scrollbars>(null)
  const [candidates, setCandidates] = useState<IJobCandidate[]>([])
  const [selectedStep, setSelectedStep] = useState<IRecruitingStep>()
  const [isSettingCandidateStep, setIsSettingCandidateStep] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitCandidatesConfirmOpen, setSubmitCandidatesConfirmOpen] = useState(false)

  useEffect(() => {
    setLoading(true)
    recruitmentService.getRecruiterProcessCandidates(process.id, null).then((res) => {
      setCandidates(res.data)
      setLoading(false)
    }).catch(() => {
      toast.error("Failed to fetch details")
    })
  }, [process])

  const candidatesForStep = useCallback((stepId: number) => {
    return candidates.filter((candidate) => candidate.recruiting_step_id === stepId).sort(
      (a, b) => (a.candidate ? a.candidate.last_name : (a.candidate_name ?? '')).localeCompare(b.candidate ? b.candidate.last_name : (b.candidate_name ?? ''))
    )
  }, [candidates])

  const reflectCandidateUpdate = useCallback((stepId: number, candidateId: number) => {
    const foundIndex = candidates.findIndex((candidate) => candidate.id === candidateId)
    if (foundIndex >= 0) {
      updateCandidateStep(candidateId, stepId)
      setCandidates(
        update(candidates, {
          [foundIndex]: {
            recruiting_step_id: {
              $set: stepId,
            }
          }
        })
      )
    }
  }, [candidates])

  const handleDrop = useCallback((stepId: number, item: { candidateId: number }) => {
    reflectCandidateUpdate(stepId, item.candidateId)
  }, [reflectCandidateUpdate])

  const updateCandidateStep = (candidateId: number, stepId: number) => {
    setIsSettingCandidateStep(true)
    jobCandidatesService.updateStep(candidateId, stepId).then(() => {
      setIsSettingCandidateStep(false)
    }).catch(() => {
      setIsSettingCandidateStep(false)
      toast.error("Failed to update the candidate's step")
    })
  }

  const submitCandidates = () => {
    recruitmentService.recruiterSubmitProcessCandidates(process.id).then(() => {
      toast.success("Successfully submitted candidates to job")
      refetchProcess()
    }).catch(() => {
      toast.error("Failed to submit candidates to job")
    })
  }

  const submitStep = useMemo(() => process.steps.find((step) => step.type === 'Ready_to_submit'), [process])
  const hasCandidatesToSubmit = useMemo(() => !!submitStep ? candidatesForStep(submitStep.id).length > 0 : false, [submitStep, candidatesForStep])

  return (
    !loading ? <>
      <Confirm
        title="Confirm candidate submission"
        confirm_text="Submit Candidates"
        info_text={`All candidates in the "To Submit" status will be removed from this pipeline and submitted to the job's recruiting pipeline`}
        onConfirm={() => submitCandidates()}
        isOpen={submitCandidatesConfirmOpen}
        setIsOpen={setSubmitCandidatesConfirmOpen}
      />
      <TealButton
        title="Submit Candidates"
        onClick={() => setSubmitCandidatesConfirmOpen(true)}
        className="mb-6 ml-4 sm:ml-0"
        disabled={!hasCandidatesToSubmit}
      />
      <HorizontalShadowScrollbars
        ref={scrollbarsRef}
        className="overflow-x-auto whitespace-nowrap pt-2"
        style={{ width: '100%', height: selectedStep !== undefined ? '132px' : (48 + 22 + (62 * MAX_CANDIDATES)) + 'px' }}
        renderTrackHorizontal={({ style, ...props }) =>
          <div {...props} style={{ 
            ...style,
            top: 0,
            right: 4,
            left: 4,
            borderRadius: 3,
            zIndex: 10
          }} />
        }
        onWheel={(e) => {
          const wrapper = document.getElementById('infinite-scroll-wrapper')
          if (wrapper && wrapper.scrollHeight <= wrapper.clientHeight) {
            scrollbarsRef.current && scrollbarsRef.current.scrollLeft(scrollbarsRef.current.getScrollLeft() + e.deltaY)
          }
        }}
      >
        <div className="pt-4 space-x-4 px-1">
          {process?.steps.map((step) => (
            <RecruitingProcessStep
              onStepClick={() => {setSelectedStep(selectedStep?.id === step.id ? undefined : step)}}
              step={step}
              candidates={candidatesForStep(step.id)}
              onDrop={(item) => handleDrop(step.id, item)}
              maxCandidates={!!selectedStep ? 0 : MAX_CANDIDATES}
              isSelected={selectedStep?.id === step.id}
              anySelected={selectedStep !== undefined}
              disabled={false}
              loading={isSettingCandidateStep}
              key={step.id}
              canManageCandidates={step.can_manage_candidates}
              groupingName={'recruiter'}
              hasHiringPerm={false}
              anyOverride={true}
            />
          ))}
        </div>
      </HorizontalShadowScrollbars>

      {!!selectedStep && <RecruitingProcessStepDetail
        steps={process?.steps ?? []}
        step={selectedStep}
        candidates={candidatesForStep(selectedStep.id)}
        closeDetail={() => setSelectedStep(undefined)}
        reflectCandidateUpdate={reflectCandidateUpdate}
      />}
    </> : <LoadingInside />
  )
}

export default RecruiterProcessCandidatesView
