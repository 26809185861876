import { ChangeEventHandler, FocusEventHandler } from "react"
import { classNames } from "@/lib/helpers"
import { Switch } from "@headlessui/react"

interface ISwitchInputFieldProps {
  fieldName: string,
  label: string | JSX.Element,
  description?: string,
  handleChange: ChangeEventHandler,
  handleBlur: FocusEventHandler,
  values: Record<string, any>,
  errors: Record<string, any>,
  touched: Record<string, any>,
  required?: boolean,
  disabled?: boolean
}

const SwitchInputField = (
  { fieldName,
    label,
    description,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    required = false,
    disabled = false
  }: ISwitchInputFieldProps) => {
  return (
    <div className="sm:border-t sm:border-gray-200 sm:pt-5">
      <Switch.Group as="li" className="py-4 flex items-center justify-between">
        <div className="flex flex-col">
          <Switch.Label as="p" className="text-sm font-medium text-gray-700" passive>{label}</Switch.Label>
          {description && <Switch.Description className="text-sm text-gray-500">{description}</Switch.Description>}
        </div>
        <Switch
          checked={values[fieldName]}
          disabled={disabled}
          onChange={(enabled: boolean) => handleChange({ target: { name: fieldName, value: enabled } } as any)}
          className={classNames(
            values[fieldName] ? 'bg-teal-500' : 'bg-gray-200',
            'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500',
            !disabled ? 'cursor-pointer' : ''
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              values[fieldName] ? 'translate-x-5' : 'translate-x-0',
              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
      </Switch.Group>
      <small className='text-red-500'>
        {errors[fieldName] && touched[fieldName] && errors[fieldName]}
      </small>
    </div>
  )
}

export default SwitchInputField
