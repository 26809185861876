import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { classNames } from '../../lib/helpers'
import BaseButton, { IBaseButtonProps } from './BaseButton'

import { ButtonStyles, ButtonWithOptionStyle } from './styles'

type ButtonOption = {
  id: string,
  title: string
}

export const BaseButtonWithOptions = (
  props: IBaseButtonProps & {
    options: ButtonOption[],
    onOptionClick?: (option: ButtonOption, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    style: ButtonWithOptionStyle
  }
) => {
  return (
    <div className="flex">
      <BaseButton
        {...props}
        className={classNames(
          props.className,
          props.style.button,
          props.options.length > 0 ? "rounded-tr-none rounded-br-none" : ""
        )}
      />
      {props.options.length > 0 && <Menu as="div" className="relative inline-block text-left overflow-visible">
        <Menu.Button className={classNames(
          ButtonStyles.Base,
          props.style.button,
          "rounded-tl-none rounded-bl-none pl-x",
          props.disabled ? 'pointer-events-none' : ''
        )} disabled={props.disabled}>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={classNames(
            "absolute right-0 z-10 mt-1 w-40 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
            props.style.menu
          )}>
            <div className="py-1 max-h-40 overflow-y-auto">
              {props.options.map((option) => <Menu.Item key={option.id}>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={(e) => props.onOptionClick && props.onOptionClick(option, e)}
                    className={classNames(
                      'w-full text-white text-sm font-medium py-1',
                      props.style.menuButton
                    )}
                  >
                    {option.title}
                  </button>
                )}
              </Menu.Item>)}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>}
    </div>
  )
}
