import BlueButton from '@/components/buttons/BlueButton'
import RedButton from '@/components/buttons/RedButton'
import Modal from '@/components/modals/Modal'
import { IRecruitingStep } from '@/recoil/types'
import { XIcon, InformationCircleIcon } from '@heroicons/react/solid'
import type { Identifier, XYCoord } from 'dnd-core'
import { FC, useState } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import RecruitingStepInfo from '../RecruitingStepInfo'

export interface RecruitingProcessStepProps {
  step: IRecruitingStep
  index: number
  moveStep: (dragIndex: number, hoverIndex: number) => boolean,
  updateStepOrder: (item: DragItem) => void,
  deleteStep: () => void,
  isLoading: boolean,
  isLocked: boolean,
  canDelete: boolean,
  companyId?: number
}

interface DragItem {
  index: number
  id: number
  type: string
}

export const RecruitingProcessStep: FC<RecruitingProcessStepProps> = ({ step, index, moveStep, updateStepOrder, deleteStep, isLoading, isLocked, canDelete, companyId }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [showingStepInfo, setShowingStepInfo] = useState(false)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: step.type === 'Normal' && step.id >= 0 ? 'Normal' : 'none',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2

      const clientOffset = monitor.getClientOffset()
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }

      moveStep(dragIndex, hoverIndex) && (item.index = hoverIndex)
    },
    drop(item) {
      updateStepOrder(item)
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: step.type,
    item: () => {
      return { id: step.id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !isLocked && (!isLoading && step.type === 'Normal')
  })

  const opacity = isDragging ? 0.25 : 1
  drag(drop(ref))

  return (
    <div ref={ref} className={`w-60 inline-block align-top`} style={{ opacity, cursor: step.type !== 'Normal' ? 'not-allowed' : (isLocked ? 'not-allowed' : (isLoading ? 'wait' : 'grab')) }} data-handler-id={handlerId}>
      <Modal
        title="Step Info"
        open={showingStepInfo}
        setOpen={setShowingStepInfo}
        hasForm={true}
      >
        <RecruitingStepInfo step={step} companyId={companyId} />
      </Modal>
      <div className={`shadow rounded-md p-2 ${step.type === 'Normal' ? 'bg-white' : 'bg-gray-200'}`}>
        <div className="font-bold text-gray-800 truncate" title={step.name}>{step.name}</div>
        <div className="text-sm text-gray-600 whitespace-normal line-clamp-2" title={step.description}>{step.description}</div>
        <div className="w-full h-2 mt-1 mb-2" style={{ backgroundColor: `#${step.color ?? '000000'}` }}></div>
        <div className="flex gap-2">
          {step.id >= 0 && <BlueButton
            title=""
            showTitle={false}
            children={<InformationCircleIcon className="h-4 w-4" aria-hidden="true" />}
            className="px-1 py-1"
            onClick={() => setShowingStepInfo(true)}
          />}
          {(step.type === 'Normal' && canDelete && !isLocked) && <RedButton
            title=""
            showTitle={false}
            children={<XIcon className="h-4 w-4" aria-hidden="true" />}
            className="px-1 py-1"
            onClick={deleteStep}
          />}
        </div>
      </div>
    </div>
  )
}
