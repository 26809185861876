import { atom } from 'recoil'
import { IUser } from '@/recoil/types'

export const userState = atom({
  key: 'userDataState',
  default: null as IUser | null,
});

export const isLoggedIn = atom({
  key: 'isLoggedIn',
  default: false as boolean,
})