import { atom } from 'recoil'

export const unreadMessageCount = atom({
  key: 'unreadMessageCount',
  default: 0
});

export const activeChatUser = atom({
  key: 'activeChatUser',
  default: null as number | null
});

export const socketState = atom({
  key: 'socketState',
  default: false as boolean
});

export const incomingMessage = atom({
  key: 'incomingMessage',
  default: {}
});

export const outgoingMessage = atom({
  key: 'outgoingMessage',
  default: {}
});