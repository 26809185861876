import { useState } from "react"
import { toast } from "react-toastify"
import { useRecoilState } from "recoil"
import { userState } from "../../../../recoil/atoms/auth"
import { accountService } from "../../../../services"
import ImageUploadForm from "../../../forms/ImageUploadForm"

const ChangePictureForm = ({ setIsEditing }: any) => {
  const [userData, setUserData] = useRecoilState<any>(userState)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const uploadPicture = (image: any) => {
    setIsSubmitting(true)
    accountService.uploadPicture(image).then((result) => {
      if (result.success) {
        const newUserData = Object.assign({}, userData, {
          person: Object.assign({}, userData.person, {
            profile_image: result.data.profile_image
          })
        })
        setUserData(newUserData)
        setIsEditing(false)
        setIsSubmitting(false)
      }
      toast.success('Profile Image Changed')
    }).catch((error) => {
      console.error(error)
      toast.error(error)
      setIsSubmitting(false)
    })
  }
  return (
    <div data-tour="changepictureform">
      <ImageUploadForm
        onSubmit={uploadPicture}
        submitOnChange={true}
        isSubmitting={isSubmitting}
        setIsEditing={setIsEditing}
      />
    </div>
  )
}

export default ChangePictureForm