import { ISelectOption, SelectSearchInput } from '@/components/inputs/SelectSearchInput'
import { IRecruitingStepAssignee, IRecruitingStepAssigneeType } from '@/recoil/types'
import { useCallback, useEffect, useState } from 'react'
import update from 'immutability-helper'
import { XCircleIcon } from '@heroicons/react/outline'
import { toast } from 'react-toastify'
import { recruitmentService } from '@/services'

interface TempAssignee {
  type: IRecruitingStepAssigneeType,
  id: number | string,
  title: string,
  permission: 'assign' | 'view'
}

export default function CreateRecruitingStepFormAssignees({ initialAssignees, handleChange, readonly = false }: { initialAssignees?: IRecruitingStepAssignee[], handleChange: React.ChangeEventHandler<Element>, readonly?: boolean }) {
  const sections: { code: IRecruitingStepAssigneeType, long: string }[] = [
    { code: 'CU', long: 'Company Users' },
    { code: 'UR', long: 'User Role' },
    { code: 'OC', long: 'Recruitment Agency' },
    { code: 'AU', long: 'Agency Users' },
    { code: 'FU', long: 'Freelance Users' },
  ]

  const addInputPlaceholderMap = {
    'CU': 'Add company user',
    'UR': 'Add user role',
    'OC': 'Add recruitment agency',
    'AU': 'Add agency user',
    'FU': 'Add freelance recruiter'
  }

  const [companyUsersOptions, setCompanyUsersOptions] = useState<ISelectOption[]>([])
  const [userRoleOptions] = useState<ISelectOption[]>([
    { id: 'CO', title: 'Company Owner' },
    { id: 'HM', title: 'Hiring Manager' },
    { id: 'RE', title: 'Recruiter' }
  ])
  const [recruitmentAgencyOptions, setRecruitmentAgencyOptions] = useState<ISelectOption[]>([])
  const [agencyUserOptions, setAgencyUserOptions] = useState<ISelectOption[]>([])
  const [freelanceUserOptions, setFreelanceUserOptions] = useState<ISelectOption[]>([])
  const [assignees, setAssignees] = useState<TempAssignee[]>([])

  useEffect(() => {
    handleChange({ target: { name: 'assignees', value: assignees.map((assignee) => ({
      assignee_type: assignee.type,
      assignee_permission: assignee.permission,
      ...((assignee.type === 'CU' || assignee.type === 'AU' || assignee.type === 'FU') && { user: assignee.id }),
      ...(assignee.type === 'UR' && { role: assignee.id }),
      ...(assignee.type === 'OC' && { company: assignee.id })
    })) } } as any)
  }, [assignees, handleChange])

  useEffect(() => {
    recruitmentService.getStepPotentialAssignees(null).then((res) => {
      if(Array.isArray(res.data.company_users) && res.data.company_users.length) {
        setCompanyUsersOptions(res.data.company_users.sort((a: any, b: any) => a.first_name.localeCompare(b.first_name)).map((v: any) => ({ id: v.id, title: `${v.first_name} ${v.last_name}` })))
      }
      if(Array.isArray(res.data.recruitment_agencies) && res.data.recruitment_agencies.length) {
        setRecruitmentAgencyOptions(res.data.recruitment_agencies.map((v: any) => ({ id: v.id, title: v.name })));
      }
      if(Array.isArray(res.data.other_agency_users) && res.data.other_agency_users.length) {
        setAgencyUserOptions(res.data.other_agency_users.map((v: any) => ({ id: v.id, title: `${v.first_name} ${v.last_name} (${v.company_name})` })))
      }
      if(Array.isArray(res.data.freelance_recruiters) && res.data.freelance_recruiters.length) {
        setFreelanceUserOptions(res.data.freelance_recruiters.sort((a: any, b: any) => a.first_name.localeCompare(b.first_name)).map((v: any) => ({ id: v.id, title: `${v.first_name} ${v.last_name}` })))
      }
    })
  }, [])

  const addAssignee = (sectionCode: IRecruitingStepAssigneeType, id: string | number) => {
    setAssignees((assignees) => {
      const foundAssignee = assignees.find((assignee) => assignee.type === sectionCode && assignee.id === id)
      if (foundAssignee) {
        toast.warn(`${foundAssignee.title} has already been added`)
        return assignees
      }

      return update(assignees, {
        $push: [{ type: sectionCode, id, permission: 'view', title: optionsForSection(sectionCode).find((option) => option.id.toString() === id.toString())?.title ?? 'Unknown' }]
      })
    })
  }

  const removeAssignee = (assignee: TempAssignee) => {
    setAssignees((assignees) => {
      const idx = assignees.findIndex((_assignee) => _assignee.id === assignee.id && _assignee.type === assignee.type)

      return idx >= 0 ? update(assignees, {
        $splice: [[idx, 1]]
      }) : assignees
    })
  }

  const optionsForSectionUnfiltered = useCallback((sectionCode: IRecruitingStepAssigneeType) => {
    let options: ISelectOption[] = []

    switch (sectionCode) {
      case 'CU':
        options = companyUsersOptions
        break
      case 'UR':
        options = userRoleOptions
        break
      case 'OC':
        options = recruitmentAgencyOptions
        break
      case 'AU':
        options = agencyUserOptions
        break
      case 'FU':
        options = freelanceUserOptions
        break
      default:
        return []
    }

    return options
  }, [agencyUserOptions, companyUsersOptions, freelanceUserOptions, recruitmentAgencyOptions, userRoleOptions])

  const optionsForSection = useCallback((sectionCode: IRecruitingStepAssigneeType) => {
    return optionsForSectionUnfiltered(sectionCode).filter((option) => !assignees.find((assignee) => assignee.type === sectionCode && assignee.id === option.id))
  }, [assignees, optionsForSectionUnfiltered])

  const updateAssigneePermission = (assignee: TempAssignee, permission: 'assign' | 'view') => {
    setAssignees((assignees) => {
      const idx = assignees.findIndex((_assignee) => _assignee.id === assignee.id && _assignee.type === assignee.type)

      return idx >= 0 ? update(assignees, {
        [idx]: {
          permission: {
            $set: permission,
          }
        }
      }) : assignees
    })
  }

  useEffect(() => {
    if (!initialAssignees) {
      return
    }

    const tempAssignees: TempAssignee[] = []

    for (const assignee of initialAssignees) {
      const id = assignee.user ?? assignee.company ?? assignee.role
      tempAssignees.push({
        id,
        type: assignee.assignee_type,
        permission: assignee.assignee_permission,
        title: optionsForSectionUnfiltered(assignee.assignee_type).find((option) => option.id.toString() === id.toString())?.title ?? 'Unknown'
      })
    }

    setAssignees(tempAssignees)
  }, [initialAssignees, optionsForSectionUnfiltered])

  return (
    <div className="space-y-3">
      {sections.map((section) => (
        <div key={section.code}>
          <div className="text-xs font-bold text-gray-700">{section.long}</div>
          <div className="shadow-sm border-gray-200 border p-1 mt-1 space-y-2 rounded-md">
            {assignees.filter((assignee) => assignee.type === section.code).length > 0 && <table className="w-full">
              <thead className="text-xs text-gray-500 text-center">
                <tr>
                  <th className="w-8/12"></th>
                  <th className="w-2/12">Assign</th>
                  <th className="w-2/12">Can View</th>
                </tr>
              </thead>
              <tbody>
                {assignees.filter((assignee) => assignee.type === section.code).map((assignee) => (
                  <tr key={assignee.id}>
                    <td className="text-sm text-gray-600 font-medium pl-1">
                      <div className="flex items-center gap-1">
                        {!readonly && <XCircleIcon className="h-4 w-4 cursor-pointer text-red-500" aria-hidden="true" onClick={() => removeAssignee(assignee)} />}
                        <span>{assignee.title}</span>
                      </div>
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={assignee.permission === 'assign'}
                        disabled={readonly}
                        onChange={(e) => updateAssigneePermission(assignee, e.target.checked ? 'assign' : 'view')}
                        className={`border-gray-300 focus:border-teal-600 focus:ring-teal-600 h-4 w-4 text-teal-600 rounded ${readonly && 'opacity-60'}`}
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={true}
                        disabled={true}
                        className="border-gray-300 focus:border-teal-600 focus:ring-teal-600 h-4 w-4 text-teal-600 rounded opacity-60"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>}
            {!readonly && <SelectSearchInput
              selectedOption={''}
              setSelectedOption={(value) => addAssignee(section.code, value)}
              options={optionsForSection(section.code)}
              placeholder={addInputPlaceholderMap[section.code]}
            />}
          </div>
        </div>
      ))}
    </div>
  )
}
