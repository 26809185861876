import { useLocation } from 'react-router-dom';
import moment from 'moment'

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ')
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function formatDate(date: Date | string, format: string = 'MM/DD/YYYY') {
  return moment(date).format(format)
}

function formatTimestamp(date: Date, relative: boolean = false) {
  const now = new Date()

  let format = 'MMM D YYYY, h:mma'
  if (relative && date.getFullYear() === now.getFullYear()) {
    if (date.getMonth() === now.getMonth() && date.getDate() === now.getDate()) {
      format = 'h:mma'
    } else {
      format = 'MMM D, h:mma'
    }
  }
  return moment(date).format(format)
}

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8)
    return v.toString(16)
  })
}

const removeDuplicates = (arr: any, key: string) => {
  return arr.filter((v: any, i: any, a: any) => a.findIndex((t: any) => (t[key] === v[key])) === i)
}

const isIOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export { classNames, useQuery, formatDate, formatTimestamp, uuidv4, removeDuplicates, isIOS }
