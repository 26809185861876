import { IJob } from '@/services/jobs.service'
import { BriefcaseIcon, LocationMarkerIcon, ClockIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

const JobInner = (job: IJob) => {
  return (
    <div className="px-4 py-4 flex items-center sm:px-6">
      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="truncate">
          <div className="flex text-sm items-center mb-4">
            <p className="font-bold md:text-base text-teal-600 truncate">{job.published_version?.title}</p>
            {/*<div className="ml-2 flex-shirnk-0 flex">
              <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-sky-100 text-sky-800">Badge</p>
            </div>*/}
          </div>
          <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                <strong>{job.company?.name} {job.published_version?.role ? `/${job.published_version?.role.title}` : ''}</strong>
              </p>
            </div>
          </div>
          {(job.published_version?.state || job.published_version?.city) && <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                {job.published_version.state?.title}{(job.published_version.state && job.published_version.city) && ', '}{job.published_version.city?.title}
              </p>
            </div>
          </div>}
          {job.published_version?.work_type && <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                {job.published_version.work_type.title}
              </p>
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}


const Job = ({ object, link = false, from = null }: any) => {
  const fromLink = from === null ? '/jobs/' : from
  return (
    <li>
      {link ?
        <Link to={{ pathname: `/jobs/${object.id}`, state: { from: fromLink } }} className="block hover:bg-teal-50">
          <JobInner {...object} />
        </Link>
        :
        <JobInner {...object} />
      }
    </li>
  )
}

export default Job
