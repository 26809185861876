import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { accountService } from '../../services'
import { toast } from 'react-toastify'

import { useSetRecoilState } from 'recoil'
import { isLoggedIn, userState } from '../../recoil/atoms/auth'
import { unreadMessageCount } from '../../recoil/atoms/chat'

export default function Logout() {
    const setLoggedIn = useSetRecoilState(isLoggedIn)
    const setUserState = useSetRecoilState(userState)
    const setUnreadMessageCount = useSetRecoilState(unreadMessageCount)

    useEffect(() => {
        accountService.logout()
        setLoggedIn(false)
        setUserState(null)
        setUnreadMessageCount(0)

        toast.dark(`Logged out...`)             
    }, [setLoggedIn, setUserState, setUnreadMessageCount])
    return (
        <Redirect to="/login" />
    )
}