import { classNames } from "../../lib/helpers"

export interface IHeadingsH2 {
  heading: string | undefined
  className?: string
}

const HeadingsH2 = ({
  heading,
  className = '',
}: IHeadingsH2) => {
  return (
    <h2
      className={classNames(
        className,
       'font-bold text-xl text-teal-900'
      )}
    >
      {heading}
    </h2>
  )
}

export default HeadingsH2
