import axios from 'axios'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ChevronLeftIcon } from '@heroicons/react/solid'

import { companyService, serviceType, companyPublicJobsService, NoteTables, trackingService } from '../services'

import { Layout } from '../components/layout'
import { LoadingInside } from '../components/layout/top_level/Loading'
import Job from '../components/jobs/Job'
import CompanyFiles from '../components/files/CompanyFiles'
import HeadingsH2 from '../components/headings/HeadingsH2'
import NotesTab from '../components/notes/NotesTab'

import InfiniteList from '../components/layout/list/InfiniteList'
import BaseTabs from '../components/layout/navigation/BaseTabs'
import { valueOrNA } from '../components/utils'

const CompanyInfo = ({ company }: any) => {
  return (
    <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pb-4">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Industry</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(company.industry?.title)}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-teal-600">Contact</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(company.contact)}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-teal-600">Description</dt>
          <dd className="mt-1 text-sm text-gray-900">{valueOrNA(company.description)}</dd>
        </div>
      </dl>
    </div>
  )
}

const OpenJobs = ({ companyId }: any) => {
  return (
    <>
      <InfiniteList
        service={companyPublicJobsService}
        onServiceError={toast.error}
        serviceParams={{ id: companyId }}
        itemComponent={Job}
        itemsProps={{ link: true, from: `/companies/${companyId}/` }}
      />
    </>
  )
}

const CompanyTabs = ({ company }: any) => {
  const tabs = [
    { slug: 'info', name: 'Company Info' },
    { slug: 'files', name: 'Files' },
    { slug: 'jobs', name: 'Open Jobs' },
    { slug: 'notes', name: 'Notes' }
  ]
  const [activeTab, setActiveTab] = useState('info')

  return (
    <>
      <BaseTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        extraButtonClass={'px-4 sm:px-6 lg:px-8 mt-4'}
      />
      {activeTab === 'info' && <CompanyInfo company={company} />}
      {activeTab === 'files' && <CompanyFiles company={company} />}
      {activeTab === 'jobs' && <OpenJobs companyId={company?.id} />}
      {activeTab === 'notes' && <NotesTab noteTable={NoteTables.Company} object={company} />}
    </>
  )
}


const Company = () => {
  let { id } = useParams<{ id: string }>()
  const [company, setCompany] = useState<any>({})
  const [loading, setLoading] = useState(true)

  const location = useLocation<any>()
  const history = useHistory()
  const hasBack = (location?.state?.from !== undefined)

  const goBack = () => {
    // we migh later use location?.state?.from -
    // now we just check if we can go back, if 1st page load, there is no back
    history.goBack()
  }

  useEffect(() => {
    const source = axios.CancelToken.source()
    companyService.detail(id, source.token).then((result: serviceType.IServiceResult) => {
      if (result.success) {
        setCompany(result.data)
        setLoading(false)
        trackingService.trackCompanyView(parseInt(id)).catch((error) => console.error(error))
      }
    }).catch((error: any) => {
      console.error(error)
      setLoading(false)
    })
    return () => {
      source.cancel()
    }
  }, [id, setCompany])

  return (
    <Layout title="Home" auth={true} back={true}>
      {!loading ?
        <div className="bg-white px-4 py-2">
          {hasBack &&
            <nav className="hidden lg:block flex items-start mb-2 py-4 lg:py-2" aria-label="Breadcrumb">
              <button
                onClick={goBack}
                className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
              >
                <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-600" aria-hidden="true" />
                <span>Back</span>
              </button>
            </nav>
          }

          <div className="flex otems-center content-center justify-between pt-4 lg:pt-0 mb-2">
            <div>
              <HeadingsH2 heading={company.name} />
              <small>{company.state?.title} - {company.city?.title}</small>
            </div>
          </div>
          <CompanyTabs company={company} />
        </div>
        :
        <LoadingInside />
      }
    </Layout>
  )
}

export default Company
