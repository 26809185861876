import { useState } from 'react'

import { Layout } from '@/components/layout'
import HeadingsH2 from '@/components/headings/HeadingsH2'
import BaseTabs from '@/components/layout/navigation/BaseTabs'

import TimeToFillReport from '@/components/reports/TimeToFillReport'
import NotesReport from '@/components/reports/NotesReport'
// import JobCandidatesReport from '@/components/reports/JobCandidatesReport'
import JobCandidateNumbersReport from '@/components/reports/JobCandidateNumbersReport'


const Reports = () => {
  const tabs = [
    { slug: 'job_candidate_stats', name: 'Job Candidate Stats' },
    { slug: 'time_to_fill', name: 'Time to Fill' },
    { slug: 'notes', name: 'Notes' },
    // { slug: 'job_candidates', name: 'Job Candidates' },
  ]
  const [activeTab, setActiveTab] = useState('job_candidate_stats')

  return (
    <Layout title="Reports" auth={true} back={true}>
      <HeadingsH2 heading="Reports" />
      <BaseTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        extraButtonClass={'px-4 sm:px-6 lg:px-8 mt-4'}
      />
      {activeTab === 'job_candidate_stats' && <JobCandidateNumbersReport />}
      {activeTab === 'time_to_fill' && <TimeToFillReport />}
      {activeTab === 'notes' && <NotesReport />}
      {/* {activeTab === 'job_candidates' && <JobCandidatesReport />} */}
    </Layout>
  )
}

export default Reports
