import { fetch, post } from './base.service'
import { IServicePaginatedListResult, IListRetrieveService, _notImplemented, IServiceResult } from './types'
import * as serverConfig from './server-config'

async function list(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false
): Promise<IServicePaginatedListResult> {
  const url = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.messaging.fetchMessages}${urlOrQueryString}`
  return await fetch(url, cancelToken)
}

async function recentUsers(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false
): Promise<IServicePaginatedListResult> {
  const url = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.messaging.recentUsers}${urlOrQueryString}`
  return await fetch(url, cancelToken)
}

async function broadcastMessage(message: string, recipients: number[]): Promise<IServiceResult> {
  const url = `${serverConfig.serverURL}${serverConfig.routes.messaging.broadcast}`
  return await post(url, { message, recipients })
}

export const messagingService = {
  list,
  broadcastMessage
}

export const messagingRecentUsersService: IListRetrieveService = {
  list: recentUsers,
  detail: _notImplemented
}