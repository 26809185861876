import { Link, useLocation } from 'react-router-dom'

import { useRecoilValue } from 'recoil'
import { userState } from '@/recoil/atoms/auth'
import { IUser } from '@/recoil/types'

import { classNames } from '@/lib/helpers'
import { routes } from '@/router/Router'

import ChatBadge from '@/components/chat/ChatBadge'


export default function Sidebar({ colClasses }: any) {
  const location = useLocation<any>()
  const user = useRecoilValue(userState) as unknown as IUser

  const navigation = routes.filter((route) => {
    return (
      route.mainMenu
      && (!route.permission || route.permission(user))
    )
  }).map((route) => {
    return {
      name: route.name,
      path: route.path,
      current: location.pathname === route.path,
      icon: route.icon,
      chat: route.chat
    }
  })

  // const subMenu = [
  //   { name: 'Business Account', href: '#' },
  //   { name: 'Terms & Conditions', href: '#' },
  //   { name: 'Support', href: '#' },
  //   { name: 'Contact', href: '#' },
  // ]
  const wrapperClassNames = classNames(
    colClasses,
    'hidden lg:block '
  )

  return (
    <div className={wrapperClassNames}>
      <nav aria-label="Sidebar" className="sticky top-4 divide-y divide-gray-200">
        <div className="pb-8 space-y-1">
          {navigation.map((item: any) => (
            <Link
              key={item.name}
              to={item.path}
              className={classNames(
                item.current ? 'bg-teal-50 text-teal-600 border-l-4 border-teal-600' : 'text-gray-500 hover:bg-teal-50 border border-transparent',
                'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
              )}
              aria-current={item.current ? 'page' : undefined}
            >
              {item.icon && <item.icon
                className={classNames(
                  item.current ? 'text-teal-600' : 'text-gray-500 group-hover:text-gray-500',
                  'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                )}
                aria-hidden="true"
              />}
              <span className="truncate">
                {item.name}
                {item.chat && <ChatBadge />}
              </span>
            </Link>
          ))}
        </div>
{/*        <div className="pt-10">
          <p
            className="px-3 text-xs font-semibold text-sky-800 uppercase tracking-wider"
            id="communities-headline"
          >
            Useful Links
          </p>
          <div className="mt-3 space-y-2" aria-labelledby="communities-headline">
            {subMenu.map((subMenuItem: any) => (
              <a
                key={subMenuItem.name}
                href={subMenuItem.href}
                className="group flex items-center px-3 py-2 text-sm font-medium text-gray-500 rounded-md hover:text-sky-800 hover:bg-sky-50"
              >
                <span className="truncate">{subMenuItem.name}</span>
              </a>
            ))}
          </div>
        </div>*/}
      </nav>
    </div>

  )
}
