import { useRef } from "react"
import { toast } from "react-toastify"
import { accountService } from "../../../services"
import { Person as PersonListItem } from '../../../components/people/Person'
import { accountConnectionsService } from "../../../services/account.service"
import PaginatedList, { withSearchAndFilter } from "../../layout/list/PaginatedList"
import { useSetRecoilState } from "recoil"
import { userState } from "@/recoil/atoms/auth"

const PaginatedListWithSearchAndFilter = withSearchAndFilter(PaginatedList)

const Connections = () => {
  const setUserData = useSetRecoilState<any>(userState)
  const connectionsListRef = useRef<any>()

  const disconnectFrom = (personId: number) => {
    accountService.deleteConnection(personId).then((result) => {
      if (result.success) {
        connectionsListRef?.current?.fetchItems()
        accountService.profile().then((res) => setUserData(res.data))
        toast.warning('You are now disconnected')
      }
    }).catch((error) => {
      if (error.code === 409) {
        accountService.profile().then((res) => setUserData(res.data))
        toast.info('You are already disconnected from this person')
      } else {
        toast.error('Failed to disconnect from this person')
      }
    })
  }

  const connectionStatus = (connection: any) => {
    switch (connection.status) {
      case 'accepted': return 'connected'
      case 'declined': return 'disconnected'
      case 'pending': return 'pending'
      default: return 'disconnected'    
    }
  }

  const itemProps = {
    link: false,
    onDisconnetClick: disconnectFrom,
    connectionStatus
  }

  return (
    <PaginatedListWithSearchAndFilter
      service={accountConnectionsService}
      ref={connectionsListRef}
      onServiceError={toast.error}
      pageSize={10}
      itemComponent={PersonListItem}
      itemsProps={itemProps}
      colsInFilter={4}
    />
  )
}

export default Connections