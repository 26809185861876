import { MouseEventHandler, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { AccountRoles } from '@/services'
import { IUser } from '@/recoil/types'

import RedButton from '@/components/buttons/RedButton'
import BlueButton from '@/components/buttons/BlueButton'

import { FormatedDate } from '@/components/utils'
import Confirm from '@/components/modals/Confirm'
import { AdjustmentsIcon, ExternalLinkIcon } from '@heroicons/react/solid'
import { CalendarIcon } from '@heroicons/react/outline'

import { AddToCalendarButton } from 'add-to-calendar-button-react';

interface INoteInnerProps {
  object: any,
  setIsConfirmDeleteOpen: (value: boolean) => void,
  onUpdateClick: MouseEventHandler<HTMLButtonElement> | null,
  relatedTitle: string | null,
  showDeleteButton: boolean,
}

const NoteInner = ({
  object,
  setIsConfirmDeleteOpen,
  onUpdateClick,
  relatedTitle,
  showDeleteButton,
}: INoteInnerProps) => {

  // Create a YYYY-MM-DD formatted date string from the utc date string in object.datetime
  const startDate = useMemo(() => {
    if (object.datetime) {
      const date = new Date(object.datetime)
      return date.toISOString().slice(0, 10)
    }
    return undefined
  }, [object.datetime]);

  const endDate = useMemo(() => {
    if (object.datetime) {
      const date = new Date(object.datetime)
      date.setHours(date.getHours() + 1)
      return date.toISOString().slice(0, 10)
    }
    return undefined
  }, [object.datetime]);

  const startTime = useMemo(() => {
    if (object.datetime) {
      const date = new Date(object.datetime)
      return date.toISOString().slice(11, 16)
    }
    return undefined
  },  [object.datetime]);

  const endTime = useMemo(() => {
    if (object.datetime) {
      const date = new Date(object.datetime)
      date.setHours(date.getHours() + 1)
      return date.toISOString().slice(11, 16)
    }
    return undefined
  }, [object.datetime]);

  return (
    <div>
      <div className="px-4 py-4 flex items-center sm:px-6">
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between truncate">
          <div className="text-sm text-gray-500 font-medium">
            <p className="mb-1 font-semibold text-gray-700">{object.text}</p>
            {object.type && <p className="flex gap-1 items-center">
              <AdjustmentsIcon className="w-3 h-3" aria-hidden="true" />
              <span>{object.type}</span>
            </p>}
            {relatedTitle && <p className="flex gap-1 items-center">
              <ExternalLinkIcon className="w-3 h-3" aria-hidden="true" />
              <span className="truncate">{relatedTitle}</span>  
            </p>}
            {object.datetime && <p className="flex gap-1 items-center">
              <CalendarIcon className="w-3 h-3" aria-hidden="true" />
              <span><FormatedDate date={new Date(object.datetime)} /></span>
            </p>}
            {object.created_by?.person && <p className="text-xs mt-1">Created by {object.created_by.person.first_name} {object.created_by.person.last_name} on <FormatedDate date={new Date(object.created)} /></p>}
          </div>
        </div>
        <div className="ml-2 sm:ml-0 flex flex-col">
          {onUpdateClick &&
            <BlueButton
              title="Update"
              type="button"
              className="mb-2"
              onClick={() => onUpdateClick(object.id)}
            />
          }
          {showDeleteButton &&
            <RedButton
              title="Delete"
              onClick={() => setIsConfirmDeleteOpen(true)}
            />
          }
        </div>
      </div>
      <div className={`px-4 py-2`}>
        <AddToCalendarButton
          listStyle='modal'
          name={object.text}
          options={['Apple','Google', 'Microsoft365']}
          startDate={startDate}
          endDate={endDate}
          startTime={startTime}
          endTime={endTime}
          timeZone="America/Chicago"
          hideCheckmark
          size="1"
        ></AddToCalendarButton>
      </div>
    </div>
  )
}

interface INoteProps {
  object: any,
  showLink?: boolean,
  onDeleteConfirm: Function,
  onUpdateClick: MouseEventHandler<HTMLButtonElement> | null,
  showDeleteButton?: boolean,
  showRelated?: boolean,
  userData?: IUser | null,
}

const Note = ({
  object,
  showLink = false,
  onDeleteConfirm,
  onUpdateClick = null,
  showDeleteButton = true,
  showRelated = false,
  userData = null
}: INoteProps) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  
  let relatedTitle = null
  let link: any = null

  if (object.job_candidate) {
    link = `/job-candidate/${object.job_candidate?.id}`
    relatedTitle = `Job Candidate: ${object.job_candidate?.candidate ? object.job_candidate.candidate.first_name + ' ' + object.job_candidate.candidate.last_name : object.job_candidate.candidate_name} for ${object.job_candidate?.job?.published_version?.title ?? object.job_candidate?.job?.latest_version?.title}`
  }
  else if (object.job) {
    link = `/jobs/${object.job.id}`
    relatedTitle = `Job: ${object.job.published_version?.title}`
  }
  else if (object.company) {
    link = `/companies/${object.company.id}` 
    relatedTitle = `Company: ${object.company.name}`
  }
  else if (object.person) {
    link = `/people/${object.person.id}`
    relatedTitle = `Person: ${object.person.first_name} ${object.person.last_name}`
  }

  if (!showRelated) relatedTitle = null
  if (!showLink) link = null

  const _showDeleteButton =  (
    showDeleteButton
    && userData !== null
    && (AccountRoles.canManageCompanyNotes(userData.role) || (object.created_by?.id === userData.id))
  )

  return (
    <>
      <Confirm
        title={`Are you sure to delete the note?`}
        onConfirm={() => onDeleteConfirm(object.id)}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      />
      <li>
        {link ?
          <Link to={{ pathname: link }} className="block hover:bg-gray-50">
            <NoteInner
              object={object}
              setIsConfirmDeleteOpen={setIsConfirmOpen}
              onUpdateClick={onUpdateClick}
              relatedTitle={relatedTitle}
              showDeleteButton={_showDeleteButton}
            />
          </Link>
          :
          <NoteInner
            object={object}
            setIsConfirmDeleteOpen={setIsConfirmOpen}
            onUpdateClick={onUpdateClick}
            relatedTitle={relatedTitle}
            showDeleteButton={_showDeleteButton}
          />
        }
      </li>
    </>
  )
}

export default Note
