import { Formik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import BlueButton from '../../../buttons/BlueButton'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

import { useRecoilValue } from 'recoil'
import { commonTables } from '../../../../recoil/atoms/common'
import { commonHelpers } from '../../../../services/common.service'

import { CityField } from '../../person/forms/JobPreferencesForm'
import { SelectField, TextInputField } from '../../../forms/fields'

const PreviousJobFields = ({ values, errors, touched, handleChange, handleBlur, common, updateFromDate, updateToDate, toDate, fromDate, setFieldValue }: any) => {
  const commonFieldProps = { values, errors, touched, handleBlur, handleChange }
  return (
    <div className="space-y-8 sm:space-y-5">
      <TextInputField
        label="Job Title"
        fieldName="title"
        required={true}
        {...commonFieldProps}
      />
      <TextInputField
        label="Job Description"
        fieldName="description"
        required={false}
        {...commonFieldProps}
      />
      <TextInputField
        label="Company Name"
        fieldName="company"
        required={true}
        {...commonFieldProps}
      />
      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="from_date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            From Date
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <DatePicker
              selected={fromDate}
              onChange={(date: any) => updateFromDate(date, setFieldValue)}
              id="from_date"
              name=""
            />
          </div>
          {errors.from_date && touched.from_date && errors.from_date}
        </div>
      </div>
      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label htmlFor="to_date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            To Date
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <DatePicker
              selected={toDate}
              onChange={(date: any) => updateToDate(date, setFieldValue)}
              id="to_date"
              name=""
            />
          </div>
          {errors.to_date && touched.to_date && errors.to_date}
        </div>
      </div>
      <SelectField
        fieldName="industry"
        label="Industry"
        options={commonHelpers.industryDictionaryToArray(common?.industries)}
        {...commonFieldProps}
      />
      <SelectField
        fieldName="role"
        label="Role"
        disabled={!values.industry}
        options={commonHelpers.rolesForIndustryByKey(common?.industries, values.industry)}
        {...commonFieldProps}
      />
      <SelectField
        fieldName="state"
        label="State"
        options={common?.states}
        {...commonFieldProps}
      />
      <CityField
        {...commonFieldProps}
      />
      <SelectField
        fieldName="work_type"
        label="Work Type"
        options={common?.work_types}
        {...commonFieldProps}
      />
      <SelectField
        fieldName="work_env"
        label="Work Environment"
        options={common?.work_environments}
        {...commonFieldProps}
      />
    </div>
  )
}

// any validation
const JobInfoSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required'),
  company: Yup.string()
    .required('Required'),
  from_date: Yup.string()
    .required('Required'),
})

const PreviousJobForm = ({ handleSubmit }: any) => {
  // const [userData, setUserData] = useRecoilState<any>(userState)
  const [toDate, setToDate] = useState<any>(null)
  const [fromDate, setFromDate] = useState(new Date())

  const common = useRecoilValue<any>(commonTables)


  const updateFromDate = (date: Date, setFieldValue: Function) => {
    setFromDate(date)
    setFieldValue('from_date', date.toISOString().split("T")[0])
  }
  const updateToDate = (date: Date, setFieldValue: Function) => {
    setToDate(date)
    setFieldValue('to_date', date?.toISOString().split("T")[0] || '')
  }

  return (
    <Formik
      initialValues={{
        // personal
        from_date: fromDate.toISOString().split("T")[0],
        to_date: toDate?.toISOString().split("T")[0] || '',
        title: '',
        company: '',
        country: commonHelpers.defaultCountryID(common),
        state: '',
        city: '',
        industry: '',
        role: '',
        work_type: '',
        work_env: ''
      }}
      validationSchema={JobInfoSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <PreviousJobFields
            {...{
              values, errors, touched, handleChange, handleBlur, handleSubmit,
              common, updateFromDate, updateToDate, toDate, fromDate, setFieldValue
            }}
          />
          <BlueButton
            disabled={!isValid || !dirty || isSubmitting}
            type="submit"
            title="Save"
            className="mt-5 w-full"
          />
        </form>
      )
      }
    </Formik >
  )
}

export default PreviousJobForm
