import WeeklyStats from './WeeklyStats'
import LastViewedJobs from './LastViewedJobs'
// import Applications from './Applications'
import HeadingsH3 from '../../../components/headings/HeadingsH3'


interface IPersonDashboardProps {
  person: any
}

const Dashboard = ({ person }: IPersonDashboardProps) => {
  return (
    <>
      <WeeklyStats />
      {/*<div className="space-y-2">
        <HeadingsH3 heading="My Recent Applications"/>
        <Applications />
      </div>*/}
      <div className="space-y-2">
        <HeadingsH3 heading="Last Viewed Jobs"/>
        <LastViewedJobs />
      </div>
    </>
  )
}

export default Dashboard
