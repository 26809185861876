import { classNames } from "../../lib/helpers"
import { ButtonStyles } from "./styles"

export interface IBaseButtonProps {
  title: string
  showTitle?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
  ref?: any
  children?: any
}

const BaseButton = ({
  onClick,
  title,
  showTitle=true,
  className = '',
  type = 'button',
  disabled = false,
  ref = null,
  children = null
}: IBaseButtonProps) => {

  const onClickWrapper = (e: any) => {
    if (onClick) {
      e.preventDefault()
      return onClick(e)
    }
  }

  return (
    <button
      type={type}
      onClick={onClickWrapper}
      ref={ref}
      disabled={disabled}
      className={classNames(
        className,
        ButtonStyles.Base,
        disabled ? 'pointer-events-none' : ''
      )}
    >
      {/* children and/or title */}
      {children && children }
      {showTitle && title}
    </button>
  )
}

export default BaseButton
