import { XIcon } from "@heroicons/react/outline"
import { useState } from "react"
import IconButton from "../buttons/IconButton"
import ImageUploadField from "./fields/ImageUploadField"


const ImageUploadForm = ({ onSubmit, submitOnChange = false, isSubmitting = false, setIsEditing, children }: any) => {
  const [imageFile, setImageFile] = useState<any>()
  const submit = (e: any) => {
    e.preventDefault()
    onSubmit(imageFile)
  }
  const _setImageFile = (image: any) => {
    setImageFile(image)
    if (submitOnChange) {
      onSubmit(image)
    }
  }
  return (
    <form onSubmit={submit} className="mb-2">
      <IconButton
        IconComponent={XIcon}
        className="relative top-10 -right-12"
        disabled={isSubmitting}
        onClick={() => setIsEditing(false)}
      />
      <ImageUploadField
        setImageFile={_setImageFile}
        isSubmitting={isSubmitting}
        hidden={true}
        labelClassName="p-1 gray-600 bg-transparent"
      />
      {children}
    </form>
  )
}

export default ImageUploadForm
