
import ConnectionItem from './ConnectionItem'

const ConnectionsList = ({objects}: any) => {
  return (
    <ul className="inline-flex space-x-4 my-1 mx-4">
      {objects.filter((person: any) => person.status !== 'disconnected').map((person: any) => (
        <ConnectionItem key={person.id} person={person} link={true}/>
      ))}
    </ul>
  )
}

export default ConnectionsList