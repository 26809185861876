import { fetch } from './base.service'
import * as serverConfig from './server-config'
import { IServicePaginatedListResult, IListRetrieveService, _notImplemented } from './types'



async function listRecommendedConnections(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false
): Promise<IServicePaginatedListResult> {
  /*
  Either pass full URL (e.g. next from DRF) or queryString when applying filter.
  */
  const url = isUrl ? urlOrQueryString : `${serverConfig.serverURL}${serverConfig.routes.profile.recommendedConnections}${urlOrQueryString}`
  return await fetch(url, cancelToken)
}

async function recommendedJobs(cancelToken: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.recommendedJobs}`
  return await fetch(url, cancelToken)
}

async function recommendedConnections(cancelToken: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.recommendedConnections}`
  return await fetch(url, cancelToken)
}

export const homeServiceRecommendedJobs: IListRetrieveService = {
  list: listRecommendedConnections,
  detail: _notImplemented
}

export const homeServiceRecommendedConnections: IListRetrieveService = {
  list: listRecommendedConnections,
  detail: _notImplemented
}

export const homeService = {
  recommendedJobs,
  recommendedConnections
}