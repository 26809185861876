// base libs
// react stuff
import React, { FunctionComponent, useRef, useState } from 'react'
// services
import { IListCRUDService, IServiceResult } from '@/services/types'
// components
import SlideOver from '@/components/slideover/SlideOver'
import TealButton from '@/components/buttons/TealButton'
import PaginatedList from './PaginatedList'


interface IPaginatedCRUDListProps {
  service: IListCRUDService
  serviceParams?: any
  onServiceError?: Function | null
  filter?: any
  itemComponent: FunctionComponent<any>
  itemsProps?: any
  formComponent: FunctionComponent<any>
  ModalComponent?: FunctionComponent<any>
  object: any
  addButtonTitle: string | null
  hasUpdate?: boolean,
  getUpdateTitle?: ((object: any) => string) | null
  formAlterValues?: Function | null
  formSuccessAction?: Function | null
  onUpdateSuccess?: Function | null
  onUpdateError?: Function | null
  onUpdateReload?: boolean
  children?: any
  noResultsText?: string
  pageSize?: number
}

const PaginatedCRUDList = ({
  service,
  serviceParams = {},
  onServiceError = null,
  filter = {},
  itemComponent,
  itemsProps,
  formComponent,
  object,
  addButtonTitle = null,
  formAlterValues,
  formSuccessAction,
  hasUpdate = false,
  getUpdateTitle = null,
  ModalComponent = SlideOver,
  onUpdateSuccess = null,
  onUpdateError = null,
  onUpdateReload = true,
  children = null,
  noResultsText = 'No Results',
  pageSize = 10
}: IPaginatedCRUDListProps) => {
  const [items, setItems] = useState<any>([])
  // create state
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  // update state
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false)
  const [objectDetail, setObjectDetail] = useState<any>(null)

  const infiniteListRef = useRef<any>()

  const [loading, setLoading] = useState(false)

  // handle create acttion
  const handleSubmit = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    if (formAlterValues) {
      values = formAlterValues(values)
    }
    service.create(values, object.id).then((result: any) => {
      if (result.success) {
        if (formSuccessAction) formSuccessAction(values, result)
        // if (addToList) {
        //   setItems([result.data].concat(items))
        // }
        infiniteListRef?.current?.fetchItems()
      }
      setSubmitting(false)
      setIsDialogOpen(false)
    }).catch((error: any) => {
      if (onServiceError) {
        onServiceError(`${error}`)
      }
      setSubmitting(false)
    })
  }

  // fetch object detail
  const fetchObjectDetail = (objectId: number) => {
    setLoading(true)
    service.detail(objectId, object.id).then(({ success, data }: IServiceResult) => {
      if (success) {
        setObjectDetail(data)
      }
      setLoading(false)
    }).catch((error: any) => {
      console.error(error)
      setLoading(false)
    })
  }

  const fetchDetailAndOpenUpdateDialog = (objectId: number) => {
    fetchObjectDetail(objectId)
    setIsUpdateDialogOpen(true)
  }

  // handle update action
  const handleUpdate = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    if (!hasUpdate) return
    if (formAlterValues) {
      values = formAlterValues(values)
    }
    service.update(objectDetail.id, object.id, values).then((result: IServiceResult) => {
      if (result.success) {
        setSubmitting(false)
        setIsUpdateDialogOpen(false)
        if (onUpdateSuccess) onUpdateSuccess()
        // if we got the ref on child infinite list, reload list on update by default
        if (onUpdateReload) infiniteListRef?.current?.fetchItems()
      }
    }).catch((error: any) => {
      if (onUpdateError) onUpdateError()
      setSubmitting(false)
    })
  }

  const deleteItem = (deleteId: number) => {
    service.delete(deleteId, object.id).then((result: any) => {
      if (result.success) {
        // const newItems = items.filter((itm: any) => itm.id !== deleteId)
        // setItems(newItems)
        infiniteListRef?.current?.fetchItems()
      }
    }).catch((error: any) => {
      console.error(error)
    })
  }

  return (
    <>
      {addButtonTitle && <ModalComponent
        title={addButtonTitle}
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        hasForm={true}
      >
        {
          React.createElement(formComponent, {
            key: object.id,
            object: object,
            handleSubmit: handleSubmit
          })
        }
      </ModalComponent>}

      {hasUpdate && <ModalComponent
        title={getUpdateTitle != null && (loading ? 'Loading...' : getUpdateTitle(objectDetail))}
        open={isUpdateDialogOpen}
        setOpen={setIsUpdateDialogOpen}
        hasForm={true}
        loading={loading}
      >
        {
          React.createElement(formComponent, {
            key: objectDetail?.id,
            object: objectDetail,
            initial: objectDetail,
            handleSubmit: handleUpdate
          })
        }
      </ModalComponent>}

      {addButtonTitle && <div className="py-4">
        <TealButton
          title={addButtonTitle}
          className=""
          onClick={() => setIsDialogOpen(true)}
        />
      </div>}

      <PaginatedList
        service={service}
        ref={infiniteListRef}
        onServiceError={onServiceError}
        filter={filter}
        itemComponent={itemComponent}
        itemsProps={{
          link: false,
          onDeleteConfirm: deleteItem,
          onUpdateClick: (hasUpdate ? fetchDetailAndOpenUpdateDialog : null),
          ...itemsProps
        }}
        serviceParams={{ ...serviceParams, id: object.id }}
        parentItems={items}
        setParentItems={setItems}
        children={children}
        noResultsText={noResultsText}
        pageSize={pageSize}
      />
    </>
  )
}

export default PaginatedCRUDList
