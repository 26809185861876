import { atom, selector } from 'recoil'
import { INotification } from '../types';

export const allNotifications = atom({
  key: 'allNotifications',
  default: [] as INotification[]
})

export const pendingNotifications = atom({
  key: 'pendingNotifications',
  default: [] as INotification[]
})

export const unseenNotifications = selector({
  key: 'unseenNotifications',
  get: ({get}) => get(pendingNotifications).filter((notif) => !notif.has_seen),
})
