import { IJob } from '@/services/jobs.service'
import JobItem from './JobItem'

const JobsList = ({objects}:any) => {
  return (
    <ul className="inline-flex space-x-4 my-1 mx-4 items-stretch">
      {objects.map((job: IJob) => (
        <JobItem key={job.id} job={job} link={true}/>
      ))}
    </ul>
  )
}
export default JobsList
