import { useEffect, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'
import { CookiesProvider } from 'react-cookie'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import './App.css'

import AppRouter from './router/Router'

import { useSetRecoilState, useRecoilValue } from 'recoil'
import { commonTables } from './recoil/atoms/common'
import { isLoggedIn } from './recoil/atoms/auth'

import { commonService } from './services'

import Loading from './components/layout/top_level/Loading'
import GlobalError from './components/errors'
import ChatSocket from './components/chat/ChatSocket'
import NotificationListener from './components/notifications/NotificationListener'
import SubscriptionSuccessListener from './components/payment/SubscriptionSuccessListener'
import CookieConsent from './components/global/CookieConsent'
import ReactGA from "react-ga4";

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';
const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? '';

ReactGA.initialize(googleAnalyticsId);
function App() {
  const [loading, setLoading] = useState(true)
  const setCommon = useSetRecoilState<any>(commonTables)
  const loggedIn = useRecoilValue(isLoggedIn)
  const [error, setError] = useState(null)
  useEffect(() => {
    commonService.onboarding().then((result) => {
      setCommon(result.data)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      setError(error)
    })
  }, [setCommon, loggedIn])

  return (
    <>
      {!loading ?
        error ? <GlobalError />
        :
        <CookiesProvider>
          <HelmetProvider>
            <GoogleOAuthProvider clientId={googleClientId}>
              <ChatSocket />
              <NotificationListener />
              <SubscriptionSuccessListener />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <DndProvider backend={HTML5Backend}>
                <AppRouter />
              </DndProvider>
              <CookieConsent />
            </GoogleOAuthProvider>
          </HelmetProvider>
        </CookiesProvider>
        :
        <Loading />
      }
    </>
  )
}

export default App
