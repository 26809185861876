import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '@/components/buttons/BlueButton'
import { TextInputField } from '@/components/forms/fields'

const ExternalCandidateSchema = Yup.object().shape({
  name: Yup.string().required('The person\'s name is required'),
  email: Yup.string().required('The person\'s email address is required').email('Please enter a valid email address')
})

export default function ExternalCandidateForm({ handleSubmit }: { handleSubmit: any }) {
  return (
    <Formik
      initialValues={{
        name: '',
        email: ''
      }}
      validationSchema={ExternalCandidateSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <TextInputField
            fieldName="name"
            label="Name"
            required={true}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <TextInputField
            fieldName="email"
            label="Email Address"
            required={true}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <BlueButton
              type="submit"
              title={isSubmitting ? "Adding..." : "Add"}
              className="w-full transition duration-150 ease-in-out"
              disabled={!isValid || !dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
