import { classNames } from '@/lib/helpers'
import BaseButton, { IBaseButtonProps } from './BaseButton'
import { ButtonStyles } from './styles'

const WhiteButton = (props: IBaseButtonProps) => {
  return (
    <BaseButton
      {...props}
      className={classNames(
        props.className,
        ButtonStyles.White
      )}
    />
  )
}

export default WhiteButton
