import { TextInputField } from '@/components/forms/fields'
import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '../../buttons/BlueButton'

const CreateRecruitingProcessFormSchema = Yup.object().shape({
  name: Yup.string().required('Pipeline name is required'),
})

const CreateRecruitingProcessForm = ({ handleSubmit }: any) => {
  return (
    <Formik
      initialValues={{
        name: ''
      }}
      validationSchema={CreateRecruitingProcessFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <TextInputField
            fieldName="name"
            label="Name"
            required={true}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <BlueButton
              type="submit"
              title={isSubmitting ? "Creating..." : "Create"}
              className="w-full transition duration-150 ease-in-out"
              disabled={!isValid || !dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

export default CreateRecruitingProcessForm
