import { PlusIcon } from '@heroicons/react/outline'
import { classNames } from '../../lib/helpers'

interface IIconButtonProps {
  IconComponent?: any,
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  disabled?: boolean
  ref?: any
  children?: any
}

const IconButton = ({
  IconComponent = PlusIcon,
  onClick,
  disabled,
  className,
  children,
  ref
}: IIconButtonProps) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      ref={ref}
      className={classNames(
        "p-1 border border-transparent rounded-full shadow-sm text-white",
        "bg-gray-600 hover:bg-gray-700 focus:outline-none",
        className
      )}
    >
      <IconComponent className="h-5 w-5" aria-hidden="true" />
      {children}
    </button>
  )
}

export default IconButton