import { toast } from "react-toastify"
import { useRecoilValue } from "recoil"
import { commonTables } from "@/recoil/atoms/common"
import { commonHelpers, companyJobsService } from "@/services"
import Job from "./jobs/Job"
import JobForm from "./jobs/JobForm"
import PaginatedCRUDList from "@/components/layout/list/PaginatedCRUDList"
import { withSearchAndFilter } from "@/components/layout/list/PaginatedList"

const PaginatedCRUDListWithSearchAndFilter = withSearchAndFilter(PaginatedCRUDList)


const CompanyJobs = ({ company }: any) => {
  const common = useRecoilValue<any>(commonTables)

  const formAlterValues = (values: any) => {
    values = Object.assign({}, values, {
      'industry': commonHelpers.industryIdFromKey(common, values.industry),
      'role': commonHelpers.conditionallyOmitRole(common, values.industry, values.role)
    })

    return values
  }

  return (
    <PaginatedCRUDListWithSearchAndFilter
      service={companyJobsService}
      onServiceError={toast.error}
      object={company}
      itemComponent={Job}
      itemsProps={{ company: company }}
      formComponent={JobForm}
      addButtonTitle={company?.is_paid && "Add New Job"}
      getUpdateTitle={(object: any) => `Update: ${object?.title}`}
      hasUpdate={true}
      formAlterValues={formAlterValues}
      onUpdateSuccess={() => toast.success('Update successful')}
      onUpdateError={() => toast.error('Error updating')}
      colsInFilter={4}
    />
  )
}

export default CompanyJobs
