import { Layout } from '@/components/layout'
import HeadingsH2 from '@/components/headings/HeadingsH2'

import JobRecommendations from '@/components/home/JobRecommendations';

const Opportunities = () => {
  return (
    <Layout title="Opportunities" auth={true} back={true}>
      <HeadingsH2 heading="Opportunities" />
      <div className="mt-4">
        
        <JobRecommendations />
      </div>
    </Layout>
  )
}

export default Opportunities
