import { useState } from 'react'

import { NoteTables } from '@/services'

import { Layout } from '@/components/layout'
import HeadingsH2 from '@/components/headings/HeadingsH2'
import NotesTableTab from '@/components/notes/NotesTableTab'
import BaseTabs from "@/components/layout/navigation/BaseTabs"


const Notes = () => {
  const tabs = [
    { slug: 'jobs', name: 'Jobs' },
    { slug: 'people', name: 'People' },
    { slug: 'companies', name: 'Companies' },
    { slug: 'job_candidates', name: 'Job Candidates' },
  ]
  const [activeTab, setActiveTab] = useState('jobs')

  return (
    <Layout title="Notes" auth={true} back={true}>
      <HeadingsH2 heading="Notes" />
      <BaseTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        extraButtonClass={'px-4 sm:px-6 lg:px-8 mt-4'}
      />
      {activeTab === 'jobs' && <NotesTableTab noteTable={NoteTables.Job} />}
      {activeTab === 'people' && <NotesTableTab noteTable={NoteTables.Person} />}
      {activeTab === 'companies' && <NotesTableTab noteTable={NoteTables.Company} />}
      {activeTab === 'job_candidates' && <NotesTableTab noteTable={NoteTables.JobCandidate} />}
    </Layout>
  )
}

export default Notes
