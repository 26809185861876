import { useRef } from "react"
import { toast } from "react-toastify"
import { jobPendingCandidatesService } from "@/services"

import PaginatedList, { withRecruitSearchAndFilter } from "@/components/layout/list/PaginatedList"
import Recruit from "./recruits/Recruit"

const PaginatedListWithSearchAndFilter = withRecruitSearchAndFilter(PaginatedList)

const Recruits = ({ company }: any) => {
  const toReviewListRef = useRef<any>()

  return (
    <div className="p-4 sm:p-0 sm:mt-4">
      <div className="mb-4 sm:mb-0">
        <h3 className="text-teal-600 font-bold mb-2">Pending Candidates (At Pipeline Start)</h3>
        <PaginatedListWithSearchAndFilter
          service={jobPendingCandidatesService}
          serviceParams={{
            company: company.id
          }}
          ref={toReviewListRef}
          pageSize={10}
          onServiceError={toast.error}
          itemComponent={Recruit}
          itemsProps={{ showCandidatesButton: true }}
          noResultsText="No pending candidates found at the start of a recruiting pipeline"
          collapsableFilter={true}
        />
      </div>
    </div>
  )
}

export default Recruits
