import { useState } from "react"

import Dashboard from './Dashboard'
import JobPreferences from './JobPreferences'
import JobHistory from './JobHistory'
import ProfileInfo from "./ProfileInfo"
import Connections from "./Connections"
import Settings from "./Settings"
import { IUser } from "../../../recoil/types"
import RecruitHistory from "./RecruitHistory"
import BaseTabs from "../../layout/navigation/BaseTabs"
import MyProfileFiles from "../../files/MyProfileFiles"


interface IPersonTabsProps {
  user: IUser
}

const Tabs = ({ user }: IPersonTabsProps) => {
  const person = user.person
  const tabs = [
    { slug: 'dashboard', name: 'Dashboard' },
    { slug: 'profile', name: 'My Profile' },
    { slug: 'preferences', name: 'Job Preferences' },
    { slug: 'profile_files', name: 'Profile Files' },
    { slug: 'history', name: 'Job History' },
    { slug: 'recruit_history', name: 'Recruit History' },
    { slug: 'connections', name: 'Connections' },
    { slug: 'settings', name: 'Settings' },
  ]
  const [activeTab, setActiveTab] = useState('dashboard')

  return (
    <>
      <BaseTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === 'dashboard' && <Dashboard person={person} />}
      {activeTab === 'profile' && <ProfileInfo person={person} />}
      {activeTab === 'preferences' && <JobPreferences person={person} />}
      {activeTab === 'profile_files' && <MyProfileFiles person={person} />}
      {activeTab === 'history' && <JobHistory />}
      {activeTab === 'recruit_history' && <RecruitHistory user={user!} />}
      {activeTab === 'connections' && <Connections />}
      {activeTab === 'settings' && <Settings user={user} />}
    </>
  )
}

export default Tabs
