import axios from 'axios'
import { _delete, fetch, patch, post, postMultipart, put } from './base.service'
import {
  IListCRUDService,
  IListRetrieveService,
  ILooseService,
  IServicePaginatedListResult,
  _notImplemented
} from './types'
import * as serverConfig from './server-config'
import {
  setLocalStorageToken,
  getLocalstorageToken,
  removeLocalstorageItem
} from './localstorage.service'
import { withServiceParamsInUrl } from './utils'

interface IJWTData {
  access?: string
  refresh?: string
  ephemeral_token?: string
  method?: 'email' | 'app' | 'yubi' | 'sms_api' | 'sms_twilio'
  error?: string
}

interface IRegisterData {
  data?: { userId: number }
  error?: string
}

interface IRegisterResult {
  data: IRegisterData,
  success: boolean
}

interface ILoginResult {
  data: IJWTData
  success: boolean
}

interface ILocalLoginCredentials {
  email: string
  password: string
}

interface IRegisterCredentials {
  email: string
  password1: string
  password2: string
}

declare global {
  interface Window { FB: any; }
}

export const AccountRoles = {
  NO_ROLE: 'NONE',
  RECRUITER: 'RE',
  JOB_SEEKER: 'JS',
  INTERNAL_STRATICE: 'ISE',
  COMPANY: 'CO',
  HIRING_MANAGER: 'HM',
  canManageCompanyJobs,
  canManageCompanyProfile,
  canManageCompanyMembers,
  canManageRecruits,
  canPrecheckManageRecruits,
  canManageCompanyNotes
}

function canPrecheckManageRecruits(role: string) {
  return [AccountRoles.INTERNAL_STRATICE].includes(role)
}

function canManageRecruits(role: string) {
  return [AccountRoles.HIRING_MANAGER, AccountRoles.COMPANY, AccountRoles.INTERNAL_STRATICE, AccountRoles.RECRUITER].includes(role)
}

function canManageCompanyJobs(role: string) {
  return [AccountRoles.HIRING_MANAGER, AccountRoles.COMPANY, AccountRoles.INTERNAL_STRATICE, AccountRoles.RECRUITER].includes(role)
}

function canManageCompanyProfile(role: string) {
  return [AccountRoles.COMPANY, AccountRoles.INTERNAL_STRATICE].includes(role)
}

function canManageCompanyNotes(role: string) {
  return [AccountRoles.COMPANY, AccountRoles.INTERNAL_STRATICE].includes(role)
}

function canManageCompanyMembers(role: string) {
  return [AccountRoles.COMPANY].includes(role)
}

async function login(credentials: ILocalLoginCredentials) {
  removeLocalstorageItem('token')
  if (!credentials || !credentials.email || !credentials.password) {
    throw new Error('Missing `email` and `password` fields!')
  }
  const response = await axios.post(
    `${serverConfig.serverURL}${serverConfig.routes.login}`,
    {
      email: credentials.email,
      password: credentials.password
    }
  )
  return completeLogin(response)
}

async function loginCode(ephemeralToken: string, code: string) {
  if (!ephemeralToken || !code) {
    throw new Error('Missing ephemeral token or code')
  }
  const response = await axios.post(
    `${serverConfig.serverURL}${serverConfig.routes.loginCode}`,
    {
      ephemeral_token: ephemeralToken,
      code
    }
  )
  return completeLogin(response)
}

async function refreshToken() {
  const token = getLocalstorageToken()
  if (!token) return { success: false, data: 'No refresh token in local storage' }
  const response = await axios.post(
    `${serverConfig.serverURL}${serverConfig.routes.refreshToken}`,
    {
      refresh: token?.refresh
    }
  )
  if (!response || response.status >= 400) {
    throw (new Error(response.data?.detail))
  } else {
    setLocalStorageToken({
      access: response.data.access,
      refresh: token.refresh,
      expiry: new Date().getTime() + serverConfig.jwtAccessTokenTTL
    })
    return {
      success: true,
      data: response.data
    }
  }
}

async function register(credentials: IRegisterCredentials) {
  removeLocalstorageItem('token')
  if (!credentials || !credentials.email || !credentials.password1 || !credentials.password1) {
    throw new Error('Missing `email` and `password` fields!')
  }
  const response = await axios.post(
    `${serverConfig.serverURL}${serverConfig.routes.register}`,
    credentials
  )
  return completeRegister(response)
}

async function logout() {
  removeLocalstorageItem('token')
  return true
}

function checkToken(): string | null {
  const token = getLocalstorageToken()
  if (!token) {
    return null
  }
  return token.access
}

async function facebookLogin() {
  const { authResponse } = await new Promise<any>((resolve, reject) => {
    window.FB.login((response: any) => {
      resolve(response)
    }, { scope: 'email' })
  });
  if (!authResponse) {
    throw (new Error('Unable to login with FB'));
  }
  const credentials = {
    "access_token": authResponse.accessToken
  }
  const response = await axios.post(
    `${serverConfig.serverURL}${serverConfig.routes.facebookLogin}`,
    credentials
  )
  return completeLogin(response)
}

async function googleLogin(googleResponse: any) {
  if (googleResponse.error) {
    throw (new Error('Unable to login with Google'))
  }
  const credentials = {
    access_token: googleResponse.access_token
  }
  const response = await axios.post(
    `${serverConfig.serverURL}${serverConfig.routes.googleLogin}`,
    credentials
  )
  return completeLogin(response)
}

async function linkedInLogin(cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.linkedInLogin}`
  return await fetch(url, cancelToken)
}

async function completeLinkedInLogin(linkedInCode: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.linkedInLogin}`
  const response = await axios.post(url, {code: linkedInCode})
  return completeLogin(response)
}

const completeRegister = (response: any): IRegisterResult => {
  if (!response || response.status >= 400) {
    throw (new Error(response.data?.detail))
  } else {
    return {
      success: true,
      data: response.data
    }
  }
}

const completeLogin = (response: any): ILoginResult => {
  if (!response || response.status >= 400) {
    throw (new Error(response.data?.error ?? response.data?.detail))
  } else {
    if (response.data.access && response.data.refresh) {
      setLocalStorageToken({
        access: response.data.access,
        refresh: response.data.refresh,
        expiry: new Date().getTime() + serverConfig.jwtAccessTokenTTL
      })
    }

    return {
      success: true,
      data: response.data
    }
  }
}

async function profile(cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.base}`
  return await fetch(url, cancelToken)
}

async function createProfile(values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.createProfile}`
  return await post(url, values)
}

async function updateProfile(id: any, values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.people.updatePerson}`.replace('<person_id>', id)
  return await patch(url, values)
}

async function fetchPreviousJobs(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null = null
): Promise<IServicePaginatedListResult> {
  if (serviceParams) {
    urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, {
      ...serviceParams,
      order_by: 'from_date'
    })
  }
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.previousJobs}${urlOrQueryString}`
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function deletePreviousJob(id: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.previousJobs}${id.toString()}/`
  return await _delete(url)
}

async function addPreviousJob(values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.previousJobs}`
  return await post(url, values)
}

async function myConnections(
  urlOrQueryString: string,
  cancelToken: any,
  isUrl = false,
  serviceParams: Record<string, any> | null = null
) {
  if (serviceParams) {
    urlOrQueryString = withServiceParamsInUrl(urlOrQueryString, serviceParams)
  }
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.myConnections}${urlOrQueryString}`
  const path = isUrl ? urlOrQueryString : url
  return await fetch(path, cancelToken)
}

async function createConnection(personId: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.myConnections}${personId.toString()}/`
  return await put(url)
}

async function deleteConnection(id: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.myConnections}${id.toString()}/`
  return await _delete(url)
}

async function createLink(values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.links}`
  return await post(url, values)
}

async function deleteLink(id: number) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.links}${id.toString()}/`
  return await _delete(url)
}

async function fetchWeeklyStats(cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.weeklyStats}`
  return await fetch(url, cancelToken)
}

async function uploadPicture(file: any) {
  const values = { file }
  const url = `${serverConfig.serverURL}${serverConfig.routes.profile.photo}` 
  return await postMultipart(url, values)
}

async function setPassword(new_password1: string, new_password2: string) {
  const values = { new_password1, new_password2 }
  const url = `${serverConfig.serverURL}${serverConfig.routes.setPassword}`
  return await post(url, values)
}

async function changePassword(email: string, currentPassword: string, password1: string, password2: string) {
  const values = {
    email: email,
    current_password: currentPassword,
    new_password1: password1,
    new_password2: password2
  }
  const url = `${serverConfig.serverURL}${serverConfig.routes.changePassword}`
  return await post(url, values)
}

async function changePasswordWithToken(values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.changePasswordWithToken}`
  return await post(url, values)
}

async function forgottenPassword(values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.forgottenPassword}`
  return await post(url, values)
}

async function getMFAMethods(cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.mfaMethods}`
  return await fetch(url, cancelToken)
}

async function activateMFAMethod(method: 'email' | 'sms_twilio', target: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.activateMfaMethod}`.replace('<method_name>', method)
  return await post(url, {
    email: target,
    phone_number: target
  })
}

async function mfaSetPrimaryMethod(method: 'email' | 'sms_twilio', code: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.mfaSetPrimaryMethod}`
  return await post(url, { method, code })
}

async function confirmMFAMethodActivation(method: 'email' | 'sms_twilio', code: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.confirmMfaMethodActivation}`.replace('<method_name>', method)
  return await post(url, { code })
}

async function deactivateMFAMethod(method: 'email' | 'sms_twilio', code: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.deactivateMfaMethod}`.replace('<method_name>', method)
  return await post(url, { code })
}

async function requestMFACode(method?: 'email' | 'sms_twilio') {
  const url = `${serverConfig.serverURL}${serverConfig.routes.requestMfaCode}`
  return await post(url, { method })
}

async function setMFAPhone(phone: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.mfaPhone}`
  return await put(url, { phone })
}

async function updateOnboardingProgress(values: any) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.onboardingProgress}`
  return await post(url, values)
}

async function getOnboardingProgress(cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.onboardingProgress}`
  return await fetch(url, cancelToken)
}

async function completeOnboarding() {
  const url = `${serverConfig.serverURL}${serverConfig.routes.completeOnboarding}`
  return await post(url)
}

async function queryCompanyName(name: string, cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.queryCompany}?name=${name}`
  return await fetch(url, cancelToken)
}

async function getMFAPartialPhone(cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.mfaPhone}`
  return await fetch(url, cancelToken)
}

async function deleteAccount(cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.deleteAccount}`
  return await fetch(url, cancelToken)
}

async function cancelAccountDeletion(token: string, cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.cancelDeleteAccount}`.replace('<token>', token)
  return await fetch(url, cancelToken)
}

async function requestAccountData(cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.requestAccountData}`
  return await fetch(url, cancelToken)
}

export const accountService = {
  login,
  loginCode,
  linkedInLogin,
  completeLinkedInLogin,
  setPassword,
  changePassword,
  changePasswordWithToken,
  forgottenPassword,
  refreshToken,
  register,
  facebookLogin,
  googleLogin,
  logout,
  profile,
  checkToken,
  createProfile,
  updateProfile,
  deletePreviousJob,
  addPreviousJob,
  myConnections,
  createConnection,
  deleteConnection,
  createLink,
  deleteLink,
  fetchWeeklyStats,
  uploadPicture,
  getMFAMethods,
  activateMFAMethod,
  confirmMFAMethodActivation,
  deactivateMFAMethod,
  requestMFACode,
  setMFAPhone,
  mfaSetPrimaryMethod,
  updateOnboardingProgress,
  getOnboardingProgress,
  completeOnboarding,
  queryCompanyName,
  getMFAPartialPhone,
  deleteAccount,
  cancelAccountDeletion,
  requestAccountData
}

export const accountConnectionsService: IListRetrieveService & ILooseService = {
  list: myConnections,
  detail: _notImplemented
}

export const accountPreviousJobsService: IListCRUDService = {
  list: fetchPreviousJobs,
  detail: _notImplemented,

  create: addPreviousJob,
  delete: deletePreviousJob,
  update: _notImplemented,
}