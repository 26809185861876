import HeadingsH2 from "@/components/headings/HeadingsH2"
import { Layout } from '../components/layout'
import ISERecruits from "@/components/profile/company/ISERecruits"

import { useRecoilValue } from "recoil"
import { userState } from '@/recoil/atoms/auth'

import { AccountRoles } from "@/services"

const ISECandidates = () => {
  const userData = useRecoilValue<any>(userState)

  return (
    <Layout title="ISE Job Candidates" auth={true} back={true}>
      {
        userData
        && AccountRoles.canPrecheckManageRecruits(userData.role)
        && (
          <>
            <HeadingsH2 heading="All Job Candidates" />
            <ISERecruits />
          </>
        )
      }
    </Layout>
  )
}

export default ISECandidates
