import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '@/components/buttons/BlueButton'
import { useEffect, useState } from 'react'
import MultiselectField, { IMultiselectFieldOption } from '@/components/forms/fields/MultiselectField'
import { IConnection } from '@/recoil/types'
import { LoadingInside } from '@/components/layout'
import { TextAreaField } from './fields'
import { useRecoilValue } from 'recoil'
import { userState } from '@/recoil/atoms/auth'

const SendMassChatSchema = Yup.object().shape({
  message: Yup.string().required('A message is required'),
  recipients: Yup.array().min(1, 'At least one recipient is required')
})

export default function SendMassChatForm({ handleSubmit }: { handleSubmit: any }) {
  const [potentialRecipients, setPotentialRecipients] = useState<IMultiselectFieldOption[]>([])
  const [loading, setLoading] = useState(true)
  const userData = useRecoilValue(userState)

  useEffect(() => {
    if (!userData || !userData.person) {
      return
    }

    setPotentialRecipients(userData.person.connections
      .map((entry: IConnection) => {
        return {
          id: entry.user_id.toString(),
          title: `${entry.first_name} ${entry.last_name}`
        }
      })
      .sort((a: IMultiselectFieldOption, b: IMultiselectFieldOption) => {
        return a.title.localeCompare(b.title)
      })
    )
    setLoading(false)
  }, [userData])

  return (
    !loading
      ? <Formik
        initialValues={{
          message: '',
          recipients: []
        }}
        validationSchema={SendMassChatSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="space-y-6">
            <MultiselectField
              fieldName="recipients"
              label="Recipients"
              options={potentialRecipients}
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <TextAreaField
              fieldName="message"
              label="Message"
              {...{ values, errors, touched, handleBlur, handleChange }}
            />
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <BlueButton
                type="submit"
                title={isSubmitting ? "Sending..." : "Send"}
                className="w-full transition duration-150 ease-in-out"
                disabled={!isValid || !dirty || isSubmitting}
              />
            </div>
          </form>
        )}
      </Formik>
      : <LoadingInside />
  )
}
