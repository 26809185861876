import { BriefcaseIcon, LocationMarkerIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

const CompanyInner = (company: any) => {
  return (
    <div className="px-4 py-4 flex items-center sm:px-6">
      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="truncate">
          <div className="flex md:text-base mb-4">
            <p className="font-bold text-teal-600 truncate">{company.name}</p>
          </div>
          <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                <strong>{company.industry}</strong>
              </p>
            </div>
          </div>
          <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                {company.state} - {company.city}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const Company = ({ object, link=false }: any) => {
  return (
    <li>
      {link ?
        <Link to={{pathname: `/companies/${object.id}`, state: {from: '/companies/'}}} className="block hover:bg-teal-50">
          <CompanyInner {...object} />
        </Link>
        :
        <CompanyInner {...object} />
      }
    </li>
  )
}

export default Company
