import { InformationCircleIcon } from '@heroicons/react/solid'
import { Link, useHistory } from 'react-router-dom'

import { IJob, JobStatusReadableMap } from '@/services/jobs.service'
import WhiteButton from '@/components/buttons/WhiteButton'
import { useCallback } from 'react'

const JobInner = ({ job }: { job: IJob }) => {
  const history = useHistory()

  const goToCandidates = useCallback(() => {
    history.push({
      pathname: `/jobs/${job.id}/candidates`,
      state: { from: '/company-profile' }
    })
  }, [job, history])

  return (<>
    <div className="px-4 py-4 flex items-center sm:px-6">
      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between truncate">
        <div className="truncate">
          <div className="flex text-sm">
            <p className="font-bold text-teal-600 truncate">{job.latest_version.title}</p>
          </div>
          <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                <strong>{JobStatusReadableMap[job.status]}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-2 sm:ml-0 flex flex-col">
        {job.has_been_published && <WhiteButton
          className="mt-3 mb-2 sm:mt-0"
          title="Candidates"
          onClick={goToCandidates}
        />}
        {/* <WhiteButton
          className="mt-3 mb-2 sm:mt-0"
          onClick={() => setIsCandidatesDialogOpen(true)}           
          title="Candidates"
        /> */}

        {/* <SlideOver
          title={`Candidates of ${job.published_version?.title ?? job.latest_version?.title}`}
          open={isCandidatesDialogOpen}
          setOpen={setIsCandidatesDialogOpen}
          hasForm={false}
          loading={false}
        >
          <RecruitsTab
            serviceParams={{
              job: job.id,
              status: JobCandidateEnum.STATUSES.COMPANY
            }}
          />
        </SlideOver> */}
      </div>
    </div>
  </>)
}

interface IJobProps {
  object: any,
  company: any,
  from?: string | null
}

const Job = ({
  object,
  company,
  from = null
}: IJobProps) => {
  const fromLink = from === null ? '/jobs/' : from
  
  return (
    <li>
      <Link to={{ pathname: `/companies/${company.id}/job/${object.id}`, state: { from: fromLink } }} className="block hover:bg-gray-50">
        <JobInner job={object} />
      </Link>
    </li>
  )
}

export default Job
