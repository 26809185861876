import { useRecoilValue } from 'recoil'
import { unreadMessageCount } from '../../recoil/atoms/chat'


export default function ChatBadge() {
  const unreadMessages = useRecoilValue(unreadMessageCount)

  return (
    <>
      {unreadMessages > 0 &&
        <span className="ml-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-sky-800 rounded-full">
          {unreadMessages}
        </span>
      }
    </>
  )
}