import { BookmarkIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import RedButton from '../../../buttons/RedButton'

import Confirm from '../../../modals/Confirm'

const MemberInvitationInner = ({ object, setIsConfirmOpen }: any) => {
  return (
    <div className="px-4 py-4 flex items-center sm:px-6">
      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="truncate">
          <div className="flex text-sm space-x-2">
            <p className="font-medium text-teal-600 truncate">{object.user_email}</p>
          </div>
          <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <BookmarkIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                <strong>
                  {{
                    "RE": "Recruiter",
                    "HM": "Hiring Manager"
                  }[object.role as "RE" | "HM"]}
                </strong>
              </p>
            </div>
          </div>
          <div className="mt-2 flex">
            <div className="flex items-center text-sm text-gray-500">
              <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p>
                <strong>{object.status}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <RedButton
          title="Delete"
          className="w-20 mt-2"
          onClick={() => setIsConfirmOpen(true)}
        />
      </div>
    </div>
  )
}


const MemberInvitation = ({ object, onDeleteConfirm }: any) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  
  return (
    <>
      <Confirm
        title={`Are you sure to delete the invitation to "${object.user_email}"?`}
        onConfirm={() => onDeleteConfirm(object.id)}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      />
      <li>
        <MemberInvitationInner object={object} setIsConfirmOpen={setIsConfirmOpen} />
      </li>
    </>
  )
}

export default MemberInvitation
