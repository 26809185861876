import { useState } from "react"
import { toast } from "react-toastify"
import { companyMemberInvitationsService, companyMembersService } from "../../../services"
import InviteMemberForm from "./members/InviteMemberForm"
import Member from "./members/Member"
import MemberInvitation from "./members/MemberInvitation"
import Modal from "../../modals/Modal"
import WhiteButton from '../../buttons/WhiteButton'
import PaginatedCRUDList from "../../layout/list/PaginatedCRUDList"

const Members = ({ company }: any) => {
  const [isInvitationListOpen, setIsInvitationListOpen] = useState(false)

  const toggleIsInvitationListOpen = () => {
    setIsInvitationListOpen(!isInvitationListOpen)
  }

  const formSuccessAction = (values: any, result: any) => {
    const addToList = isInvitationListOpen
    setIsInvitationListOpen(true)
    toast.success(`Invitation sent to: ${values.user_email}.`)
    return addToList
  }

  return (
    <>
      <WhiteButton
        title={isInvitationListOpen ? 'Show Members' : 'Show Invitations'}
        onClick={toggleIsInvitationListOpen}
        className="absolute mt-4 right-0"
      />

      { isInvitationListOpen ?
        <PaginatedCRUDList
          service={companyMemberInvitationsService}
          onServiceError={toast.error}
          object={company}
          filter={{status: 'Sent'}}
          itemComponent={MemberInvitation}
          formComponent={InviteMemberForm}
          ModalComponent={Modal}
          addButtonTitle="Invite Member"
          formSuccessAction={formSuccessAction}
        />
        :
        <PaginatedCRUDList
          service={companyMembersService}
          onServiceError={toast.error}
          object={company}
          itemComponent={Member}
          formComponent={InviteMemberForm}
          ModalComponent={Modal}
          addButtonTitle="Invite Member"
          formSuccessAction={formSuccessAction}
        />
      }
    </>
  )
}

export default Members
