import { NoMenuLayout } from '../components/layout'
import Features from '../components/welcome/Features'
import CrossroadButtons from '../components/welcome/CrossroadButtons'
import Header from '../components/welcome/Header'



export default function Welcome() {
  return (
    <NoMenuLayout title='Welcome!' >
      <Header title='Welcome to Gumption!' info='The hiring platform where you can find your dream job and make money by recruiting candidates!'/>
      <CrossroadButtons />
      <Features />
    </NoMenuLayout>
  )
}
