import { ChangeEventHandler, FocusEventHandler } from "react"
import { classNames } from "../../../lib/helpers"

interface IPasswordFieldProps {
  fieldName: string,
  label: string,
  handleChange: ChangeEventHandler,
  handleBlur: FocusEventHandler,
  values: Record<string, any>,
  errors: Record<string, any>,
  touched: Record<string, any>,
  required?: boolean
}


const PasswordField = (
  { fieldName,
    label,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    required = false
  }: IPasswordFieldProps) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label htmlFor={fieldName} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <input
          id={fieldName}
          name={fieldName}
          type="password"
          required={required}
          autoComplete="password"
          className={classNames(
            errors[fieldName] && touched[fieldName] ?
              'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
              'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
            , 'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm '
          )}
          onChange={handleChange} onBlur={handleBlur} value={values[fieldName]}
        />
      </div>
      <small className='text-red-500'>
        {errors[fieldName] && touched[fieldName] && errors[fieldName]}
      </small>
    </div>
  )
}

export default PasswordField
