import axios from 'axios'
import * as serverConfig from './server-config'
import { IServiceResult } from './types'

class CustomErrorResponse {
  detail: string
  code: number

  constructor(detail: string, code: number) {
    this.detail = detail
    this.code = code
  }

  toString() {
    return this.detail.replaceAll('non_field_errors : ', '')
  }
}

export async function fetch(url: string, cancelToken: any, headers?: Record<string, string>): Promise<IServiceResult> {
  const response = await axios.get(
    `${url}`,
    {
      ...(cancelToken && { cancelToken: cancelToken }),
      ...(headers && { headers: headers })
    },
  )
  if (response.status >= 400) {
    throw new CustomErrorResponse(response.data?.detail, response.status)
  } else {
    return {
      success: response.status !== serverConfig.requestCanceledByClientCode,
      data: response.data
    }
  }
}

export async function post(url: string, values?: any) {
  const response = await axios.post(
    url,
    values
  )
  if (response.status >= 400) {
    throw new CustomErrorResponse(response.data?.detail, response.status)
  } else {
    return {
      success: response.status !== serverConfig.requestCanceledByClientCode,
      data: response.data
    }
  }
}

export async function postMultipart(url: string, values?: any) {
  let form_data = new FormData()
  for (let key in values) {
    let value: any = values[key]
    if (value instanceof File) {
      form_data.append(key, value, value.name)
    } else {
      form_data.append(key, value)
    }
  }

  const response = await axios.post(
    url,
    form_data,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  )

  if (response.status >= 400) {
    throw new CustomErrorResponse(response.data?.detail, response.status)
  } else {
    return {
      success: response.status !== serverConfig.requestCanceledByClientCode,
      data: response.data
    }
  }
}

export async function patch(url: string, values?: any, headers?: Record<string, string>) {
  const response = await axios.patch(
    url,
    values,
    headers && { headers }
  )
  if (!response || response.status >= 400) {
    throw new CustomErrorResponse(response.data?.detail, response.status)
  } else {
    return {
      data: response.data,
      success: true
    }
  }
}

export async function put(url: string, values?: any) {
  const response = await axios.put(url, values)
  if (!response || response.status >= 400) {
    throw new CustomErrorResponse(response.data?.detail, response.status)
  } else {
    return {
      data: response.data,
      success: true
    }
  }
}

export async function _delete(url: string, data?: any) {
  const response = await axios.delete(
    url,
    data && { data }
  )
  if (!response || response.status >= 400) {
    throw new CustomErrorResponse(response.data?.detail, response.status)
  } else {
    return {
      data: response.data,
      success: true
    }
  }
}

export async function notImplementedSuccess(url: string, values?: any): Promise<IServiceResult> {
  return new Promise((res) => setTimeout(() => res({
    data: `notImplementedSuccess ${url} -> ${values}`,
    success: false
  }), 1000))
}