import { homeService } from '../../services'

import JobsList from './jobs/JobsList'
import CarouselList from '../layout/list/CarouselList'

const JobRecommendations = function () {
  return (
    <CarouselList
      noResultsText="Please complete your profile to receive job recommendations."
      itemComponent={JobsList}
      serviceMethod={homeService.recommendedJobs}
    />
  )
}

export default JobRecommendations
