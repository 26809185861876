import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { accountService, serviceType } from '../../../services'
import { LoadingInside } from '../../layout/top_level/Loading'


const WeeklyStats = () => {
  const [stats, setStats] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const source = axios.CancelToken.source()
    accountService.fetchWeeklyStats(source.token).then((result: serviceType.IServiceResult) => {
      if (result.success) {
        setLoading(false)
        setStats(result.data)
      }
    }).catch((error) => {
      toast.error(`Error Fetching Weekly Stats: ${error}`)
      setLoading(false)
    })
    return () => {
      source.cancel()
      setLoading(false)
    }
  }, [setStats])

  return (
    <ul className="grid grid-cols-2 gap-2 mt-6 mb-6">
      {loading ?
        <LoadingInside />
        :
        <>
          {stats.map((stat) => (
            <li key={stat.name} className="col-span-1 bg-white hover:bg-teal-50 rounded-lg shadow divide-y divide-gray-200">
              <div className="w-full flex items-center text-center justify-between p-6 space-x-6">
                <div className="flex-1 truncate">
                  <h3 className="text-gray-700 font-semibold truncate">{stat.name}</h3>
                  {stat.values.map((statValue: any) => (
                    <p key={statValue.name + statValue.value} className="mt-1 text-gray-500 text-sm truncate">{statValue.value} {statValue.name}</p>
                  ))}
                </div>
              </div>
            </li>
          ))}
        </>
      }
    </ul>
  )
}

export default WeeklyStats
