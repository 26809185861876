import { useRef } from "react"
import { toast } from "react-toastify"
import {
  JobCandidateEnum,
  jobCandidatesService,
  notesService
} from "@/services"

import PaginatedList, { withRecruitSearchAndFilter } from "@/components/layout/list/PaginatedList"
import Recruit from "@/components/profile/company/recruits/Recruit"

const PaginatedListWithSearchAndFilter = withRecruitSearchAndFilter(PaginatedList)

const ISERecruits = () => {
  const toReviewListRef = useRef<any>()
  const reviewedListRef = useRef<any>()

  const toReviewItemsProps = {
    acceptAction: (candidateId: any) => {
      const values = {
        status: JobCandidateEnum.STATUS.INTERNAL_APPROVED
      }
      jobCandidatesService.update(candidateId, values).then((result: any) => {
        if (result.success) {
          toReviewListRef?.current?.fetchItems()
          reviewedListRef?.current?.fetchItems()
          toast.success(`Candidate Accepted!`)
        }
      }).catch((error: any) => {
        console.error(error)
        toast.error(`${error}`)
      })
    },
    denyAction: (candidateId: any) => {
      const values = {
        status: JobCandidateEnum.STATUS.INTERNAL_DENIED
      }
      jobCandidatesService.update(candidateId, values).then((result: any) => {
        if (result.success) {
          toReviewListRef?.current?.fetchItems()
          reviewedListRef?.current?.fetchItems()
          toast.success(`Candidate Denied!`)
        }
      }).catch((error: any) => {
        console.error(error)
        toast.error(`${error}`)
      })
    }
  }

  const reviewedItemsProps = {
    showNotesButton: true,
    changeStatusAction: (jobCandidate: any, formValues: any) => {
      const values = {
        status: formValues.status
      }
      jobCandidatesService.update(jobCandidate.id, values).then((result: any) => {
        if (result.success) {
          reviewedListRef?.current?.fetchItems()
          if (values.status === JobCandidateEnum.STATUS.TO_BE_REVIEWED) {
            toReviewListRef?.current?.fetchItems()
          }
          toast.success(`Candidate Updated!`)

          if (formValues.attach_note) {
            const noteValues = {
              job_candidate: jobCandidate.id,
              job: jobCandidate.job.id,
              company: jobCandidate.job.company_id,
              type: formValues.note_type,
              text: formValues.note_text,
              datetime: formValues.note_datetime
            }
            notesService.create(noteValues).then((result: any) => {
              if (result.success) {
                toast.success(`Note Created`)
              }
            }).catch((error: any) => {
              console.error(error)
              toast.error(`${error}`)
            })
          }
        }
      }).catch((error: any) => {
        console.error(error)
        toast.error(`${error}`)
      })
    }
  }

  const reviewedListStatuses = JobCandidateEnum.STATUSES.INTERNAL

  return (
    <div className="p-4 sm:p-0 sm:mt-4">
      <div className="sm:grid sm:grid-cols-2 sm:gap-4">
        <div className="mb-4 sm:mb-0">
          <h3 className="text-teal-600 font-bold mb-2">To Review by ISE</h3>
          <PaginatedListWithSearchAndFilter
            service={jobCandidatesService}
            serviceParams={{
              status: JobCandidateEnum.STATUS.TO_BE_REVIEWED
            }}
            ref={toReviewListRef}
            pageSize={10}
            onServiceError={toast.error}
            itemComponent={Recruit}
            itemsProps={toReviewItemsProps}
            noResultsText="No Candidates to Review"
            collapsableFilter={true}
          />
        </div>
        <div className="mb-4 sm:mb-0">
          <h3 className="text-teal-600 font-bold mb-2">All Other Candidates</h3>
          <PaginatedListWithSearchAndFilter
            service={jobCandidatesService}
            serviceParams={{
              status: reviewedListStatuses
            }}
            pageSize={10}
            ref={reviewedListRef}
            onServiceError={toast.error}
            itemComponent={Recruit}
            itemsProps={reviewedItemsProps}
            noResultsText="No Reviewed Candidates"
            extraFields={{
              status: {
                options: JobCandidateEnum.statusOptions(reviewedListStatuses)
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ISERecruits
