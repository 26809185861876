import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '@/components/buttons/BlueButton'
import { NumberInputField, TextInputField } from  '@/components/forms/fields'
import ColorInputField from '@/components/forms/fields/ColorInputField'
import SwitchInputField from '@/components/forms/fields/SwitchInputField'
import { IRecruitingStep, IRecruitingStepAssigneeType } from '@/recoil/types'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import CreateRecruitingStepFormAssignees from './CreateRecruitingStepFormAssignees'

export interface TempAssignee {
  type: IRecruitingStepAssigneeType,
  id: number | string,
  title: string,
  permission: 'assign' | 'view'
}

const CreateRecruitingStepSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  duration: Yup.number().min(0, 'Time spent must be atleast 0').required('Time spent is required'),
  color: Yup.string().required('Color is required')
})

export default function CreateRecruitingStepForm({ handleSubmit, initialStep, isAbstract, companyId, order }: { handleSubmit: any, initialStep?: IRecruitingStep, isAbstract: boolean, companyId?: number, order?: number }) {
  return (
    <Formik
      initialValues={{
        name: initialStep?.name ?? '',
        description: initialStep?.description ?? '',
        duration: initialStep?.duration ?? 0,
        color: initialStep?.color ? `#${initialStep.color}` : `#${(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')}`,
        involves_candidate: initialStep === undefined ? false : initialStep.involves_candidate,
        is_abstract: isAbstract,
        order: order ?? 1,
        assignees: initialStep?.assignees ?? ''
      }}
      validationSchema={CreateRecruitingStepSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <TextInputField
            fieldName="name"
            label="Name"
            required={true}
            styles={{ input: 'block w-full shadow-sm sm:text-sm rounded-md' }}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <TextInputField
            fieldName="description"
            label="Description"
            required={true}
            styles={{ input: 'block w-full shadow-sm sm:text-sm rounded-md' }}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <NumberInputField
            fieldName="duration"
            label="Expected Time Spent in Step (hours)"
            required={true}
            styles={{ input: 'block w-full shadow-sm sm:text-sm rounded-md' }}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <ColorInputField
            fieldName="color"
            label="Color"
            required={true}
            styles={{ input: 'block w-full shadow-sm sm:text-sm rounded-md' }}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          {companyId && <SwitchInputField
            fieldName="involves_candidate"
            label={
              <span className="flex items-center gap-1">
                <span>Involves Candidate?</span>
                <span className="group relative">
                  <span className="block w-52 text-xs font-medium pointer-events-none absolute -top-16 left-1/2 -translate-x-1/2 rounded bg-black px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:opacity-100">
                    Enabling this sends notifications to the candidate when they are moved into this step
                  </span>
                  <QuestionMarkCircleIcon className="h-4 w-4" aria-hidden="true" />
                </span>
              </span>
            }
            {...{ values, errors, touched, handleBlur, handleChange }}
          />}
          {companyId && <div className="sm:border-t sm:border-gray-200 sm:pt-5">
            <div className="text-sm font-medium text-gray-700 mb-4">Assign Users / Roles / Recruitment Agencies</div>
            <CreateRecruitingStepFormAssignees
              initialAssignees={initialStep?.assignees}
              handleChange={handleChange}
            />
          </div>}
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <BlueButton
              type="submit"
              title={isAbstract ? (isSubmitting ? "Creating..." : "Create Step") : (isSubmitting ? "Adding..." : "Add Step")}
              className="w-full transition duration-150 ease-in-out"
              disabled={isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
