import { Layout } from '@/components/layout'
import HeadingsH2 from '@/components/headings/HeadingsH2'
import { useState } from 'react'
import BaseTabs from '@/components/layout/navigation/BaseTabs'
import RecruitProcessTable from '@/components/recruit/RecruitProcessTable'
import RecruitRecruitsTable from '@/components/recruit/RecruitRecruitsTable'

const Recruit = () => {
  const tabs = [
    { slug: 'recruits', name: 'Candidates' },
    { slug: 'job_processes', name: 'Job Pipelines' },
    { slug: 'abstract_processes', name: 'Abstract Pipelines' },
  ]
  const [activeTab, setActiveTab] = useState('job_processes')

  return (
    <Layout title="Candidates" auth={true} back={true}>
      <HeadingsH2 heading="Candidates" />
      <BaseTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        extraButtonClass={'px-4 sm:px-6 lg:px-8 mt-4'}
      />
      {activeTab === 'recruits' && <RecruitRecruitsTable />}
      {activeTab === 'job_processes' && <RecruitProcessTable type="linked" />}
      {activeTab === 'abstract_processes' && <RecruitProcessTable type="abstract" />}
    </Layout>
  )
}

export default Recruit
