interface IReportRowProps {
  object: any,
  reportMap: Function,
}

const ReportRow = ({
  object,
  reportMap
}: IReportRowProps) => {
  const item = reportMap(object)

  return (
    <>
      <table className="table-fixed w-full text-sm">
        <tbody>
          <tr className="hover:bg-teal-50">
            {item.map((object: any, index: number) => (
              <td key={index} className="border border-gray-200 p-2">
                {object}
              </td>
            ))}
          </tr>
         </tbody>
      </table>
    </>
  )
}

export default ReportRow
