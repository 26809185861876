import { classNames } from '../../lib/helpers'
import BaseButton, { IBaseButtonProps } from './BaseButton'

import { ButtonStyles } from './styles'

const BlueButton = (props: IBaseButtonProps) => {
  return (
    <BaseButton
      {...props}
      className={classNames(
        props.className,
        ButtonStyles.Blue
      )}
    />
  )
}

export default BlueButton
