import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '../buttons/BlueButton'

import { classNames } from '../../lib/helpers'

import {
  Link
} from 'react-router-dom'

const SigninSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
})

export default function LoginForm({ handleSubmit }: { handleSubmit: any }) {
  return (
    <Formik
      initialValues={{
        password: "",
        email: "",
      }}
      validationSchema={SigninSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className={classNames(
                  errors.email && touched.email && errors.email ?
                    'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
                    'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
                  , 'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm '
                )}
                onChange={handleChange} onBlur={handleBlur} value={values.email}
              />
            </div>
            <small className='text-red-500'>
              {errors.email && touched.email && errors.email}
            </small>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className={classNames(
                  errors.email && touched.email && errors.email ?
                    'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
                    'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
                  , 'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm '
                )}
                onChange={handleChange} onBlur={handleBlur} value={values.password}
              />
            </div>
            <small className='text-red-500'>
              {errors.password && touched.password && errors.password}
            </small>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link to="/forgotten-password/" className="font-medium text-teal-600 hover:text-teal-500">
                Forgot your password?
              </Link>
            </div>
          </div>

          <div>
            <BlueButton
                title={isSubmitting ? "Signing in..." : "Sign in"}
                type="submit"
                disabled={isSubmitting}
                className="w-full"
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
