import { useEffect, useState, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { accountService } from '../../services'
import * as serverConfig from '../../services/server-config'
import { isLoggedIn } from '../../recoil/atoms/auth'
import { incomingMessage, outgoingMessage, unreadMessageCount, socketState, activeChatUser } from '../../recoil/atoms/chat'
import { uuidv4 } from "../../lib/helpers"

export const ChatSocket = () => {
  const userLoggedIn = useRecoilValue(isLoggedIn)
  const [, setUnreadMessages] = useRecoilState(unreadMessageCount)
  const [socketOutgoingMessage, setSocketOutgoingMessage] = useRecoilState(outgoingMessage)
  const [, setSocketIncomingMessage] = useRecoilState(incomingMessage)
  const ignoreSenderId = useRecoilValue(activeChatUser)

  const [, setSocketOpened] = useRecoilState<boolean>(socketState)
  const [waitingToReconnect, setWaitingToReconnect] = useState<boolean>(false)

  const socketRef = useRef<any>()
  const ignoreUserRef = useRef<any>(null)
  // const personRef = useRef<any>(person)

  const accessToken = accountService.checkToken()

  useEffect(() => {
    ignoreUserRef.current = ignoreSenderId
  }, [ignoreSenderId])

  useEffect(() => {
    if (!socketRef.current) return
    socketRef.current.send(JSON.stringify(socketOutgoingMessage))
  }, [socketOutgoingMessage])

  const handleMessage = useRef((event: any) => {
    const newMessage = JSON.parse(event.data)
    console.log('onmessage', newMessage)
    if (newMessage.type === 'unread') {
      setUnreadMessages(newMessage.count)
    }
    else if (newMessage.type === 'message') {
      if (ignoreUserRef.current !== newMessage.from_user) {
        setUnreadMessages((previousState) => previousState + 1)
      }
    }
    setSocketIncomingMessage(newMessage)
    // setChatMessages((previousMessages: any) => [{ ...newMessage }].concat(previousMessages))
  })

  useEffect(() => {
    if (!userLoggedIn || !accessToken) {
      console.log('not logged, socket not launching')
      return
    }

    if (waitingToReconnect || socketRef.current) {
      return
    }
    console.log('launching socket')

    const socket = new WebSocket(`${serverConfig.websocketServerURL}${serverConfig.routes.messaging.websocketChat}?token=${accessToken}`)
    socketRef.current = socket

    socket.onmessage = handleMessage.current
    socket.onerror = (e) => console.error(e)
    socket.onopen = () => {
      console.log('socket opened')
      setSocketOpened(true)

      setSocketOutgoingMessage({
        request_id: uuidv4(),
        type: 'unread'
      })
    }
    socket.onclose = () => {
      if (socketRef.current) {
          // Connection failed
        console.log('socket closed by server')
      }
      else {
        console.log('socket closed by app component unmount')
        return
      }

      if (waitingToReconnect) {
        return
      }

      setSocketOpened(false)
      setWaitingToReconnect(true)
      setTimeout(() => setWaitingToReconnect(false), 3000);
    }

    return () => {
      socketRef.current = null
      socket.close()
    }
  }, [userLoggedIn, accessToken, waitingToReconnect, setSocketOpened, setSocketOutgoingMessage])

  // debug:
  // const closeSocket = () => socketRef.current.close()
  // return (<button onClick={closeSocket}>Close Socket</button>)

  return (null)
}

export default ChatSocket