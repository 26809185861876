import { classNames } from "@/lib/helpers"
import { FocusEventHandler, useEffect } from "react"
import { ChangeEventHandler } from "react"

interface IRangeSliderFieldProps {
  fieldName: string,
  label: string,
  min: number,
  max: number,
  step?: number,
  handleChange: ChangeEventHandler,
  handleBlur: FocusEventHandler,
  values: Record<string, any>,
  errors: Record<string, any>,
  touched: Record<string, any>,
  required?: boolean,
  disabled?: boolean,
  styles?: { wrapper?: string }
}

const RangeSliderField = ({
  fieldName,
  label,
  min,
  max,
  step = 1,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  required = false,
  disabled = false,
  styles
}: IRangeSliderFieldProps) => {
  useEffect(() => {
    if (values[fieldName] > max) {
      handleChange({ target: { name: fieldName, value: max } } as any)
    }
  }, [fieldName, handleChange, max, values])

  return (
    <div className={styles?.wrapper ?? 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'}>
      <label htmlFor={fieldName} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <input
          id={fieldName}
          name={fieldName}
          type="range"
          min={min}
          max={max}
          step={step}
          required={required}
          disabled={disabled}
          onChange={handleChange} onBlur={handleBlur} value={values[fieldName]}
          className={classNames(
            errors[fieldName] && touched[fieldName] && errors[fieldName] ?
              'border-red-500 focus:border-red-500 focus:ring-red-500 ' :
              'border-gray-300 focus:border-teal-600 focus:ring-teal-600 '
            , 'w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700'
          )}
        />
        <div className="text-center text-sm text-gray-700 font-medium mt-2">{values[fieldName]}</div>
      </div>
      <small className='text-red-500'>
        {errors[fieldName] && touched[fieldName] && errors[fieldName]}
      </small>
    </div>
  )
}

export default RangeSliderField
