import { userState } from '@/recoil/atoms/auth'
import { AccountRoles } from '@/services'
import { IJob, IJobVersion, jobsService, JobStatusReadableMap } from '@/services/jobs.service'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useRecoilValue } from 'recoil'
import BlueButton from '../buttons/BlueButton'
import RedButton from '../buttons/RedButton'
import TealButton from '../buttons/TealButton'
import WhiteButton from '../buttons/WhiteButton'
import BlueLinkButton from '../links/BlueButtonLink'
import Confirm from '../modals/Confirm'
import Modal from '../modals/Modal'
import { FormatedDate } from '../utils'
import SubmitForReviewForm from './forms/SubmitForReviewForm'
import JobReviewers from './JobReviewers'

const JobVersion = ({ jobVersion, job, refetch }: { jobVersion: IJobVersion, job: IJob, refetch: () => void }) => {
  const [isPublishConfirmOpen, setIsPublishConfirmOpen] = useState(false)
  const [submitForReviewModalOpen, setSubmitForReviewModalOpen] = useState(false)
  const [reviewersModalOpen, setReviewersModalOpen] = useState(false)
  const userData = useRecoilValue(userState)

  const submitForReview = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    jobsService.submitVersionForReview(
      jobVersion.id,
      values['required_reviewers'],
      values['optional_reviewers'],
      values['optional_reviewers_required'],
      values['notify_users']
    ).then(({success, data}) => {
      if (success) {
        toast.success('Successfully submitted version for review')
        setSubmitForReviewModalOpen(false)
        refetch()
      } else {
        console.error(data)
        toast.error('Failed to submit version for review')
      }
      setSubmitting(false)
    }).catch((error) => {
      if (error.code === 409) {
        toast.warning('You cannot have reviewer(s) that are both mandatory and optional reviewers')
      } else {
        console.error(error)
        toast.error('Failed to submit version for review')
      }
      setSubmitting(false)
    })
  }

  // const approveVersion = () => {
  //   jobsService.approveVersion(jobVersion.id).then(() => {
  //     toast.success(`Approved job version ${jobVersion.version_number}`)
  //     refetch()
  //   }).catch(() => {
  //     toast.error(`Failed to approve job version ${jobVersion.version_number}`)
  //   })
  // }

  // const rejectVersion = () => {
  //   jobsService.rejectVersion(jobVersion.id).then(() => {
  //     toast.success(`Rejected job version ${jobVersion.version_number}`)
  //     refetch()
  //   }).catch(() => {
  //     toast.error(`Failed to reject job version ${jobVersion.version_number}`)
  //   })
  // }

  const endReview = () => {
    jobsService.endVersionReview(jobVersion.id).then(() => {
      toast.success(`Ended review for version ${jobVersion.version_number}`)
      refetch()
    }).catch(() => {
      toast.error(`Failed to end review for version ${jobVersion.version_number}`)
    })
  }

  const unpublishVersion = () => {
    jobsService.unpublishVersion(jobVersion.id).then(() => {
      toast.success(`Unpublished version ${jobVersion.version_number}`)
      refetch()
    }).catch(() => {
      toast.error(`Failed to unpublish version ${jobVersion.version_number}`)
    })
  }

  const closeVersion = () => {
    jobsService.closeVersion(jobVersion.id).then(() => {
      toast.success(`Closed version ${jobVersion.version_number}`)
      refetch()
    }).catch(() => {
      toast.error(`Failed to close version ${jobVersion.version_number}`)
    })
  }

  const publishVersion = () => {
    jobsService.publishVersion(jobVersion.id).then(() => {
      toast.success(`Published version ${jobVersion.version_number}`)
      refetch()
    }).catch(() => {
      toast.error(`Failed to publish version ${jobVersion.version_number}`)
    })
  }

  const canPublishJobVersion = useMemo(() => {
    if (jobVersion.approved && jobVersion.status !== 'Published') {
      return true
    }
    
    if (userData?.role === AccountRoles.COMPANY) {
      return jobVersion.status === 'Draft' || jobVersion.status === 'Review'
    } else if (userData?.role === AccountRoles.HIRING_MANAGER) {
      return jobVersion.status === 'Review' && jobVersion.approved
    } else {
      return false
    }
  }, [jobVersion, userData])
  
  return (
    <>
      <Confirm
        title="Are you sure you want to publish this job version?"
        confirm_text="Publish"
        info_text="Publishing a job will notify en masse all users whose preferences match this job."
        onConfirm={() => publishVersion()}
        isOpen={isPublishConfirmOpen}
        setIsOpen={setIsPublishConfirmOpen}
      />
      <Modal
        title="Submit Version for Review"
        open={submitForReviewModalOpen}
        setOpen={setSubmitForReviewModalOpen}
        hasForm={true}
      >
        <SubmitForReviewForm companyId={job.company!.id} handleSubmit={submitForReview} />
      </Modal>
      <Modal
        title={`Reviewers for Version ${jobVersion?.version_number}`}
        open={reviewersModalOpen}
        setOpen={setReviewersModalOpen}
      >
        {jobVersion && <JobReviewers jobVersion={jobVersion} />}
      </Modal>
      <div className="px-4 py-4 flex sm:px-6">
        <div className="min-w-0 flex-1 sm:flex sm:justify-between truncate">
          <div className="truncate">
            <div className="flex text-sm">
              <Link to={{
                pathname: `/companies/${job.company!.id}/job/${job.id}/version-explorer`,
                search: `?num=${jobVersion.version_number}`,
                state: { from: `/companies/${job.company!.id}/job/${job.id}` }
              }}>
                <p className="font-bold text-teal-600 truncate">Version {jobVersion.version_number}</p>
              </Link>
            </div>
            <div className="mt-2 flex">
              <div className="flex items-center text-sm text-gray-500">
                <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                <p>
                  <strong>{JobStatusReadableMap[jobVersion.status]} {jobVersion.status === 'Closed' && jobVersion.closed_reason ? `(${jobVersion.closed_reason})` : ''}</strong>
                  {(jobVersion.status === 'Published' || (jobVersion.status === 'Closed' && !!jobVersion.published_start)) && <span className="block">
                    (
                    { (!!jobVersion.published_start && !jobVersion.published_end) && "as of " }
                    { jobVersion.status === 'Closed' && "was published " }
                    <FormatedDate date={new Date(jobVersion.published_start!)} relative={true} />
                    {!!jobVersion.published_end && <>
                      &nbsp;-&nbsp;
                      <FormatedDate date={new Date(jobVersion.published_end!)} relative={true} />
                    </>}
                    )
                  </span>}
                </p>
              </div>
            </div>
            {((jobVersion.status === 'Draft' || jobVersion.status === 'Review') && jobVersion.approved) && <div className="mt-2 flex">
              <div className="flex items-center text-sm text-teal-600">
                <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
                <p className="font-semibold">Approved</p>
              </div>
            </div>}
          </div>
        </div>
        {job.open && <div className="ml-2 sm:ml-0 flex flex-col gap-2">
          {(jobVersion.status === 'Review' && jobVersion.can_review && !jobVersion.has_reviewed) && <>
            {/* <TealButton
              title="Approve"
              onClick={() => approveVersion()}
            />
            <RedButton
              title="Reject"
              onClick={() => rejectVersion()}
            /> */}
            <BlueLinkButton
              title="Review"
              to={{
                pathname: `/companies/${job.company!.id}/job/${job.id}/review/${jobVersion.id}`,
                state: { from: `/companies/${job.company!.id}/job/${job.id}` }
              }}
            />
          </>}
          {jobVersion.status === 'Review' && <WhiteButton
            title="Reviewers"
            onClick={() => setReviewersModalOpen(true)}
          />}
          {jobVersion.status === 'Review' && <RedButton
            title="End Review"
            onClick={() => endReview()}
          />}
          {canPublishJobVersion && job.company?.is_paid && <TealButton
            title="Publish"
            onClick={() => setIsPublishConfirmOpen(true)}
          />}
          {jobVersion.status === 'Draft' && <BlueButton
            title="Submit for Review"
            onClick={() => setSubmitForReviewModalOpen(true)}
          />}
          {jobVersion.status === 'Published' && <RedButton
            title="Unpublish"
            onClick={() => unpublishVersion()}
          />}
          {jobVersion.status === 'Published' && <RedButton
            title="Close"
            onClick={() => closeVersion()}
          />}
        </div>}
      </div>
    </>
  )
}

export default JobVersion
