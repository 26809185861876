import { Formik } from 'formik'
import * as Yup from 'yup'
import BlueButton from '@/components/buttons/BlueButton'
import { SelectField, TextAreaField } from  '@/components/forms/fields'

const AddJobCommentSchema = Yup.object().shape({
  comment: Yup.string().required('Comment text is required')
})

const CommentFields = [
  { id: '', title: 'General Comment' },
  { id: 'title', title: 'Title' },
  { id: 'description', title: 'Description' },
  { id: 'requirements', title: 'Responsibilities' },
  { id: 'benefits', title: 'Benefits' },
  { id: 'qualification', title: 'Qualification' },
  { id: 'state', title: 'State' },
  { id: 'City', title: 'City' },
  { id: 'industry', title: 'Industry' },
  { id: 'role', title: 'Role' },
  { id: 'work_type', title: 'Work Type' },
  { id: 'work_env', title: 'Work Env' },
  { id: 'experience', title: 'Experience' },
  { id: 'availability', title: 'Availability' }
]

export default function AddJobCommentForm({ handleSubmit }: { handleSubmit: any }) {
  return (
    <Formik
      initialValues={{
        field: "",
        comment: "",
      }}
      validationSchema={AddJobCommentSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="space-y-6">
          <SelectField
            fieldName="field"
            label="Job Field"
            options={CommentFields}
            includeNotSpecified={false}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <TextAreaField
            fieldName="comment"
            label="Comment Text"
            required={true}
            {...{ values, errors, touched, handleBlur, handleChange }}
          />
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <BlueButton
              type="submit"
              title={isSubmitting ? "Adding..." : "Add Comment"}
              className="w-full transition duration-150 ease-in-out"
              disabled={!isValid || !dirty || isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}
