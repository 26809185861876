import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ChevronLeftIcon, ClipboardListIcon, InformationCircleIcon, ClipboardCopyIcon, PencilIcon, CheckCircleIcon } from '@heroicons/react/solid'

import { Layout } from '@/components/layout'
import { LoadingInside } from '@/components/layout/top_level/Loading'
import { IJob, jobsService, JobStatusReadableMap } from '@/services/jobs.service'
import HeadingsH2 from '@/components/headings/HeadingsH2'
import AppliedPeople from '@/components/jobs/applied/AppliedPeople'
import BaseTabs from '@/components/layout/navigation/BaseTabs'
import WhiteButton from '@/components/buttons/WhiteButton'
import RedButton from '@/components/buttons/RedButton'
import BlueButton from '@/components/buttons/BlueButton'
import BlueButtonWithOptions from '@/components/buttons/BlueButtonWithOptions'
import axios, { CancelTokenSource } from 'axios'
import JobVersions from '@/components/jobs/JobVersions'
import { toast } from 'react-toastify'
import Confirm from '@/components/modals/Confirm'
import Modal from '@/components/modals/Modal'
import CloneJobForm from '@/components/jobs/forms/CloneJobForm'
import UpdateJobPositionsForm from '@/components/jobs/forms/UpdateJobPositionsForm'
import { useRecoilValue } from 'recoil'
import { userState } from '@/recoil/atoms/auth'
import { AccountRoles, serviceType } from '@/services'
import UpdateJobVettingForm from '@/components/jobs/forms/UpdateJobVettingForm'
import EmailShareForm from '@/components/jobs/forms/EmailShareForm'

const JobTabs = ({ job, refetchJob }: { job: IJob, refetchJob: () => void }) => {
  const tabs = [
    { slug: 'versions', name: 'Versions' },
    { slug: 'candidates', name: 'Candidates' },
  ]

  const [activeTab, setActiveTab] = useState('versions')

  return (
    <>
      <BaseTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        extraButtonClass={'px-4 sm:px-6 lg:px-8'}
      />
      {activeTab === 'versions' && <JobVersions job={job} jobVersions={job.versions} refetchJob={refetchJob} />}
      {activeTab === 'candidates' && <AppliedPeople job={job} jobVersion={job.published_version ?? job.latest_version} refetchJob={refetchJob} />}
    </>
  )
}


const CompanyJob = () => {
  let { job_id, company_id } = useParams<{ job_id: string, company_id: string }>()

  const userData = useRecoilValue(userState)
  const [job, setJob] = useState<IJob>()
  const [loading, setLoading] = useState(true)
  const [tempDraftCreationId, setTempDraftCreationId] = useState<number>()
  const [isDraftCreationConfirmOpen, setIsDraftCreationConfirmOpen] = useState(false)
  const [isDeletionConfirmOpen, setIsDeletionConfirmOpen] = useState(false)
  const [cloneJobModalOpen, setCloneJobModalOpen] = useState(false)
  const [updatePositionsAvailableModalOpen, setUpdatePositionsAvailableModalOpen] = useState(false)
  const [vettingModalOpen, setVettingModalOpen] = useState(false)
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false)
  const apiSource = useRef<CancelTokenSource>()

  const location = useLocation<any>()
  const history = useHistory()
  const hasBack = (location?.state?.from !== undefined)

  const hasPublicVersion = useMemo(() => {
    return !!job?.published_version
  }, [job])

  const fetchJob = useCallback(() => {
    setLoading(true)

    if (apiSource.current) {
      apiSource.current.cancel()
    }

    apiSource.current = axios.CancelToken.source()

    jobsService.detail(parseInt(job_id), apiSource.current.token).then((res: any) => {
      setJob(res.data)
      setLoading(false)
    })
  }, [job_id])

  useEffect(() => {
    fetchJob()

    return () => {
      apiSource.current?.cancel()
    }
  }, [fetchJob])

  const draftCreationOptions = useMemo(() => {
    if (!job || job.versions.length === 0) {
      return []
    }

    const options = []
    for (const jobVersion of job.versions) {
      options.push({ id: jobVersion.id.toString(), title: `Version ${jobVersion.version_number}` })
    }

    return options
  }, [job])

  const gotoPublicView = () => {
    history.push(`/jobs/${job?.id}`)
  }

  const goBack = () => {
    history.goBack()
  }

  const closeJob = () => {
    jobsService.closeJob(job!.id).then(() => {
      toast.success('This job has been closed')
      fetchJob()
    }).catch(() => {
      toast.error('Failed to close job')
    })
  }

  const deleteJob = () => {
    jobsService.deleteJob(parseInt(job_id)).then(() => {
      toast.success('Successfully deleted job')
      history.push('/company-profile')
    }).catch(() => {
      toast.error('Failed to delete job')
    })
  }

  const cloneJob = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    jobsService.cloneJob(
      job!.id,
      values['version_id'],
      values['recruiting_process'],
      values['auto_publish']
    ).then(({success, data}) => {
      if (success) {
        toast.success('Successfully cloned job')
        setCloneJobModalOpen(false)
        history.push(`/companies/${company_id}/job/${data.id}`)
      } else {
        console.error(data)
        toast.error('Failed to clone job')
      }
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to clone job')
      setSubmitting(false)
    })
  }

  const updatePositionsAvailable = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    jobsService.updatePositionsAvailable(
      job!.id,
      values['positions_available']
    ).then(({success, data}) => {
      if (success) {
        toast.success('Successfully updated the number of positions available')
        setUpdatePositionsAvailableModalOpen(false)
        fetchJob()
      } else {
        console.error(data)
        toast.error('Failed to update the number of positions available')
      }
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to update the number of positions available')
      setSubmitting(false)
    })
  }

  const updateVettingType = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    jobsService.updateVettingType(
      job!.id,
      values['vetting_type'],
      (values['vetting_type'] === 'Recruiter' || values['vetting_type'] === 'Internal') ? values['vetting_users'] : undefined
    ).then(({success, data}) => {
      if (success) {
        toast.success('Successfully updated the vetting settings')
        setVettingModalOpen(false)
        fetchJob()
      } else {
        console.error(data)
        toast.error('Failed to update the vetting settings')
      }
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to update the vetting settings')
      setSubmitting(false)
    })
  }

  const createNewDraft = useCallback((versionId?: number) => {
    jobsService.createNewDraft(job!.id, versionId).then((res) => {
      toast.success('Created new draft version')
      fetchJob()
    }).catch((err) => {
      toast.error('Failed to create new draft version')
    })
  }, [fetchJob, job])

  const createNewDraftWrapper = useCallback((versionId?: number) => {
    if (job!.status === 'Review') {
      setTempDraftCreationId(versionId)
      setIsDraftCreationConfirmOpen(true)
    } else {
      createNewDraft(versionId)
    }
  }, [createNewDraft, job])

  const handleShareSubmit = (values: any, result: any) => {
    jobsService.share(parseInt(job_id), values.email).then((result: serviceType.IServiceResult) => {
      if (result.success) {
        setIsShareDialogOpen(false)
        toast.success(`Job detail was sent to: ${values.email}.`)
      } else {
        toast.error(`Share message failed to send`)
      }
    }).catch((error: any) => {
      console.error(error)
      toast.error(`Share message failed to send`)
    })
  }

  return (
    <Layout title="Job" auth={true} back={true}>
      <Confirm
        title="Are you sure you want to create a new draft version?"
        confirm_text="Create"
        info_text="Creating a new draft version will close the version that is currently in review."
        onConfirm={() => createNewDraft(tempDraftCreationId!)}
        isOpen={isDraftCreationConfirmOpen}
        setIsOpen={setIsDraftCreationConfirmOpen}
      />
      <Confirm
        title="Are you sure you want to delete this job?"
        confirm_text="Delete"
        info_text="Deletion is permanent and cannot be reversed."
        onConfirm={() => deleteJob()}
        isOpen={isDeletionConfirmOpen}
        setIsOpen={setIsDeletionConfirmOpen}
      />
      <Modal
        title="Clone Job"
        open={cloneJobModalOpen}
        setOpen={setCloneJobModalOpen}
        hasForm={true}
      >
        {job && <CloneJobForm job={job} handleSubmit={cloneJob} />}
      </Modal>
      <Modal
        title="Update Positions Available"
        open={updatePositionsAvailableModalOpen}
        setOpen={setUpdatePositionsAvailableModalOpen}
        hasForm={true}
      >
        {job && <UpdateJobPositionsForm job={job} handleSubmit={updatePositionsAvailable} />}
      </Modal>
      <Modal
        title="Update Vetting Preferences"
        open={vettingModalOpen}
        setOpen={setVettingModalOpen}
        hasForm={true}
      >
        {job && <UpdateJobVettingForm job={job} handleSubmit={updateVettingType} />}
      </Modal>
      <Modal
        title="Share"
        open={isShareDialogOpen}
        setOpen={setIsShareDialogOpen}
        hasForm={true}
      >
        <EmailShareForm handleSubmit={handleShareSubmit} />
      </Modal>
      {!loading ?
        <>
          <div className="bg-white px-4 py-2">
            {hasBack &&
              <nav className="hidden lg:block flex items-start mb-2 py-4 lg:py-2" aria-label="Breadcrumb">
                <button
                  onClick={goBack}
                  className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
                >
                  <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-600" aria-hidden="true" />
                  <span>Back</span>
                </button>
              </nav>
            }

            <div className="flex content-center justify-between pt-4 lg:pt-0 mb-2 space-x-2 sm:space-x-0 flex-col sm:flex-row">
              <div className="truncate space-y-4">
                <HeadingsH2 heading={job?.latest_version.title} className="truncate" />
                <div className="flex items-center text-sm text-gray-500">
                  <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <small>
                    {job?.status && JobStatusReadableMap[job.status]}
                  </small>
                </div>
                {job?.filled === 1 && <div className="flex items-center text-sm text-teal-600">
                  <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-teal-600" aria-hidden="true" />
                  <small>
                    Filled
                  </small>
                </div>}
                <div className="flex items-center text-sm text-gray-500">
                  <ClipboardListIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <small>
                    {job?.positions_available} Position(s) Total
                  </small>
                  {job?.open && <BlueButton
                    title=""
                    showTitle={false}
                    onClick={() => setUpdatePositionsAvailableModalOpen(true)}
                    className="mx-2 text-xs px-1 py-1"
                    children={
                      <PencilIcon className="h-3 w-3" aria-hidden="true" />
                    }
                  />}
                </div>
                <div className="flex items-center text-sm text-gray-500">
                  <ClipboardCopyIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <small>
                    {job ? Math.max(job.positions_available - Math.round(job.filled * job.positions_available), 0) : 0} Position(s) Unfilled
                  </small>
                </div>
                {/* <div className="flex items-center text-sm text-gray-500">
                  <AdjustmentsIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <small>
                    Vetting: {JobVettingTypeReadableMap[job?.vetting_type ?? 'Open']}
                  </small>
                  {job?.open && <BlueButton
                    title=""
                    showTitle={false}
                    onClick={() => setVettingModalOpen(true)}
                    className="mx-2 text-xs px-1 py-1"
                    children={
                      <PencilIcon className="h-3 w-3" aria-hidden="true" />
                    }
                  />}
                </div> */}
              </div>

              <div className="flex flex-col space-y-2 mt-4 sm:mt-0">
                {job?.open && <div className="group relative">
                  {!hasPublicVersion && <div className="text-sm font-medium pointer-events-none absolute -top-10 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-black px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:opacity-100">
                    This job has no published version
                  </div>}
                  <WhiteButton
                    title="Public View"
                    onClick={() => gotoPublicView()}
                    className="w-full"
                    disabled={!hasPublicVersion}
                  />
                </div>}
                {job?.open && <RedButton
                  title="Close Job"
                  onClick={() => closeJob()}
                  className="w-full"
                />}
                {job?.company?.is_paid && <BlueButton
                  title="Clone"
                  onClick={() => setCloneJobModalOpen(true)}
                  className="w-full"
                />}
                {job?.open && <BlueButtonWithOptions
                  title="Create New Draft"
                  onClick={() => createNewDraftWrapper()}
                  options={draftCreationOptions}
                  onOptionClick={(option) => createNewDraftWrapper(parseInt(option.id))}
                  className="w-full"
                />}
                {job?.open && <BlueButton
                  title="Publish"
                  onClick={() => setIsShareDialogOpen(true)}
                  className="w-full"
                />}
                {userData?.role === AccountRoles.COMPANY && <RedButton
                  title="Delete"
                  onClick={() => setIsDeletionConfirmOpen(true)}
                  className="w-full"
                />}
              </div>
            </div>

            {job && <JobTabs job={job} refetchJob={fetchJob} />}
          </div>
        </>
        :
        <LoadingInside />
      }
    </Layout>
  )
}

export default CompanyJob
