import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useRecoilValue } from 'recoil'
import { userState } from '@/recoil/atoms/auth'
import { IMFAMethod } from '../../../recoil/types'
import { accountService } from '../../../services'
import BlueButton from '../../buttons/BlueButton'
import { LoadingInside } from '../../layout/top_level/Loading'
import RedButton from '@/components/buttons/RedButton'
import Modal from '@/components/modals/Modal'
import AddMFAMethodForm from './forms/AddMFAMethodForm'
import RemoveMFAMethodForm from './forms/RemoveMFAMethodForm'

const MFASettings = () => {
  const userData = useRecoilValue<any>(userState)
  const [mfaMethods, setMfaMethods] = useState<{ results: IMFAMethod[] }>()
  const [loading, setLoading] = useState(true)
  const [addMFAModalOpen, setAddMFAModalOpen] = useState(false)
  const [removeMFAModalOpen, setRemoveMFAModalOpen] = useState(false)

  const friendlyMethodMap = {
    'email': 'Email',
    'app': 'Authenticator App',
    'yubi': 'YubiKey',
    'sms_api': 'Text Message',
    'sms_twilio': 'Text Message'
  }

  const fetchMfaMethods = useCallback(() => {
    setLoading(true)
    
    accountService.getMFAMethods().then(async (result) => {
      for (const method of result.data.results as IMFAMethod[]) {
        if (method.name === 'email') {
          method.target = userData.email
        } else if (method.name === 'sms_twilio') {
          const numberLast4 = await accountService.getMFAPartialPhone()
          method.target = 'number ending in ' + numberLast4.data.partial_phone
        }
      }
      setMfaMethods(result.data)
      setLoading(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to fetch MFA method')
    })
  }, [userData.email])

  useEffect(() => {
    fetchMfaMethods()
  }, [fetchMfaMethods])

  useEffect(() => {
    if (removeMFAModalOpen && mfaMethods?.results[0]) {
      accountService.requestMFACode(mfaMethods?.results[0].name as 'email' | 'sms_twilio')
    }
  }, [removeMFAModalOpen, mfaMethods])

  const removeMFAMethod = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    if (!mfaMethods || mfaMethods.results.length === 0) {
      toast.error('No MFA method to remove')
      setSubmitting(false)
      return;
    }

    accountService.deactivateMFAMethod(mfaMethods.results[0].name as 'email' | 'sms_twilio', values['code']).then(({success, data}) => {
      if (success) {
        toast.success('Successfully removed MFA method')
      } else {
        console.error(data)
        toast.error('Failed to remove MFA method. Please try again')
      }
      fetchMfaMethods()
      setRemoveMFAModalOpen(false)
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error('Failed to remove MFA method. Please try again')
      setRemoveMFAModalOpen(false)
      setSubmitting(false)
    })
  }

  const confirmMFACode = (values: any, { setSubmitting }: { setSubmitting: Function }) => {
    accountService.confirmMFAMethodActivation(values['method'], values['code']).then(({success, data}) => {
      if (success) {
        toast.success('Successfully added MFA method')
        accountService.mfaSetPrimaryMethod(values['method'], values['code'])
        fetchMfaMethods()
        setAddMFAModalOpen(false)
      } else {
        console.error(data)
        toast.error('Failed to add MFA method. Please request a new code')
      }
      setSubmitting(false)
    }).catch((error) => {
      console.error(error)
      toast.error(`${error}`)
      setSubmitting(false)
    })
  }

  return (
    <>
      <Modal
        title="Add MFA Method"
        open={addMFAModalOpen}
        setOpen={setAddMFAModalOpen}
        hasForm={true}
      >
        <AddMFAMethodForm
          handleSubmit={confirmMFACode}
          onCancel={() => setAddMFAModalOpen(false)}
        />
      </Modal>
      <Modal
        title="Remove MFA Method"
        open={removeMFAModalOpen}
        setOpen={setRemoveMFAModalOpen}
        hasForm={true}
      >
        <RemoveMFAMethodForm
          handleSubmit={removeMFAMethod}
          onCancel={() => setRemoveMFAModalOpen(false)}
        />
      </Modal>
      {loading
        ? <LoadingInside />
        : mfaMethods?.results.length
          ? <>
              <div className="text-sm font-medium text-gray-700">
                Current MFA Method:
              </div>
              <div className="text-sm font-medium text-gray-500">
                {friendlyMethodMap[mfaMethods.results[0].name]} ({mfaMethods.results[0].target})
              </div>
              <RedButton
                type="button"
                title="Remove MFA Method"
                className="mt-4"
                onClick={() => setRemoveMFAModalOpen(true)}
              />
            </>
          : <BlueButton
              type="button"
              title="Add MFA Method"
              onClick={() => setAddMFAModalOpen(true)}
            />
      }
    </>
  )
}

export default MFASettings
