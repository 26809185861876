export interface IListSimplePagination {
  next: string,
  previous?: string
}

export interface IListPagination {
  next: string,
  previous?: string,
  count: number,
  pageSize: number
}

export interface IListItem {
  id: string
}

export interface ILooseService {
    [key: string]: any
}

export interface IListService {
  list: Function
}

export interface IRetrieveService {
  detail: Function
}

export interface IListRetrieveService extends IListService, IRetrieveService  {
}

export interface ICRUDService {
  create: Function
  update: Function
  delete: Function
}

export interface IListCRUDService extends IListRetrieveService, ICRUDService {

}

export interface IInvitationService {
  accept: Function
}

export interface IServiceResult {
  success: boolean,
  data: any
}

export interface IServicePaginatedListResult {
  success: boolean,
  data: {
    results: Array<any>
    next: string
    previous: string
    count: number
  }
}

export function _notImplemented() {
  throw new Error('Action Not Implemented')
}