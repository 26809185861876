// import { Link } from 'react-router-dom'
import { BriefcaseIcon} from '@heroicons/react/solid'
import { ProfileImage } from '../utils'
import { classNames } from '../../lib/helpers'

const ChatPersonInner = ({ person } : any) => {
  return (
    <div className="flex items-center">
      <div className="mr-4 my-auto">
        <ProfileImage image={person.profile_image} size={10} iconClass={'flex-shrink-0'} />
      </div>

      <div className={classNames(
        "truncate",
        person.unread_count > 0 && "font-bold"
      )}>
        <div className="flex text-sm items-center mb-4">
          <p className="md:text-base text-teal-600 truncate">{person.first_name} {person.last_name}</p>
        </div>
        <div className="mt-2 flex">
          <div className="flex items-center text-sm text-gray-500">
              <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              <p className="truncate w-10/12 sm:w-full">
                <span className="truncate">{person.industry}</span>
              </p>
            </div>
        </div>
      </div>
    </div>
  )
}

const ChatPerson = ({ object, onCellClick, activePersonUserId = null }: any) => {
  return (
    <li className={classNames(
      "py-4 px-2 sm:p-4 hover:bg-teal-50",
      object.user_id && object.user_id === activePersonUserId && "border-l-4 bg-teal-50"
    )}>
      <div className="flex items-center space-x-4">
        <div
          className="cursor-pointer min-w-0 flex-1 sm:flex sm:items-center sm:justify-between"
          onClick={() => onCellClick(object)}
         >
          <ChatPersonInner person={object} />
        </div>
      </div>
    </li>
  )
}

export default ChatPerson

export { ChatPerson }
