import axios from 'axios'
import React, { useEffect, useState, FunctionComponent } from 'react'
import { toast } from 'react-toastify'

import { LoadingInside } from '../../layout/top_level/Loading'
import NoResults from '../../layout/list/NoResults'

interface ICarouselListProps {
  noResultsText?: string
  serviceMethod: Function
  itemComponent: FunctionComponent<any>
  resultsMap?: Function | null
}

const CarouselList = function ({
  noResultsText = 'No Results',
  serviceMethod,
  itemComponent,
  resultsMap
}: ICarouselListProps) {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const source = axios.CancelToken.source()
    serviceMethod('', source.token).then((result: any) => {
      if (result.success) {
        if (resultsMap) {
          const data = resultsMap(result.data)
          setItems(data)
        } else {
          setItems(result.data)
        }
        setLoading(false)
      }
    }).catch((error: any) => {
      toast.error(`Error Fetching ${error}`)
      setLoading(false)
    })
    return () => {
      source.cancel()
      setLoading(false)
    }
  }, [setItems, serviceMethod, resultsMap])

  return (
    <div>
      {loading ? <LoadingInside /> :
        <>
          {
            items.length > 0 ?
              <div className="overflow-x-auto scrollbar-hide -ml-3 -mr-3">
                {
                  React.createElement(itemComponent, {
                    objects: items
                  })
                }
              </div>
              :
              <NoResults text={noResultsText} />
          }
        </>
      }
    </div>
  )
}

export default CarouselList
