import { fetch, post } from './base.service'
import * as serverConfig from './server-config'

async function getAccount(cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.payments.account}`
  return await fetch(url, cancelToken)
}

async function createAccount() {
  const url = `${serverConfig.serverURL}${serverConfig.routes.payments.account}`
  return await post(url)
}

async function accountOnboarding() {
  const url = `${serverConfig.serverURL}${serverConfig.routes.payments.accountOnboarding}`
  return await post(url)
}

async function accountLoginLink(cancelToken: any = null) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.payments.accountLoginLink}`
  return await fetch(url, cancelToken)
}

async function subscribe(priceId: string) {
  const url = `${serverConfig.serverURL}${serverConfig.routes.payments.subscribe}`.replace(
    "<price_id>",
    priceId
  )
  return await fetch(url, null)
}

async function unsubscribe() {
  const url = `${serverConfig.serverURL}${serverConfig.routes.payments.unsubscribe}`
  return await post(url)
}

export const paymentService = {
  getAccount,
  createAccount,
  accountOnboarding,
  accountLoginLink,
  subscribe,
  unsubscribe
}